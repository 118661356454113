import { useState, useEffect, useCallback } from "react";
import { RoundedShadowContainer } from "../ui/rounded-shadow-container";
import { PlanBoard } from "./PlanBoard";
import { ToolBox } from "./toolbox/ToolBox";
import { useParams } from "react-router-dom";
import { useGetFloorPlanQuery } from "../../queries/floor-plan.query";
import { FloorPlan } from "../../queries/models/InstallationPlans/floor-plan.model";
import { useQueryClient } from "react-query";
import { useToast } from "../ui/toast-context-provider";
import { useUpdateFloorPlanItemsMutation } from "../../queries/floor-plan.query";
import { UpdateFloorPlanItems } from "../../queries/models/InstallationPlans/update-floor-plan-items.model";
import { AttachedPin, UnattachedPin } from "../ui/DragDropPlan";
import { v4 as uuidv4 } from "uuid";
import { FloorPlanItem } from "../../queries/models/InstallationPlans/floor-plan-item.model";
import { useStateWithChanges } from "../../hooks/useStateWithChanges";
import { NoteSection } from "./note/NoteSection";
import { classNames } from "primereact/utils";
import { AddNoteRequest } from "../../queries/models/add-note-request";
import { useAddNoteMutation } from "../../queries/notes.query";
import { useAddEmptyNoteMutation } from "../../queries/floor-plan.query";
import { useTranslation } from "react-i18next";

export function BuildingPlanPage() {
  const { t } = useTranslation();
  const params = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const floorPlanQuery = useGetFloorPlanQuery(Number(params.id));
  const updateFloorPlanItemsMutation = useUpdateFloorPlanItemsMutation();
  const addEmptyNoteMutation = useAddEmptyNoteMutation();
  const addNoteMutation = useAddNoteMutation();
  const loading = floorPlanQuery.isLoading;
  const [floorPlan, setFloorPlan, changesController] = useStateWithChanges<
    FloorPlan | undefined
  >(undefined, { enableKeyboardShortcuts: true });
  const [noteSectionVisible, setNoteSectionVisible] = useState(false);
  const [selectedItemsGuids, setSelectedItemsGuids] = useState<string[]>([]);

  const handleUnattachItem = useCallback(
    async (pin: AttachedPin | UnattachedPin) => {
      let items = floorPlan?.floorPlanItems;
      if (items) {
        const index = items.findIndex((x) => x.guid === pin.guid);
        if (index !== -1 && items[index]) items[index].isAttached = false;

        setFloorPlan({ ...floorPlan, floorPlanItems: items } as FloorPlan);
      }

      if (selectedItemsGuids.includes(pin.guid ?? "")) {
        setSelectedItemsGuids(selectedItemsGuids.filter((x) => x !== pin.guid));
      }
    },
    [floorPlan, selectedItemsGuids, setFloorPlan]
  );

  useEffect(() => {
    let floorPlanData = floorPlanQuery.data;
    if (floorPlanData) {
      floorPlanData.floorPlanItems =
        floorPlanData?.floorPlanItems?.map((x) => ({ ...x, guid: uuidv4() })) ??
        [];
      setSelectedItemsGuids(
        floorPlanData.floorPlanItems?.map((x) => x.guid!) ?? []
      );
      changesController.initializeState({ ...floorPlanData });
    }
  }, [floorPlanQuery.data, setFloorPlan, changesController]);

  const handleSaveFloorPlan = useCallback(async () => {
    const mutateOptions = {
      onSuccess: async () => {
        toast.current?.show({
          severity: "success",
          detail: t("alert.saveSucces"),
        });
        await queryClient.invalidateQueries([
          "buildingPlanFloorPlans",
          `FloorPlan${Number(params.id)}`,
        ]);
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error?.data,
        });
      },
    };
    if (floorPlan) {
      const request: UpdateFloorPlanItems = {
        floorPlanId: floorPlan.id,
        floorPlanItems:
          floorPlan.floorPlanItems?.map((x) => ({
            id: x.id ?? undefined,
            name: x.name,
            additionalDescription: x.additionalDescription,
            color: x.color,
            textColor: x.textColor,
            isAttached: x.isAttached,
            size: x.size,
            xPosition: x.xPosition,
            yPosition: x.yPosition,
            floorPlanId: x.floorPlanId,
            assetId: x.assetId,
          })) ?? [],
      };

      await updateFloorPlanItemsMutation.mutateAsync(request, mutateOptions);
    }
  }, [
    floorPlan,
    params.id,
    queryClient,
    t,
    toast,
    updateFloorPlanItemsMutation,
  ]);

  const [editableFloorPlanItem, setEditableFloorPlanItem] =
    useState<FloorPlanItem>();

  const handleAddNote = useCallback(
    async (data: AddNoteRequest) => {
      if (floorPlan) {
        let noteId = floorPlan?.noteId;
        const addingNoteItemMutateOptions = {
          onSuccess: async () => {
            toast.current?.show({
              severity: "success",
              detail: t("alert.saveSucces"),
            });
            await queryClient.invalidateQueries(["notes", noteId]);
          },
          onError: async (error: any) => {
            toast.current?.show({
              severity: "error",
              detail: error?.data,
            });
          },
        };

        if (noteId) {
          const request: AddNoteRequest = { ...data, noteId: noteId };
          return addNoteMutation.mutateAsync(
            request,
            addingNoteItemMutateOptions
          );
        } else {
          const addEmptyNoteMutateOptions = {
            onSuccess: async () => {
              await queryClient.invalidateQueries("notes");
              await queryClient.invalidateQueries([
                "buildingPlanFloorPlans",
                `FloorPlan${floorPlan.id}`,
              ]);
            },
            onError: async (error: any) => {
              toast.current?.show({
                severity: "error",
                detail: error?.data,
              });
            },
          };

          noteId = await addEmptyNoteMutation.mutateAsync(
            floorPlan?.id,
            addEmptyNoteMutateOptions
          );

          const request: AddNoteRequest = { ...data, noteId: noteId };

          await addNoteMutation.mutateAsync(
            request,
            addingNoteItemMutateOptions
          );

          await queryClient.invalidateQueries(["notes", noteId]);
        }
      }
    },
    [addEmptyNoteMutation, addNoteMutation, floorPlan, queryClient, t, toast]
  );

  return (
    <div className="md:h-full p-1">
      <RoundedShadowContainer
        fullHeight
        medium
      >
        <div className="flex flex-column md:flex-row md:h-full">
          <div className="w-12 md:w-4 h-full">
            <div className="h-full">
              <div className="p-1 h-full">
                <ToolBox
                  loading={loading}
                  floorPlan={floorPlan}
                  installationId={Number(params.installationId)}
                  setFloorPlan={setFloorPlan}
                  onSaveFloorPlan={handleSaveFloorPlan}
                  editableFloorPlanItem={editableFloorPlanItem}
                  setEditableFloorPlanItem={setEditableFloorPlanItem}
                  setNoteSectionVisible={setNoteSectionVisible}
                  noteSectionVisible={noteSectionVisible}
                  selectedItemsGuids={selectedItemsGuids}
                  setSelectedItemsGuids={setSelectedItemsGuids}
                />
              </div>
            </div>
          </div>
          <div className="w-12 md:w-8 h-full p-1">
            <div className="flex flex-column md:flex-row md:h-full ">
              <div
                className={classNames(
                  "h-full p-1 transition-duration-500",
                  noteSectionVisible ? "w-12 md:w-8" : "w-12"
                )}
              >
                <PlanBoard
                  isLoading={loading}
                  floorPlan={floorPlan}
                  onFloorPlanChange={setFloorPlan}
                  selectedItemsGuids={selectedItemsGuids}
                  setSelectedItemsGuids={setSelectedItemsGuids}
                  onUnattachPin={handleUnattachItem}
                  onClickPin={(pin) => {
                    const matchedItem = floorPlan?.floorPlanItems?.find(
                      (x) => x.guid === pin.guid
                    );
                    setEditableFloorPlanItem(matchedItem);
                  }}
                  changesController={changesController}
                />
              </div>
              <div
                className={classNames(
                  "transition-duration-500 overflow-hidden",
                  noteSectionVisible ? "w-12 md:w-4 h-full p-1" : "w-0"
                )}
              >
                {noteSectionVisible && (
                  <NoteSection
                    noteId={floorPlan?.noteId ?? undefined}
                    onAddNote={handleAddNote}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </RoundedShadowContainer>
    </div>
  );
}
