import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { WorkInstanceTimeEntry } from "../queries/models/work-instance-time-entry.model";
import { CreateWorkInstanceTimeEntry } from "../queries/models/create-work-instance-time-entry.model";
import { UpdateWorkInstanceTimeEntry } from "../queries/models/edit-work-instance-time-entry.model";

class WorkEntriesService extends BaseService {
  async getUserEntries(
    userId: number,
    month: Date
  ): Promise<WorkInstanceTimeEntry[]> {
    const url = `${
      this.endpointPath
    }/overview/${userId}/${month.toISOString()}`;
    return this.http
      .get<WorkInstanceTimeEntry[]>(url)
      .then((response) => response.data);
  }
  async addWorkInstance(request: CreateWorkInstanceTimeEntry) {
    const url = `${this.endpointPath}/work-entry`;
    return this.http.post<any>(url, request).then((response) => response.data);
  }
  async editWorkInstance(request: UpdateWorkInstanceTimeEntry) {
    const url = `${this.endpointPath}/work-entry`;
    return this.http.put<any>(url, request).then((response) => response.data);
  }
  async deleteUser(id: number) {
    const url = `${this.endpointPath}/work-entry/${id}`;
    return this.http.delete(url).then((response) => response.data);
  }
}

export const workEntriesService = new WorkEntriesService(
  API_ENDPOINTS.WORK_ENTRIES
);
