import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { WorkEvent } from "../queries/models/work-event.model";

class WorkEventsService extends BaseService {
    async getWorkEventById(id: number): Promise<WorkEvent> {
        const url = `${this.endpointPath}/${id}`;
        return this.http.get<WorkEvent>(url).then((response) => response.data);
    }
}

export const workEventsService = new WorkEventsService(API_ENDPOINTS.WORK_EVENTS);