function getMobileOperatingSystem(): "Android" | "iOS" | undefined {
  var userAgent: string =
    navigator.userAgent || (navigator as any).vendor || (window as any).opera;
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return "iOS";
  }
  return undefined;
}

export const RedirectMobileDownload = () => {
  const mobileSystem = getMobileOperatingSystem();

  if (mobileSystem === "iOS") {
    window.location.replace(
      "https://apps.apple.com/us/app/enetic-purpleapp/id6462684635"
    );
  } else if (mobileSystem === "Android") {
    window.location.replace(
      "https://play.google.com/store/apps/details?id=com.pcpl.PurpleApp"
    );
  } else {
    window.location.replace(window.location.origin);
  }

  return null;
};
