import "./App.scss";
import { Routes, Route, Navigate } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { MobileUsers } from "./pages/MobileUsers";
import { Login } from "./pages/Authentication/Login";
import { ContentPage } from "./pages/ContentPage";
import { useCallback, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { ToastContextProvider } from "./components/ui/toast-context-provider";
import { OrdersValidatorPage } from "./pages/OrdersValidator/OrdersValidatorPage";
import { useWindowSize } from "./hooks/use-window-size";
import { ForgotPassword } from "./pages/Authentication/ForgotPassword";
import { ResetPassword } from "./pages/Authentication/ResetPassword";
import { SummaryPage } from "./pages/Summary/SummaryPage";
import { MobileOrdersValidatorPage } from "./pages/OrdersValidator/Mobile/MobileOrdersValidatorPage";
import { addLocale } from "primereact/api";
import { InstallationsOverview } from "./pages/Installations/InstallationsOverview";
import { InstallationDetails } from "./pages/Installations/InstallationDetails/InstallationDetails";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ConfirmPopup } from "primereact/confirmpopup";
import { PlanningPage } from "./pages/Planning/PlanningPage";
import { TranslationsPage } from "./pages/Translations/TranslationsPage";
import { MobileDashboard } from "./pages/Dashboard/Mobile/MobileDashboard";
import { MobileSummary } from "./pages/Summary/MobileSummary";
import { WorkInstancesOverview } from "./pages/WorkInstance/WorkInstancesOverview";
import { WorkInstanceDetails } from "./pages/WorkInstance/WorkInstanceDetails";
import { AssetsPage } from "./pages/AllAssets/AssetsPage";
import { AssetDetails } from "./pages/AllAssets/AssetDetails";
import { TagsComponent } from "./pages/Tags/TagsComponent";
import { AssetStickerComponent } from "./pages/AssetSticker/AssetStickerComponent";
import { WorkEntriesPage } from "./pages/WorkEntries/WorkEntriesPage";
import { Roles } from "./pages/Roles/RolesPage";
import { BuildingPlanPage } from "./components/buildingPlan/BuildingPlanPage";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { authService } from "./services/auth.service";
import { AppFeatures } from "./queries/models/enums/app-feature-enum";
import { ProtectedPage } from "./components/navigation/ProtectedPage";
import { Page401 } from "./pages/Authentication/Page401";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useLoggedUser } from "./hooks/useLoggedUser";
import { MobileTagsComponent } from "./pages/Tags/Mobile/MobileTagsComponent";
import { MobileRolesPage } from "./pages/Roles/Mobile/MobileRolesPage";
import { Tooltip } from "primereact/tooltip";
import { Users } from "./pages/Users/UsersPage";
import { registerCustomFilters } from "./utils/dataTableCustomFilters";
import AppVersionChecker from "./components/AppVersionChecker";
import { DARK_THEME } from "./utils/local-storage-keys";
import { Companies } from "./pages/Companies/CompaniesPage";
import { RedirectMobileDownload } from "./pages/RedirectMobileDownload";
import { ConfirmCompanyRegistration } from "./pages/Authentication/ConfirmCompanyRegistration";
import { TasksBoard } from "./pages/TasksBoard/TasksBoard";
import { MobileAssetTypesComponent } from "./pages/AssetTypes/Mobile/MobileAssetTypesComponent";
import { AssetTypesComponent } from "./pages/AssetTypes/AssetTypeComponent";

import { useTranslation } from "react-i18next";
import { useTranslationsForCultureJsonQuery } from "./queries/translations.query";
import { DragItemContextProvider } from "./components/ui/drag-item-conttext-provider";

library.add(fas);

addLocale("en", {
  firstDayOfWeek: 1,
});

registerCustomFilters();

function App() {
  const toast = useRef<Toast>(null);
  const { lg } = useWindowSize();
  const user = useLoggedUser();
  const { i18n } = useTranslation();

  const translationsQuery = useTranslationsForCultureJsonQuery(i18n.language);

  useEffect(() => {
    if (translationsQuery.data) {
      const translations = JSON.parse(translationsQuery.data ?? "");
      i18n.addResourceBundle(i18n.language, "translation", translations);
      i18n.changeLanguage(i18n.language); // triggers reload on all hooks
    }
    // i18n would loop this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationsQuery.data]);

  useEffect(() => {
    let isDarkTheme = localStorage.getItem(DARK_THEME) ?? false;

    if (isDarkTheme === undefined) {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        isDarkTheme = true;
      }
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: light)").matches
      ) {
        isDarkTheme = false;
      }
    }

    if (isDarkTheme) {
      document.body.classList.remove("theme-light");
      document.body.classList.add("theme-dark");
    } else {
      document.body.classList.add("theme-light");
      document.body.classList.remove("theme-dark");
    }
  }, []);

  const defaultRoute = useCallback(() => {
    if (!user) {
      return <Navigate to="/" />;
    } else if (authService.hasAccess(AppFeatures.WebDashboardScreen)) {
      return <Navigate to="dashboard" />;
    } else if (authService.hasAccess(AppFeatures.WebInstallationsScreen)) {
      return <Navigate to="installations" />;
    } else if (authService.hasAccess(AppFeatures.WebUsersScreen)) {
      return <Navigate to="users" />;
    } else if (authService.hasAccess(AppFeatures.WebInvoicingScreen)) {
      return <Navigate to="orders-validator" />;
    } else if (authService.hasAccess(AppFeatures.WebPlanningScreen)) {
      return <Navigate to="planning" />;
    } else if (authService.hasAccess(AppFeatures.WebSummaryScreen)) {
      return <Navigate to="summary" />;
    } else if (authService.hasAccess(AppFeatures.WebTranslationsScreen)) {
      return <Navigate to="translations" />;
    } else if (authService.hasAccess(AppFeatures.WebAssetsScreen)) {
      return <Navigate to="assets" />;
    } else if (authService.hasAccess(AppFeatures.WebWorkInstancesScreen)) {
      return <Navigate to="work-instances" />;
    } else if (authService.hasAccess(AppFeatures.WebTagsScreen)) {
      return <Navigate to="tags" />;
    } else if (authService.hasAccess(AppFeatures.WebAssetStickerScreen)) {
      return <Navigate to="asset-sticker" />;
    } else if (authService.hasAccess(AppFeatures.WebWorkEntriesScreen)) {
      return <Navigate to="work-entries" />;
    } else if (authService.hasAccess(AppFeatures.WebRolesScreen)) {
      return <Navigate to="roles" />;
    } else if (authService.hasAccess(AppFeatures.WebCompaniesScreen)) {
      return <Navigate to="companies" />;
    } else {
      return <Navigate to="/401" />;
    }
  }, [user]);

  return (
    <div className="h-full">
      {
        /* Prime tooltip doesn't detect menu appearing with items that match "target". 
        Initialize it only after menu appeared, that is after user logged in. */
        user && <Tooltip target="[data-pr-tooltip].tooltip-default" />
      }
      <ConfirmDialog />
      <ConfirmPopup />
      <Toast ref={toast} />
      <AppVersionChecker />
      <ToastContextProvider toastRef={toast}>
        <DndProvider backend={lg ? HTML5Backend : TouchBackend}>
          <DragItemContextProvider>
            <Routes>
              <Route
                path="/"
                element={<Login />}
              />
              <Route
                path="/forgotpassword"
                element={<ForgotPassword />}
              />
              <Route
                path="/ResetPassword"
                element={<ResetPassword />}
              />
              <Route
                path="/ConfirmCompanyRegistration"
                element={<ConfirmCompanyRegistration />}
              />
              <Route
                path="/401"
                element={<Page401 />}
              />
              <Route
                path="/mobileDownload"
                element={<RedirectMobileDownload />}
              />
              <Route
                path="app"
                element={<ContentPage />}
              >
                <Route
                  path=""
                  element={defaultRoute()}
                />
                <Route
                  path="dashboard"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebDashboardScreen
                      )}
                    >
                      {lg ? <Dashboard /> : <MobileDashboard />}
                    </ProtectedPage>
                  }
                />
                <Route
                  path="installations"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebInstallationsScreen
                      )}
                    >
                      <InstallationsOverview />
                    </ProtectedPage>
                  }
                />
                <Route
                  path="installations/details/:id?"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebInstallationsScreen
                      )}
                    >
                      <InstallationDetails />
                    </ProtectedPage>
                  }
                />
                <Route
                  path="building-plan/:id/:installationId"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebInstallationsScreen
                      )}
                    >
                      <BuildingPlanPage />
                    </ProtectedPage>
                  }
                />
                <Route
                  path="users"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebUsersScreen
                      )}
                    >
                      {lg ? <Users /> : <MobileUsers />}
                    </ProtectedPage>
                  }
                />
                <Route
                  path="orders-validator"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebInvoicingScreen
                      )}
                    >
                      {lg ? (
                        <OrdersValidatorPage />
                      ) : (
                        <MobileOrdersValidatorPage />
                      )}
                    </ProtectedPage>
                  }
                />
                <Route
                  path="planning"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebPlanningScreen
                      )}
                    >
                      <PlanningPage />
                    </ProtectedPage>
                  }
                />
                <Route
                  path="summary"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebSummaryScreen
                      )}
                    >
                      {lg ? <SummaryPage /> : <MobileSummary />}
                    </ProtectedPage>
                  }
                />
                <Route
                  path="translations"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebTranslationsScreen
                      )}
                    >
                      <TranslationsPage />
                    </ProtectedPage>
                  }
                />
                <Route
                  path="assets"
                  element={<AssetsPage />}
                />
                <Route
                  path="assets/details/:id?"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebAssetsScreen
                      )}
                    >
                      <AssetDetails />
                    </ProtectedPage>
                  }
                />
                <Route
                  path="work-instances"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebWorkInstancesScreen
                      )}
                    >
                      <WorkInstancesOverview />
                    </ProtectedPage>
                  }
                />
                <Route
                  path="work-instances/details/:id"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebWorkInstancesScreen
                      )}
                    >
                      <WorkInstanceDetails />
                    </ProtectedPage>
                  }
                />
                <Route
                  path="tags"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebTagsScreen
                      )}
                    >
                      {lg ? <TagsComponent /> : <MobileTagsComponent />}
                    </ProtectedPage>
                  }
                />
                <Route
                  path="asset-types"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebAssetsScreen
                      )}
                    >
                      {lg ? (
                        <AssetTypesComponent />
                      ) : (
                        <MobileAssetTypesComponent />
                      )}
                    </ProtectedPage>
                  }
                />
                <Route
                  path="asset-sticker"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebAssetStickerScreen
                      )}
                    >
                      <AssetStickerComponent />
                    </ProtectedPage>
                  }
                />
                <Route
                  path="work-entries"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebWorkEntriesScreen
                      )}
                    >
                      <WorkEntriesPage />
                    </ProtectedPage>
                  }
                />
                <Route
                  path="roles"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebRolesScreen
                      )}
                    >
                      {lg ? <Roles /> : <MobileRolesPage />}
                    </ProtectedPage>
                  }
                />
                <Route
                  path="companies"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebCompaniesScreen
                      )}
                    >
                      <Companies />
                    </ProtectedPage>
                  }
                />
                <Route
                  path="tasks-board"
                  element={
                    <ProtectedPage
                      isAllowed={authService.hasAccess(
                        AppFeatures.WebKanbanBoardScreen
                      )}
                    >
                      <TasksBoard />
                    </ProtectedPage>
                  }
                />
              </Route>
            </Routes>
          </DragItemContextProvider>
        </DndProvider>
      </ToastContextProvider>
    </div>
  );
}

export default App;
