import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

export function AddNewTaskButton({ onClick }: { onClick: () => void }) {
  return (
    <motion.div
      whileTap={{ scale: 0.9 }}
      className="surface-400 border-round cursor-pointer text-white h-2rem min-w-max px-2 flex align-items-center justify-content-center"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faPlus} />
      <span className="ml-1 select-none">New item</span>
    </motion.div>
  );
}
