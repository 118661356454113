import { useQuery } from "react-query";
import { ReportSummaryItem } from "./models/report-summary-witem.model";
import { reportingService } from "../services/reporting.service";

async function fetchReportSummary(
  from: Date | undefined,
  to: Date | undefined
) {
  if (!from || !to) {
    return [];
  } else {
    return await reportingService.getSummary(from, to);
  }
}

export function useReportSummaryQuery(
  from: Date | undefined,
  to: Date | undefined
) {
  return useQuery<ReportSummaryItem[]>(
    ["summaryReport", from, to],
    () => fetchReportSummary(from, to),
    { refetchOnWindowFocus: false }
  );
}
