import { format } from "date-fns";
import { classNames } from "primereact/utils";
import { Fragment, useMemo } from "react";
import { Tooltip } from "primereact/tooltip";
import { AppFeatures } from "../../../queries/models/enums/app-feature-enum";
import { CalendarEntry, GroupedCalendarEntry } from "./calendar-entry";

interface CalendarDayProps {
  day: Date;
  entries: CalendarEntry[];
  notWorkingDay?: boolean;
  holiday?: boolean;
  dayStyle?: string;
  showUserOnly?: boolean;
  warnMissingMobile?: boolean;
  groupEntriesByName?: boolean;
  dayHeader?: (entries: CalendarEntry[]) => JSX.Element;
}

export function CalendarDay({
  day,
  entries,
  groupEntriesByName = false,
  notWorkingDay = false,
  holiday = false,
  showUserOnly = false,
  warnMissingMobile = false,
  dayStyle = "w-full text-right text-2xl font-bold",
  dayHeader,
}: CalendarDayProps) {
  const dayEntries = useMemo(() => {
    if (groupEntriesByName) {
      const itemsWithCount: GroupedCalendarEntry[] = [];

      entries.forEach((entry) => {
        const existingEntry = itemsWithCount.find(
          (item) =>
            item.title === entry.title &&
            item.subtitle === entry.subtitle &&
            item.username === entry.username &&
            item.workInstanceId === entry.workInstanceId
        );

        if (existingEntry) {
          existingEntry.count += 1;
        } else {
          let newEntry: GroupedCalendarEntry = {
            ...entry,
            count: 1,
          };
          itemsWithCount.push(newEntry);
        }
      });
      return itemsWithCount;
    } else {
      return entries;
    }
  }, [entries, groupEntriesByName]);

  return (
    <>
      <div className="">
        {entries.length > 0 && !showUserOnly && (
          <div className="flex mb-3">
            {dayHeader && dayHeader(entries)}
            <div className={classNames(dayStyle)}>{format(day, "dd")}</div>
          </div>
        )}
        {entries.length > 0 && showUserOnly && (
          <>
            <div className={classNames(dayStyle)}>{format(day, "dd")}</div>
            <div>
              {Array.from(new Set(entries.map((entry) => entry.username))).map(
                (entry, key) => (
                  <div
                    className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                    key={key}
                  >
                    {entry}
                  </div>
                )
              )}
            </div>
          </>
        )}
        {entries.length <= 0 && (
          <div>
            <div className={classNames(dayStyle)}>{format(day, "dd")}</div>
          </div>
        )}
        {dayEntries.map((entry, key) => (
          <Fragment key={key}>
            <Tooltip target={"#target-missing-mobile-" + entry.workInstanceId}>
              Missing mobile role
            </Tooltip>
            <div className="mb-1">
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "90%",
                  color: "white",
                }}
              >
                {entry.title && (
                  <span
                    className="font-medium shadow-1"
                    style={{
                      backgroundColor: "var(--primary-color)",
                      borderRadius: "7px 7px 0px 0px",
                      padding: "0px 5px 0px 5px",
                      color: "white",
                    }}
                  >
                    {warnMissingMobile &&
                      !entry.user?.role?.appFeatures.find(
                        (x) => x === AppFeatures.MobileInstallationWorkAccess
                      ) && (
                        <span
                          className="text-red-400"
                          id={"target-missing-mobile-" + entry.workInstanceId}
                        >
                          {"! "}
                        </span>
                      )}
                    {entry.title}
                  </span>
                )}
              </div>
              {entry.subtitle && (
                <div
                  className="shadow-1 white-space-nowrap"
                  style={{
                    backgroundColor: "#ECECEC",
                    color: entry.textColor ? entry.textColor : "black",
                    borderRadius: "0px 7px 0px 0px",
                    padding: "0px 5px 5px 5px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {entry.subtitle}{" "}
                  {"count" in entry && (entry.count as number) > 1
                    ? `(${entry.count}x)`
                    : ""}
                </div>
              )}
              {entry.indicatorColor && (
                <div
                  className="shadow-1"
                  style={{
                    backgroundColor: entry.indicatorColor,
                    height: "5px",
                  }}
                ></div>
              )}
            </div>
          </Fragment>
        ))}
      </div>
    </>
  );
}
