import {
  FileUpload,
  FileUploadHeaderTemplateOptions,
  FileUploadRemoveEvent,
  FileUploadSelectEvent,
  FileUploadUploadEvent,
  FileUploadProps as ImageFileUploadProps,
  ItemTemplateOptions,
} from "primereact/fileupload";
import { FormInputLabel } from "./FormInputLabel";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  ErrorMessage,
  FastField,
  Field,
  FieldInputProps,
  FieldMetaProps,
  FormikContextType,
} from "formik";
import TextError from "./TextError";
import { FileInfo } from "../../../utils/file-info";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Tooltip } from "primereact/tooltip";
import * as Yup from "yup";

export interface FormikImageFileUploadProps extends ImageFileUploadProps {
  label: string;
  name: string;
  validationSchema?: Yup.ObjectSchema<any, Yup.AnyObject, any, "">;
  isIndependent?: boolean;
  imageOnly?: boolean;
  previewWidth?: number;
}

function FormikImageFileUpload({
  label,
  name,
  isIndependent = false,
  validationSchema,
  maxFileSize = 25000000, //25MB
  multiple = true,
  imageOnly = false,
  previewWidth = 80,
  ...rest
}: FormikImageFileUploadProps) {
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef<FileUpload>(null);
  const beforeSelectSingleFileEventRef = useRef<FileUploadSelectEvent>();

  const onTemplateSelect = useCallback(
    async (e: FileUploadSelectEvent, form: FormikContextType<any>) => {
      let _totalSize = 0;
      let files = e.files;

      let fileInfos = [] as FileInfo[];

      for (let file of files) {
        _totalSize += file.size || 0;

        const reader = new FileReader();

        fileInfos.push({
          value: reader.result,
          name: file.name,
          size: file.size,
          blob: file,
        } as FileInfo);
      }

      setTotalSize(_totalSize);

      form.setFieldValue(name, multiple ? fileInfos : fileInfos[0]);
    },
    [multiple, name]
  );

  const onTemplateUpload = useCallback((e: FileUploadUploadEvent) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
  }, []);

  const onTemplateRemove = useCallback(
    (e: FileUploadRemoveEvent, form: FormikContextType<any>) => {
      if (!multiple) {
        setTotalSize(0);
        form.setFieldValue(name, undefined);
        return;
      } else {
        setTotalSize(totalSize - e.file.size);

        const lastValue = (form.values[name] as FileInfo[]) ?? [];
        const newValue = lastValue.filter((x) =>
          x.blob ? x.blob !== e.file : x.name !== e.file.name
        );
        form.setFieldValue(name, newValue);
      }
    },
    [name, totalSize, multiple]
  );

  const onTemplateClear = useCallback(() => {
    setTotalSize(0);
  }, []);

  const headerTemplate = useCallback(
    (options: FileUploadHeaderTemplateOptions) => {
      const { className, chooseButton, cancelButton } = options;
      const value = totalSize / 250000;
      const formatedValue =
        fileUploadRef && fileUploadRef.current
          ? fileUploadRef.current.formatSize(totalSize)
          : "0 B";

      return (
        <div
          className={className}
          style={{
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
          }}
        >
          {chooseButton}
          <div className="flex align-items-center gap-3 ml-auto">
            <span>
              {formatedValue} / {Math.round((maxFileSize / 1000000) * 10) / 10}{" "}
              MB
            </span>
            <ProgressBar
              value={value}
              showValue={false}
              style={{ width: "10rem", height: "12px" }}
            ></ProgressBar>
          </div>
          {cancelButton}
        </div>
      );
    },
    [maxFileSize, totalSize]
  );

  const itemTemplate = useCallback(
    (fileArg: object, props: ItemTemplateOptions) => {
      const file: File = fileArg as File;

      return (
        <div className="flex align-items-center flex-wrap">
          <div
            className="flex align-items-center"
            style={{ width: "80%" }}
          >
            {file.type.includes("image") && (
              <img
                alt={file.name}
                role="presentation"
                src={
                  "objectURL" in file && typeof file.objectURL === "string"
                    ? file.objectURL
                    : undefined
                }
                width={previewWidth}
              />
            )}
            <div className="flex gap-2 justify-content-center align-items-center">
              <div className="flex flex-column text-left ml-3">
                {file.name}
                <small>{new Date().toLocaleDateString()}</small>
              </div>
              <div className="w-2rem">
                <Button
                  type="button"
                  icon="pi pi-times"
                  className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                  onClick={props.onRemove}
                />
              </div>
            </div>
          </div>
        </div>
      );
    },
    [previewWidth]
  );

  const emptyTemplate = useCallback(() => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image p-4"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-1"
        >
          Drag and Drop Files Here
        </span>
      </div>
    );
  }, []);

  const Component = useMemo(() => {
    return isIndependent ? FastField : Field;
  }, [isIndependent]);

  const chooseOptions = useMemo(
    () => ({
      icon: "pi pi-fw pi-plus",
      iconOnly: true,
      className: "custom-choose-btn p-button-rounded p-button-outlined",
    }),
    []
  );

  return (
    <>
      <FormInputLabel
        nameFor={name}
        validationSchema={validationSchema}
      >
        {label}
      </FormInputLabel>
      <Component name={name}>
        {({
          form,
          field,
          meta,
        }: {
          form: FormikContextType<any>;
          field: FieldInputProps<any>;
          meta: FieldMetaProps<string>;
        }) => {
          return (
            <div className="flex flex-row">
              <Tooltip
                target=".custom-choose-btn"
                content="Choose"
                position="bottom"
              />
              <Tooltip
                target=".custom-upload-btn"
                content="Upload"
                position="bottom"
              />
              <Tooltip
                target=".custom-cancel-btn"
                content="Clear"
                position="bottom"
              />

              <FileUpload
                id={name}
                chooseLabel={(field.value as FileInfo)?.name || "Choose"}
                maxFileSize={maxFileSize} //25MB
                customUpload
                auto
                name={name}
                url="/api/upload"
                ref={fileUploadRef}
                multiple={multiple}
                onUpload={onTemplateUpload}
                onBeforeSelect={(x) => {
                  if (multiple) return;
                  beforeSelectSingleFileEventRef.current = x;
                }}
                onSelect={(x) => {
                  if (!multiple && !x.files.length) {
                    // fix for component removing file on sigle selection of already selected file
                    fileUploadRef.current?.setFiles(
                      beforeSelectSingleFileEventRef.current?.files ?? []
                    );
                  } else {
                    onTemplateSelect(x, form);
                  }
                }}
                onRemove={(x) => onTemplateRemove(x, form)}
                onError={onTemplateClear}
                onClear={onTemplateClear}
                headerTemplate={headerTemplate}
                itemTemplate={itemTemplate}
                emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions}
                accept={imageOnly ? "image/*" : undefined}
                {...rest}
              />
            </div>
          );
        }}
      </Component>
      <ErrorMessage
        component={TextError}
        name={name}
      />
    </>
  );
}

export default FormikImageFileUpload;
