import { useState, useMemo, useCallback } from "react";
import { WorkTask } from "../../../queries/models/work-task.model";
import { BoardColumnConfig } from "./board-column-config.type";
import { BoardColumnHeaderElement } from "./BoardColumnHeaderElement";
import { classNames } from "primereact/utils";
import { AddNewTaskButton } from "./AddNewTaskButton";
import { motion } from "framer-motion";
import { WorkTaskElement } from "../WorkTaskElement/WorkTaskElement";
import { useDrop } from "react-dnd";
import { DraggableItemTypes } from "../../../constants/draggable-item-types.constants";
import { useUpdateTaskStateMutation } from "../../../queries/work-tasks.query";
import { useToast } from "../../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import { UpdateWorkTaskState } from "../../../queries/models/update-work-task-state.model";
import { useTranslation } from "react-i18next";
import { TaskStateToTranslationKey } from "../../../utils/task-state.conversions";
import { BoardWorkTask } from "../../../queries/models/board-work-task.model";

interface Props {
  items: BoardWorkTask[];
  config: BoardColumnConfig;
  setSelectedWorkingTask: (task?: WorkTask) => void;
  onItemOver: (item: WorkTask) => void;
  onItemDragging: (item: WorkTask, isDragging: boolean) => void;
  onItemReorder: (item: WorkTask, newIndex: number) => void;
  onRevertDrag: (item: WorkTask) => void;
  onItemsSequenceChange: () => void;
}

export function BoardColumnElement({
  items,
  config,
  setSelectedWorkingTask,
  onItemOver,
  onItemDragging,
  onItemReorder,
  onRevertDrag,
  onItemsSequenceChange,
}: Props) {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [expanded, setExpanded] = useState(true);

  const updateWorkTaskStateMutation = useUpdateTaskStateMutation();

  const handleItemDropped = useCallback(
    async (item: BoardWorkTask) => {
      onItemDragging(item, false);

      onItemsSequenceChange();

      if (item.initState !== config.stateRepresenting) {
        const mutateOptions = {
          onSuccess: async () => {
            await queryClient.invalidateQueries(["work-tasks"]);
          },
          onError: async (error: any) => {
            toast.current?.show({
              severity: "error",
              detail: error?.data,
            });
          },
        };

        const request: UpdateWorkTaskState = {
          workTaskId: item.id ?? 0,
          state: config.stateRepresenting,
        };

        await updateWorkTaskStateMutation.mutateAsync(request, mutateOptions);
      }
    },
    [
      config.stateRepresenting,
      onItemDragging,
      onItemsSequenceChange,
      queryClient,
      toast,
      updateWorkTaskStateMutation,
    ]
  );

  const orderedItems = useMemo(() => {
    const result = items.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
    return result;
  }, [items]);

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DraggableItemTypes.WORK_TASK_CARD,
      drop: (item, monitor) => {
        handleItemDropped(item as WorkTask);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
      hover(item: any, monitor) {
        onItemOver(item);
      },
    }),
    [items]
  );

  const columnHeader = useMemo(
    () =>
      t(TaskStateToTranslationKey[config.stateRepresenting]) +
      (config.showTasksCount ? ` (${items.length})` : ""),

    [config.showTasksCount, config.stateRepresenting, items.length, t]
  );

  return (
    <div
      className={classNames(
        "h-full flex flex-column surface-200 border-round-xl transition-duration-500",
        expanded ? "w-full" : "w-3rem",
        isOver ? "surface-400" : "surface-200"
      )}
    >
      <BoardColumnHeaderElement
        name={columnHeader}
        color={config.color}
        expanded={expanded}
        setExpanded={setExpanded}
        isOver={isOver}
      />
      {expanded ? (
        <div className="h-full p-1 border-round-bottom-xl">
          <div className="h-full flex flex-column">
            <div className="flex gap-2 p-1">
              {config.showAddNewItemButton && (
                <AddNewTaskButton
                  onClick={() =>
                    setSelectedWorkingTask?.({ name: "" } as WorkTask)
                  }
                />
              )}
            </div>
            <div
              ref={drop}
              className="p-1 h-full overflow-x-hidden overflow-y-auto mb-5"
              style={{ width: "100%" }}
            >
              {orderedItems.map((x, i) => (
                <div
                  key={x.id}
                  className="py-1"
                >
                  <WorkTaskElement
                    index={x.index!}
                    workTask={x}
                    onEditClick={() => setSelectedWorkingTask(x)}
                    onIsDragging={(isDragging: boolean) => {
                      onItemDragging(x, isDragging);
                    }}
                    onReorder={onItemReorder}
                    onRevertDrag={onRevertDrag}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <>
          {Array.from(columnHeader.replace(/[()]/g, "")).map((x, i) => (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, rotate: 90 }}
              transition={{ duration: 0.25, delay: i / 15 }}
              className="text-center text-lg font-bold"
              style={{ whiteSpace: "pre" }}
              key={i}
            >
              {x}
            </motion.div>
          ))}
        </>
      )}
    </div>
  );
}
