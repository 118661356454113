import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import QRCode from "react-qr-code";
import { useZxing } from "react-zxing";
import { useWindowSize } from "../../hooks/use-window-size";

interface QrCodeReaderProps {
  qrCode: string | undefined;
  setQrCode: (code: string | undefined) => void;
}

export function QrCodeReader({
  qrCode: newQrCode,
  setQrCode: setNewQrCode,
}: QrCodeReaderProps) {
  const { lg } = useWindowSize();
  const { ref } = useZxing({
    onDecodeResult(result) {
      const value = result.getText();
      if (value !== newQrCode) setNewQrCode(value);
    },
  });

  return (
    <>
      <div className="p-1 surface-200 border-round">
        <div className="flex justify-content-end p-1">
          <Button
            className="delete-button w-10rem"
            label="Delete code"
            disabled={!newQrCode}
            onClick={() => setNewQrCode(undefined)}
          />
        </div>

        <div className="grid m-0">
          <div className={lg ? "col-6" : "col-12"}>
            <div
              style={{
                position: "relative",
              }}
            >
              <video
                id="video"
                width="100%"
                height="100%"
                ref={ref}
              />
              <div
                style={{
                  height: "80%",
                  width: "80%",
                  position: "absolute",
                  top: "10%",
                  left: "10%",
                  right: "0",
                  bottom: "0",
                  border: "5px solid #4CAF50AA",
                  pointerEvents: "none",
                }}
              ></div>
            </div>
          </div>
          <div
            className={classNames(
              "flex justify-content-center align-items-center",
              lg ? "col-6" : "col-12"
            )}
          >
            {newQrCode ? (
              <div>
                <QRCode value={newQrCode} />
                <div className="text-center">{newQrCode}</div>
              </div>
            ) : (
              <div className="text-500 text-xl font-bold">Please scan code</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
