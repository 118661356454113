import { useMutation, useQuery, UseQueryOptions } from "react-query";
import { CoordinateModel } from "./models/coordinate-model";
import { GeocodeResult } from "./models/geocode-result.model";
import { geolocationService } from "../services/geolocation.service";

export function useGeolocationQuery(
  country: string,
  city: string,
  street: string,
  shouldFetch: boolean
) {
  const queryOptions: UseQueryOptions<CoordinateModel> = {
    enabled: shouldFetch,
  };

  return useQuery<CoordinateModel>(
    ["geolocation", country, city, street],
    () => geolocationService.getCoordinateModel(country, city, street),
    queryOptions
  );
}

export function useGeolocationFeedbackQuery(
  country: string,
  city: string,
  street: string,
  shouldFetch: boolean
) {
  const queryOptions: UseQueryOptions<GeocodeResult> = {
    enabled: shouldFetch,
  };
  return useQuery<GeocodeResult>(
    ["geolocation-feedback", country, city, street],
    () => geolocationService.getGeolocationFeedback(country, city, street),
    queryOptions
  );
}

export function useGeolocationFeedbackMutation() {
  return useMutation(
    (data: { country: string; city: string; street: string }) =>
      geolocationService.getGeolocationFeedback(
        data.country,
        data.city,
        data.street
      )
  );
}
