import { Dispatch, SetStateAction, memo } from "react";
import { FloorplanImageSettings } from "./FloorplanAsImageGenerator";
import { InputNumber } from "primereact/inputnumber";
import { Slider } from "primereact/slider";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

type ExportSettingsConfigurationOption = {
  [k in keyof FloorplanImageSettings]-?: {
    setting: k;
    label: string;
    defaultValue: FloorplanImageSettings[k];
    editor: "numberInput" | "numberSlider";
    /** [min, max, step] */
    range?: number extends FloorplanImageSettings[k]
      ?
          | [number | undefined, number | undefined]
          | [number | undefined, number | undefined, number | undefined]
      : never;
    unit?: string;
  };
}[keyof FloorplanImageSettings];

const exportSettingsConfigurationOptions: ExportSettingsConfigurationOption[] =
  [
    {
      setting: "imageMargin",
      label: "common.imageMargin",
      defaultValue: 50,
      editor: "numberInput",
      range: [0, 500],
      unit: "px",
    },
    {
      setting: "textFontSize",
      label: "common.fontSize",
      defaultValue: 24,
      editor: "numberInput",
      range: [1, 99],
      unit: "px",
    },
    {
      setting: "imageScale",
      label: "common.imageScale",
      defaultValue: 1,
      editor: "numberInput",
      range: [0.1, 5, 0.1],
    },
    {
      setting: "colorFillingOpacity",
      label: "common.colorFillingOpacity",
      defaultValue: 1,
      editor: "numberSlider",
      range: [0, 1, 0.1],
    },
  ];

export const ExportSettingsConfiguration = memo(
  ({
    settings,
    setSettings,
  }: {
    settings: FloorplanImageSettings;
    setSettings: Dispatch<SetStateAction<FloorplanImageSettings>>;
  }) => {
    const { t } = useTranslation();

    return (
      <>
        {exportSettingsConfigurationOptions.map((configuration, i) => {
          return (
            <div
              className="flex flex-row align-items-center"
              key={i}
            >
              {t(configuration.label)}
              {configuration.editor === "numberInput" ? (
                <InputNumber
                  className="ml-2"
                  style={{
                    width: 100,
                  }}
                  value={settings[configuration.setting]}
                  onChange={(e) => {
                    if (e.value === null) return;
                    let v = e.value;
                    const [min, max] = configuration.range ?? [];
                    if (min !== undefined && v < min) v = min;
                    if (max !== undefined && v > max) v = max;
                    setSettings((x) => {
                      return {
                        ...x,
                        [configuration.setting]: v,
                      };
                    });
                  }}
                  min={configuration.range?.[0]}
                  max={configuration.range?.[1]}
                  step={configuration.range?.[2] ?? 1}
                />
              ) : configuration.editor === "numberSlider" ? (
                <div
                  className="ml-2"
                  style={{ minWidth: 100 }}
                >
                  <Slider
                    className="mx-2"
                    value={settings[configuration.setting]}
                    onChange={(e) => {
                      if (e.value === null) return;
                      let v = Array.isArray(e.value) ? e.value[0] : e.value;
                      const [min, max] = configuration.range ?? [];
                      if (min !== undefined && v < min) v = min;
                      if (max !== undefined && v > max) v = max;
                      setSettings((x) => {
                        return {
                          ...x,
                          [configuration.setting]: v,
                        };
                      });
                    }}
                    min={configuration.range?.[0]}
                    max={configuration.range?.[1]}
                    step={configuration.range?.[2] ?? 1}
                  />
                </div>
              ) : null}
              {configuration.unit}
              {configuration.defaultValue !== undefined && (
                <Button
                  className="ml-2"
                  label={t("common.reset")}
                  disabled={
                    settings[configuration.setting] ===
                    configuration.defaultValue
                  }
                  onClick={() =>
                    setSettings((x) => {
                      return {
                        ...x,
                        [configuration.setting]: configuration.defaultValue,
                      };
                    })
                  }
                />
              )}
            </div>
          );
        })}
      </>
    );
  }
);
