import { useEffect } from "react";
import { APP_VERSION } from "../utils/local-storage-keys";
import { useWebVersionQuery } from "../queries/web-version.query";

const AppVersionChecker = () => {
  const localAppVersion = parseInt(localStorage.getItem(APP_VERSION) ?? "0");

  const { data } = useWebVersionQuery(localAppVersion);

  useEffect(() => {
    const checkAppVersion = async () => {
      if (data !== localAppVersion) {
        localStorage.setItem(APP_VERSION, data?.toString() ?? "0");
        const registrations = await navigator.serviceWorker.getRegistrations();
        const names = await caches.keys();
        await Promise.all([
          ...names.map((name) => caches.delete(name)),
          ...registrations.map((registration) => registration.unregister()),
        ]);
        window.location.reload();
      }
    };

    checkAppVersion();
  }, [data, localAppVersion]);

  return null;
};

export default AppVersionChecker;
