import { useCallback, useEffect, useMemo, useState } from "react";
import { useLoginMutation } from "../../queries/models/_shared/auth.query";
import { authService } from "../../services/auth.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HttpStatusCode } from "axios";
import { useToast } from "../../components/ui/toast-context-provider";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { useWindowSize } from "../../hooks/use-window-size";
import { LoginDesktop } from "../../components/ui/Login/LoginDesktop";
import { LoginMobile } from "../../components/ui/Login/LoginMobile";
import { useTranslation } from "react-i18next";

export function Login() {
  const { i18n } = useTranslation();
  const [loginError, setLoginError] = useState<string>();

  const { lg } = useWindowSize();
  const [queryParameters] = useSearchParams();
  const registerMode = queryParameters.get("register");

  const loginMutation = useLoginMutation();
  const navigate = useNavigate();
  const toast = useToast();

  const [isLoginVisible, setIsLoginVisible] = useState<boolean>(true);
  const [isRegistrationVisible, setIsRegistrationVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (registerMode === "true") {
      setIsLoginVisible(false);
      setIsRegistrationVisible(true);
    } else {
      setIsLoginVisible(true);
      setIsRegistrationVisible(false);
    }
  }, [registerMode]);

  function navigateToForgotPassword() {
    navigate("forgotpassword");
  }

  function executeLogin(
    username: string | undefined,
    password: string | undefined
  ) {
    loginMutation.mutate(
      {
        username: username!,
        password: password!,
      },
      {
        onSuccess: (response) => {
          if (
            response.user.role.appFeatures.includes(AppFeatures.WebAccess) ===
            false
          ) {
            toast.current?.show({
              severity: "warn",
              detail: "You don't have access to this application.",
            });
            return;
          }

          authService.setLoggedUser({
            accessToken: response.token,
            id: response.user.id,
            appFeatures: response.user.role.appFeatures,
            username: response.user.username,
            contactEmail: response.user.contactEmail,
            defaultLanguage: response.user.defaultLanguage,
            companyId: response.user.companyId ?? 0,
            companyName: response.user.company?.name ?? "N/A",
            companyActive: response.user.company?.isActive ?? false,
            companyOdooIntegrated:
              response.user.company?.isOdooIntegrated ?? false,
          });

          i18n.changeLanguage(response.user.defaultLanguage ?? "en");

          navigate("app");
        },
        onError: (error: any) => {
          if (error.status === HttpStatusCode.Conflict)
            setLoginError(error?.data);

          console.error(error);
        },
      }
    );
  }

  function toggleMode() {
    setIsLoginVisible(!isLoginVisible);
    setIsRegistrationVisible(!isRegistrationVisible);
  }

  return lg ? (
    <LoginDesktop
      executeLogin={executeLogin}
      isVisible={isLoginVisible}
      setIsVisible={setIsLoginVisible}
      navigateToForgotPassword={navigateToForgotPassword}
      loginError={loginError}
      setLoginError={setLoginError}
      signUp={toggleMode}
    />
  ) : (
    <LoginMobile
      executeLogin={executeLogin}
      isVisible={isLoginVisible}
      setIsVisible={setIsLoginVisible}
      navigateToForgotPassword={navigateToForgotPassword}
      loginError={loginError}
      setLoginError={setLoginError}
      signUp={toggleMode}
    />
  );
}
