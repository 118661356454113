import { BaseService } from "./base.service";
import { WorkTask } from "../queries/models/work-task.model";
import { API_ENDPOINTS } from "../utils/endpoints";
import { CreateOrUpdateWorkTask } from "../queries/models/create-or-update-work-task";
import { UpdateWorkTaskState } from "../queries/models/update-work-task-state.model";
import { UpdateWorkTaskName } from "../queries/models/update-work-task-name.model";
import { BoardWorkTask } from "../queries/models/board-work-task.model";
import { UpdateWorkTasksIndexesRequest } from "../queries/models/update-work-tasks-indexes-request";

class WorkTasksService extends BaseService {
  async getActiveBoardWorkTasks(): Promise<BoardWorkTask[]> {
    const url = `${this.endpointPath}`;
    return this.http.get<WorkTask[]>(url).then((response) => response.data);
  }

  async addWorkTask(request: CreateOrUpdateWorkTask): Promise<number> {
    const url = `${this.endpointPath}`;
    return this.http
      .post<number>(url, request)
      .then((response) => response.data);
  }

  async editWorkTask(request: CreateOrUpdateWorkTask) {
    const url = `${this.endpointPath}`;
    return this.http
      .put<CreateOrUpdateWorkTask>(url, request)
      .then((response) => response.data);
  }

  async updateWorkTasksIndexes(request: UpdateWorkTasksIndexesRequest) {
    const url = `${this.endpointPath}/indexes`;
    return this.http
      .put<CreateOrUpdateWorkTask>(url, request)
      .then((response) => response.data);
  }

  async updateWorkTaskState(request: UpdateWorkTaskState) {
    const url = `${this.endpointPath}`;
    return this.http
      .patch<UpdateWorkTaskState>(url, request)
      .then((response) => response.data);
  }

  async updateWorkTaskName(request: UpdateWorkTaskName) {
    const url = `${this.endpointPath}/update-name`;
    return this.http
      .post<UpdateWorkTaskName>(url, request)
      .then((response) => response.data);
  }

  async deleteWorkTask(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.delete(url).then((response) => response.data);
  }

  async getWorkTasksByInstallation(installationId: number) {
    const url = `${this.endpointPath}/by-installation/${installationId}`;
    return this.http.get<WorkTask[]>(url).then((response) => response.data);
  }
}

export const workTasksService = new WorkTasksService(
  API_ENDPOINTS.WORKING_TASKS
);
