import { useRef, useEffect } from "react";
import { BoardWorkTask } from "../../../queries/models/board-work-task.model";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";
import { DraggableItemTypes } from "../../../constants/draggable-item-types.constants";
import { OverlayPanel } from "primereact/overlaypanel";
import { EditWorkTaskPanel } from "./EditWorkTaskPanel";
import { ProgressSpinner } from "primereact/progressspinner";
import { WorkTaskChipsInfoElement } from "./WorkTaskChipsInfosElement";
import { classNames } from "primereact/utils";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useDragItem } from "../../../components/ui/drag-item-conttext-provider";

interface Props {
  workTask: BoardWorkTask;
  onEditClick: () => void;
  isLoading?: boolean;
  index: number;
  onReorder: (item: BoardWorkTask, hoverIndex: number) => void;
  onIsDragging: (isDragging: boolean) => void;
  onRevertDrag: (item: BoardWorkTask) => void;
}

export function WorkTaskElement({
  workTask,
  onEditClick,
  onIsDragging,
  onReorder,
  onRevertDrag,
  isLoading = false,
  index,
}: Props) {
  const opRef = useRef<OverlayPanel>(null);
  const ref = useRef<HTMLDivElement>(null);
  const { setDragItemRef } = useDragItem();

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: DraggableItemTypes.WORK_TASK_CARD,
    item: () => {
      setDragItemRef(ref);
      return workTask;
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        onRevertDrag(item);
      }
      setDragItemRef(null);
    },
  }));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: false });
  }, [preview]);

  useEffect(() => {
    onIsDragging(isDragging);
  }, [isDragging]);

  const [{ handlerId }, drop] = useDrop<
    BoardWorkTask,
    void,
    { handlerId: Identifier | null }
  >({
    accept: DraggableItemTypes.WORK_TASK_CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: BoardWorkTask, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index ?? 0;
      const hoverIndex = index;
      const hoverItem = workTask;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      if (item.id === hoverItem.id) {
        return;
      }

      if (item.state !== hoverItem.state) {
        onReorder(item, hoverIndex);
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Consider only right column
      const clientOffsetX = (clientOffset as XYCoord).x;
      if (
        clientOffsetX <= hoverBoundingRect.left ||
        clientOffsetX >= hoverBoundingRect.right
      ) {
        return;
      }

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY - 5 <= hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY + 5 >= hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      onReorder(item, hoverIndex);
    },
  });

  useEffect(() => {
    if (isDragging) {
      opRef.current?.hide();
    }
  }, [isDragging]);

  drag(drop(ref));
  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      className={classNames(
        "surface-300 border-round p-1 border-left-3  border-400 cursor-pointer",
        workTask.isDragging ? "border-dashed  border-500 surface-400" : ""
      )}
    >
      <div className="flex flex-column gap-1">
        <div className="flex justify-content-between align-items-center">
          <div
            className="font-bold cursor-pointer hover:underline"
            onClick={onEditClick}
          >
            {`#${workTask.id} ${workTask.name}`}
          </div>
          <div className="px-1">
            {!isLoading ? (
              <div
                className="border-circle hover:surface-400 px-1"
                onClick={(e) => opRef.current?.toggle(e)}
              >
                <FontAwesomeIcon icon={faEllipsis} />
              </div>
            ) : (
              <ProgressSpinner
                className="h-1rem w-1rem purple-spinner"
                strokeWidth="8"
              />
            )}
          </div>
        </div>

        <>{workTask.installation?.name}</>

        <WorkTaskChipsInfoElement workTask={workTask} />
      </div>

      <OverlayPanel
        ref={opRef}
        className="w-15rem"
      >
        <EditWorkTaskPanel
          taskId={workTask.id ?? 0}
          taskName={workTask.name}
          onEditClick={onEditClick}
          onClose={() => opRef.current?.hide()}
        />
      </OverlayPanel>
    </div>
  );
}
