import { AttachedPin } from "../../ui/DragDropPlan";
import { measureBreakingText } from "./BreakingTextGenerator";
import { measurePin } from "./PinGenerator";

type LegendOptions = {
  fontSize?: number;
  colorFillingOpacity?: number;
  maxWidth?: number;
};

type LegendMeasurment = {
  height: number;
};

export function forNamedPins(
  pins: AttachedPin[],
  f: (pin: AttachedPin, text: string) => void
) {
  let pinNumber = 1;
  pins.forEach((pin) =>
    f(pin, pin.asText ? pin.name : (pinNumber++).toString())
  );
}

export function measureLegend(
  context: CanvasRenderingContext2D,
  pins: AttachedPin[],
  descriptions: Map<AttachedPin, string | string[]>,
  posX: number,
  posY: number,
  options?: LegendOptions
): [LegendMeasurment, () => void] {
  const o = {
    fontSize: options?.fontSize ?? 24,
    colorFillingOpacity: options?.colorFillingOpacity ?? 1,
    maxWidth: options?.maxWidth,
  };

  const drawContainer: (() => void)[] = [];

  context.save();
  context.font = `${o.fontSize}px Arial`;
  const spaceBetweenLegendEntries = o.fontSize / 4;
  let lastBottom = posY;
  forNamedPins(pins, (pin, pinText) => {
    const description = descriptions.get(pin);
    if (!description) return;

    const [pinBox, drawPin] = measurePin(context, pinText, posX, lastBottom, {
      fontSize: o.fontSize,
      fillingColor: pin.color ?? "purple",
      fillingOpacity: o.colorFillingOpacity,
      position: {
        x: "left",
        y: "top",
        includePaddingX: true,
        includePaddingY: true,
      },
    });

    const textTop = pinBox.bottom - o.fontSize;
    const [measuredText, drawText] = measureBreakingText(
      context,
      description,
      posX,
      textTop,
      {
        fontSize: o.fontSize,
        firstLineIndent: pinBox.right - pinBox.left + o.fontSize / 2,
        maxWidth: o.maxWidth,
      }
    );
    lastBottom = measuredText.box.height + textTop + spaceBetweenLegendEntries;

    drawContainer.push(drawPin, drawText);
  });
  context.restore();

  function draw() {
    drawContainer.forEach((x) => x());
  }

  return [{ height: lastBottom - posY }, draw];
}
