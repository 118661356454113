import { useCallback, useMemo } from "react";
import { Form, Formik } from "formik";
import { EnumExtensions } from "../../utils/enum-extensions";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { classNames } from "primereact/utils";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import { useWindowSize } from "../../hooks/use-window-size";
import { useToast } from "../../components/ui/toast-context-provider";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { Role } from "../../queries/models/role-model";
import { AddOrEditRole } from "../../queries/models/add-edit-role";
import FormikInlineMultiSelect from "../../components/ui/formik/FormikInlineMultiSelect";
import { authService } from "../../services/auth.service";
import { useTranslation } from "react-i18next";
import { SelectItem } from "primereact/selectitem";
import { AppFeaturesToTranslationKey } from "../../utils/app-feature-convertions";

export interface RolesFormProps {
  role: Role;
  isSubmitting?: boolean;
  onSave: (form: AddOrEditRole) => Promise<any>;
  onCancel: () => Promise<any> | void;
  onDelete: () => Promise<any> | void;
}

export function RolesForm({
  role,
  onSave,
  onCancel,
  onDelete,
}: RolesFormProps) {
  const { t } = useTranslation();
  const { lg } = useWindowSize() || {};
  const toast = useToast();

  const appFeaturesOptions: SelectItem[] = useMemo(
    () =>
      EnumExtensions.getValues(AppFeatures).map((x) => {
        return {
          label: t(AppFeaturesToTranslationKey[x as AppFeatures]),
          value: x,
        };
      }),
    [t]
  );

  const hasWriteAccess = useMemo(() => {
    return authService.hasAccess(AppFeatures.WebRolesWrite);
  }, []);

  const initialValues: AddOrEditRole = {
    id: role?.id ?? 0,
    name: role?.name ?? "",
    description: role?.description ?? "",
    appFeatures: role?.appFeatures ?? [],
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t("alert.mustBe3CharactersOrMore"))
      .required(t("alert.required")),
    description: Yup.string().optional(),
    appFeatures: Yup.array(),
  });

  const onSubmit = useCallback(
    (values: AddOrEditRole) => {
      if (!hasWriteAccess) return;
      return onSave(values);
    },
    [hasWriteAccess, onSave]
  );

  return (
    <div
      className="h-full w-12"
      style={{ padding: lg ? "0.5rem" : "" }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange
        validateOnMount
      >
        {(formik) => (
          <Form
            autoComplete="off"
            className={classNames(
              "flex h-full",
              lg ? "flex-column" : " flex-column-reverse"
            )}
          >
            <EditContentHeader
              header={role?.id ? role.name : "Add new role"}
              showDeleteButton={hasWriteAccess && !!role.id}
              saveButtonDisabled={
                !hasWriteAccess || !formik.dirty || !formik.isValid
              }
              onSaveClick={async () => {
                if (!formik.isValid) {
                  toast.current?.show({
                    severity: "error",
                    detail: t("alert.formInvalid"),
                  });
                  return;
                }
                return formik.submitForm();
              }}
              onCancelClick={onCancel}
              onDeleteClick={onDelete}
            />

            <div
              className="w-12 flex gap-2 "
              style={{ height: "calc(100% - 40px)" }}
            >
              <div className={classNames("w-6 h-full", !lg ? "pl-1" : "")}>
                <FormikInputText
                  label={t("common.name")}
                  name="name"
                  validationSchema={validationSchema}
                  isIndependent
                  autoComplete="off"
                  disabled={!hasWriteAccess}
                />
                <FormikInputText
                  label={t("common.description")}
                  name="description"
                  validationSchema={validationSchema}
                  isIndependent
                  autoComplete="off"
                  disabled={!hasWriteAccess}
                />
              </div>
              <div className="w-6 h-full overflow-hidden">
                <FormikInlineMultiSelect
                  label={t("common.selectFeatures")}
                  name="appFeatures"
                  validationSchema={validationSchema}
                  options={appFeaturesOptions}
                  disabled={!hasWriteAccess}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
