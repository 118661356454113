import { useQuery } from "react-query";
import { fileService } from "../services/image.service";
import { AxiosProgressEvent } from "axios";

export function useImageQuery(
  imageSrc: string,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
) {
  return useQuery(["image", imageSrc], () =>
    fileService.getImageBase64(imageSrc, onDownloadProgress)
  );
}


export function useNullableImageQuery(
  imageSrc: string | undefined,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
) {

  return useQuery(["image", imageSrc], () =>
    fileService.getImageBase64(imageSrc!, onDownloadProgress),
    { refetchOnWindowFocus: false, enabled: imageSrc !== undefined });
}

export function useNullableFileQuery(
  downloadUrl: string | undefined, fileName: string,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
) {

  return useQuery(["file", downloadUrl, fileName, downloadUrl], () =>
    fileService.downloadFileBlob(downloadUrl!, fileName, onDownloadProgress),
    { refetchOnWindowFocus: false, enabled: downloadUrl !== undefined });
}