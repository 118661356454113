import { TaskState } from "../queries/models/enums/task-state.enum";

export const TaskStateToTranslationKey: {
  [key in TaskState]: string;
} = {
  [TaskState.Unspecified]: "common.unspecified",
  [TaskState.Backlog]: "common.backlog",
  [TaskState.Todo]: "common.todo",
  [TaskState.Doing]: "common.doing",
  [TaskState.Finished]: "common.finished",
};
