import { string } from "yup";
import { AppFeatures } from "../queries/models/enums/app-feature-enum";

export const AppFeaturesToTranslationKey : {[key in AppFeatures]: string} = {
    [AppFeatures.GlobalRead]: "common.globalRead",
    [AppFeatures.GlobalWrite]: "common.globalWrite",
    [AppFeatures.WebAccess]: "common.webAccess",
    [AppFeatures.MobileAccess]: "common.mobileAccess",
    [AppFeatures.CanSeeConfidentialNotes]: "common.canSeeConfidentialNotes",
    [AppFeatures.CanForceEndWork]: "common.canForceEndWork",
    [AppFeatures.CanManageWorkEntries]: "common.canManageWorkEntries",
    [AppFeatures.CanSubmitInvoiceOrders]: "common.canSubmitInvoiceOrders",
    [AppFeatures.GlobalInstallationsFilter]: "common.globalInstallationsFilter",
    [AppFeatures.HasAllCompaniesAccess]: "common.hasAllCompaniesAccess",
    [AppFeatures.WebDashboardScreen]: "common.webDashboardScreen",
    [AppFeatures.WebDashboardWrite]: "common.webDashboardWrite",
    [AppFeatures.WebUsersScreen]: "common.webUsersScreen",
    [AppFeatures.WebUsersWrite]: "common.webUsersWrite",
    [AppFeatures.WebUsersLogout]: "common.webUsersLogout",
    [AppFeatures.WebWorkInstancesScreen]: "common.webWorkInstancesScreen",
    [AppFeatures.WebWorkInstancesWrite]: "common.webWorkInstancesWrite",
    [AppFeatures.WebPlanningScreen]: "common.webPlanningScreen",
    [AppFeatures.WebPlanningWrite]: "common.webPlanningWrite",
    [AppFeatures.WebInvoicingScreen]: "common.webInvoicingScreen",
    [AppFeatures.WebInvoicingWrite]: "common.webInvoicingWrite",
    [AppFeatures.WebSummaryScreen]: "common.webSummaryScreen",
    [AppFeatures.WebSummaryWrite]: "common.webSummaryWrite",
    [AppFeatures.WebAssetsScreen]: "common.webAssetsScreen",
    [AppFeatures.WebAssetsWrite]: "common.webAssetsWrite",
    [AppFeatures.WebAssetStickerScreen]: "common.webAssetStickerScreen",
    [AppFeatures.WebAssetStickerWrite]: "common.webAssetStickerWrite",
    [AppFeatures.WebTagsScreen]: "common.webTagsScreen",
    [AppFeatures.WebTagsWrite]: "common.webTagsWrite",
    [AppFeatures.WebTranslationsScreen]: "common.webTranslationsScreen",
    [AppFeatures.WebTranslationsWrite]: "common.webTranslationsWrite",
    [AppFeatures.WebRolesScreen]: "common.webRolesScreen",
    [AppFeatures.WebRolesWrite]: "common.webRolesWrite",
    [AppFeatures.WebInstallationsScreen]: "common.webInstallationsScreen",
    [AppFeatures.WebInstallationsWrite]: "common.webInstallationsWrite",
    [AppFeatures.WebWorkEntriesScreen]: "common.webWorkEntriesScreen",
    [AppFeatures.WebWorkEntriesWrite]: "common.webWorkEntriesWrite",
    [AppFeatures.WebCompaniesWrite]: "common.webCompaniesWrite",
    [AppFeatures.WebCompaniesScreen]: "common.webCompaniesScreen",
    [AppFeatures.WebKanbanBoardScreen]: "common.webKanbanBoardScreen",
    [AppFeatures.WebKanbanBoardWrite]: "common.webKanbanBoardWrite",
    [AppFeatures.MobileInstallationWorkAccess]: "common.mobileInstallationWorkAccess",
    [AppFeatures.MobileLogConceptualWorkTimeAccess]: "common.mobileLogConceptualWorkTimeAccess",
    [AppFeatures.MobileWarehouseOperationsAccess]: "common.mobileWarehouseOperationsAccess",
}