import { useCallback, useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { Company } from "../../queries/models/company-model";
import {
  useActiveCompaniesQuery,
  useAddCompanyMutation,
  useDeleteCompanyMutation,
  useEditCompanyMutation,
} from "../../queries/companies.query";
import { CreateOrUpdateCompanyRequest } from "../../queries/models/create-or-upodate-company-request";
import { CompaniesTableOverview } from "./CompaniesTableOverview";
import { CompanyForm } from "./CompanyForm";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export function Companies() {
  const { t } = useTranslation();
  const toast = useToast();
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(
    undefined
  );
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);

  const queryClient = useQueryClient();
  const companiesQuery = useActiveCompaniesQuery();
  const editCompanyMutation = useEditCompanyMutation();
  const addCompanyMutation = useAddCompanyMutation();
  const deleteCompanyMutation = useDeleteCompanyMutation();

  const itemsLoading: boolean = companiesQuery.isFetching;

  const hasWriteAccess = useMemo(
    () => authService.hasAccess(AppFeatures.WebUsersWrite),
    []
  );

  const handleAddNewCompany = useCallback(() => {
    setSelectedCompany({
      dateCreated: new Date(),
      dateOfExpire: undefined,
      name: "",
      odooAccessConfig_OdooDbName: "",
      odooAccessConfig_OdooPassword: "",
      odooAccessConfig_OdooUrl: "",
      odooAccessConfig_OdooUsername: "",
      odooBusinessConfig_CompanyId: 0,
      odooBusinessConfig_TravelKmProductId: 0,
      odooBusinessConfig_WorkingHourProductId: 0,
      isActivated: false,
      isActive: false,
      isOdooIntegrated: false,
      dateModified: new Date(),
      id: 0,
      lastModifiedById: 0,
    } as Company);
  }, []);

  const saveNewCompany = useCallback(
    (data: CreateOrUpdateCompanyRequest) => {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedCompany(undefined);
          toast.current?.show({
            severity: "success",
            detail: t("alert.saveSuccess"),
          });
          await queryClient.invalidateQueries("companies");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: CreateOrUpdateCompanyRequest = {
        name: data.name,
        dateOfExpire: data.dateOfExpire,
        odooAccessConfig_OdooDbName: data.odooAccessConfig_OdooDbName,
        odooAccessConfig_OdooPassword: data.odooAccessConfig_OdooPassword,
        odooAccessConfig_OdooUrl: data.odooAccessConfig_OdooUrl,
        odooAccessConfig_OdooUsername: data.odooAccessConfig_OdooUsername,
        odooBusinessConfig_CompanyId: data.odooBusinessConfig_CompanyId,
        odooBusinessConfig_TravelKmProductId:
          data.odooBusinessConfig_TravelKmProductId,
        odooBusinessConfig_WorkingHourProductId:
          data.odooBusinessConfig_WorkingHourProductId,
        address: data.address,
        city: data.city,
        email: data.email,
        postalCode: data.postalCode,
        vatEu: data.vatEu,
      };

      return addCompanyMutation.mutateAsync(request, mutateOptions);
    },
    [addCompanyMutation, queryClient, t, toast]
  );

  const editExistingCompany = useCallback(
    (data: CreateOrUpdateCompanyRequest) => {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedCompany(undefined);
          toast.current?.show({
            severity: "success",
            detail: t("alert.editSuccess"),
          });
          await queryClient.invalidateQueries("companies");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: CreateOrUpdateCompanyRequest = {
        id: data.id,
        name: data.name,
        dateOfExpire: data.dateOfExpire,
        odooAccessConfig_OdooDbName: data.odooAccessConfig_OdooDbName,
        odooAccessConfig_OdooPassword: data.odooAccessConfig_OdooPassword,
        odooAccessConfig_OdooUrl: data.odooAccessConfig_OdooUrl,
        odooAccessConfig_OdooUsername: data.odooAccessConfig_OdooUsername,
        odooBusinessConfig_CompanyId: data.odooBusinessConfig_CompanyId,
        odooBusinessConfig_TravelKmProductId:
          data.odooBusinessConfig_TravelKmProductId,
        odooBusinessConfig_WorkingHourProductId:
          data.odooBusinessConfig_WorkingHourProductId,
        address: data.address,
        city: data.city,
        email: data.email,
        postalCode: data.postalCode,
        vatEu: data.vatEu,
      };

      return editCompanyMutation.mutateAsync(request, mutateOptions);
    },
    [editCompanyMutation, queryClient, t, toast]
  );

  const handleSaveCompany = useCallback(
    (data: CreateOrUpdateCompanyRequest) => {
      return data.id ? editExistingCompany(data) : saveNewCompany(data);
    },
    [editExistingCompany, saveNewCompany]
  );

  const handleDeleteCompany = useCallback(async () => {
    if (selectedCompany?.id) {
      const mutateOptions = {
        onSuccess: async () => {
          await queryClient.invalidateQueries("companies");
          toast.current?.show({
            severity: "success",
            detail: t("alert.companySuccessfullyDeleted"),
          });
          setSelectedCompany(undefined);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };
      setIsDeleteConfirmationModalOpen(false);
      return deleteCompanyMutation.mutateAsync(
        selectedCompany.id,
        mutateOptions
      );
    }
  }, [deleteCompanyMutation, queryClient, selectedCompany?.id, t, toast]);

  const deleteConfirmationModalProps: CustomModalProps = useMemo(() => {
    return {
      header: t("common.confirmation"),
      body: t("dialog.deleteThisCompany"),
      height: "160px",
      isOpen: isDeleteConfirmationModalOpen,
      confirmation: true,
      centered: true,
      justified: true,
      onClose: () => setIsDeleteConfirmationModalOpen(false),
      onConfirm: () => handleDeleteCompany(),
    };
  }, [handleDeleteCompany, isDeleteConfirmationModalOpen, t]);

  return (
    <div className="flex gap-2 p-1 h-full">
      <CustomModal {...deleteConfirmationModalProps} />
      <div className="w-7 h-full">
        <RoundedShadowContainer
          small
          fullHeight
        >
          <LoaderWrapper isLoading={itemsLoading}>
            <CompaniesTableOverview
              items={companiesQuery.data ?? []}
              onAddNew={handleAddNewCompany}
              selectedCompany={selectedCompany}
              onSelectCompany={setSelectedCompany}
              hasWriteAccess={hasWriteAccess}
            />
          </LoaderWrapper>
        </RoundedShadowContainer>
      </div>
      <div className="w-5 h-full">
        <RoundedShadowContainer
          small
          fullHeight
        >
          {selectedCompany ? (
            <CompanyForm
              user={selectedCompany}
              onSave={handleSaveCompany}
              onCancel={() => setSelectedCompany(undefined)}
              onDelete={() => setIsDeleteConfirmationModalOpen(true)}
              hasWriteAccess={hasWriteAccess}
            />
          ) : (
            <div className="h-full flex align-items-center justify-content-center">
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{ fontSize: 270 }}
                color="#7C4DFF"
                cursor="pointer"
                onClick={handleAddNewCompany}
              />
            </div>
          )}
        </RoundedShadowContainer>
      </div>
    </div>
  );
}
