import { useMutation, useQuery } from "react-query";
import { PlanningUser } from "./models/planning-users.model";
import { planningService } from "../services/planning.service";
import { PlanningWorkInstance } from "./models/planning-work-instance.model";
import { CreateWorkInstanceRequest } from "./models/create-work-instance.model";
import { UpdateWorkInstanceRequest } from "./models/edit-work-instance.model";

export function usePlanningUsersQuery(month: Date | undefined) {
  return useQuery<PlanningUser[]>(
    ["planningUsers", month],
    () => fetchUsers(month),
    { refetchOnWindowFocus: false, enabled: month !== undefined }
  );
}
async function fetchUsers(month: Date | undefined) {
  return await planningService.getPlanningUsers(month!);
}
export function useUserHasPendingWorkQuery(userId: number | undefined) {
  return useQuery<boolean>(
    ["hasPendingWork", userId],
    () => fetchUserHasPendingWork(userId),
    { refetchOnWindowFocus: false, enabled: userId !== undefined }
  );
}
async function fetchUserHasPendingWork(userId: number | undefined) {
  return await planningService.getUserHasPendingWork(userId!);
}

export function usePlanningWorkInstancesQuery(
  month: Date | undefined,
  userIds: number[] | undefined
) {
  return useQuery<PlanningWorkInstance[]>(
    ["planningWorkInstances", month, userIds],
    () => fetchWorkInstances(month, userIds),
    {
      refetchOnWindowFocus: false,
      enabled: month !== undefined && userIds !== undefined,
    }
  );
}
async function fetchWorkInstances(
  month: Date | undefined,
  userIds: number[] | undefined
) {
  if (!month || !userIds) {
    return [];
  } else {
    return await planningService.getPlanningWorkInstance(month, userIds);
  }
}
export function useAddWorkInstanceMutation() {
  return useMutation((request: CreateWorkInstanceRequest) =>
    planningService.addWorkInstance(request)
  );
}
export function useEditWorkInstanceMutation() {
  return useMutation((request: UpdateWorkInstanceRequest) =>
    planningService.editWorkInstance(request)
  );
}
