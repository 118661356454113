export enum WorkEventType {
  Unspecified = 0,
  WorkStarted = 1,
  WorkEnded = 2,
  WorkContinued = 3,
  PauseStarted = 4,
  PauseEnded = 5,
  AddNote = 6,
  AssetAdded = 7,
  AssetInstalled = 8,
  AssetNote = 9,
  AssetServiced = 10,
  WorkForceEnded = 21,
  WorkAutoEnded = 22,
}
