import { useCallback, useState } from "react";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { PrintingSettingsForm } from "./PrintingSettingsForm";
import { useGetNextQrsMutation } from "../../queries/app-settings.query";
import { PrintingPreview } from "./PrintingPreview";
import { jsPDF } from "jspdf";
import { PrintingSettings } from "./models/priniting-settings";

export function AssetStickerComponent() {
  const [qrCodes, setQrCodes] = useState<string[]>([]);
  const getNextQrsMutation = useGetNextQrsMutation();

  const onGenerateQrCodes = useCallback(
    async (count: number) => {
      const nextQrCodes = await getNextQrsMutation.mutateAsync(count);
      setQrCodes(nextQrCodes);
    },
    [getNextQrsMutation]
  );

  const onExportToPdf = useCallback(
    (values: PrintingSettings) => {
      var printingQrPage = values.setting;

      const rowsPerPage = Math.floor(
        (printingQrPage.height -
          (printingQrPage.topMargin + printingQrPage.bottomMargin)) /
          (printingQrPage.qrMargin * 2 + printingQrPage.qrSize)
      );
      const columnsPerPage = Math.floor(
        (printingQrPage.width -
          (printingQrPage.leftMargin + printingQrPage.rightMargin)) /
          (printingQrPage.qrMargin * 2 + printingQrPage.qrSize)
      );
      const itemsPerPage = rowsPerPage * columnsPerPage;
      const pagesCount = Math.ceil(qrCodes.length / itemsPerPage);

      var doc = new jsPDF({
        orientation: printingQrPage.orientation,
        unit: "mm",
        compress: false,
        format: printingQrPage.format,
      });

      doc.setFontSize(15);
      doc.setTextColor("#333333");

      let itemCounter = 0;

      for (let pageCount = 0; pageCount < pagesCount; pageCount++) {
        if (pageCount > 0) {
          doc.addPage(printingQrPage.format, printingQrPage.orientation);
        }

        let x: number, y: number;
        for (let row = 0; row < rowsPerPage; row++) {
          for (let col = 0; col < columnsPerPage; col++) {
            if (itemCounter >= qrCodes.length) {
              break;
            }
            x =
              printingQrPage.leftMargin +
              printingQrPage.qrMargin +
              col *
                (printingQrPage.qrMargin +
                  printingQrPage.qrSize +
                  printingQrPage.qrMargin);
            y =
              printingQrPage.topMargin +
              printingQrPage.qrMargin +
              row *
                (printingQrPage.qrMargin +
                  printingQrPage.qrSize +
                  printingQrPage.qrMargin);

            const qrCodeCanvasElement = document.getElementById(
              `qrCode${itemCounter}`
            ) as HTMLCanvasElement;

            const qrCodeURL = qrCodeCanvasElement
              .toDataURL("image/png")
              .replace("image/png", "image/octet-stream");

            doc.addImage(
              qrCodeURL,
              "JPEG",
              x,
              y,
              printingQrPage.qrSize,
              printingQrPage.qrSize
            );

            doc.text(
              qrCodes[itemCounter],
              x + printingQrPage.qrSize / 2,
              y + printingQrPage.qrSize + 5,
              { align: "center" }
            );

            itemCounter++;
          }
        }
      }

      doc.save(`PurpleAssetSticker-${new Date().toISOString()}.pdf`);
    },
    [qrCodes]
  );

  return (
    <div className="h-full p-1 flex gap-2">
      <div className="h-full w-6">
        <RoundedShadowContainer
          medium
          fullHeight>
          <PrintingSettingsForm
            exportButtonDisabled={!qrCodes.length}
            onGenerateQrCodes={onGenerateQrCodes}
            onExportToPdf={onExportToPdf}
          />
        </RoundedShadowContainer>
      </div>
      <div className="h-full w-6">
        <RoundedShadowContainer
          medium
          fullHeight>
          <PrintingPreview qrCodes={qrCodes} />
        </RoundedShadowContainer>
      </div>
    </div>
  );
}
