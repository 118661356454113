import { Dropdown } from "primereact/dropdown";
import { SelectItem } from "primereact/selectitem";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";

interface Props {
  installationOptions: SelectItem[];
  userOptions: SelectItem[];
  selectedInstallationItem?: SelectItem;
  setSelectedInstallationItem: (item: SelectItem) => void;
  selectedUserItems?: SelectItem[];
  setSelectedUserItems: (items: SelectItem[]) => void;
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  onClearFilters: () => void;
}

export function TasksFilterElement({
  installationOptions,
  userOptions,
  selectedInstallationItem,
  setSelectedInstallationItem,
  selectedUserItems,
  setSelectedUserItems,
  globalFilter,
  setGlobalFilter,
  onClearFilters,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex gap-2 justify-content-end w-full pr-4">
      <InputText
        className="w-15rem"
        placeholder={t("common.filter")}
        value={globalFilter}
        onChange={(e) => setGlobalFilter(e.target.value)}
      />
      <Dropdown
        options={installationOptions}
        value={selectedInstallationItem}
        placeholder={t("common.installation")}
        onChange={(e) => setSelectedInstallationItem(e.value)}
        className="w-15rem"
        filter
        showClear
      />
      <MultiSelect
        options={userOptions}
        className="w-15rem"
        placeholder={t("common.assignedUsers")}
        value={selectedUserItems}
        onChange={(e) => {
          setSelectedUserItems(e.target.value);
          e.stopPropagation();
        }}
        filter
        showClear
      />
      <Button
        label={t("common.clear")}
        icon="pi pi-times"
        onClick={onClearFilters}
      />
    </div>
  );
}
