import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { PlanningUser } from "../queries/models/planning-users.model";
import { PlanningWorkInstance } from "../queries/models/planning-work-instance.model";
import { CreateWorkInstanceRequest } from "../queries/models/create-work-instance.model";
import { UpdateWorkInstanceRequest } from "../queries/models/edit-work-instance.model";

class PlanningService extends BaseService {
  async getUserHasPendingWork(userId: number): Promise<boolean> {
    const url = `${this.endpointPath}/user/${userId}/has-pending-work`;
    return this.http.get<boolean>(url).then((response) => response.data);
  }
  async getPlanningUsers(month: Date): Promise<PlanningUser[]> {
    const url = `${this.endpointPath}/users/${month.toISOString()}`;
    return this.http.get<PlanningUser[]>(url).then((response) => response.data);
  }
  async getPlanningWorkInstance(
    month: Date,
    userIds: number[]
  ): Promise<PlanningWorkInstance[]> {
    const url = `${this.endpointPath}/work-instances/get`;
    return this.http
      .post<PlanningWorkInstance[]>(url, {
        month: month.toISOString(),
        userIds: userIds,
      })
      .then((response) => response.data);
  }

  async addWorkInstance(request: CreateWorkInstanceRequest) {
    const url = `${this.endpointPath}/work-instances`;
    return this.http.post<any>(url, request).then((response) => response.data);
  }
  async editWorkInstance(request: UpdateWorkInstanceRequest) {
    const url = `${this.endpointPath}/work-instances`;
    return this.http.put<any>(url, request).then((response) => response.data);
  }
}

export const planningService = new PlanningService(API_ENDPOINTS.PLANNING);
