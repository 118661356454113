export enum AppFeatures {
  // 1-99 are global user features
  GlobalRead = 1,
  GlobalWrite = 2,
  WebAccess = 3,
  MobileAccess = 4,
  CanSeeConfidentialNotes = 11,
  CanForceEndWork = 12,
  CanManageWorkEntries = 13,
  CanSubmitInvoiceOrders = 14,
  GlobalInstallationsFilter = 90,
  HasAllCompaniesAccess = 99,

  // 100-999 are web application specific features
  WebDashboardScreen = 100,
  WebDashboardWrite = 101,
  WebUsersScreen = 110,
  WebUsersWrite = 111,
  WebUsersLogout = 112,
  WebWorkInstancesScreen = 120,
  WebWorkInstancesWrite = 121,
  WebPlanningScreen = 130,
  WebPlanningWrite = 131,
  WebInvoicingScreen = 140,
  WebInvoicingWrite = 141,
  WebSummaryScreen = 150,
  WebSummaryWrite = 151,
  WebAssetsScreen = 160,
  WebAssetsWrite = 161,
  WebAssetStickerScreen = 170,
  WebAssetStickerWrite = 171,
  WebTagsScreen = 180,
  WebTagsWrite = 181,
  WebTranslationsScreen = 200,
  WebTranslationsWrite = 201,
  WebRolesScreen = 210,
  WebRolesWrite = 211,
  WebInstallationsScreen = 220,
  WebInstallationsWrite = 221,
  WebWorkEntriesScreen = 230,
  WebWorkEntriesWrite = 231,
  WebCompaniesWrite = 250,
  WebCompaniesScreen = 261,
  WebKanbanBoardScreen = 270,
  WebKanbanBoardWrite = 271,

  // 1000 - 9999 mobile features
  MobileInstallationWorkAccess = 1000,
  MobileLogConceptualWorkTimeAccess = 1001,
  MobileWarehouseOperationsAccess = 2000,

  // 10000+ are other features
}
