import { DashboardDetailsItem } from "../queries/models/dashboard-details-item.model";
import { DashboardOverviewItem } from "../queries/models/dashboard-overview-item.model";
import { LocationLog } from "../queries/models/location-log.model";
import { ForceFinishWorkRequest } from "../queries/models/force-finish-work-request.model";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class DashboardService extends BaseService {

    async getOverview(): Promise<DashboardOverviewItem[]>{
        const url = `${this.endpointPath}/overview`;
        return this.http.get<DashboardOverviewItem[]>(url).then((response) => response.data);
    }

    async getWorkDetails(id: number): Promise<DashboardDetailsItem>{
      const url = `${this.endpointPath}/work-item/${id}`;
      return this.http.get<DashboardDetailsItem>(url).then((response) => response.data);
    }

    async getAllLocation(): Promise<LocationLog[]>{
      const url = `${this.endpointPath}/users-latest-location`;
      return this.http.get<LocationLog[]>(url).then((response) => response.data);
  }

  async forceFinishWork(request: ForceFinishWorkRequest): Promise<void> {
    const url = `${this.endpointPath}/finish-work-instance`;
    return this.http.patch(url, request).then((response) => response.data);
  }
}

export const dashboardService = new DashboardService(API_ENDPOINTS.DASHBOARD);