import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { WorkInstance } from "../queries/models/work-instance.model";
import { WorkInstanceDetails } from "../queries/models/work-instance-details";
import { ValidatedWorkInstance } from "../queries/models/validated-work-instance.model";

class WorkInstanceService extends BaseService {
  async getActiveByDateRange(from: Date, to: Date): Promise<WorkInstance[]> {
    const url = `${
      this.endpointPath
    }/active-by-date-range/${from.toISOString()}/${to.toISOString()}`;
    return this.http.get<WorkInstance[]>(url).then((response) => response.data);
  }

  async getById(id: number): Promise<WorkInstanceDetails> {
    const url = `${this.endpointPath}/${id}`;
    return this.http
      .get<WorkInstanceDetails>(url)
      .then((response) => response.data);
  }

  async getByInstallationId(installationId: number): Promise<WorkInstance[]> {
    const url = `${this.endpointPath}/by-installation/${installationId}`;
    return this.http.get<WorkInstance[]>(url).then((response) => response.data);
  }

  async getValidatedByInstallation(
    installationId: number
  ): Promise<ValidatedWorkInstance[]> {
    const url = `${this.endpointPath}/validated-by-installation/${installationId}`;
    return this.http
      .get<ValidatedWorkInstance[]>(url)
      .then((response) => response.data);
  }

  async deleteWorkInstance(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.delete(url).then((response) => response.data);
  }
}

export const workInstancesService = new WorkInstanceService(
  API_ENDPOINTS.WORK_INSTANCES
);
