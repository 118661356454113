import { TaskSeverity } from "../queries/models/enums/task-severity.enum";

export const TaskSeverityToTranslationKey: {
  [key in TaskSeverity]: string;
} = {
  [TaskSeverity.Unspecified]: "common.unspecified",
  [TaskSeverity.Low]: "common.low",
  [TaskSeverity.Normal]: "common.normal",
  [TaskSeverity.High]: "common.high",
  [TaskSeverity.Critical]: "common.critical",
};

export const TaskSeverityToColor: { [key in TaskSeverity]: string } = {
  [TaskSeverity.Unspecified]: "#BDBDBD",
  [TaskSeverity.Low]: "#BDBDBD",
  [TaskSeverity.Normal]: "#388E3C",
  [TaskSeverity.High]: "#FFA000",
  [TaskSeverity.Critical]: "#D32F2F",
};
