import { LoginRequest } from "../queries/models/login-request.model";
import { LoginResponse } from "../queries/models/login-response.model";
import { BaseService } from "./base.service";
import { LoggedUserInfo } from "../queries/models/_shared/logged-user-info";
import { API_ENDPOINTS } from "../utils/endpoints";
import { ForgotPasswordRequest } from "../queries/models/forgot-password-request.model";
import { ResetPasswordRequest } from "../queries/models/reset-password-request.model";
import { User } from "../queries/models/user.model";
import { AppFeatures } from "../queries/models/enums/app-feature-enum";
import { AUTH_USER_INFO } from "../utils/local-storage-keys";
import { Company } from "../queries/models/company-model";
import { ConfirmCompanyRequest } from "../queries/models/confirm-company-request.model";
import i18n from "i18next";

type LoggedUserSubscriber = (user: LoggedUserInfo | null) => void;

class AuthService extends BaseService {
  private loggedUserSubscribers: LoggedUserSubscriber[] = [];
  subscribe(callback: LoggedUserSubscriber) {
    this.loggedUserSubscribers.push(callback);
    return {
      unsubscribe: () => {
        const index = this.loggedUserSubscribers.indexOf(callback);
        if (index > 0) this.loggedUserSubscribers.splice(index, 1);
      },
    };
  }

  async getUserByToken(token: string): Promise<User> {
    const url = `${this.endpointPath}/user-by-token/${token}`;
    return this.http.get<User>(url).then((response) => response.data);
  }
  async getCompanyByToken(token: string): Promise<Company> {
    const url = `${this.endpointPath}/company-by-token/${token}`;
    return this.http.get<Company>(url).then((response) => response.data);
  }

  async forgotPassword(request: ForgotPasswordRequest): Promise<any> {
    return this.http
      .post(`${this.endpointPath}/forgot-password`, request)
      .then((res) => res.data);
  }
  async resetPassword(request: ResetPasswordRequest): Promise<any> {
    return this.http
      .post(`${this.endpointPath}/reset-password`, request)
      .then((res) => res.data);
  }
  async confirmCompany(request: ConfirmCompanyRequest): Promise<any> {
    return this.http
      .post(`${this.endpointPath}/confirm-company/${request.token}`)
      .then((res) => res.data);
  }
  async login(loginRequest: LoginRequest): Promise<LoginResponse> {
    return this.http
      .post(`${this.endpointPath}/login`, loginRequest)
      .then((res) => res.data);
  }

  getLoggedUser = (): LoggedUserInfo | null => {
    const valueString = localStorage.getItem(AUTH_USER_INFO);
    return valueString ? JSON.parse(valueString) : null;
  };

  setLoggedUser(info: LoggedUserInfo) {
    this.loggedUserSubscribers.forEach((x) => x(info));
    localStorage.setItem(AUTH_USER_INFO, JSON.stringify(info));
  }

  logout() {
    this.loggedUserSubscribers.forEach((x) => x(null));
    localStorage.removeItem(AUTH_USER_INFO);
  }

  hasAccess = (role: AppFeatures): boolean => {
    const loggedUser = this.getLoggedUser();
    return loggedUser?.appFeatures?.find((x) => x === role) !== undefined;
  };
}

export const authService = new AuthService(API_ENDPOINTS.AUTHENTICATION);
