import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { TranslationKeyValue } from "../queries/models/translation-key-value";
import { SetTranslationRequest } from "../queries/models/set-translation--request.model";

class TranslationService extends BaseService {
  async getLanguageTranslations(id: number): Promise<TranslationKeyValue[]> {
    const url = `${this.endpointPath}/${id}`;
    return this.http
      .get<TranslationKeyValue[]>(url)
      .then((response) => response.data);
  }

  async setTranslation(translation: SetTranslationRequest) {
    const url = `${this.endpointPath}`;
    return this.http.post(url, translation).then((response) => response.data);
  }

  async getTranslationsForCultureJson(culture: string): Promise<string> {
    const url = `${this.endpointPath}/web/json/${culture}`;
    return this.http.get<string>(url).then((response) => response.data);
  }
}

export const translationService = new TranslationService(
  API_ENDPOINTS.TRANSLATIONS
);
