import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TimeSpan } from "../../utils/timespan";
import { useEffect, useMemo, useState, useRef, useCallback } from "react";
import {
  faCheck,
  faClose,
  faEdit,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";

export interface EditableTimeProps {
  timeSpan: TimeSpan;
  disabled: boolean;
  onEdit: (ts: TimeSpan, comment: string) => void;
}

export function EditableTime({
  timeSpan,
  disabled,
  onEdit,
}: EditableTimeProps) {
  const [newTimeSpan, setNewTimeSpan] = useState(timeSpan);
  const [editableTimeSpan, setEditableTimeSpan] = useState(timeSpan);
  const [comment, setComment] = useState<string>();
  const overlayRef = useRef<OverlayPanel>(null);

  useEffect(() => {
    setEditableTimeSpan(timeSpan);
  }, [timeSpan]);

  useEffect(() => {
    setNewTimeSpan(timeSpan);
  }, [timeSpan]);

  const updateWorkTime = useCallback(
    (ticks: number, addTicks: boolean) => {
      setEditableTimeSpan(
        new TimeSpan(
          addTicks
            ? editableTimeSpan.ticks + ticks
            : editableTimeSpan.ticks - ticks >= 0
            ? editableTimeSpan.ticks - ticks
            : editableTimeSpan.ticks
        )
      );
    },
    [editableTimeSpan.ticks]
  );

  const isValid = useMemo(() => {
    return comment !== undefined && comment !== "";
  }, [comment]);

  return (
    <div className="w-full">
      <OverlayPanel
        ref={overlayRef}
        onHide={() => setEditableTimeSpan(newTimeSpan)}
      >
        <div className="flex justify-content-evenly">
          <div className="flex">
            <div className="flex flex-column">
              <FontAwesomeIcon
                icon={faChevronUp}
                onClick={() => updateWorkTime(36000000000, true)}
                className="cursor-pointer text-500 hover:text-800"
              />
              <InputNumber
                className="edit-time-input"
                value={Math.floor(editableTimeSpan.totalHours)}
                onChange={(e) => {
                  setEditableTimeSpan(
                    new TimeSpan(
                      (e.value ?? 0) * 36000000000 +
                        Math.floor(editableTimeSpan.minutes) * 600000000
                    )
                  );
                }}
                min={0}
              />
              <FontAwesomeIcon
                icon={faChevronDown}
                onClick={() => updateWorkTime(36000000000, false)}
                className="cursor-pointer text-500 hover:text-800"
              />
            </div>
            <div className="flex align-items-center p-1">:</div>
            <div className="flex flex-column">
              <FontAwesomeIcon
                icon={faChevronUp}
                onClick={() => updateWorkTime(600000000, true)}
                className="cursor-pointer text-500 hover:text-800"
              />
              <InputNumber
                className="edit-time-input"
                value={Math.floor(editableTimeSpan.minutes)}
                onChange={(e) => {
                  setEditableTimeSpan(
                    new TimeSpan(
                      Math.floor(editableTimeSpan.totalHours) * 36000000000 +
                        (e.value ?? 0) * 600000000
                    )
                  );
                }}
                min={0}
                max={59}
              />
              <FontAwesomeIcon
                icon={faChevronDown}
                onClick={() => updateWorkTime(600000000, false)}
                className="cursor-pointer text-500 hover:text-800"
              />
            </div>
          </div>
          <div className="flex flex-column gap-1 justify-content-evenly">
            <FontAwesomeIcon
              icon={faCheck}
              className={classNames({
                "fa-m ml-3": true,
                "text-green-600 hover:text-green-800 cursor-pointer": isValid,
                "text-gray-500": !isValid,
              })}
              onClick={() => {
                if (isValid === false) return;
                setNewTimeSpan(editableTimeSpan);
                setComment(undefined);
                onEdit(editableTimeSpan, comment!);
                overlayRef.current?.hide();
              }}
            />
            <FontAwesomeIcon
              icon={faClose}
              className="fa-m ml-3 text-red-600 hover:text-red-800 cursor-pointer"
              onClick={() => overlayRef.current?.hide()}
            />
          </div>
        </div>
        <InputText
          className={classNames("mt-1", {
            "w-full": true,
            "p-invalid": !isValid,
          })}
          value={comment ?? ""}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Comment"
          required
        />
      </OverlayPanel>

      <div className="w-full flex align-items-center gap-2">
        <div className="w-full flex justify-content-end">
          {newTimeSpan.toHhMm()}
        </div>
        {!disabled && (
          <div className="flex">
            <Button
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
              }}
              className="text-green-600 hover:text-green-800 focus:border-transparent"
              icon={<FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>}
              onClick={(e) => overlayRef.current?.toggle(e)}
            ></Button>
          </div>
        )}
      </div>
    </div>
  );
}
