import { useEffect, useState } from "react";
import { authService } from "../services/auth.service";
import { LoggedUserInfo } from "../queries/models/_shared/logged-user-info";

export function useLoggedUser() {
  const [user, setUser] = useState<LoggedUserInfo | null>(
    authService.getLoggedUser()
  );

  useEffect(() => {
    const subscription = authService.subscribe(setUser);
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return user;
}
