import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TimeSpan } from "../../../utils/timespan";
import { useMemo, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { InputNumber } from "primereact/inputnumber";

interface MobileEditableTimeProps {
  timeSpan: TimeSpan;
  onEditTime: (timeSpan: TimeSpan, comment: string) => void;
  isLoading: boolean;
}

export function MobileEditableTime({
  timeSpan,
  onEditTime,
  isLoading,
}: MobileEditableTimeProps) {
  const [time, setTime] = useState(timeSpan.ticks);
  const [comment, setComment] = useState<string>();

  const handleTimeChange = (value: number) => {
    if (time + value >= 0) {
      setTime(time + value);
    }
  };

  const timeValue = useMemo(() => {
    const milliseconds = time / 10000;
    const totalMinutes = Math.floor(milliseconds / 60000);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours: hours, minutes: minutes };
  }, [time]);

  const isValid = useMemo(() => {
    return comment !== undefined && comment !== "";
  }, [comment]);

  return (
    <>
      <div className="w-full flex justify-content-center">
        <div className="flex flex-column w-3 ">
          <FontAwesomeIcon
            icon={faChevronUp}
            onClick={() => handleTimeChange(36000000000)}
            className="cursor-pointer text-800 p-2 text-2xl"
          />
          <InputNumber
            inputClassName="w-12 text-xl"
            value={timeValue.hours}
            onChange={(e) => {
              setTime(
                (e.value ?? 0) * 36000000000 +
                  Math.floor(timeValue.minutes) * 600000000
              );
            }}
            min={0}
          />
          <FontAwesomeIcon
            icon={faChevronDown}
            onClick={() => handleTimeChange(-36000000000)}
            className="cursor-pointer text-800 p-2 text-2xl"
          />
        </div>
        <div className="flex flex-column w-1 justify-content-center align-items-center pb-1 text-800 text-2xl">
          :
        </div>
        <div className="flex flex-column w-3">
          <FontAwesomeIcon
            icon={faChevronUp}
            onClick={() => handleTimeChange(600000000)}
            className="cursor-pointer text-800 p-2 text-2xl"
          />
          <InputNumber
            inputClassName="w-12 text-xl"
            value={timeValue.minutes}
            onChange={(e) => {
              setTime(
                Math.floor(timeValue.hours) * 36000000000 +
                  (e.value ?? 0) * 600000000
              );
            }}
            min={0}
          />
          <FontAwesomeIcon
            icon={faChevronDown}
            onClick={() => handleTimeChange(-600000000)}
            className="cursor-pointer text-800 p-2 text-2xl"
          />
        </div>
      </div>
      <InputText
        className={classNames({
          "w-full": true,
          "p-invalid": !isValid,
        })}
        value={comment ?? ""}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Comment"
        required
      />
      <Button
        className="green-action-button w-full mt-2"
        label="Save new work time"
        style={{ height: "3rem" }}
        loading={isLoading}
        disabled={!isValid}
        onClick={() => onEditTime(TimeSpan.fromTicks(time), comment!)}
      />
    </>
  );
}
