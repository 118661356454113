import { useCallback, useState } from "react";
import { SelectItem } from "primereact/selectitem";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { TasksFilterElement } from "./TasksFilterElement";
import { TasksBoardElement } from "./TasksBoardElement";
import { BoardConfigurationElement } from "./BoardConfigurationElement";
import { useInstallationsOptionsQuery } from "../../queries/installations.query";
import { useActiveUsersAsSelectOptionQuery } from "../../queries/users.query";

export function TasksBoard() {
  const [showTasksCount, setShowTasksCount] = useState(true);

  const [selectedInstallationOption, setSelectedInstallationOption] = useState<
    SelectItem | undefined
  >(undefined);
  const [selectedUserOptions, setSelectedUserOptions] = useState<SelectItem[]>(
    []
  );

  const [globalFilter, setGlobalFilter] = useState("");

  const installationsOptionsQuery = useInstallationsOptionsQuery();
  const usersOptionsQuery = useActiveUsersAsSelectOptionQuery();

  const handleClearFilters = useCallback(() => {
    setGlobalFilter("");
    setSelectedInstallationOption(undefined);
    setSelectedUserOptions([]);
  }, []);

  return (
    <div className="h-full p-1 overflow-hidden">
      <RoundedShadowContainer
        small
        fullHeight
      >
        <div className="h-full flex flex-column">
          <div className="flex justify-content-between p-1 pr-2">
            <TasksFilterElement
              installationOptions={installationsOptionsQuery.data ?? []}
              userOptions={usersOptionsQuery.data ?? []}
              selectedInstallationItem={selectedInstallationOption}
              setSelectedInstallationItem={setSelectedInstallationOption}
              selectedUserItems={selectedUserOptions}
              setSelectedUserItems={setSelectedUserOptions}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              onClearFilters={handleClearFilters}
            />
            <BoardConfigurationElement
              showTasksCount={showTasksCount}
              setShowTasksCount={setShowTasksCount}
            />
          </div>
          <TasksBoardElement
            globalFilter={globalFilter}
            showTasksCount={showTasksCount}
            selectedInstallation={selectedInstallationOption}
            selectedUsers={selectedUserOptions}
          />
        </div>
      </RoundedShadowContainer>
    </div>
  );
}
