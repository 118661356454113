import {
  Field,
  ErrorMessage,
  FastField,
  FormikContextType,
  FieldInputProps,
  FieldMetaProps,
} from "formik";
import TextError from "./TextError";
import { FormInputLabel } from "./FormInputLabel";
import { classNames } from "primereact/utils";
import { MultiSelect, MultiSelectProps } from "primereact/multiselect";
import { useMemo } from "react";
import { SelectItem } from "primereact/selectitem";
import * as Yup from "yup";

export interface FormikMultiSelectPros extends MultiSelectProps {
  label: string;
  name: string;
  showFilter?: boolean;
  options?: SelectItem[];
  validationSchema?: Yup.ObjectSchema<any, Yup.AnyObject, any, "">;
  isIndependent?: boolean;
  isRequired?: boolean;
  mutableOptions?: boolean;
}

function FormikMultiSelect({
  label,
  name,
  showFilter = false,
  options = [],
  validationSchema,
  isIndependent = false,
  mutableOptions = false,
  isRequired = undefined,
  ...rest
}: FormikMultiSelectPros) {
  const Component = useMemo(() => {
    return isIndependent ? FastField : Field;
  }, [isIndependent]);
  return (
    <>
      <FormInputLabel
        nameFor={name}
        validationSchema={validationSchema}
        forceIsRequired={isRequired}
      >
        {label}
      </FormInputLabel>
      <Component name={name}>
        {({
          form,
          field,
          meta,
        }: {
          form: FormikContextType<any>;
          field: FieldInputProps<any>;
          meta: FieldMetaProps<any>;
        }) => {
          return (
            <MultiSelect
              maxSelectedLabels={1}
              id={name}
              className={classNames({
                "w-full": true,
                "p-invalid": meta.error && meta.touched,
              })}
              type="text"
              onClick={(e) => e.stopPropagation()}
              options={options}
              placeholder={label}
              value={
                mutableOptions
                  ? field.value.filter((x: any) =>
                      options.some((y) => y.value === x)
                    )
                  : field.value
              }
              onHide={() => form.setFieldTouched(name, true)}
              onChange={(e) => {
                e.originalEvent.stopPropagation();
                form.setFieldValue(name, e.target.value);
              }}
              filter={showFilter}
              {...rest}
            />
          );
        }}
      </Component>
      <ErrorMessage
        component={TextError}
        name={name}
      />
    </>
  );
}

export default FormikMultiSelect;
