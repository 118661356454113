import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindowF,
  PolylineF,
  CircleF,
} from "@react-google-maps/api";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { MarkerModel } from "./marker-model";
import { LocationLog } from "../../../queries/models/location-log.model";
import { GEOGRAPHICAL_MIDDLE_OF_BELGIUM } from "../../../constants/geographical-middle-of-belgium.constants";
import Enumerable from "linq";

export interface MapWithMarkersProps {
  markers: MarkerModel[];
  strokes: LocationLog[];
  currenPosition?: LocationLog;
  onPositionHover?: (position?: LocationLog) => void;
  showInfoWindows: boolean;
  disableDefaultMapUI?: boolean;
  mapBorderRadius?: string;
}

export function MapWithMarkers({
  markers,
  strokes,
  currenPosition,
  showInfoWindows,
  disableDefaultMapUI = true,
  mapBorderRadius = "0",
  onPositionHover,
}: MapWithMarkersProps) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    language: "en",
    version: "beta",
  });

  const locationIconOptions = useMemo(() => {
    if (!isLoaded) return undefined;
    return {
      url: "https://maps.google.com/mapfiles/kml/shapes/placemark_circle.png",
      anchor: new google.maps.Point(16, 16),
      size: new google.maps.Size(32, 32),
    };
  }, [isLoaded]);

  const [mapCenter, setMapCenter] = useState({
    lat: GEOGRAPHICAL_MIDDLE_OF_BELGIUM.latitude,
    lng: GEOGRAPHICAL_MIDDLE_OF_BELGIUM.longitude,
  });
  const [mapZoom, setMapZoom] = useState(6);
  const [openInfoWindows, setOpenInfoWindows] = useState<MarkerModel[]>([]);

  const lastUpdatedMapCenter = useRef<{ lat: number; lng: number }>();
  useEffect(() => {
    if (isLoaded && markers.length > 0) {
      const markerList = Enumerable.from(markers);
      let minLat = markerList.min((x) => x.latitude);
      let maxLat = markerList.max((x) => x.latitude);
      let minLng = markerList.min((x) => x.longitude);
      let maxLng = markerList.max((x) => x.longitude);

      const centerLat = (maxLat + minLat) / 2;
      const centerLng = (maxLng + minLng) / 2;
      if (
        !lastUpdatedMapCenter.current ||
        lastUpdatedMapCenter.current.lat !== centerLat ||
        lastUpdatedMapCenter.current.lng !== centerLng
      ) {
        lastUpdatedMapCenter.current = { lat: centerLat, lng: centerLng };
        setMapCenter(lastUpdatedMapCenter.current);

        const latZoom =
          Math.floor(Math.log2(360 / Math.abs(maxLat - minLat))) + 0.75;
        const lngZoom =
          Math.floor(Math.log2(360 / Math.abs(maxLng - minLng))) + 0.75;
        const calculatedZoom = Math.min(latZoom, lngZoom, 15);
        setMapZoom(calculatedZoom);

        if (showInfoWindows) {
          setOpenInfoWindows(markers);
        }
      }
    } else {
      lastUpdatedMapCenter.current = undefined;
    }
  }, [isLoaded, markers, showInfoWindows]);

  function showInfoWindow(marker: MarkerModel) {
    if (openInfoWindows.find((x) => x === marker)) return;
    setOpenInfoWindows([...openInfoWindows, marker]);
  }
  function hideInfoWindow(marker: MarkerModel) {
    setOpenInfoWindows(openInfoWindows.filter((x) => x !== marker));
  }

  return (
    <>
      {isLoaded && (
        <div className="h-full w-12">
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "100%",
              borderRadius: mapBorderRadius,
            }}
            center={mapCenter}
            zoom={mapZoom}
            options={{
              disableDefaultUI: disableDefaultMapUI,
            }}
            onMouseOut={(e) => {
              onPositionHover?.(undefined);
            }}
          >
            <PolylineF
              path={strokes.map((x) => {
                return { lat: x.latitude, lng: x.longitude };
              })}
            />
            {strokes.map((x, i) => (
              <MarkerF
                key={i}
                position={{ lat: x.latitude, lng: x.longitude }}
                icon={locationIconOptions}
                onMouseOver={(e) => {
                  onPositionHover?.(x);
                }}
                options={{
                  zIndex: 0,
                  draggable: false,
                }}
              />
            ))}
            {markers.map((marker, index) => (
              <Fragment key={index}>
                <MarkerF
                  onClick={(x) => showInfoWindow(marker)}
                  position={{ lat: marker.latitude, lng: marker.longitude }}
                  title={marker.title}
                  icon={{
                    url:
                      marker.markerIconUrl ??
                      "http://maps.google.com/mapfiles/kml/paddle/red-circle.png",
                    scaledSize: new window.google.maps.Size(
                      marker.iconSize ?? 45,
                      marker.iconSize ?? 45
                    ),
                  }}
                >
                  {openInfoWindows.find((x) => x === marker)
                    ?.showInfoWindow && (
                    <InfoWindowF
                      onCloseClick={() => hideInfoWindow(marker)}
                      position={{
                        lat: marker.latitude,
                        lng: marker.longitude,
                      }}
                      options={
                        {
                          headerContent: marker.title,
                        } as any
                      }
                    >
                      <div className="w-12 h-full">{marker.infoWindowBody}</div>
                    </InfoWindowF>
                  )}
                </MarkerF>
                {marker.radius && (
                  <CircleF
                    center={{ lat: marker.latitude, lng: marker.longitude }}
                    radius={marker.radius}
                    options={{
                      fillColor: "#4CAF50",
                      fillOpacity: 0.55,
                      strokeColor: "#4CAF50",
                      strokeWeight: 1.2,
                      clickable: false,
                      draggable: false,
                    }}
                  />
                )}
              </Fragment>
            ))}
            {currenPosition && (
              <>
                <CircleF
                  center={{
                    lat: currenPosition.latitude,
                    lng: currenPosition.longitude,
                  }}
                  radius={currenPosition.accuracy + 1}
                  options={{
                    fillColor: "blue",
                    fillOpacity: 0.55,
                    strokeColor: "blue",
                    strokeWeight: 1.2,
                    clickable: false,
                    draggable: false,
                  }}
                />

                <MarkerF
                  position={{
                    lat: currenPosition.latitude,
                    lng: currenPosition.longitude,
                  }}
                  icon={{
                    url: "https://maps.gstatic.com/mapfiles/ms2/micons/man.png",
                    scaledSize: new window.google.maps.Size(45, 45),
                  }}
                  draggable={false}
                  clickable={false}
                  zIndex={10}
                ></MarkerF>
              </>
            )}
          </GoogleMap>
        </div>
      )}
    </>
  );
}
