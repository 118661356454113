import { isWithinInterval } from "date-fns";
import { FilterService } from "primereact/api";

// parsers
export const dateParser = (x?: (string | null)[]) =>
  x && (Array.isArray(x) ? x.map((y) => y && new Date(y)) : new Date(x));

// filter functions
export const CustomFilterFunctions = {
  dateRange: (value: any, filter: (Date | null)[]) => {
    if (!filter || filter.length !== 2 || (!filter[0] && !filter[1])) {
      return true;
    }
    const [startDate, endDate] = filter;
    if (value === null || value === undefined) {
      return false;
    }
    const startOfDay = new Date(startDate ?? 0);
    startOfDay.setHours(0, 0, 0, 0);

    const endOfDay = new Date(endDate ?? "9999-01-01T00:00:00Z");
    endOfDay.setHours(23, 59, 59, 999);

    return isWithinInterval(value, {
      start: startOfDay,
      end: endOfDay,
    });
  },
} satisfies {
  [key: string]: (value: any, filter: any) => boolean;
};

export const CustomFilterMatchMode = Object.fromEntries(
  Object.keys(CustomFilterFunctions).map((x) => [x, x])
) as {
  [key in keyof typeof CustomFilterFunctions]: key;
};

export function registerCustomFilters() {
  Object.entries(CustomFilterFunctions).forEach(([name, func]) => {
    FilterService.register(name, func);
  });
}
