import { useState, useCallback, useEffect } from "react";
import { Fieldset } from "primereact/fieldset";
import { MobileOverviewSectionComponent } from "./MobileOverviewSectionComponent";
import { MobileWorkSectionComponent } from "./MobileWorkSectionComponent";
import { MobileWorkDetailsSectionComponent } from "./MobileWorkDetailsSectionComponent";
import { useQueryClient } from "react-query";
import { useOrdersValidatorUsersOverviewQuery } from "../../../queries/orders-validator.query";
import { UserMonthlyOrdersOverviewItem } from "../../../queries/models/user-monthly-orders-overview.model";
import { ValidatedWorkInstance } from "../../../queries/models/validated-work-instance.model";
import { useOrdersValidatorUserValidationQuery } from "../../../queries/orders-validator.query";
import { useWorkInstanceDetailsQuery } from "../../../queries/orders-validator.query";
import { UserMonthlyOrdersValidation } from "../../../queries/models/user-monthly-orders-validation.model";
import Enumerable from "linq";
import { OrderStatus } from "../../../queries/models/enums/order-status.enum";
import { SendOrdersRequest } from "../../../queries/models/send-orders-request";
import { useSendOrdersMutation } from "../../../queries/orders-validator.query";
import { InstallatorTravelOrder } from "../../../queries/models/orders/instllator-travel-order.model";
import { InstallatorWorkTimeOrder } from "../../../queries/models/orders/instllator-worktime-order.model";
import { LoaderWrapper } from "../../../components/ui/LoaderWrapper";
import { useTranslation } from "react-i18next";

export function MobileOrdersValidatorPage() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [date, setDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth())
  );
  const [selectedOverviewItem, setSelectedOvervievItem] =
    useState<UserMonthlyOrdersOverviewItem>();
  const [selectedUserValidation, setSelectedUserValidation] =
    useState<UserMonthlyOrdersValidation>();
  const [selectedWorkInstance, setSelectedWorkInstance] =
    useState<ValidatedWorkInstance>();

  const overviewQuery = useOrdersValidatorUsersOverviewQuery(date);
  const validationQuery = useOrdersValidatorUserValidationQuery(
    selectedOverviewItem?.userId,
    date
  );
  const workInstanceDetailsQuery = useWorkInstanceDetailsQuery(
    selectedWorkInstance?.id
  );
  const sendOrdersMutation = useSendOrdersMutation();

  useEffect(() => {
    if (validationQuery.data) {
      setSelectedUserValidation(validationQuery.data);
    }
    if (selectedWorkInstance) {
      setSelectedWorkInstance(
        validationQuery.data?.workInstances.find(
          (x) => x.id === selectedWorkInstance.id
        )
      );
    }
  }, [validationQuery.data, selectedWorkInstance]);

  const handleSendUserOrders = useCallback(
    (sub: UserMonthlyOrdersValidation) => {
      let workOrdersIds = Enumerable.from(sub.workInstances)
        .selectMany((x) => x.installatorWorkTimeOrders)
        .where((x) => x?.status !== OrderStatus.Sent)
        .select((x) => x.id)
        .toArray();

      let travelOrdersIds = Enumerable.from(sub.workInstances)
        .selectMany((x) => x.installatorTravelOrders)
        .where((x) => x?.status !== OrderStatus.Sent)
        .select((x) => x.id)
        .toArray();

      let request: SendOrdersRequest = {
        orderIds: [...workOrdersIds, ...travelOrdersIds],
      };
      sendOrdersMutation.mutateAsync(request).then(() => {
        queryClient.invalidateQueries("ordersValidator");
      });
    },
    [queryClient, sendOrdersMutation]
  );

  const handleSendWorkInstanceOrders = useCallback(
    (sub: ValidatedWorkInstance) => {
      let workOrdersIds = Enumerable.from(sub.installatorWorkTimeOrders)
        .where((x) => x?.status !== OrderStatus.Sent)
        .select((x) => x.id)
        .toArray();

      let travelOrdersIds = Enumerable.from(sub.installatorTravelOrders)
        .where((x) => x?.status !== OrderStatus.Sent)
        .select((x) => x.id)
        .toArray();

      let request: SendOrdersRequest = {
        orderIds: [...workOrdersIds, ...travelOrdersIds],
      };

      sendOrdersMutation.mutateAsync(request).then(() => {
        queryClient.invalidateQueries("ordersValidator");
      });
    },
    [queryClient, sendOrdersMutation]
  );

  const handleSendSingleOrder = useCallback(
    (sub: InstallatorTravelOrder | InstallatorWorkTimeOrder) => {
      const request: SendOrdersRequest = {
        orderIds: [sub.id],
      };

      sendOrdersMutation.mutateAsync(request).then(() => {
        queryClient.invalidateQueries("ordersValidator");
      });
    },
    [queryClient, sendOrdersMutation]
  );

  return (
    <div>
      <div className="flex flex-column gap-2 py-1 mb-8 mt-2 overflow-y-scroll">
        <Fieldset
          toggleable={true}
          legend={t("common.overview")}
        >
          <LoaderWrapper isLoading={overviewQuery.isLoading}>
            <MobileOverviewSectionComponent
              items={overviewQuery.data ?? []}
              date={date}
              selectedItem={selectedOverviewItem}
              onDateChange={setDate}
              onSelectItem={(e) => {
                setSelectedOvervievItem(e);
                setSelectedWorkInstance(undefined);
              }}
            />
          </LoaderWrapper>
        </Fieldset>
        <Fieldset
          toggleable={true}
          legend={t("common.work")}
        >
          <LoaderWrapper isLoading={validationQuery.isLoading}>
            <MobileWorkSectionComponent
              validation={selectedUserValidation}
              selectedOverviewItem={selectedOverviewItem}
              selectedItem={selectedWorkInstance}
              onSelectItem={setSelectedWorkInstance}
              onSendUserOrders={handleSendUserOrders}
              onSendWorkInstanceOrders={handleSendWorkInstanceOrders}
            />
          </LoaderWrapper>
        </Fieldset>
        <Fieldset
          toggleable={true}
          legend={t("common.details")}
        >
          <LoaderWrapper isLoading={workInstanceDetailsQuery.isLoading}>
            <MobileWorkDetailsSectionComponent
              selectedOverViewItem={selectedOverviewItem}
              validation={selectedUserValidation}
              workInstance={workInstanceDetailsQuery}
              onSendOrder={handleSendSingleOrder}
            />
          </LoaderWrapper>
        </Fieldset>
      </div>
    </div>
  );
}
