function generateBreakingText(
  context: CanvasRenderingContext2D,
  text: string,
  maxWidth: number,
  options?: {
    breakOnlyOneLine?: boolean;
  }
): string[] {
  const result: string[] = [];
  if (!text) return result;
  const words = text.split(/\s+/);
  let measure = context.measureText(text);
  let i = 0;
  while (i < words.length) {
    let line = "";
    while (i < words.length) {
      const currentWord = words[i];
      let test = line + (line.length > 0 ? " " : "") + currentWord;
      measure = context.measureText(test);
      if (measure.width > maxWidth) {
        if (line.length === 0) {
          for (let j = 0; j < currentWord.length; j++) {
            test = line + currentWord[j];
            measure = context.measureText(test);
            if (measure.width > maxWidth) {
              if (j === 0) {
                line = test;
              }
              const leftovers = currentWord.slice(Math.max(j, 1));
              words[i] = leftovers;
              i--;
              break;
            }
            line = test;
          }
          i++;
        }
        break;
      }
      line = test;
      i++;
    }
    result.push(line);
    if (options?.breakOnlyOneLine) {
      if (i < words.length) result.push(words.slice(i).join(" "));
      break;
    }
  }

  return result;
}

type BreakingTextOptions = {
  fontSize?: number;
  firstLineIndent?: number;
  maxWidth?: number;
  fillStyle?: string;
};

type BreakingTextMeasurment = {
  box: {
    height: number;
  };
  lines: string[];
};

export function measureBreakingText(
  context: CanvasRenderingContext2D,
  text: string | string[] | undefined,
  posX: number,
  posY: number,
  options?: BreakingTextOptions
): [BreakingTextMeasurment, () => void] {
  const o = {
    fontSize: options?.fontSize ?? 24,
    firstLineIndent: options?.firstLineIndent ?? 0,
    maxWidth: options?.maxWidth,
    fillStyle: options?.fillStyle ?? "black",
  };

  let lines: string[] = [];
  if (!text) {
  } else if (Array.isArray(text)) {
    lines = text;
  } else if (o.maxWidth === undefined) {
    lines = [text];
  } else if (o.firstLineIndent === undefined) {
    lines = generateBreakingText(context, text, o.maxWidth);
  } else {
    const [firstLine, rest] = generateBreakingText(
      context,
      text,
      o.maxWidth - o.firstLineIndent,
      {
        breakOnlyOneLine: true,
      }
    );
    lines = [
      firstLine,
      ...generateBreakingText(context, rest ?? "", o.maxWidth),
    ];
  }

  function draw() {
    context.save();
    context.textBaseline = "top";
    context.font = `${o.fontSize}px Arial`;
    context.fillStyle = o.fillStyle;

    lines.forEach((t, i) => {
      if (i === 0 && o.firstLineIndent) {
        context.fillText(t, posX + o.firstLineIndent, posY + o.fontSize * i);
      } else {
        context.fillText(t, posX, posY + o.fontSize * i);
      }
    });
    context.restore();
  }

  return [
    {
      lines: lines,
      box: {
        height: lines.length * o.fontSize,
      },
    },
    draw,
  ];
}
