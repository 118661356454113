import { classNames } from "primereact/utils";
import { ReactNode, useMemo } from "react";

enum ShadowSize {
  xSmall,
  small,
  medium,
  large,
  xLarge,
}

type ShadowOptions<T = boolean> = { [key in keyof typeof ShadowSize]: T };

type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
      Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

type RoundedShadowContainerProps = RequireOnlyOne<ShadowOptions> & {
  children: ReactNode;
  mobile?: boolean;
  fullHeight?: boolean;
  className?: string;
};

const shadowSizeMap: Record<keyof ShadowOptions, string> = {
  xSmall: "shadow-2",
  small: "shadow-3",
  medium: "shadow-4",
  large: "shadow-6",
  xLarge: "shadow-8",
};

export function RoundedShadowContainer(props: RoundedShadowContainerProps) {
  const shadowOption = useMemo(
    () =>
      Object.keys(props).find((key) =>
        Object.keys(ShadowSize).includes(key)
      ) as keyof ShadowOptions,
    [props]
  );

  const selectedShadowClass = shadowOption ? shadowSizeMap[shadowOption] : "";

  return (
    <div
      className={classNames(
        props.mobile ?? true
          ? `border-round-xl w-full 
      ${props.fullHeight ? "h-full" : ""}  ${selectedShadowClass} ${
              props.className
            }`
          : "h-full"
      )}
    >
      {props.children}
    </div>
  );
}
