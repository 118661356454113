import { Form, Formik } from "formik";
import * as Yup from "yup";
import { AddNoteRequest } from "../../../queries/models/add-note-request";
import { NoteSeverity } from "../../../queries/models/enums/note-severity";
import FormikInputSwitch from "../formik/FormikInputSwitch";
import { useCallback, useMemo, useState } from "react";
import FormikInputTextarea from "../formik/FormikInputTextarea";
import { Button } from "primereact/button";
import { AddNoteInfoWrapper } from "../../../queries/models/add-note-info-wrapper";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { useLoggedUser } from "../../../hooks/useLoggedUser";
import { FileUpload } from "../file-upload/file-upload";
import { FileInfo } from "../../../utils/file-info";
import { ImageWrapper } from "../ImageWrapper";
import { GalleryPreview } from "../GalleryPreview/gallery-preview";
import { ImageModel } from "../../../queries/models/image.model";

interface AddNoteItemProps {
  onSave: (e: AddNoteRequest) => void;
  onCancel: () => void;
  addingWithWebcam?: boolean;
}

export function AddNoteItem({
  onSave,
  onCancel,
  addingWithWebcam = false,
}: AddNoteItemProps) {
  const loggedUser = useLoggedUser();
  const { t } = useTranslation();
  const [isConfidential, setIsConfidential] = useState<boolean>(false);
  const [photoFiles, setPhotoFiles] = useState<FileInfo[]>([]);
  const [activeGalleryIndex, setActiveGalleryIndex] = useState<number>();
  const [files, setFiles] = useState<FileInfo[]>([]);

  const photos = useMemo(() => {
    return photoFiles.map(
      (x) =>
        ({
          isPublic: true,
          imageSrc: x.blob,
          thumbnailSrc: x.blob,
        } as ImageModel)
    );
  }, [photoFiles]);

  const initialValues: AddNoteInfoWrapper = {
    message: "",
    severity: NoteSeverity.OperatorInput,
    isConfidential: false,
    noteId: 0,
    files: [],
    cameraAddedFiles: [],
  };

  const validationSchema = Yup.object({
    message: Yup.string().required(t("alert.required")),
    isConfidential: Yup.boolean(),
    files: Yup.array(),
    cameraAddedFiles: Yup.array(),
  });

  const handleFilesChoosen = useCallback((files: FileList, formik: any) => {
    var chosenFiles = Array.from(files).map((file) => {
      let fi = {
        value: null,
        name: file.name,
        size: file.size,
        blob: file,
        type: file.type,
      } as FileInfo;

      return fi;
    });

    var filesArray = formik.values.files.concat(chosenFiles);

    setPhotoFiles(
      filesArray.filter((x: FileInfo) => x.type!.includes("image"))
    );
    setFiles(filesArray.filter((x: FileInfo) => !x.type!.includes("image")));

    formik.setFieldValue("files", filesArray);
  }, []);

  const handleAddNote = useCallback(
    (e: AddNoteInfoWrapper) => {
      const request: AddNoteRequest = {
        message: e.message,
        severity: e.severity,
        isConfidential: e.isConfidential,
        noteId: e.noteId,
        files: (e.files ?? []).concat(e.cameraAddedFiles ?? []),
      };
      onSave(request);
    },
    [onSave]
  );

  const handleDeleteFile = useCallback(
    (file: FileInfo, formik: any) => () => {
      var filesArray = formik.values.files.filter(
        (x: FileInfo) => x.name !== file.name
      );
      setFiles(filesArray.filter((x: FileInfo) => !x.type!.includes("image")));
      formik.setFieldValue("files", filesArray);
    },
    []
  );

  const handleDeletePhoto = useCallback(
    (index: number, formik: any) => () => {
      var filesArray = formik.values.files.filter(
        (x: FileInfo) => x.name !== photoFiles[index].name
      );
      setPhotoFiles(photoFiles.filter((x, i) => i !== index));
      formik.setFieldValue("files", filesArray);
    },
    [photoFiles]
  );

  return (
    <div
      className="flex mx-2 mt-2"
      style={{ width: "calc(100% - 1rem)" }}
    >
      <div
        className="pt-2"
        style={{ width: "50px" }}
      >
        <div
          style={{
            width: "50px",
            height: "50px",
            fontSize: "25px",
            borderRadius: "100%",
            backgroundColor: "var(--primary-color)",
          }}
          className="justify-content-center align-items-center flex shadow-3"
        >
          <FontAwesomeIcon
            icon={faUser}
            style={{
              color: "white",
            }}
          />
        </div>
      </div>

      <div
        style={{
          width: "calc(100% - 50px - 0.5rem)",
          marginLeft: "0.5rem",
          backgroundColor: "#EDEDED",
          borderRadius: "12px",
        }}
        className="shadow-3"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleAddNote}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {(formik) => (
            <Form className="h-full flex flex-column w-full p-2">
              <div className="flex">
                <div className="w-6 text-xl font-medium">
                  {loggedUser?.username}
                </div>
                <div className="w-6 flex justify-content-end">
                  <FormikInputSwitch
                    className=""
                    inlineLabel={true}
                    label={t("common.confidential")}
                    name="isConfidential"
                    validationSchema={validationSchema}
                    checked={isConfidential}
                    onChange={(e) => {
                      setIsConfidential(!isConfidential);
                      formik.setFieldValue("isConfidential", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="w-full py-1">
                <FormikInputTextarea
                  validationSchema={validationSchema}
                  showLabel={false}
                  label={t("common.message")}
                  name="message"
                  rows={3}
                />
              </div>

              <div className="w-full flex flex-row flex-wrap align-items-end">
                <div className="w-12">
                  <div className="flex justify-content-start flex-wrap my-1">
                    {photoFiles && activeGalleryIndex !== undefined && (
                      <GalleryPreview
                        images={photos}
                        activeIndex={activeGalleryIndex}
                        onItemChange={(e) => setActiveGalleryIndex(e)}
                        onClose={() => setActiveGalleryIndex(undefined)}
                      />
                    )}
                    {photos.map((image, index) => {
                      let imgEl = (
                        <div className="cursor-pointer">
                          <div className="relative px-1">
                            <ImageWrapper
                              key={index}
                              imageSrc={image.imageSrc}
                              width="64px"
                              height="64px"
                              preview={true}
                              onClick={() => setActiveGalleryIndex(index)}
                            />
                            <FontAwesomeIcon
                              icon={faTimes}
                              style={{
                                top: "-8px",
                                right: "0px",
                                zIndex: 1,
                              }}
                              size="lg"
                              className="absolute cursor-pointer text-red-500 hover:text-red-800 pl-2"
                              onClick={handleDeletePhoto(index, formik)}
                            />
                          </div>
                        </div>
                      );
                      return <div key={index}>{imgEl}</div>;
                    })}
                  </div>
                  <div>
                    <div className="my-1">
                      {files.map((file) => (
                        <div className="flex align-items-center">
                          <div className="font-italic">{file.name}</div>
                          <FontAwesomeIcon
                            icon={faTimes}
                            size="lg"
                            className="cursor-pointer text-red-500 hover:text-red-800 pl-2"
                            onClick={handleDeleteFile(file, formik)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="w-12 flex gap-1 justify-content-end">
                  <FileUpload
                    inputKey="file-upload"
                    accept="*"
                    icon="paperclip"
                    onFilesChoosen={(files) =>
                      handleFilesChoosen(files, formik)
                    }
                  />
                  <FileUpload
                    inputKey="image-upload"
                    accept="image/*"
                    icon="image"
                    capture="user"
                    onFilesChoosen={(files) =>
                      handleFilesChoosen(files, formik)
                    }
                  />
                  <Button
                    label={t("common.cancel")}
                    type="button"
                    className="yellow-button"
                    onClick={onCancel}
                    size="small"
                  />
                  <Button
                    label={t("common.save")}
                    type="submit"
                    className="green-action-button"
                    disabled={!formik.isValid || !formik.dirty}
                    size="small"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
