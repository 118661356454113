import { format } from "date-fns";
import { WorkDetailsSectionComponentProps } from "../WorkDetailsSectionComponent";
import { TimeSpan } from "../../../utils/timespan";
import { OrderStatus } from "../../../queries/models/enums/order-status.enum";
import { Button } from "primereact/button";
import { useState, useMemo } from "react";
import React from "react";
import { MobileEditableTime } from "../../../components/ui/MobileUIComponents/MobileEditableTime";
import { InstallatorWorkTimeOrder } from "../../../queries/models/orders/instllator-worktime-order.model";
import { useUpdateWorkTimeMutation } from "../../../queries/orders-validator.query";
import { useQueryClient } from "react-query";
import {
  CustomModal,
  CustomModalProps,
} from "../../../components/ui/MobileModal/custom-modal";
import { SpaceBeforeCapital } from "../../../utils/space-before-capital";
import { WorkInstanceType } from "../../../queries/models/enums/work-instance-type.enum";
import { useTranslation } from "react-i18next";

export function MobileWorkDetailsSectionComponent({
  selectedOverViewItem,
  workInstance,
  validation,
  onSendOrder,
}: WorkDetailsSectionComponentProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [selectedLog, setSelectedLog] = useState<string[]>();
  const [logDialogVisible, setLogDialogVisible] = useState(false);
  const [
    selectedInstallatorWorkTimeOrder,
    setSelectedInstallatorWorkTimeOrder,
  ] = useState<InstallatorWorkTimeOrder>();

  const [editWorkTimeDialogVisible, setEditWorkTimeDialogVisible] =
    useState(false);

  const updateWorkTimeMutation = useUpdateWorkTimeMutation();

  const handleOrderDurationUpdate = (timeSpan: TimeSpan, comment: string) => {
    if (selectedInstallatorWorkTimeOrder) {
      updateWorkTimeMutation
        .mutateAsync({
          orderid: selectedInstallatorWorkTimeOrder?.id,
          durationTicks: timeSpan.ticks,
          comment: comment,
        })
        .then(() => {
          queryClient.invalidateQueries("ordersValidator");
          setEditWorkTimeDialogVisible(false);
        });
    }
  };

  const workTimeOrdersElement = useMemo(
    () => (
      <div className="surface-200 p-2 border-round">
        <div className="underline font-bold">Work time orders</div>
        {workInstance.data
          ? workInstance.data.installatorWorkTimeOrders?.map((x) => (
              <React.Fragment key={x.id}>
                <div className="flex justify-content-evenly align-items-center">
                  <div className="text-lg">#{x.id}</div>
                  <div className="text-lg">
                    {TimeSpan.fromTicks(x.durationTicks).toHhMm()}
                  </div>
                  <div className="text-lg">{OrderStatus[x.status]}</div>
                </div>
                <div className="flex gap-1">
                  <Button
                    className="yellow-button w-full"
                    label="Display log"
                    onClick={() => {
                      setSelectedLog(x.messageLog.split("\n"));
                      setLogDialogVisible(true);
                    }}
                  />
                  <Button
                    className="w-full"
                    label="Edit work time"
                    disabled={x.status === OrderStatus.Sent}
                    onClick={() => {
                      setSelectedInstallatorWorkTimeOrder(x);
                      setEditWorkTimeDialogVisible(true);
                    }}
                  />
                  <Button
                    className="green-action-button w-full"
                    label="Send"
                    disabled={
                      x.status === OrderStatus.Sent ||
                      !selectedOverViewItem?.hasOdooIdMapping
                    }
                    onClick={() => onSendOrder(x)}
                  />
                </div>
              </React.Fragment>
            ))
          : ""}
      </div>
    ),
    [onSendOrder, selectedOverViewItem?.hasOdooIdMapping, workInstance.data]
  );

  const travelOrdersElement = useMemo(
    () => (
      <div className="surface-200 p-2 border-round">
        <div className="underline font-bold">Work travel orders</div>
        {workInstance.data
          ? workInstance.data.installatorTravelOrders?.map((x) => (
              <React.Fragment key={x.id}>
                <div className="flex justify-content-evenly align-items-center">
                  <div className="text-lg">#{x.id}</div>
                  <div className="text-lg">{x.distanceInKm}km</div>
                  <div className="text-lg">{OrderStatus[x.status]}</div>
                </div>
                <div className="flex gap-1">
                  <Button
                    className="yellow-button w-full"
                    label={t("common.displayLog")}
                    onClick={() => {
                      setSelectedLog(x.messageLog.split("\n"));
                      setLogDialogVisible(true);
                    }}
                  />
                  <Button
                    className="green-action-button w-full"
                    label={t("common.send")}
                    disabled={x.status === OrderStatus.Sent}
                    onClick={() => onSendOrder(x)}
                  />
                </div>
              </React.Fragment>
            ))
          : ""}
      </div>
    ),
    [onSendOrder, workInstance.data]
  );

  const selectedLogBody = (
    <div
      className="w-full flex flex-column"
      style={{ overflowWrap: "break-word" }}
    >
      {selectedLog &&
        selectedLog.map(
          (x, i) =>
            i !== selectedLog.length - 1 && (
              <div
                key={i}
                className="w-full mt-2 "
                style={{ borderBottom: "1px rgba(199, 199, 199, 0.8) solid" }}
              >
                {x}
              </div>
            )
        )}
    </div>
  );

  const logMobileModalProps: CustomModalProps = {
    header: t("common.log"),
    isOpen: logDialogVisible,
    height: "fit-content",
    justified: true,
    centered: true,
    body: selectedLogBody,
    onClose: () => setLogDialogVisible(false),
  };

  const editWorkTimeMobileModalProps: CustomModalProps = {
    header: t("common.editWorkTime"),
    isOpen: editWorkTimeDialogVisible,
    justified: true,
    centered: true,
    width: "80%",
    height: "fit-content",
    body: selectedInstallatorWorkTimeOrder && (
      <MobileEditableTime
        timeSpan={TimeSpan.fromTicks(
          selectedInstallatorWorkTimeOrder.durationTicks
        )}
        onEditTime={(timeSpan, comment) =>
          handleOrderDurationUpdate(timeSpan, comment)
        }
        isLoading={updateWorkTimeMutation.isLoading}
      />
    ),
    onClose: () => setEditWorkTimeDialogVisible(false),
  };

  return (
    <>
      <CustomModal {...logMobileModalProps} />
      <CustomModal {...editWorkTimeMobileModalProps} />
      {workInstance.data ? (
        <>
          <div className="flex justify-content-between">
            <div className="text-lg font-bold">
              {workInstance.data ? workInstance?.data.installation?.name : ""}
            </div>
            <div className="text-lg font-bold">
              {workInstance.data
                ? format(workInstance?.data.dateStarted!, "dd/LL/yyyy")
                : ""}
            </div>
          </div>

          <div className="w-full grid pt-1">
            <div className="col-5 text-500 text-right">
              {t("common.startedWork")}:
            </div>
            <div className="col-7">
              {workInstance.data
                ? format(workInstance.data.dateStarted!, "HH:mm:ss dd/LL/yyyy")
                : ""}
            </div>
            <div className="col-5 text-500 text-right">
              {t("common.endedWork")}:
            </div>
            <div className="col-7">
              {workInstance.data
                ? format(workInstance.data.dateEnded!, "HH:mm:ss dd/LL/yyyy")
                : ""}
            </div>
            <div className="col-5 text-500 text-right">
              {t("common.totalWork")}:
            </div>
            <div className="col-7">
              {workInstance.data
                ? TimeSpan.fromTicks(
                    workInstance.data.summary?.workDurationTicks
                  ).toHhMm()
                : ""}
            </div>
            <div className="col-5 text-500 text-right">
              {t("common.totalPauses")}:
            </div>
            <div className="col-7">
              {workInstance.data
                ? TimeSpan.fromTicks(
                    workInstance.data.summary?.pauseDurationTicks
                  ).toHhMm()
                : ""}
              {validation?.user.includePauseTimeForInvoicing && (
                <span className="text-red-500">
                  {` (${t("common.pauseTimeIncludedInWorktime")})`}
                </span>
              )}
            </div>
            <div className="col-5 text-500 text-right">
              {t("common.workType")}:
            </div>
            <div className="col-7">
              {workInstance.data
                ? SpaceBeforeCapital.transform(
                    WorkInstanceType[workInstance.data.type]
                  )
                : ""}
            </div>
          </div>

          <div className="pt-1">
            <div className="flex flex-column gap-1">
              {workInstance.data?.installatorWorkTimeOrders?.length !== 0 &&
                workTimeOrdersElement}
              {workInstance.data?.installatorTravelOrders?.length !== 0 &&
                travelOrdersElement}
            </div>
          </div>
        </>
      ) : (
        <div
          style={{ height: "8vh" }}
          className="flex justify-content-center align-items-center text-xl font-bold text-500"
        >
          {t("common.SELECT_WORK_INSTANCE")}
        </div>
      )}
    </>
  );
}
