import { SelectItem } from "primereact/selectitem";
import { SpaceBeforeCapital } from "./space-before-capital";
import Enumerable from "linq";

export class EnumExtensions {
  static getLabelAndValues<T extends number>(e: any): SelectItem[] {
    return EnumExtensions.getNames(e).map((x) => ({
      label: SpaceBeforeCapital.transform(x),
      value: e[x] as T,
    }));
  }

  static getNames(e: any): string[] {
    return Object.values(e).filter((x) => typeof x === "string") as string[];
  }

  static getValues<T extends number>(e: any): T[] {
    return EnumExtensions.getObjValues(e).filter(
      (v) => typeof v === "number"
    ) as T[];
  }

  private static getObjValues(e: any): (number | string)[] {
    return Object.keys(e).map((k) => e[k]);
  }

  static getFlagValues(e: any, value: number) {
    const values = this.getLabelAndValues(e);
    const result = Enumerable.from(values)
      .where((x) => x!.value !== 0 && (x!.value & value) === x!.value)
      .select((x) => x.value)
      .toArray();

    return result;
  }

  static getSelectedValues(value: number) {
    const values = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048];
    const result = Enumerable.from(values)
      .where((x) => x !== 0 && (x! & value) === x)
      .toArray();

    return result;
  }
}
