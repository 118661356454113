import { useState, useMemo, useCallback, useEffect } from "react";
import { TaskState } from "../../queries/models/enums/task-state.enum";
import { BoardColumnElement } from "./BoardColumn/BoardColumnElement";
import { BoardWorkTask } from "../../queries/models/board-work-task.model";
import { Dialog } from "primereact/dialog";
import { SelectItem } from "primereact/selectitem";
import { WorkTaskManager } from "./WorkTaskManager";
import {
  useActiveBoardWorkTasksQuery,
  useUpdateWorkTasksIndexesMutation,
} from "../../queries/work-tasks.query";
import { useTranslation } from "react-i18next";
import { WorkTask } from "../../queries/models/work-task.model";
import { UpdateWorkTasksIndexesRequest } from "../../queries/models/update-work-tasks-indexes-request";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import { WorkTaskElementDragPreview } from "./WorkTaskElement/WorkTaskElementDragPreview";
import { useLocation, useNavigate } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

interface Props {
  showTasksCount: boolean;
  selectedInstallation: SelectItem | undefined;
  selectedUsers: SelectItem[];
  globalFilter: string | undefined;
}

export function TasksBoardElement({
  showTasksCount,
  selectedInstallation,
  selectedUsers,
  globalFilter,
}: Props) {
  const workTaskIdParamName = "workTaskId";
  const query = useQuery();
  const navigate = useNavigate();
  const queryTaskId = query.get(workTaskIdParamName)
    ? Number(query.get(workTaskIdParamName))
    : undefined;
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const workTasksQuery = useActiveBoardWorkTasksQuery();
  const updateWorkTasksIndexesMutation = useUpdateWorkTasksIndexesMutation();
  const [selectedWorkingTask, setSelectedWorkingTask] = useState<
    BoardWorkTask | undefined
  >(undefined);
  const [allWorkTasks, setAllWorkTasks] = useState<BoardWorkTask[]>([]);

  const taskMatchesFilters = useCallback(
    (task: BoardWorkTask) => {
      const {
        installationId,
        installation,
        name,
        description,
        assignedUserIds,
      } = task;
      const filterLowercase = globalFilter?.toLowerCase() ?? "";

      const matchesInstallation =
        !selectedInstallation || installationId === selectedInstallation;
      const matchesUsers =
        selectedUsers?.length === 0 || selectedUsers === null
          ? true
          : selectedUsers?.some((x) => assignedUserIds.includes(x as number));
      const matchesName = name?.toLowerCase().includes(filterLowercase);
      const matchesDescription = description
        ?.toLowerCase()
        .includes(filterLowercase);
      const matchesInstallationName = installation?.name
        ?.toLowerCase()
        .includes(filterLowercase);

      return (
        matchesInstallation &&
        matchesUsers &&
        (matchesName || matchesDescription || matchesInstallationName)
      );
    },
    [globalFilter, selectedInstallation, selectedUsers]
  );

  useEffect(() => {
    if (workTasksQuery.data) {
      const newData = workTasksQuery.data;
      let extraIndex = 0;
      newData.forEach((x, i) => {
        const itemIndex = x.boardIndex > 0 ? x.boardIndex : extraIndex--;

        x.index = itemIndex;
        x.initIndex = itemIndex;
        x.initState = x.state;
        x.isDragging = false;
      });

      const result = newData.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
      setAllWorkTasks(result);
    }
  }, [allWorkTasks.length, workTasksQuery.data]);

  const workTasks = useMemo(() => {
    if (!selectedInstallation && !globalFilter && !selectedUsers) {
      return allWorkTasks;
    } else {
      return allWorkTasks.filter(taskMatchesFilters);
    }
  }, [
    allWorkTasks,
    globalFilter,
    selectedInstallation,
    selectedUsers,
    taskMatchesFilters,
  ]);

  const handleBoardColumnItemOver = useCallback(
    (item: WorkTask, state: TaskState) => {
      const tasks = allWorkTasks;
      const task = tasks.find((x) => x.id === item.id);
      if (!task) return;
      task.isDragging = true;

      if (task.state === state) {
        return;
      }

      task.index = tasks.length;
      task.state = state;
      setAllWorkTasks([...tasks]);
    },
    [allWorkTasks]
  );

  const handleBoardColumnItemDraggingChange = useCallback(
    (item: WorkTask, isDragging: boolean) => {
      const tasks = allWorkTasks;
      const task = tasks.find((x) => x.id === item.id);
      if (!task) return;
      task.isDragging = isDragging;
      setAllWorkTasks([...tasks]);
    },
    [allWorkTasks]
  );

  const handleBoardColumnItemReorder = useCallback(
    (subject: BoardWorkTask, newIndex: number) => {
      let items = allWorkTasks;

      let itemToMove = items.find((x) => x.id === subject.id) as BoardWorkTask;
      itemToMove.state =
        items.find((x) => x.index === newIndex)?.state ?? itemToMove.state;
      itemToMove.isDragging = true;

      items = items.filter((item) => item.id !== itemToMove.id);

      items.splice(newIndex, 0, itemToMove);

      items.forEach((item, index) => {
        item.index = index;
      });
      setAllWorkTasks([...items]);
    },
    [allWorkTasks]
  );

  const handleItemDragReverted = useCallback(
    (item: BoardWorkTask) => {
      handleBoardColumnItemOver(item, item.initState!);
      handleBoardColumnItemReorder(item, item.initIndex!);
    },
    [handleBoardColumnItemOver, handleBoardColumnItemReorder]
  );

  const handleItemsSequenceChange = useCallback(async () => {
    const mutateOptions = {
      onSuccess: async () => {},
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error?.data,
        });
      },
    };

    const request: UpdateWorkTasksIndexesRequest = {
      items: allWorkTasks.map((x) => ({
        id: x.id ?? 0,
        index: x.index ?? 0,
      })),
    };

    await updateWorkTasksIndexesMutation.mutateAsync(request, mutateOptions);
  }, [allWorkTasks, toast, updateWorkTasksIndexesMutation]);

  const handleItemsSequencePrepended = useCallback(
    async (newId: number) => {
      const mutateOptions = {
        onSuccess: async () => {},
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      let items = allWorkTasks;

      items = items.filter((item) => item.id !== newId);

      items.splice(0, 0, { id: newId, index: 0 } as BoardWorkTask);

      items.forEach((item, index) => {
        item.index = index;
      });

      const request: UpdateWorkTasksIndexesRequest = {
        items: items.map((x, i) => ({
          id: x.id ?? 0,
          index: x.index ?? 0,
        })),
      };

      await updateWorkTasksIndexesMutation.mutateAsync(request, mutateOptions);
    },
    [allWorkTasks, toast, updateWorkTasksIndexesMutation]
  );

  useEffect(() => {
    if (queryTaskId !== undefined) {
      setSelectedWorkingTask(
        queryTaskId === 0
          ? ({ name: "" } as WorkTask)
          : workTasksQuery.data?.find((x) => x.id === queryTaskId)
      );
    }
  }, [queryTaskId, workTasksQuery.data]);

  const handleCloseWorkTaskDialog = useCallback(() => {
    setSelectedWorkingTask(undefined);
    const params = new URLSearchParams(window.location.search);
    params.delete(workTaskIdParamName);
    navigate({ search: params.toString() }, { replace: true });
  }, [navigate]);

  const handleSelectWorkingTask = useCallback(
    (workTask: WorkTask | undefined) => {
      setSelectedWorkingTask(undefined);
      const params = new URLSearchParams(window.location.search);
      params.set(
        workTaskIdParamName,
        workTask ? workTask.id?.toString() ?? "0" : ""
      );
      navigate({ search: params.toString() }, { replace: true });
    },
    [navigate]
  );

  return (
    <div
      className="flex gap-2 p-1"
      style={{ height: "calc(100% - 3rem)" }}
    >
      <BoardColumnElement
        items={workTasks?.filter((x) => x.state === TaskState.Backlog) ?? []}
        config={{
          stateRepresenting: TaskState.Backlog,
          showAddNewItemButton: true,
          color: "#FD8A8A",
          showTasksCount: showTasksCount,
        }}
        setSelectedWorkingTask={handleSelectWorkingTask}
        onRevertDrag={handleItemDragReverted}
        onItemOver={(item) =>
          handleBoardColumnItemOver(item, TaskState.Backlog)
        }
        onItemDragging={(item, isDragging) =>
          handleBoardColumnItemDraggingChange(item, isDragging)
        }
        onItemReorder={handleBoardColumnItemReorder}
        onItemsSequenceChange={handleItemsSequenceChange}
      />
      <BoardColumnElement
        items={workTasks?.filter((x) => x.state === TaskState.Todo) ?? []}
        config={{
          stateRepresenting: TaskState.Todo,
          showAddNewItemButton: false,
          color: "#FFD5A4",
          showTasksCount: showTasksCount,
        }}
        setSelectedWorkingTask={handleSelectWorkingTask}
        onRevertDrag={handleItemDragReverted}
        onItemOver={(item) => handleBoardColumnItemOver(item, TaskState.Todo)}
        onItemDragging={(item, isDragging) =>
          handleBoardColumnItemDraggingChange(item, isDragging)
        }
        onItemReorder={handleBoardColumnItemReorder}
        onItemsSequenceChange={handleItemsSequenceChange}
      />
      <BoardColumnElement
        items={workTasks?.filter((x) => x.state === TaskState.Doing) ?? []}
        config={{
          stateRepresenting: TaskState.Doing,
          showAddNewItemButton: false,
          color: "#9EA1D4",
          showTasksCount: showTasksCount,
        }}
        setSelectedWorkingTask={handleSelectWorkingTask}
        onRevertDrag={handleItemDragReverted}
        onItemOver={(item) => handleBoardColumnItemOver(item, TaskState.Doing)}
        onItemDragging={(item, isDragging) =>
          handleBoardColumnItemDraggingChange(item, isDragging)
        }
        onItemReorder={handleBoardColumnItemReorder}
        onItemsSequenceChange={handleItemsSequenceChange}
      />
      <BoardColumnElement
        items={workTasks?.filter((x) => x.state === TaskState.Finished) ?? []}
        config={{
          stateRepresenting: TaskState.Finished,
          showAddNewItemButton: false,
          color: "#C5DBC4",
          showTasksCount: showTasksCount,
        }}
        setSelectedWorkingTask={handleSelectWorkingTask}
        onRevertDrag={handleItemDragReverted}
        onItemOver={(item) =>
          handleBoardColumnItemOver(item, TaskState.Finished)
        }
        onItemDragging={(item, isDragging) =>
          handleBoardColumnItemDraggingChange(item, isDragging)
        }
        onItemReorder={handleBoardColumnItemReorder}
        onItemsSequenceChange={handleItemsSequenceChange}
      />

      <WorkTaskElementDragPreview />
      <Dialog
        header={
          selectedWorkingTask?.name ? t("common.edit") : t("common.addNewTask")
        }
        draggable={false}
        closeOnEscape={false}
        showHeader={false}
        style={{ height: "70%", maxWidth: "50vw" }}
        visible={!!selectedWorkingTask}
        onHide={handleCloseWorkTaskDialog}
        className={selectedWorkingTask?.noteId ? "w-8" : "w-6"}
      >
        {selectedWorkingTask && (
          <WorkTaskManager
            workingTask={selectedWorkingTask}
            setWorkingTask={setSelectedWorkingTask}
            onCancelEdition={handleCloseWorkTaskDialog}
            onSaveItemsSequenceChange={handleItemsSequencePrepended}
          />
        )}
      </Dialog>
    </div>
  );
}
