import { useState, useCallback } from "react";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { useActiveTagsQuery } from "../../queries/tags.query";
import { useAddTagMutation } from "../../queries/tags.query";
import { useUpdateTagMutation } from "../../queries/tags.query";
import { useDeleteTagMutation } from "../../queries/tags.query";
import { TagsOverview } from "./TagsOverview";
import { Tag } from "../../queries/models/tag.model";
import { CreateOrUpdateTag } from "../../queries/models/create-or-update-tag";
import { CreateOrUpdateTagRequest } from "../../queries/models/create-or-update-tag-request.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { TagForm } from "./TagForm";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";

export function TagsComponent() {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [selectedTag, setSelectedTag] = useState<Tag | undefined>(undefined);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const tagsQuery = useActiveTagsQuery();
  const addTagMutation = useAddTagMutation();
  const updateTagMutation = useUpdateTagMutation();
  const deleteTagMutation = useDeleteTagMutation();

  const saveNewTag = useCallback(
    (data: CreateOrUpdateTag) => {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedTag(undefined);
          toast.current?.show({
            severity: "success",
            detail: "Save success",
          });
          await queryClient.invalidateQueries("tags");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: CreateOrUpdateTagRequest = {
        name: data.name,
        comment: data.comment,
        textColor: `#${data.textColor}`,
        backgroundColor: `#${data.backgroundColor}`,
        icon: data.icon,
      };

      return addTagMutation.mutateAsync(request, mutateOptions);
    },
    [addTagMutation, queryClient, toast]
  );

  const editExistingTag = useCallback(
    (data: CreateOrUpdateTag) => {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedTag(undefined);
          toast.current?.show({
            severity: "success",
            detail: "Edit success",
          });
          await queryClient.invalidateQueries("tags");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: CreateOrUpdateTag = {
        id: data.id,
        name: data.name,
        comment: data.comment,
        textColor: `#${data.textColor}`,
        backgroundColor: `#${data.backgroundColor}`,
        icon: data.icon,
      };

      return updateTagMutation.mutateAsync(request, mutateOptions);
    },
    [queryClient, toast, updateTagMutation]
  );

  const handleSaveTag = useCallback(
    (data: CreateOrUpdateTag) =>
      data.id === 0 ? saveNewTag(data) : editExistingTag(data),
    [editExistingTag, saveNewTag]
  );

  const handleDeleteTag = useCallback(() => {
    if (selectedTag?.id) {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedTag(undefined);
          toast.current?.show({
            severity: "success",
            detail: "Delete success",
          });
          await queryClient.invalidateQueries("tags");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      deleteTagMutation.mutateAsync(selectedTag.id, mutateOptions);
    }
  }, [deleteTagMutation, queryClient, selectedTag?.id, toast]);

  const deleteConfirmationModalProps: CustomModalProps = {
    isOpen: isDeleteConfirmationModalOpen,
    onClose: () => setIsDeleteConfirmationModalOpen(false),
    header: "Confirmation",
    body: "Delete this tag?",
    justified: true,
    centered: true,
    height: "max-content",
    confirmation: true,
    onConfirm: async () => {
      handleDeleteTag();
      setIsDeleteConfirmationModalOpen(false);
    },
  };

  return (
    <div className="h-full p-1 flex gap-2">
      <CustomModal {...deleteConfirmationModalProps} />
      <div className="h-full w-6">
        <RoundedShadowContainer
          medium
          fullHeight
        >
          <TagsOverview
            items={tagsQuery.data}
            isLoading={tagsQuery.isLoading}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
          />
        </RoundedShadowContainer>
      </div>
      <div className="h-full w-6">
        <RoundedShadowContainer
          medium
          fullHeight
        >
          <div className="h-full">
            {selectedTag ? (
              <TagForm
                tag={selectedTag}
                onCancel={() => setSelectedTag(undefined)}
                onSave={handleSaveTag}
                onDelete={() => setIsDeleteConfirmationModalOpen(true)}
              />
            ) : (
              <div className="h-full flex justify-content-center align-items-center">
                <FontAwesomeIcon
                  icon={faTag}
                  className="text-primary cursor-pointer"
                  style={{ fontSize: 270 }}
                  onClick={() => setSelectedTag({ name: "" } as Tag)}
                />
              </div>
            )}
          </div>
        </RoundedShadowContainer>
      </div>
    </div>
  );
}
