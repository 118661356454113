import "./gallery-preview.scss";
import { Galleria } from "primereact/galleria";
import { ImageModel } from "../../../queries/models/image.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
} from "@fortawesome/free-solid-svg-icons";
import { PrivateImage } from "../PrivateImage";
import { useEffect, useState } from "react";
import { useEventListener } from "primereact/hooks";
import KeyCodes from "../../../utils/key-codes";

interface GalleryPreviewProps {
  images: ImageModel[];
  activeIndex: number;
  onItemChange(id: number | undefined): any;
  onClose(): any;
}

export function GalleryPreview({
  images,
  activeIndex,
  onItemChange,
  onClose,
}: GalleryPreviewProps) {
  const [zoom, setZoom] = useState<number>(0.9);

  const [bindKeyDown, unbindKeyDown] = useEventListener({
    type: "keydown",
    listener: (e: KeyboardEvent) => {
      switch (e.key) {
        case KeyCodes.ESCAPE:
          onClose();
          break;
        case KeyCodes.ARROW_LEFT:
          if (activeIndex > 0) onItemChange(activeIndex - 1);
          break;
        case KeyCodes.ARROW_RIGHT:
          if (activeIndex < images.length - 1) onItemChange(activeIndex + 1);
          break;
        case KeyCodes.PLUS:
          setZoom(zoom * 1.1);
          break;
        case KeyCodes.MINUS:
          setZoom(zoom * 0.9);
          break;
      }
    },
  });

  useEffect(() => {
    bindKeyDown();

    return () => {
      unbindKeyDown();
    };
  }, [bindKeyDown, unbindKeyDown]);

  const itemTemplate = (item: ImageModel) => {
    return (
      <div className="w-full h-full">
        {item.imageSrc && (
          <PrivateImage
            zoomLevel={zoom}
            maxHeight="100%"
            maxWidth="100%"
            imageSrc={item.imageSrc}
            withProgressBar
            progressBarClassName="w-full absolute left-0 bottom-0"
          />
        )}
      </div>
    );
  };

  return (
    <div className="bg-blur">
      <div className="top-bar-wrapper">
        <div className="icons-wrapper">
          <FontAwesomeIcon
            size={"xl"}
            color="white"
            icon={faMagnifyingGlassPlus}
            onClick={() => setZoom(zoom * 1.1)}
          />
          <FontAwesomeIcon
            size={"xl"}
            color="white"
            icon={faMagnifyingGlassMinus}
            onClick={() => setZoom(zoom * 0.9)}
          />
          <FontAwesomeIcon
            size={"xl"}
            color="white"
            icon={faXmark}
            onClick={onClose}
          />
        </div>
      </div>
      <div className="content">
        <div className="gallery-wrapper">
          <Galleria
            value={images}
            activeIndex={activeIndex}
            item={itemTemplate}
            onItemChange={(e) => {
              setZoom(0.9);
              onItemChange(e.index);
            }}
            showItemNavigators
            showThumbnails={false}
          />
        </div>
      </div>
    </div>
  );
}
