import { BaseService } from "./base.service";
import { User } from "../queries/models/user.model";
import { API_ENDPOINTS } from "../utils/endpoints";
import { CreateUserRequest } from "../queries/models/create-user-request.model";
import { UpdateUserRequest } from "../queries/models/update-user-request";
import { SelectItem } from "primereact/selectitem";
import { CreateCompanyRequest } from "../queries/models/create-company-request.model";

class UsersService extends BaseService {
  async getUserById(id: number): Promise<User> {
    const url = `${this.endpointPath}/${id}`;
    return this.http.get<User>(url).then((response) => response.data);
  }

  async getAllActiveUsers(): Promise<User[]> {
    const url = `${this.endpointPath}/active`;
    return this.http.get<User[]>(url).then((response) => response.data);
  }

  async getAllActiveUsersToUsersScreen(): Promise<User[]> {
    const url = `${this.endpointPath}/active-for-user-screen`;
    return this.http.get<User[]>(url).then((response) => response.data);
  }

  async getAllActiveUsersAsSelectOptions(): Promise<SelectItem[]> {
    const url = `${this.endpointPath}/active-as-options`;
    return this.http.get<SelectItem[]>(url).then((response) => response.data);
  }

  async addUser(request: CreateUserRequest) {
    const url = `${this.endpointPath}/create`;
    return this.http
      .post<CreateUserRequest>(url, request)
      .then((response) => response.data);
  }
  async editUser(request: UpdateUserRequest) {
    const url = `${this.endpointPath}/update`;
    return this.http
      .put<UpdateUserRequest>(url, request)
      .then((response) => response.data);
  }

  async deleteUser(id: number) {
    const url = `${this.endpointPath}/delete/${id}`;
    return this.http.delete(url).then((response) => response.data);
  }

  async forceLoginAgainUser(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.patch(url).then((response) => response.data);
  }

  async addCompany(request: CreateCompanyRequest) {
    const url = `${this.endpointPath}/register-company`;
    return this.http
      .post<CreateCompanyRequest>(url, request)
      .then((response) => response.data);
  }
}

export const usersService = new UsersService(API_ENDPOINTS.USERS);
