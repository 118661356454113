import { useDragLayer } from "react-dnd";
import { BoardWorkTask } from "../../../queries/models/board-work-task.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { WorkTaskChipsInfoElement } from "./WorkTaskChipsInfosElement";
import { useDragItem } from "../../../components/ui/drag-item-conttext-provider";
import { useMemo } from "react";

interface Props {}

export function WorkTaskElementDragPreview() {
  const { dragItemRef } = useDragItem();

  const dimmensions = useMemo(() => {
    if (!dragItemRef?.current) {
      return { width: 0, height: 0 };
    }

    const { width, height } = dragItemRef.current.getBoundingClientRect();
    return { width, height };
  }, [dragItemRef]);

  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  if (!isDragging) {
    return null;
  }

  const getItemStyles = (initialOffset: any, currentOffset: any) => {
    if (!initialOffset || !currentOffset) {
      return {
        display: "none",
      };
    }

    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  };

  return (
    <div
      style={{
        position: "fixed",
        pointerEvents: "none",
        zIndex: 100,
        left: 0,
        top: 0,
      }}
    >
      <div
        className="surface-300 border-round p-1 border-3  border-400"
        style={getItemStyles(initialOffset, currentOffset)}
      >
        <div
          className="flex flex-column gap-1"
          style={{ minWidth: dimmensions.width }}
        >
          <div className="flex justify-content-between align-items-center">
            <div className="font-bold cursor-pointer hover:underline">
              {`#${item?.id} ${item?.name}`}
            </div>
            <div className="px-1">
              <div className="border-circle hover:surface-400 px-1">
                <FontAwesomeIcon icon={faEllipsis} />
              </div>
            </div>
          </div>

          <span>{item?.installation?.name}</span>
          {item && <WorkTaskChipsInfoElement workTask={item} />}
        </div>
      </div>
    </div>
  );
}
