import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { AssetType } from "../queries/models/asset-type.model";
import { CreateOrUpdateAssetTypeRequest } from "../queries/models/create-or-update-asset-type-request.model";

class AssetTypesService extends BaseService {
  async getAll(): Promise<AssetType[]> {
    const url = this.endpointPath;
    return this.http.get<AssetType[]>(url).then((response) => response.data);
  }

  async getAllActive(): Promise<AssetType[]> {
    const url = `${this.endpointPath}/active`;
    return this.http.get<AssetType[]>(url).then((response) => response.data);
  }

  async getById(id: number): Promise<AssetType> {
    const url = `${this.endpointPath}/${id}`;
    return this.http.get<AssetType>(url).then((response) => response.data);
  }

  async addAssetType(request: CreateOrUpdateAssetTypeRequest) {
    const url = this.endpointPath;
    return this.http
      .post<CreateOrUpdateAssetTypeRequest>(url, request)
      .then((response) => response.data);
  }

  async updateAssetType(request: CreateOrUpdateAssetTypeRequest) {
    const url = this.endpointPath;
    return this.http
      .put<CreateOrUpdateAssetTypeRequest>(url, request)
      .then((response) => response.data);
  }

  async deleteAssetType(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.delete(url).then((response) => response.data);
  }
}

export const assetTypesService = new AssetTypesService(
  API_ENDPOINTS.ASSET_TYPES
);
