const KeyCodes = {
    ENTER: 'Enter',
    ESCAPE: 'Escape',
    ARROW_LEFT: 'ArrowLeft',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_UP: 'ArrowUp',
    ARROW_DOWN: 'ArrowDown',
    PLUS: '+',
    MINUS: '-',
    // Add more key codes as needed
};

export default KeyCodes;