import { PlanningWorkInstance } from "../../queries/models/planning-work-instance.model";
import { SelectItem } from "primereact/selectitem";
import * as Yup from "yup";
import { AddOrEdtiWorkInstance as AddOrUpdateWorkInstance } from "../../queries/models/form-work-instance.model";
import { Form, Formik, FormikHelpers } from "formik";
import { WorkInstanceState } from "../../queries/models/enums/work-instance-state.enum";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import { Button } from "primereact/button";
import FormikDropdown from "../../components/ui/formik/FormikDropdown";
import { PlanningUser } from "../../queries/models/planning-users.model";
import { useCallback, useEffect, useMemo, useRef } from "react";
import FormikMultiSelect from "../../components/ui/formik/FormikMultiSelect";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { useGlobalLoader } from "../../hooks/useGlobalLoader";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

interface PlanningFormProps {
  entry: PlanningWorkInstance | undefined;
  selectedUsers?: PlanningUser[];
  installationOptions?: SelectItem[] | undefined;
  onCreateWorkInstance: (e: AddOrUpdateWorkInstance) => void | Promise<any>;
  onUpdateWorkInstance: (e: AddOrUpdateWorkInstance) => void | Promise<any>;
  onDeleteWorkInstance: (e: AddOrUpdateWorkInstance) => void | Promise<any>;
  multipleUsers?: boolean;
}

export function PlanningForm({
  entry,
  selectedUsers = [],
  installationOptions = [],
  onCreateWorkInstance,
  onUpdateWorkInstance,
  onDeleteWorkInstance,
  multipleUsers,
}: PlanningFormProps) {
  const { t } = useTranslation();
  const formRef =
    useRef<FormikProps<AddOrUpdateWorkInstance & { userIds?: number[] }>>(null);
  const globalLoader = useGlobalLoader();
  const userOptions = useMemo<SelectItem[]>(() => {
    return selectedUsers.map((x) => ({ label: x.username, value: x.userId }));
  }, [selectedUsers]);
  const hasWriteAccess = useMemo(() => {
    return authService.hasAccess(AppFeatures.WebPlanningWrite);
  }, []);

  const initialValues: AddOrUpdateWorkInstance & { userIds?: number[] } = {
    id: entry?.workInstanceId ?? undefined,
    userId: multipleUsers
      ? undefined
      : entry?.userId ??
        (selectedUsers.length === 1 ? selectedUsers[0].userId : undefined),
    userIds: entry?.userId ? [entry.userId] : [],
    installationId: entry?.installationId ?? undefined,
    comment: entry?.comment ?? "",
    date: entry?.date,
  };

  const validationSchema = Yup.object({
    userId: multipleUsers
      ? Yup.number()
      : Yup.number().required(t("alert.required")),
    userIds: multipleUsers
      ? Yup.array()
          .of(Yup.number())
          .min(1, t("alert.atLeast1UserMustBeSelected"))
          .required(t("alert.required"))
      : Yup.array(),
    installationId: Yup.number().required(t("alert.required")),
    comment: Yup.string().optional(),
  });

  const isEditable = useCallback(() => {
    return (
      hasWriteAccess &&
      (entry === undefined || entry.state === WorkInstanceState.Pending)
    );
  }, [entry, hasWriteAccess]);

  const onSubmit = useCallback(
    (
      values: AddOrUpdateWorkInstance & { userIds?: number[] },
      { resetForm }: FormikHelpers<any>
    ) => {
      if (!hasWriteAccess) return;

      const mutationPromises: (Promise<any> | void)[] = [];
      if (multipleUsers) {
        const valuesNormalized = values.userIds?.map(
          (x) =>
            ({
              ...values,
              userIds: undefined,
              userId: x as number,
            } as AddOrUpdateWorkInstance)
        );
        if (valuesNormalized === undefined) return;

        valuesNormalized.forEach((value) => {
          mutationPromises.push(
            value.id ? onUpdateWorkInstance(value) : onCreateWorkInstance(value)
          );
        });
      } else {
        mutationPromises.push(
          values.id
            ? onUpdateWorkInstance(values)
            : onCreateWorkInstance(values)
        );
      }

      if (entry === undefined) {
        Promise.all(mutationPromises).then(() => {
          resetForm();
        });
      }
    },
    [
      entry,
      hasWriteAccess,
      multipleUsers,
      onCreateWorkInstance,
      onUpdateWorkInstance,
    ]
  );

  useEffect(() => {
    const selectedUserIds = selectedUsers.map((x) => x.userId);
    const current = formRef.current?.values?.userIds ?? [];
    const newValue = current.filter((x) => selectedUserIds.includes(x));
    formRef.current?.setFieldValue("userIds", newValue);
  }, [selectedUsers]);

  return (
    <>
      {userOptions && installationOptions && (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="px-2 ">{entry?.date.toLocaleDateString()}</div>
              <div className="flex w-full">
                <div
                  className="field p-1"
                  style={{ width: "calc(25%)" }}
                >
                  {multipleUsers ? (
                    <FormikMultiSelect
                      label={t("common.users")}
                      name="userIds"
                      validationSchema={validationSchema}
                      options={userOptions}
                      filter
                      mutableOptions
                      disabled={!isEditable()}
                    />
                  ) : (
                    <FormikDropdown
                      label={t("common.user")}
                      name="userId"
                      validationSchema={validationSchema}
                      options={userOptions}
                      filter
                      disabled={!isEditable()}
                    />
                  )}
                </div>
                <div
                  className="field p-1"
                  style={{ width: "calc(25%)" }}
                >
                  <FormikDropdown
                    label={t("common.installation")}
                    name="installationId"
                    validationSchema={validationSchema}
                    options={installationOptions}
                    filter
                    disabled={!isEditable()}
                  />
                </div>
                <div
                  className="field p-1"
                  style={{
                    width:
                      entry !== undefined
                        ? "calc(50% - 205px)"
                        : "calc(50% - 105px)",
                  }}
                >
                  <FormikInputText
                    label={t("common.comment")}
                    name="comment"
                    validationSchema={validationSchema}
                    isIndependent
                    disabled={!isEditable()}
                  />
                </div>
                <div
                  className="field p-1 flex h-full"
                  style={{
                    width: entry !== undefined ? "205px" : "105px",
                  }}
                >
                  <div className="actions">
                    <Button
                      label={t("common.save")}
                      type="submit"
                      className="green-action-button mr-1"
                      disabled={
                        !formik.isValid || !formik.dirty || globalLoader
                      }
                    ></Button>
                    {entry !== undefined && (
                      <Button
                        label={t("common.delete")}
                        type="button"
                        className="delete-button mr-1"
                        onClick={() => onDeleteWorkInstance(formik.values)}
                        disabled={!isEditable()}
                      ></Button>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
