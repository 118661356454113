import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { UserMonthlyOrdersOverviewItem } from "../queries/models/user-monthly-orders-overview.model";
import { UserMonthlyOrdersValidation } from "../queries/models/user-monthly-orders-validation.model";
import { SendOrdersRequest } from "../queries/models/send-orders-request";
import { UpdateWorkTimeRequest } from "../queries/models/update-work-time-request.model";
import { ValidatedWorkInstance } from "../queries/models/validated-work-instance.model";
import { ExportOrderData } from "../queries/models/export-order-data.model";


class OrdersValidatorService extends BaseService {

    async getMonthlyExportData(date: Date): Promise<ExportOrderData[]> {
        let url = `${this.endpointPath}/monthly-export-data/${date.toISOString()}`;
    
        return this.http.get<ExportOrderData[]>(url).then((res) => res.data);
    }

    async getOverview(date: Date): Promise<UserMonthlyOrdersOverviewItem[]> {
        let url = `${this.endpointPath}/overview/${date.toISOString()}`;
    
        return this.http.get<UserMonthlyOrdersOverviewItem[]>(url).then((res) => res.data);
    }
    async getUserValidation(userId : number, date: Date): Promise<UserMonthlyOrdersValidation> {
        let url = `${this.endpointPath}/user/${userId}/${date.toISOString()}`;
    
        return this.http.get<UserMonthlyOrdersValidation>(url).then((res) => res.data);
    }
    async getWorkInstance(workInstanceId : number): Promise<ValidatedWorkInstance> {
        let url = `${this.endpointPath}/work-instance/${workInstanceId}`;
    
        return this.http.get<ValidatedWorkInstance>(url).then((res) => res.data);
    }
    async sendOrders(request: SendOrdersRequest) {
        const url = `${this.endpointPath}/send`;
        return this.http.post<any>(url, request).then((response) => response.data);
    }
    async updateWorkTime(request: UpdateWorkTimeRequest) {
        const url = `${this.endpointPath}/update-working-hours`;
        return this.http.put<any>(url, request).then((response) => response.data);
    }
}

export const ordersValidatorService = new OrdersValidatorService(API_ENDPOINTS.ORDERS_VALIDATOR);