import { useMemo, useCallback } from "react";
import { ValidatedWorkInstance } from "../../../queries/models/validated-work-instance.model";
import { WorkInstanceType } from "../../../queries/models/enums/work-instance-type.enum";
import { WorkInstanceState } from "../../../queries/models/enums/work-instance-state.enum";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { LoaderWrapper } from "../../../components/ui/LoaderWrapper";
import { TimeSpan } from "../../../utils/timespan";

function WorkItemElement({
  workInstance,
}: {
  workInstance: ValidatedWorkInstance;
}) {
  const { t } = useTranslation();
  const handleNavigateToDetails = useCallback(
    () =>
      window.open(`/app/work-instances/details/${workInstance.id}`, "_blank"),
    [workInstance.id]
  );

  return (
    <div
      style={{
        marginLeft: "0.5rem",
        borderRadius: "12px",
      }}
      className="shadow-3 p-1 cursor-pointer surface-200 hover:surface-300"
      onClick={handleNavigateToDetails}
    >
      <div className="grid">
        <div className="col-6">
          <div className="grid">
            <div className="col-6 text-500 text-right">
              {t("common.dateStarted")}:
            </div>
            <div className="col-6">
              {workInstance.dateStarted
                ? format(new Date(workInstance.dateStarted), "dd/MM/yyyy")
                : "-"}
            </div>
            <div className="col-6 text-500 text-right">
              {t("common.datePlanned")}:
            </div>
            <div className="col-6">
              {workInstance.datePlanned
                ? format(new Date(workInstance.datePlanned), "dd/MM/yyyy")
                : "-"}
            </div>
            <div className="col-6 text-500 text-right">
              {t("common.dateEnded")}:
            </div>
            <div className="col-6">
              {workInstance.dateEnded
                ? format(new Date(workInstance.dateEnded), "dd/MM/yyyy")
                : "-"}
            </div>
            <div className="col-6 text-500 text-right">
              {t("common.totalWork")}:
            </div>
            <div className="col-6">
              {TimeSpan.fromTicks(
                workInstance.summary.workDurationTicks
              ).toHhMm()}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="grid">
            <div className="col-6 text-500 text-right">{t("common.type")}:</div>
            <div className="col-6">{WorkInstanceType[workInstance.type]}</div>
            <div className="col-6 text-500 text-right">
              {t("common.state")}:
            </div>
            <div className="col-6">{WorkInstanceState[workInstance.state]}</div>
            <div className="col-6 text-500 text-right">{t("common.user")}:</div>
            <div className="col-6">{workInstance.user?.username}</div>
            <div className="col-6 text-500 text-right">
              {t("common.totalPauses")}
            </div>
            <div className="col-6">
              {TimeSpan.fromTicks(
                workInstance.summary.pauseDurationTicks
              ).toHhMm()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface Props {
  isLoading: boolean;
  items: ValidatedWorkInstance[];
}

export function InstallationWorkInstances({ isLoading, items }: Props) {
  const groupedItems = useMemo(() => {
    const result = new Map<string, ValidatedWorkInstance[]>();

    items
      .filter((x) => x.dateStarted || x.datePlanned)
      .sort(
        (a, b) =>
          +(b.dateStarted || b.datePlanned!) -
          +(a.dateStarted || a.datePlanned!)
      )
      .forEach((item) => {
        const dateString = format(
          new Date(item.dateStarted ?? item.datePlanned!),
          "yyyy-MM-dd"
        );

        if (!result.has(dateString)) {
          result.set(dateString, []);
        }
        result.get(dateString)!.push(item);
      });

    return [...Array.from(result.entries())];
  }, [items]);

  return (
    <div className="h-full w-full">
      <LoaderWrapper isLoading={isLoading}>
        {groupedItems.map(([group, values], index) => (
          <div key={index}>
            <div className="w-full flex flex-row">
              <div
                className="flex-grow-1 mx-5"
                style={{
                  height: "0.75rem",
                  borderBottom: "solid 1px rgb(182, 182, 182)",
                }}
              ></div>
              {group}
              <div
                className="flex-grow-1 mx-5"
                style={{
                  height: "0.75rem",
                  borderBottom: "solid 1px rgb(182, 182, 182)",
                }}
              ></div>
            </div>
            <div className="flex flex-column gap-3 py-2">
              {values.map((item, index) => (
                <div
                  key={index}
                  className="mx-5"
                >
                  <WorkItemElement workInstance={item} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </LoaderWrapper>
    </div>
  );
}
