import { ReactNode } from "react";
import { Loader } from "./Loader";

interface LoaderWrapperProps {
  isLoading: boolean;
  loaderClassName?: string;
  containerClassName?: string;
  children?: ReactNode;
}

export function LoaderWrapper({
  loaderClassName,
  isLoading,
  children,
  containerClassName = "h-full w-full overflow-auto",
}: LoaderWrapperProps) {
  return (
    <div className="h-full w-full relative">
      {isLoading && (
        <div
          className="absolute z-2 w-full h-full flex justify-content-center align-items-center"
          style={{
            backdropFilter: "blur(2px)",
            borderRadius: "15px",
          }}
        >
          <Loader
            type="spinner"
            className={loaderClassName}
          />
        </div>
      )}
      <div className={containerClassName}>{children}</div>
    </div>
  );
}
