import { BoardWorkTask } from "../../../queries/models/board-work-task.model";
import { TaskSeverityToTranslationKey } from "../../../utils/task-severity.convertions";
import { TaskSeverityToColor } from "../../../utils/task-severity.convertions";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPerson,
  faPaperclip,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { getContrastColor } from "../../../utils/string-utils";

interface Props {
  workTask: BoardWorkTask;
}

export function WorkTaskChipsInfoElement({ workTask }: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-wrap align-items-center gap-3">
      <div
        className="py-1 px-3 border-round-xl font-medium"
        style={{
          backgroundColor: TaskSeverityToColor[workTask.severity],
          color: getContrastColor(TaskSeverityToColor[workTask.severity]),
        }}
      >
        {t(TaskSeverityToTranslationKey[workTask.severity])}
      </div>

      {!!workTask.assignedUserIds?.length && (
        <div>
          <FontAwesomeIcon
            icon={faPerson}
            size="lg"
          />
          <span className="pl-1">{workTask.assignedUserIds?.length}</span>
        </div>
      )}

      {!!workTask.noteAttachmentsCount && (
        <div>
          <FontAwesomeIcon icon={faPaperclip} />
          <span className="pl-1">{workTask.noteAttachmentsCount}</span>
        </div>
      )}

      {!!workTask.noteCommentsCount && (
        <div>
          <FontAwesomeIcon icon={faComment} />
          <span className="pl-1">{workTask.noteCommentsCount}</span>
        </div>
      )}

      <div>{workTask.noteItemsCount}</div>

      {workTask.dueDate && (
        <div>{format(new Date(workTask.dueDate), "dd/LL/yyyy")}</div>
      )}
    </div>
  );
}
