import { useQuery, useMutation } from 'react-query';
import { dashboardService } from '../services/dashboard.service';
import { DashboardOverviewItem } from './models/dashboard-overview-item.model';
import { DashboardDetailsItem } from './models/dashboard-details-item.model';
import { ForceFinishWorkRequest } from './models/force-finish-work-request.model';

export function useDashboardOverviewQuery(refetchInterval: number) {
  return useQuery<DashboardOverviewItem[]>(
    ["dashboardOverview"],
    () => fetchOashboardOverview(),
    { refetchOnWindowFocus: false, refetchInterval: refetchInterval }
  );
}
async function fetchOashboardOverview() {
  return await dashboardService.getOverview();
}

export function useDashboardDetailQuery(id: number | undefined) {
  return useQuery<DashboardDetailsItem | undefined>(
    ["dashboardDetails", id],
    () => fetchOashboardDetails(id),
    { refetchOnWindowFocus: false }
  );
}
async function fetchOashboardDetails(id: number | undefined) {
  if (!id) {
    return undefined;
  } else {
    return await dashboardService.getWorkDetails(id);
  }
}

export function useForceFinishWorkMutation() {
  return useMutation((request: ForceFinishWorkRequest) => dashboardService.forceFinishWork(request));
}