import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { ReportSummaryItem } from "../queries/models/report-summary-witem.model";


class ReportingService extends BaseService {

    async getSummary(from: Date, to: Date): Promise<ReportSummaryItem[]> {
        let url = `${this.endpointPath}/summary/${from.toISOString()}/${to.toISOString()}`;
    
        return this.http.get<ReportSummaryItem[]>(url).then((res) => res.data);
    }
}

export const reportingService = new ReportingService(API_ENDPOINTS.RERPORTING);