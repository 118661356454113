import { format } from "date-fns";
import { WorkEntryForm } from "./WorkEntryForm";
import { SelectItem } from "primereact/selectitem";
import { useMemo, useState, useCallback } from "react";
import { AddOrEdtiWorkInstance as AddOrUpdateWorkInstance } from "../../queries/models/form-work-instance.model";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { WorkInstanceTimeEntry } from "../../queries/models/work-instance-time-entry.model";
import {
  useAddWorkInstanceTimeEntryMutation,
  useDeleteWorkInstanceTimeEntryMutation,
  useUpdateWorkInstanceTimeEntryMutation,
} from "../../queries/work-entries.query";
import { CreateWorkInstanceTimeEntry } from "../../queries/models/create-work-instance-time-entry.model";
import { WorkInstanceType } from "../../queries/models/enums/work-instance-type.enum";
import { UpdateWorkInstanceTimeEntry } from "../../queries/models/edit-work-instance-time-entry.model";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface EditWorkDayComponentProps {
  selectedDate: Date;
  entries?: WorkInstanceTimeEntry[];
  userId: number;
  installations?: SelectItem[] | undefined;
}

export function EditWorkDayComponent({
  entries = [],
  installations = [],
  selectedDate,
  userId,
}: EditWorkDayComponentProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const addWorkInstanceMutation = useAddWorkInstanceTimeEntryMutation();
  const updateWorkInstanceMutation = useUpdateWorkInstanceTimeEntryMutation();
  const deleteWorkInstanceMutation = useDeleteWorkInstanceTimeEntryMutation();
  const [workInstanceToDelete, setWorkInstanceToDelete] = useState<
    AddOrUpdateWorkInstance | undefined
  >(undefined);

  const createNewWorkInstance = (data: AddOrUpdateWorkInstance) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        toast.current?.show({
          severity: "success",
          detail: t("alert.saveSuccess"),
        });
        await queryClient.invalidateQueries("userEntries");
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error?.data,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
      },
    };

    const request: CreateWorkInstanceTimeEntry = {
      userId: data.userId!,
      installationId: data.installationId!,
      comment: data.comment,
      date: new Date(selectedDate.setHours(6, 0, 0, 0)),
      durationTicks: data.durationTicks!,
      type: WorkInstanceType.Conceptual,
    };

    return addWorkInstanceMutation.mutateAsync(request, mutateOptions);
  };
  const updateWorkInstance = (data: AddOrUpdateWorkInstance) => {
    setIsSubmitting(true);
    const mutateOptions = {
      onSuccess: async () => {
        toast.current?.show({
          severity: "success",
          detail: t("alert.updateSuccess"),
        });
        await queryClient.invalidateQueries("userEntries");
      },
      onError: async (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error?.data,
        });
      },
      onSettled: () => {
        setIsSubmitting(false);
      },
    };

    const request: UpdateWorkInstanceTimeEntry = {
      userId: data.userId!,
      installationId: data.installationId!,
      comment: data.comment,
      date: data.date!,
      workInstanceId: data.id!,
      durationTicks: data.durationTicks!,
      type: WorkInstanceType.Conceptual,
    };
    return updateWorkInstanceMutation.mutateAsync(request, mutateOptions);
  };
  const deleteWorkInstance = useCallback(
    (data: AddOrUpdateWorkInstance) => {
      setIsSubmitting(true);
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: t("alert.deleteSuccess"),
          });
          await queryClient.invalidateQueries("userEntries");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
        onSettled: () => {
          setIsSubmitting(false);
          setWorkInstanceToDelete(undefined);
        },
      };

      deleteWorkInstanceMutation.mutateAsync(data.id!, mutateOptions);
    },
    [deleteWorkInstanceMutation, queryClient, t, toast]
  );

  const sortedEntries = useMemo(
    () => [...entries].sort((a, b) => +a.workInstanceId - +b.workInstanceId),
    [entries]
  );

  const modalProps: CustomModalProps = useMemo(
    () => ({
      isOpen: !!workInstanceToDelete,
      onClose: () => setWorkInstanceToDelete(undefined),
      header: t("common.confirmation"),
      body: t("dialog.deleteThisWorkEntry"),
      confirmation: true,
      justified: true,
      centered: true,
      height: "max-content",
      onConfirm: () => {
        if (workInstanceToDelete) {
          deleteWorkInstance(workInstanceToDelete);
        }
      },
    }),
    [deleteWorkInstance, t, workInstanceToDelete]
  );

  return (
    <div className="h-full">
      <CustomModal {...modalProps} />
      <div style={{ height: "calc(100% - 5rem)" }}>
        <section>
          <div className="section-header">
            {`${t("common.addWorkEntriesFor")} ${format(
              selectedDate,
              "dd/LL/yyyy"
            )}`}
          </div>
          <div className="section-content">
            <WorkEntryForm
              loading={isSubmitting}
              entry={undefined}
              userId={userId}
              installationOptions={installations}
              onCreateWorkInstance={createNewWorkInstance}
              onUpdateWorkInstance={updateWorkInstance}
              onDeleteWorkInstance={deleteWorkInstance}
            />
          </div>
        </section>

        <section className="h-full">
          <div className="section-header">{t("common.workEntries")}</div>
          <div
            className="section-content overflow-y-scroll"
            style={{ height: "calc(100% - 8rem)" }}
          >
            {sortedEntries.length > 0 ? (
              sortedEntries.map((entry) => (
                <div
                  className="border-top-1 border-400"
                  key={entry.workInstanceId}
                >
                  <div
                    className="px-1 pt-1 underline font-bold text-blue-500 cursor-pointer hover:text-blue-900"
                    onClick={() =>
                      navigate(
                        `../work-instances/details/${entry.workInstanceId}`
                      )
                    }
                  >
                    {t("common.details")}
                  </div>
                  <WorkEntryForm
                    entry={entry}
                    userId={userId}
                    installationOptions={installations}
                    onCreateWorkInstance={createNewWorkInstance}
                    onUpdateWorkInstance={updateWorkInstance}
                    onDeleteWorkInstance={(e) => {
                      setWorkInstanceToDelete(e);
                    }}
                  />
                </div>
              ))
            ) : (
              <div className="w-full h-full flex align-items-center justify-content-center">
                <FontAwesomeIcon
                  icon={faCalendarCheck}
                  style={{ fontSize: 270 }}
                  color="#7C4DFF"
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}
