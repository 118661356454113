import { AxiosProgressEvent } from "axios";
import { BaseService } from "./base.service";
import { FileExtension } from "../utils/file-extensions.ts";

class FilesService extends BaseService {
  async getImage(
    imageSrc: string,
    onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
  ) {
    return this.http<Blob>(imageSrc, {
      responseType: "blob",
      onDownloadProgress,
    });
  }

  async blobToBase64(blob: Blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async getImageBase64(
    imageSrc: string,
    onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
  ) {
    return this.getImage(imageSrc, onDownloadProgress).then(async (result) => {
      return (await this.blobToBase64(result.data)) as string;
    });
  }

  async downloadFileBlob(
    downloadUrl: string,
    fileName: string,
    onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
  ) {
    return this.http<Blob>(downloadUrl, {
      responseType: "blob",
      onDownloadProgress,
    }).then((result) => {
      return FileExtension.downloadBlob(result.data, fileName)
    });
  }
}

export const fileService = new FilesService("");
