import { useQuery, useMutation, UseQueryOptions } from "react-query";
import { workInstancesService } from "../services/work-instances.service";
import { WorkInstance } from "./models/work-instance.model";
import { WorkInstanceDetails } from "./models/work-instance-details";

export function useActiveWorkInstancesQuery(
  from: Date | undefined | null,
  to: Date | undefined | null
) {
  const queryOptions: UseQueryOptions<WorkInstance[]> = {
    enabled: !!(from && to),
  };

  return useQuery<WorkInstance[]>(
    ["work-instances", from, to],
    () => workInstancesService.getActiveByDateRange(from!, to!),
    queryOptions
  );
}

export function useWorkInstanceDetailsQuery(id: number) {
  return useQuery<WorkInstanceDetails>(["work-instance", id], () =>
    workInstancesService.getById(id)
  );
}

export function useInstallationWorkInstancesQuery(
  installationId: number | undefined
) {
  return useQuery(
    ["work-instances", "installation", installationId],
    () => workInstancesService.getByInstallationId(installationId!),
    {
      enabled: !!installationId,
    }
  );
}

export function useInstallationValidatedWorkInstancesQuery(
  installationId: number | undefined
) {
  return useQuery(
    ["work-instances", "installation", installationId, "validated"],
    () => workInstancesService.getValidatedByInstallation(installationId!),
    {
      enabled: !!installationId,
    }
  );
}

export function useDeleteWorkInstanceMutation() {
  return useMutation((id: number) =>
    workInstancesService.deleteWorkInstance(id)
  );
}
