import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  isAllowed: boolean;
  redirectPath?: string;
  children: React.ReactNode;
}

export function ProtectedPage({
  isAllowed,
  redirectPath = "/401",
  children,
}: ProtectedRouteProps): any {
  if (!isAllowed) {
    return (
      <Navigate
        to={redirectPath}
        replace
      />
    ) as any;
  }

  return children;
}
