import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useState } from "react";
import {
  bubbles1,
  bubbles2,
  bubbles3,
  bubbles4,
} from "../AnimationAssets/login-mobile-bubbles";
import { btnVariants, btnStyles } from "../FramerMotion/button-states";
import { LoginFormProps } from "./LoginForm";
import { RegisterMobileForm } from "./RegisterMobileForm";
import { Password } from "primereact/password";
const bubbleHeight = 100;

export function LoginMobileForm({
  executeLogin,
  navigateToForgotPassword,
  loginError,
  setLoginError,
  isVisible,
  setIsVisible,
  signUp,
}: LoginFormProps) {
  const [username, setUsername] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();

  const [companyRegistered, setCompanyRegistered] = useState<boolean>(false);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: "120%", opacity: 0.001 }}
        animate={{ y: "0%", opacity: 1 }}
        exit={{ y: "100%", opacity: 0.001 }}
        transition={{ ease: "circOut", duration: 0.4 }}
        className="w-full flex h-max flex-grow-1"
        style={{
          borderRadius: "0px 20px 20px 0px",
          marginTop: bubbleHeight / 2,
        }}
      >
        <div className="min-h-max w-full flex flex-grow-1">
          <div
            className="absolute w-full"
            style={{
              overflowX: "clip",
            }}
          >
            {bubbles4.map((e, index) => (
              <motion.div
                initial={{ y: e.y + Math.random() * (200 - 100) + 100 }}
                animate={{ y: 1 }}
                transition={{
                  ease: "linear",
                  duration: 0.3,
                  delay: Math.random() * (0.6 - 0.3) + 0.1,
                }}
                key={index}
                className="absolute"
                style={{
                  width: e.r,
                  height: bubbleHeight,
                  borderRadius: "100%",
                  backgroundColor: e.color,
                  marginLeft: e.x,
                  marginTop: e.y,
                  zIndex: 1,
                  boxShadow: "0px -5px 10px -7px rgba(0, 0, 0, 0.4)",
                }}
              ></motion.div>
            ))}
          </div>
          <div
            className="z-2 flex-grow-1 flex flex-column"
            style={{ backgroundColor: "var(--light-primary-color)" }}
          >
            <div
              className="absolute w-full"
              style={{
                overflowX: "clip",
              }}
            >
              {bubbles3.map((e, index) => (
                <motion.div
                  initial={{ y: e.y + Math.random() * (200 - 100) + 100 }}
                  animate={{ y: 1 }}
                  transition={{
                    ease: "linear",
                    duration: 0.3,
                    delay: Math.random() * (0.6 - 0.3) + 0.1,
                  }}
                  key={index}
                  className="absolute"
                  style={{
                    width: e.r,
                    height: bubbleHeight,
                    borderRadius: "100%",
                    backgroundColor: e.color,
                    marginLeft: e.x,
                    marginTop: e.y,
                    zIndex: 1,
                    boxShadow: "0px -5px 10px -7px rgba(0, 0, 0, 0.4)",
                  }}
                ></motion.div>
              ))}
            </div>
            <AnimatePresence>
              <motion.div
                initial={{ y: "120%", opacity: 0.001 }}
                animate={{ y: "0%", opacity: 1 }}
                exit={{ y: "100%", opacity: 0.001 }}
                transition={{ ease: "easeInOut", duration: 0.4 }}
                className="z-2 flex-grow-1 flex flex-column h-max"
                style={{ backgroundColor: "#8d66f7", marginTop: "10px" }}
              >
                <div
                  className="absolute w-full"
                  style={{
                    overflowX: "clip",
                  }}
                >
                  {bubbles2.map((e, index) => (
                    <motion.div
                      initial={{ y: e.y + Math.random() * (200 - 100) + 100 }}
                      animate={{ y: 1 }}
                      transition={{
                        ease: "linear",
                        duration: 0.3,
                        delay: Math.random() * (0.6 - 0.3) + 0.1,
                      }}
                      key={index}
                      className="absolute"
                      style={{
                        width: e.r,
                        height: bubbleHeight,
                        borderRadius: "100%",
                        backgroundColor: e.color,
                        marginLeft: e.x,
                        marginTop: e.y,
                        zIndex: 1,
                        boxShadow: "0px -5px 10px -7px rgba(0, 0, 0, 0.4)",
                      }}
                    ></motion.div>
                  ))}
                </div>
                <AnimatePresence>
                  {!isVisible && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.4 }}
                      className="h-max w-full z-3"
                    >
                      <RegisterMobileForm
                        isVisible={!isVisible}
                        setIsVisible={setIsVisible}
                        setCompanyRegistered={setCompanyRegistered}
                        companyRegistered={companyRegistered}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
                <AnimatePresence>
                  {isVisible && (
                    <motion.div
                      initial={{ y: "110%" }}
                      animate={{ y: "0%" }}
                      exit={{ y: "110%" }}
                      transition={{ ease: "easeInOut", duration: 0.4 }}
                      className="absolute w-full h-full z-4 flex-grow-1 flex flex-column"
                      style={{ backgroundColor: "var(--primary-color)" }}
                    >
                      <div
                        className="absolute w-full"
                        style={{
                          overflowX: "clip",
                        }}
                      >
                        {bubbles1.map((e, index) => (
                          <motion.div
                            initial={{
                              y: e.y + Math.random() * (200 - 100) + 100,
                            }}
                            animate={{ y: 1 }}
                            transition={{
                              ease: "linear",
                              duration: 0.3,
                              delay: Math.random() * (0.6 - 0.3) + 0.1,
                            }}
                            key={index}
                            className="absolute"
                            style={{
                              width: e.r,
                              height: bubbleHeight,
                              borderRadius: "100%",
                              backgroundColor: e.color,
                              left: e.x,
                              top: e.y,
                              zIndex: 2,
                              boxShadow:
                                "0px -5px 10px -7px rgba(0, 0, 0, 0.4)",
                            }}
                          ></motion.div>
                        ))}
                      </div>
                      <div
                        className="z-3 flex-grow-1 mt-4"
                        style={{ backgroundColor: "var(--primary-color)" }}
                      >
                        <div
                          className="w-full flex mt-5 flex-column "
                          style={{}}
                        >
                          <div
                            style={{ color: "white", width: "75%" }}
                            className="flex justify-content-center text-xl font-bolder mb-5 align-self-center"
                          >
                            Login to Admin Panel
                          </div>
                          <div
                            style={{ width: "75%" }}
                            className="align-self-center"
                          >
                            <div
                              className=" font-semibold mb-1"
                              style={{ color: "white" }}
                            >
                              Username
                            </div>
                            <InputText
                              id="username"
                              className={classNames(
                                "w-full shadow-2",
                                loginError ? "p-invalid" : ""
                              )}
                              value={username ?? ""}
                              onChange={(e) => {
                                setUsername(e.target.value);
                                setLoginError(undefined);
                              }}
                            />
                          </div>
                          <div
                            style={{ width: "75%" }}
                            className="align-self-center"
                          >
                            <div
                              className="w-full font-semibold mb-1"
                              style={{ color: "white" }}
                            >
                              Password
                            </div>
                            <Password
                              id="password"
                              className={classNames(
                                "w-full shadow-2",
                                loginError ? "p-invalid" : ""
                              )}
                              inputClassName="w-full"
                              value={password ?? ""}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                setLoginError(undefined);
                              }}
                              onKeyDown={(e) =>
                                e.key === "Enter"
                                  ? executeLogin(username, password)
                                  : {}
                              }
                              feedback={false}
                              toggleMask
                            />
                          </div>
                          <div
                            style={{ width: "75%" }}
                            className="align-self-center"
                          >
                            <div className="w-full text-right mb-4">
                              {loginError && (
                                <div
                                  className="w-12 h-3rem text-red-500 mt-2 px-2 py-1 border-2 border-round-sm flex"
                                  style={{
                                    backgroundColor: "rgba(255,0,0,0.12)",
                                  }}
                                >
                                  <div className="w-2rem h-full flex align-items-center text-2xl justify-content-center">
                                    <FontAwesomeIcon
                                      icon={faCircleExclamation}
                                    />
                                  </div>
                                  <div className="w-full h-full flex justify-content-center align-items-center">
                                    {loginError}
                                  </div>
                                </div>
                              )}
                              <div
                                className="w-12 cursor-pointer"
                                onClick={() => navigateToForgotPassword()}
                                style={{ color: "white" }}
                              >
                                Forgot password?
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ width: "75%" }}
                            className="flex gap-5 mb-5 align-self-center"
                          >
                            <AnimatePresence>
                              <motion.button
                                style={btnStyles.paOutlinedButton}
                                initial="iniOutlinedButton"
                                whileHover="hovOutlinedButton"
                                variants={btnVariants}
                                onClick={signUp}
                              >
                                Sign up
                              </motion.button>
                            </AnimatePresence>
                            <AnimatePresence>
                              <motion.button
                                style={btnStyles.paButton}
                                initial="iniButton"
                                whileHover="hovButton"
                                variants={btnVariants}
                                onClick={() => executeLogin(username, password)}
                              >
                                Sign in
                              </motion.button>
                            </AnimatePresence>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
