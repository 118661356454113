import { useMemo, useState } from "react";
import { LanguagesOverview } from "./LanguagesOverview";
import { LanguageTranslationsManagement } from "./LanguageTranslationsManagement";
import { Language } from "../../queries/models/language.model";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";

export function TranslationsPage() {
  const [selectedLanguage, setSelectedLanguage] = useState<
    Language | undefined
  >(undefined);
  const hasWriteAccess = useMemo(
    () => authService.hasAccess(AppFeatures.WebTranslationsWrite),
    []
  );

  return (
    <>
      <div className="flex gap-2 p-1 h-full w-full">
        <div className="w-3">
          <RoundedShadowContainer
            small
            fullHeight
          >
            <LanguagesOverview
              hasWriteAccess={hasWriteAccess}
              onSelectedLanguageChange={setSelectedLanguage}
            />
          </RoundedShadowContainer>
        </div>
        <div className="w-9">
          <RoundedShadowContainer
            small
            fullHeight
          >
            <LanguageTranslationsManagement
              selectedLanguage={selectedLanguage}
              setSelectedLanguageChange={setSelectedLanguage}
              hasWriteAccess={hasWriteAccess}
            />
          </RoundedShadowContainer>
        </div>
      </div>
    </>
  );
}
