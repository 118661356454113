import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { Language } from "../../queries/models/language.model";
import { SelectItem } from "primereact/selectitem";
import { useTranslation } from "react-i18next";

interface TranslationManagerTableHeaderProps {
  languageName: string;
  isSelectedLanguagePublished: boolean;
  translationTypesOptions: SelectItem[];
  selectedTranslationTypesOptions: SelectItem[];
  onSelectedTranslationTypesOptionsChange: (items: SelectItem[]) => void;
  onSelectedLanguageChange: (language: Language | undefined) => void;
  onDisactiveLanguage: () => void;
  onPublishLanguage: () => void;
  onUnPublishLanguage: () => void;
  disactiveButtonDisable?: boolean;
  publishButtonDisable?: boolean;
}

export function TranslationManagerTableHeader({
  languageName,
  onSelectedLanguageChange,
  translationTypesOptions,
  selectedTranslationTypesOptions,
  onSelectedTranslationTypesOptionsChange,
  onDisactiveLanguage,
  isSelectedLanguagePublished,
  onPublishLanguage,
  onUnPublishLanguage,
  disactiveButtonDisable = true,
  publishButtonDisable = true,
}: TranslationManagerTableHeaderProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-content-between">
        <div className="text-xl font-bold">
          {`${t("common.translationManager")} (${languageName})`}
        </div>
        <div className="flex gap-1">
          <Button
            label={t("common.disactive")}
            className="delete-button"
            style={{ width: "max-content" }}
            icon="pi pi-times"
            disabled={isSelectedLanguagePublished || disactiveButtonDisable}
            onClick={onDisactiveLanguage}
          />
          <Button
            label={isSelectedLanguagePublished ? t("common.unpublish") : t("common.publish")}
            className="delete-button"
            icon={
              isSelectedLanguagePublished ? "pi pi-backward" : "pi pi-forward"
            }
            style={{ width: "max-content" }}
            onClick={
              isSelectedLanguagePublished
                ? onUnPublishLanguage
                : onPublishLanguage
            }
            disabled={publishButtonDisable}
          />
          <Button
            label="Cancel"
            className="yellow-button"
            style={{ width: "max-content" }}
            onClick={() => onSelectedLanguageChange(undefined)}
          />
        </div>
      </div>
      <div className="w-full py-1">
        <SelectButton
          options={translationTypesOptions}
          className="translation-types-button"
          value={selectedTranslationTypesOptions}
          onChange={(e) =>
            onSelectedTranslationTypesOptionsChange(e.target.value)
          }
          optionLabel="label"
          multiple
        />
      </div>
    </>
  );
}
