export enum Repetition {
    None = 0,
    EveryWeek = 1,
    By2Weeks = 2,
    By3Weeks = 3,
    By4Weeks = 4,
    EveryMonth = 10,
    By2Months = 11,
    ByQuarter = 12,
    Every6Months = 13,
    EveryYear = 20,
    ByTwoYears = 21,
}