import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import { Note } from "../../ui/Note/Note";
import { useGetNoteQuery } from "../../../queries/notes.query";
import { AddNoteRequest } from "../../../queries/models/add-note-request";
import { useTranslation } from "react-i18next";

export function NoteSection({
  noteId,
  onAddNote,
}: {
  noteId: number | undefined;
  onAddNote: (data: AddNoteRequest) => void;
}) {
  const { t } = useTranslation();
  const notesQuery = useGetNoteQuery(noteId);

  return (
    <div className="h-full">
      <section className="height-full">
        <div className="section-header flex align-items-center gap-2">
          <FontAwesomeIcon icon={faNoteSticky} />
          <div>{t("common.note")}</div>
        </div>
        <div className="section-content">
          <Note
            items={notesQuery.data?.items ?? []}
            isLoading={notesQuery.isLoading}
            onAddNote={onAddNote}
            hasWriteAccess
          />
        </div>
      </section>
    </div>
  );
}
