import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AxiosProgressEvent } from "axios";
import { CustomProgressBar } from "./custom-progress-bar";
import { FileModel } from "../../queries/models/file-model";
import { fileService } from "../../services/image.service";

interface FileDownloadProps {
  file: FileModel;
}

export function FileDownload({ file }: FileDownloadProps) {
  const [progress, setProgress] = useState<number | undefined>(0);
  const lastProgressUpdateRef = useRef<Date>();
  const onProgress = useCallback(
    (e: AxiosProgressEvent) => {
      const now = new Date();
      if (
        !lastProgressUpdateRef.current ||
        +now - +lastProgressUpdateRef.current >= 100 ||
        e.progress === 1 ||
        e.progress === undefined
      ) {
        lastProgressUpdateRef.current = now;
        setProgress(e.progress && e.progress * 100);
      }
    },
    [lastProgressUpdateRef]
  );

  const displayFileName = useMemo(() => {
    const date = file.date?.toLocaleDateString();
    return `${date} ${file.fileName}`;
  }, [file.date, file.fileName]);

  useEffect(() => {
    if (file) {
      setProgress(0);
    } else {
      setProgress(undefined);
    }
  }, [file]);

  function handleDownload() {
    fileService.downloadFileBlob(file.downloadUrl!, file.fileName, onProgress);
  }

  return (
    <div className="flex flex-row cursor-pointer font-italic hover:text-indigo-600 hover:underline w-full">
      <div
        className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
        onClick={handleDownload}
      >
        {displayFileName}
      </div>
      {progress !== undefined && progress > 0 && progress < 100 && (
        <div>
          <CustomProgressBar value={progress} />
        </div>
      )}
    </div>
  );
}
