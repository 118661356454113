import { AnimatePresence, motion } from "framer-motion";
import emailSent from "../../../assets/icons/email_send.svg";

export interface CompanyRegisteredProps {
  onShowLogin: () => void;
}

export function CompanyRegistered({ onShowLogin }: CompanyRegisteredProps) {
  return (
    <AnimatePresence>
      {
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: "0%" }}
          exit={{ x: "100%" }}
          transition={{ ease: "linear", duration: 0.6 }}
          className="w-full flex h-full"
          style={{ borderRadius: "0px 20px 20px 0px" }}
        >
          <div className="p-4">
            <img
              style={{
                width: "100px",
                height: "100px",
                margin: "0 auto",
                display: "block",
              }}
              src={emailSent}
              alt="PurpleApp logo"
            />
            <div className="text-xl text-white">
              We've sent you an email with link to confirm account. Please,
              check you email box.
            </div>
            <div className="text-xl mt-4 text-gray-200 text-center">
              Email was not delivered?
            </div>
            <div className="text-gray-200 mt-2">
              Check your folders: spam, offer, community or contact our support
              service.
            </div>

            <div
              onClick={onShowLogin}
              className="mt-2 p-link no-underline hover:underline text-blue-800 hover:text-blue-600"
            >
              Back to logging page
            </div>
          </div>
        </motion.div>
      }
    </AnimatePresence>
  );
}
