import { useQuery, useMutation } from "react-query";
import { CreateOrUpdateAssetTypeRequest } from "./models/create-or-update-asset-type-request.model";
import { assetTypesService } from "../services/asset-types.service";

export function useAssetTypesQuery() {
  return useQuery(["asset-types"], () => assetTypesService.getAll());
}

export function useActiveAssetTypesQuery() {
  return useQuery(["asset-types", "active"], () =>
    assetTypesService.getAllActive()
  );
}

export function useAssetTypeQuery(id: number | undefined) {
  return useQuery(["asset-types", id], () => assetTypesService.getById(id!), {
    enabled: !!id,
  });
}

export function useAddAssetTypeMutation() {
  return useMutation((request: CreateOrUpdateAssetTypeRequest) =>
    assetTypesService.addAssetType(request)
  );
}

export function useUpdateAssetTypeMutation() {
  return useMutation((request: CreateOrUpdateAssetTypeRequest) =>
    assetTypesService.updateAssetType(request)
  );
}

export function useDeleteAssetTypeMutation() {
  return useMutation((id: number) => assetTypesService.deleteAssetType(id));
}
