import { useMutation, useQuery } from 'react-query';
import { appSettingsService } from '../services/app-settings.service';
import { AppSettings } from './models/app-settings.model';
import { UpdateAppSettings } from './models/update-app-settings.model';

export function useAppSettingsQuery() {
  return useQuery<AppSettings>(
    ["appSettings"],
    () => fetchAppSettingsOverview(),
    { refetchOnWindowFocus: false }
  );
}
async function fetchAppSettingsOverview() {
  return await appSettingsService.getAppSettings();
}
export function useEditAppSettingsMutation() {
  return useMutation((request: UpdateAppSettings) => appSettingsService.editAppSettings(request));
}
export function useGetNextQrMutation() {
  return useMutation(() => appSettingsService.getNextQr());
}
export function useGetNextQrsMutation() {
  return useMutation((count: number) => appSettingsService.getNextQrs(count));
}