import { useQueryClient } from "react-query";
import { useToast } from "../../../components/ui/toast-context-provider";
import { useCallback, useState } from "react";
import {
  useActiveTagsQuery,
  useAddTagMutation,
  useDeleteTagMutation,
  useUpdateTagMutation,
} from "../../../queries/tags.query";
import { CreateOrUpdateTag } from "../../../queries/models/create-or-update-tag";
import { CreateOrUpdateTagRequest } from "../../../queries/models/create-or-update-tag-request.model";
import {
  CustomModal,
  CustomModalProps,
} from "../../../components/ui/MobileModal/custom-modal";
import { TagsOverview } from "../TagsOverview";
import { TagForm } from "../TagForm";
import { Tag } from "../../../queries/models/tag.model";

export function MobileTagsComponent() {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [selectedTag, setSelectedTag] = useState<Tag | undefined>(undefined);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const tagsQuery = useActiveTagsQuery();
  const addTagMutation = useAddTagMutation();
  const updateTagMutation = useUpdateTagMutation();
  const deleteTagMutation = useDeleteTagMutation();

  const saveNewTag = useCallback(
    (data: CreateOrUpdateTag) => {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedTag(undefined);

          toast.current?.show({
            severity: "success",
            detail: "Save success",
          });
          await queryClient.invalidateQueries("tags");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: CreateOrUpdateTagRequest = {
        name: data.name,
        comment: data.comment,
        textColor: `#${data.textColor}`,
        backgroundColor: `#${data.backgroundColor}`,
        icon: data.icon,
      };

      return addTagMutation.mutateAsync(request, mutateOptions);
    },
    [addTagMutation, queryClient, toast]
  );

  const editExistingTag = useCallback(
    (data: CreateOrUpdateTag) => {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedTag(undefined);
          toast.current?.show({
            severity: "success",
            detail: "Edit success",
          });
          await queryClient.invalidateQueries("tags");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: CreateOrUpdateTag = {
        id: data.id,
        name: data.name,
        comment: data.comment,
        textColor: `#${data.textColor}`,
        backgroundColor: `#${data.backgroundColor}`,
        icon: data.icon,
      };

      return updateTagMutation.mutateAsync(request, mutateOptions);
    },
    [queryClient, toast, updateTagMutation]
  );

  const handleSaveTag = useCallback(
    (data: CreateOrUpdateTag) =>
      data.id === 0 ? saveNewTag(data) : editExistingTag(data),
    [editExistingTag, saveNewTag]
  );

  const handleDeleteTag = useCallback(() => {
    if (selectedTag?.id) {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedTag(undefined);
          toast.current?.show({
            severity: "success",
            detail: "Delete success",
          });
          await queryClient.invalidateQueries("tags");
          setIsDeleteConfirmationModalOpen(false);
          setSelectedTag(undefined);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      deleteTagMutation.mutateAsync(selectedTag.id, mutateOptions);
    }
  }, [deleteTagMutation, queryClient, selectedTag?.id, toast]);

  const deleteConfirmationModalProps: CustomModalProps = {
    isOpen: isDeleteConfirmationModalOpen,
    onClose: () => setIsDeleteConfirmationModalOpen(false),
    header: "Confirmation",
    body: "Delete this tag?",
    justified: true,
    centered: true,
    height: "max-content",
    confirmation: true,
    onConfirm: async () => {
      handleDeleteTag();
    },
  };

  const selectedTagModal: CustomModalProps = {
    isOpen: !!selectedTag,
    onClose: () => {
      setSelectedTag(undefined);
    },
    header: selectedTag?.name ?? "",
    justified: true,
    centered: true,
    height: "max-content",
    confirmation: false,
    body: selectedTag && (
      <TagForm
        tag={selectedTag}
        onCancel={() => {
          setSelectedTag(undefined);
        }}
        onSave={handleSaveTag}
        onDelete={() => setIsDeleteConfirmationModalOpen(true)}
      />
    ),
  };

  return (
    <div className="h-full p-1">
      <CustomModal {...selectedTagModal} />
      <CustomModal {...deleteConfirmationModalProps} />
      <div className="h-full w-12">
        <TagsOverview
          items={tagsQuery.data}
          isLoading={tagsQuery.isLoading}
          selectedTag={selectedTag}
          setSelectedTag={(x) => {
            setSelectedTag(x);
          }}
        />
      </div>
    </div>
  );
}
