import { useQuery, useMutation, UseQueryOptions } from "react-query";
import { Asset } from "./models/asset.model";
import { assetsService } from "../services/assets.service";
import { CreateOrUpdateAsset } from "./models/create-or-update-asset.model";
import { SelectItem } from "primereact/selectitem";

export function useGetAllAssetsQuery() {
  return useQuery<Asset[]>(["assets"], () => assetsService.getAllActive());
}

export function useGetAllAssetsAsSelectOptionsQuery() {
  return useQuery<SelectItem[]>(["assets"], () =>
    assetsService.getAllAsSelectOptions()
  );
}

export function useGetAllInstallationAssetsQuery(
  installtionId: number | undefined
) {
  return useQuery<Asset[]>(
    ["assets", installtionId],
    () => assetsService.getAllInInstallation(installtionId!),
    {
      refetchOnWindowFocus: false,
      enabled: installtionId !== undefined,
    }
  );
}

export function useGetAllInstallationAssetsAsSelectOptionsQuery(
  installationId: number | undefined
) {
  const queryOptions: UseQueryOptions<SelectItem[]> = {
    enabled: !!installationId,
  };

  return useQuery<SelectItem[]>(
    ["assets", installationId],
    () => assetsService.getAllInInstallationAsSelectOptions(installationId!),
    queryOptions
  );
}

export function useGetAssetQuery(id: number | undefined) {
  return useQuery<Asset | undefined>(
    ["addresses", id],
    () => assetsService.getById(id!),
    { refetchOnWindowFocus: false, enabled: id !== undefined }
  );
}

export function useAddAssetMutation() {
  return useMutation((request: CreateOrUpdateAsset) =>
    assetsService.create(request)
  );
}

export function useEditAssetMutation() {
  return useMutation((request: CreateOrUpdateAsset) =>
    assetsService.update(request)
  );
}

export function useDeleteAssetMutation() {
  return useMutation((id: number) => assetsService.delete(id));
}

export function useAddEmptyNoteMutation() {
  return useMutation((id: number) => assetsService.addEmptyNote(id));
}
