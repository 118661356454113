export interface MoveScalePoint {
  id?: any;
  x: number;
  y: number;
}
export interface MoveScalePlane {
  scale: number;
  transform: MoveScalePoint;
}
type Bound = [number | undefined, number | undefined];
export interface MoveScalePlaneBounds {
  scale?: Bound;
  x?: Bound;
  y?: Bound;
}

function center(points: MoveScalePoint[] | MoveScalePoint): MoveScalePoint {
  if (!Array.isArray(points)) return points;
  const center = points.reduce(
    (a, b) => {
      a.x += b.x;
      a.y += b.y;
      return a;
    },
    { x: 0, y: 0 }
  );

  center.x /= points.length;
  center.y /= points.length;

  return center;
}

function difference(
  pointA: MoveScalePoint,
  pointB: MoveScalePoint
): MoveScalePoint {
  return {
    x: pointB.x - pointA.x,
    y: pointB.y - pointA.y,
  };
}

function sum(pointA: MoveScalePoint, pointB: MoveScalePoint): MoveScalePoint {
  return {
    x: pointB.x + pointA.x,
    y: pointB.y + pointA.y,
  };
}

function multiply(point: MoveScalePoint, coefficient: number): MoveScalePoint {
  return {
    x: point.x * coefficient,
    y: point.y * coefficient,
  };
}

function distance(pointA: MoveScalePoint, pointB: MoveScalePoint): number {
  return Math.sqrt((pointA.x - pointB.x) ** 2 + (pointA.y - pointB.y) ** 2);
}

function cutBorder(x: number, option?: Bound): number {
  if (option?.[0] !== undefined && x < option[0]) return option[0];
  if (option?.[1] !== undefined && option[1] < x) return option[1];
  return x;
}

export function movePointersOnPlane(
  initial: MoveScalePoint | [MoveScalePoint, MoveScalePoint] | MoveScalePoint[],
  final: MoveScalePoint | [MoveScalePoint, MoveScalePoint] | MoveScalePoint[],
  initialPlane?: MoveScalePlane,
  bounds?: MoveScalePlaneBounds
): MoveScalePlane {
  let scale = 1;
  if (
    Array.isArray(initial) &&
    Array.isArray(final) &&
    initial.length >= 2 &&
    final.length >= 2
  ) {
    const initialDistance = distance(initial[0], initial[1]);
    const finalDistance = distance(final[0], final[1]);
    if (initialDistance) scale = finalDistance / initialDistance;
    else scale = Number.MAX_SAFE_INTEGER;
  }

  const centerInitial = center(initial);
  const centerFinal = center(final);

  let transform = difference(centerInitial, centerFinal);

  if (initialPlane) {
    scale = cutBorder(initialPlane.scale * scale, bounds?.scale);

    transform = sum(
      sum(
        multiply(transform, initialPlane.scale / scale),
        initialPlane.transform
      ),
      multiply(
        sum(centerInitial, initialPlane.transform),
        initialPlane.scale / scale - 1
      )
    );
  }

  scale = cutBorder(scale, bounds?.scale);

  transform.x = cutBorder(transform.x, bounds?.x);
  transform.y = cutBorder(transform.y, bounds?.y);

  return {
    scale,
    transform,
  };
}

export function scaleAround(
  around: MoveScalePoint,
  by: number,
  initialPlane?: MoveScalePlane,
  bounds?: MoveScalePlaneBounds
): MoveScalePlane {
  const baseOffset = 10;
  const initialPointA: MoveScalePoint = {
    x: around.x - baseOffset,
    y: around.y,
  };
  const initialPointB: MoveScalePoint = {
    x: around.x + baseOffset,
    y: around.y,
  };
  const finalPointA: MoveScalePoint = {
    x: around.x - baseOffset * by,
    y: around.y,
  };
  const finalPointB: MoveScalePoint = {
    x: around.x + baseOffset * by,
    y: around.y,
  };
  return movePointersOnPlane(
    [initialPointA, initialPointB],
    [finalPointA, finalPointB],
    initialPlane,
    bounds
  );
}
