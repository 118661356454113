import { useCallback, useMemo } from "react";
import { WorkTask } from "../../queries/models/work-task.model";
import { SelectItem } from "primereact/selectitem";
import { CreateOrUpdateWorkTask } from "../../queries/models/create-or-update-work-task";
import { TaskState } from "../../queries/models/enums/task-state.enum";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { Form, Formik } from "formik";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import FormikDropDown from "../../components/ui/formik/FormikDropdown";
import FormikMultiSelect from "../../components/ui/formik/FormikMultiSelect";
import FormikCalendar from "../../components/ui/formik/FormikCalendar";
import * as Yup from "yup";
import { TaskSeverity } from "../../queries/models/enums/task-severity.enum";
import { TaskType } from "../../queries/models/enums/task-type.enum";
import { EnumExtensions } from "../../utils/enum-extensions";
import { useTranslation } from "react-i18next";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";

interface Props {
  workTask: WorkTask;
  userOptions: SelectItem[];
  installationOptions: SelectItem[];
  onCancel: () => Promise<any> | void;
  onSave: (form: CreateOrUpdateWorkTask) => Promise<any>;
  onDelete: () => Promise<any> | void;
}

export function WorkTaskForm({
  workTask,
  userOptions,
  installationOptions,
  onCancel,
  onSave,
  onDelete,
}: Props) {
  const { t } = useTranslation();
  const severityOptions = useMemo(
    () =>
      EnumExtensions.getLabelAndValues(TaskSeverity).filter(
        (x) => x.value !== TaskSeverity.Unspecified
      ),
    []
  );

  const hasWriteAccess = useMemo(
    () => authService.hasAccess(AppFeatures.WebKanbanBoardWrite),
    []
  );

  const initialValues: CreateOrUpdateWorkTask = {
    id: workTask.id ?? 0,
    name: workTask.name ?? "",
    description: workTask.description ?? "",
    state: workTask.state ?? TaskState.Backlog,
    dueDate: workTask.dueDate ? new Date(workTask.dueDate) : undefined,
    assignedUserIds: workTask.assignedUserIds ?? [],
    installationId: workTask.installationId ?? undefined,
    type: workTask.type ?? TaskType.Unspecified,
    severity: workTask.severity ?? TaskSeverity.Normal,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t("alert.mustBe3CharactersOrMore"))
      .required(t("alert.required")),
    description: Yup.string()
      .min(3, t("alert.mustBe3CharactersOrMore"))
      .optional(),
    installationId: Yup.number(),
    assignedUserIds: Yup.array(),
    severity: Yup.mixed<TaskSeverity>().oneOf(
      Object.values(TaskSeverity) as number[]
    ),
    dueDate: Yup.date().optional().nullable(),
  });

  const onSubmit = useCallback(
    (values: CreateOrUpdateWorkTask) => {
      onSave(values);
    },
    [onSave]
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange
        validateOnMount
      >
        {(formik) => (
          <Form autoComplete="off">
            <div className="pl-2 pr-1 pb-1 pt-4 sticky top-0 bg-white z-2">
              <EditContentHeader
                header={workTask.name}
                showDeleteButton={hasWriteAccess && !!workTask.id}
                saveButtonDisabled={
                  !hasWriteAccess || !formik.dirty || !formik.isValid
                }
                onSaveClick={formik.submitForm}
                onCancelClick={onCancel}
                onDeleteClick={onDelete}
              />
            </div>

            <div className="formgrid grid m-0">
              <div className="field col-6">
                <FormikInputText
                  name="name"
                  label={t("common.name")}
                  validationSchema={validationSchema}
                  isIndependent
                  autoComplete="off"
                />
              </div>
              <div className="field col-6">
                <FormikInputText
                  name="description"
                  label={t("common.description")}
                  validationSchema={validationSchema}
                  isIndependent
                  autoComplete="off"
                />
              </div>
              <div className="field col-6">
                <FormikDropDown
                  name="installationId"
                  label={t("common.installation")}
                  options={installationOptions}
                  validationSchema={validationSchema}
                  isIndependent
                  showClear
                  filter
                />
              </div>
              <div className="field col-6">
                <FormikMultiSelect
                  name="assignedUserIds"
                  label={t("common.assignedUsers")}
                  options={userOptions}
                  validationSchema={validationSchema}
                  filter
                />
              </div>
              <div className="field col-6">
                <FormikDropDown
                  name="severity"
                  label={t("common.severity")}
                  options={severityOptions}
                  validationSchema={validationSchema}
                  isIndependent
                />
              </div>
              <div className="field col-6">
                <FormikCalendar
                  name="dueDate"
                  label={t("common.dueDate")}
                  dateFormat="dd/mm/yy"
                  validationSchema={validationSchema}
                  isIndependent
                  showButtonBar
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
