import logo from "../../assets/icons/logo-white.svg";
import errorBg from "../../assets/icons/error.png";

export function Page401() {
  return (
    <div className="center">
      <div className="img-container">
        <img src={errorBg} alt="My Happy SVG" />
      </div>
      <div className="error-container">
        <div className="error-code">401</div>
        <div className="error-desc">Not Authorized</div>
        <div>You don't have permission to access this resource.</div>
        <a href="/app">
          <img src={logo} alt="My Happy SVG" />
          <div>Navigate me back</div>
        </a>
      </div>
    </div>
  );
}
