import { Toast } from "primereact/toast";
import { RefObject, createContext, useContext, useState } from "react";

export interface DragItemContextProviderProps {
  children: React.ReactNode;
}

const DragItemContext = createContext<any>(
  {} as React.MutableRefObject<React.RefObject<HTMLDivElement>>
);

export function DragItemContextProvider({
  children,
}: DragItemContextProviderProps) {
  const [dragItemRef, setDragItemRef] = useState(null);

  return (
    <DragItemContext.Provider value={{ dragItemRef, setDragItemRef }}>
      {children}
    </DragItemContext.Provider>
  );
}

export function useDragItem() {
  return useContext(DragItemContext);
}
