import { CalendarComponent } from "../../components/ui/calendar/CalendarComponent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useActiveUsersAsSelectOptionQuery } from "../../queries/users.query";
import { useInstallationsOptionsQuery } from "../../queries/installations.query";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { useUserEntriesQuery } from "../../queries/work-entries.query";
import { authService } from "../../services/auth.service";
import { Dropdown } from "primereact/dropdown";
import { SelectItem } from "primereact/selectitem";
import { Divider } from "primereact/divider";
import { CalendarEntry } from "../../components/ui/calendar/calendar-entry";
import { TimeSpan } from "../../utils/timespan";
import { WorkInstanceType } from "../../queries/models/enums/work-instance-type.enum";
import { EditWorkDayComponent } from "./EditWorkDayComponent";
import { isSameDay } from "date-fns";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import {
  asDataTableFilterOptions,
  useDataTableLocalFilters,
} from "../../hooks/useDataTableLocalFilters";
import { dateParser } from "../../utils/dataTableCustomFilters";
import { FilterMatchMode } from "primereact/api";
import { useTranslation } from "react-i18next";

const localFiltersOptions = asDataTableFilterOptions({
  useUrlParams: true,
  dataTableSchema: {
    selectedMonth: {
      external: true,
      parse: dateParser,
      matchMode: FilterMatchMode.EQUALS,
    },
    selectedUserId: {
      external: true,
      matchMode: FilterMatchMode.EQUALS,
    },
    selectedDate: {
      external: true,
      parse: dateParser,
      matchMode: FilterMatchMode.EQUALS,
    },
  },
});

export function WorkEntriesPage() {
  const { t } = useTranslation();
  const { getFilterValue, setFilter } =
    useDataTableLocalFilters(localFiltersOptions);

  const [selectedMonth, setSelectedMonth] = useState<Date>(
    getFilterValue("selectedMonth") ?? new Date()
  );
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(
    getFilterValue("selectedUserId")
  );
  const userEntriesQuery = useUserEntriesQuery(selectedUserId, selectedMonth);
  const usersOptionsQuery = useActiveUsersAsSelectOptionQuery();
  const installationsOptionsQuery = useInstallationsOptionsQuery();
  const [selectedDate, setSelectedDate] = useState<Date>(
    getFilterValue("selectedDate") ?? new Date()
  );

  const regularWorkColor = "#388E3C";
  const conceputalWorkColor = "#303F9F";

  const calendarEntries = useMemo(() => {
    return (
      userEntriesQuery.data?.map((x) => {
        return {
          date: x.date,
          subtitle: x.installationName,
          numberValue: x.durationTicks,
          title: TimeSpan.fromTicks(x.durationTicks).toHhMm(),
          textColor: x.hasInvalidWorkHours ? "red" : undefined,
          indicatorColor:
            x.workInstanceType === WorkInstanceType.Regular
              ? regularWorkColor
              : conceputalWorkColor,
          comment: x.comment,
        } as CalendarEntry;
      }) ?? []
    );
  }, [userEntriesQuery.data]);

  const calendarDayHeader = useCallback((entries: CalendarEntry[]) => {
    const durationSum = entries.reduce(
      (prev, curr) => prev + (curr.numberValue ?? 0),
      0
    );
    return (
      <div className="h-full w-full flex justify-content-center align-self-center">
        <div className="flex text-l font-bold">
          {TimeSpan.fromTicks(durationSum).toHhMm()}
        </div>
      </div>
    );
  }, []);

  const visibleUsers: SelectItem[] = useMemo(() => {
    if (authService.hasAccess(AppFeatures.CanManageWorkEntries)) {
      return usersOptionsQuery.data ?? [];
    } else {
      const user = authService.getLoggedUser();
      return [{ label: user?.username, value: user?.id }] as SelectItem[];
    }
  }, [usersOptionsQuery.data]);

  useEffect(() => {
    var newValue = authService.getLoggedUser()?.id;
    setSelectedUserId((prev) => {
      if (prev !== undefined) return prev;
      setFilter("selectedUserId", {
        value: newValue,
        matchMode: FilterMatchMode.EQUALS,
      });
      return newValue;
    });
  }, [setFilter]);

  const onMonthSelected = useCallback(
    (x: Date | undefined) => {
      const newValue = x ?? new Date();
      setSelectedMonth((prev) => {
        if (prev && newValue && +prev === +newValue) return prev;
        setFilter("selectedMonth", {
          value: newValue,
          matchMode: FilterMatchMode.EQUALS,
        });
        return newValue;
      });
    },
    [setFilter]
  );

  const selectedDayEntries = useMemo(() => {
    return userEntriesQuery.data?.filter((x) =>
      isSameDay(x.date, selectedDate!)
    );
  }, [userEntriesQuery.data, selectedDate]);

  const onSelectedDateChanged = useCallback(
    (x: Date[]) => {
      var newValue = x[0] ?? new Date();
      setSelectedDate((prev) => {
        if (prev && newValue && +prev === +newValue) return prev;
        setFilter("selectedDate", {
          value: newValue,
          matchMode: FilterMatchMode.EQUALS,
        });
        return newValue;
      });
    },
    [setFilter]
  );

  return (
    <div className="flex h-full w-full p-1 gap-2 overflow-hidden">
      <div className="w-7 h-full">
        <RoundedShadowContainer
          small
          fullHeight
        >
          <LoaderWrapper
            containerClassName="h-full w-full flex flex-column pb-8"
            isLoading={
              usersOptionsQuery.isLoading || userEntriesQuery.isLoading
            }
          >
            <Dropdown
              value={selectedUserId}
              onChange={(e) => {
                setSelectedUserId(e.value);
                setFilter("selectedUserId", {
                  value: e.value,
                  matchMode: FilterMatchMode.EQUALS,
                });
              }}
              options={visibleUsers}
              optionLabel="label"
              placeholder={t("common.user")}
              className="w-full md:w-14rem m-2"
              filter
            />
            <Divider />

            <CalendarComponent
              onDatesSelected={onSelectedDateChanged}
              defaultDatesSelected={selectedDate}
              onMonthSelected={onMonthSelected}
              defaultMonthSelected={selectedMonth}
              entries={calendarEntries}
              singleSelection
              canSelectFuture={false}
              dayHeader={calendarDayHeader}
              legend={[
                {
                  color: regularWorkColor,
                  description: t("common.regularWork"),
                },
                {
                  color: conceputalWorkColor,
                  description: t("common.conceptualWork"),
                },
              ]}
            ></CalendarComponent>
          </LoaderWrapper>
        </RoundedShadowContainer>
      </div>
      <div className="w-5 h-full">
        {selectedDate && selectedUserId && (
          <EditWorkDayComponent
            selectedDate={selectedDate}
            installations={installationsOptionsQuery.data}
            userId={selectedUserId}
            entries={selectedDayEntries}
          ></EditWorkDayComponent>
        )}
      </div>
    </div>
  );
}
