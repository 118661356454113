import { useState, useMemo } from "react";
import { Button } from "primereact/button";
import { Chips, ChipsProps } from "primereact/chips";
import {
  FastField,
  Field,
  FieldInputProps,
  FieldMetaProps,
  FormikContextType,
} from "formik";
import { FormInputLabel } from "./FormInputLabel";
import { CustomModal } from "../MobileModal/custom-modal";
import { useWindowSize } from "../../../hooks/use-window-size";
import { QrCodeReader } from "../../../pages/AllAssets/QrCodeReader";
import * as Yup from "yup";
import QRCode from "react-qr-code";
import { useGetNextQrMutation } from "../../../queries/app-settings.query";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";

export interface FormikQrCodesProps extends ChipsProps {
  label: string;
  name: string;
  validationSchema?: Yup.ObjectSchema<any, Yup.AnyObject, any, "">;
  isIndependent?: boolean;
}

export function FormikQrCodes({
  label,
  name,
  validationSchema,
  isIndependent,
  ...rest
}: FormikQrCodesProps) {
  const { t } = useTranslation();
  const getNextQrMutation = useGetNextQrMutation();
  const { lg } = useWindowSize() || {};
  const [newQrCode, setNewQrCode] = useState<string | undefined>();
  const [isAssetCodesModalOpen, setIsAssetCodesModalOpen] = useState(false);

  const Component = useMemo(() => {
    return isIndependent ? FastField : Field;
  }, [isIndependent]);

  const handleGenerateNextQrCode = async (form: any, field: any) => {
    const nextQrCode = await getNextQrMutation.mutateAsync();
    const newCodeArray = Array.from(
      new Set<string>([...field.value, nextQrCode!])
    );
    form.setFieldValue(name, newCodeArray);
  };

  return (
    <>
      <FormInputLabel
        nameFor={name}
        validationSchema={validationSchema}
      >
        {label}
      </FormInputLabel>
      <Component name={name}>
        {({
          form,
          field,
          meta,
        }: {
          form: FormikContextType<any>;
          field: FieldInputProps<string[]>;
          meta: FieldMetaProps<string[]>;
        }) => {
          return (
            <div className="flex flex-column h-full w-full gap-1">
              <CustomModal
                isOpen={isAssetCodesModalOpen}
                header={t("common.assetCodes")}
                onClose={() => {
                  setIsAssetCodesModalOpen(false);
                  setNewQrCode(undefined);
                }}
                justified={true}
                width={lg ? "60%" : "95%"}
                height="max-content"
                body={
                  <>
                    <QrCodeReader
                      qrCode={newQrCode}
                      setQrCode={setNewQrCode}
                    />
                    <div className="py-1">
                      <Button
                        label={t("common.addCode")}
                        disabled={!newQrCode}
                        className="green-action-button w-8rem"
                        onClick={() => {
                          const newCodeArray = Array.from(
                            new Set<string>([...field.value, newQrCode!])
                          );
                          form.setFieldValue(name, newCodeArray);
                          setNewQrCode(undefined);
                        }}
                      />
                    </div>
                  </>
                }
              />
              <div
                className={classNames(
                  lg ? " flex-row" : "flex-column",
                  "w-full flex"
                )}
              >
                <div className={lg ? "w-6" : "w-full"}>
                  <Chips
                    value={field.value}
                    className="w-full qr-codes-chips"
                    data-pr-classname="w-full"
                    removable
                    onChange={(e) => {
                      const newCodeArray = Array.from(new Set<string>(e.value));
                      form.setFieldValue(name, newCodeArray);
                    }}
                    onRemove={(e) => {
                      const newCodeArray = field.value?.filter(
                        (x) => x !== e.value[0]
                      );
                      form.setFieldValue(name, newCodeArray);
                    }}
                  />
                  <div className="flex flex-row gap-1 py-2">
                    <Button
                      type="button"
                      className="green-action-button w-10rem"
                      label={t("common.scanNew")}
                      disabled={rest.disabled}
                      onClick={() => setIsAssetCodesModalOpen(true)}
                    />
                    <Button
                      type="button"
                      className="green-action-button w-10rem"
                      label={t("common.generateNew")}
                      disabled={rest.disabled}
                      onClick={() => handleGenerateNextQrCode(form, field)}
                    />
                  </div>
                </div>

                <div
                  className={classNames(
                    lg ? "w-6 " : "w-full",
                    "flex px-4 flex-row flex-wrap h-full gap-4"
                  )}
                >
                  {field.value?.map((x, i) => (
                    <div
                      className="flex flex-column"
                      key={i}
                    >
                      <QRCode
                        value={x}
                        size={100}
                      />
                      <div className="text-center">{x}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        }}
      </Component>
    </>
  );
}
