import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { useTranslation } from "react-i18next";

interface Props {
  showTasksCount: boolean;
  setShowTasksCount: (value: boolean) => void;
}

export function BoardConfigurationElement({
  showTasksCount,
  setShowTasksCount,
}: Props) {
  const { t } = useTranslation();
  const overlayRef = useRef<OverlayPanel>(null);

  return (
    <div className="flex align-items-center">
      <div
        className="cursor-pointer"
        onClick={(e) => overlayRef.current?.toggle(e)}
      >
        <FontAwesomeIcon
          icon={faGear}
          size="xl"
        />
      </div>
      <OverlayPanel ref={overlayRef}>
        <div className="flex gap-2">
          <Checkbox
            checked={showTasksCount}
            onChange={(e) => setShowTasksCount(e.checked ?? false)}
          />
          <div>{t("common.showTasksCount")}</div>
        </div>
      </OverlayPanel>
    </div>
  );
}
