import { useQuery, UseQueryOptions, useMutation } from "react-query";
import { floorPlanService } from "../services/floor-plan.service";
import { CreateOrUpdateFloorPlan } from "./models/InstallationPlans/create-or-update-floor-plan.model";
import { CreateOrUpdateFloorPlanGeneralInfo } from "./models/InstallationPlans/create-or-update-floor-plan-general-info.model";
import { FloorPlan } from "./models/InstallationPlans/floor-plan.model";
import { UpdateFloorPlanItems } from "./models/InstallationPlans/update-floor-plan-items.model";

export function useAddFloorPlanMutation() {
  return useMutation((request: CreateOrUpdateFloorPlan) =>
    floorPlanService.create(request)
  );
}

export function useAddFloorPlanWithGeneralInfoMutation() {
  return useMutation((request: CreateOrUpdateFloorPlanGeneralInfo) =>
    floorPlanService.createWithGeneralInfo(request)
  );
}

export function useUpdateFloorPlanMutation() {
  return useMutation((request: CreateOrUpdateFloorPlan) =>
    floorPlanService.update(request)
  );
}

export function useUpdateFloorPlanGeneralInfoMutation() {
  return useMutation((request: CreateOrUpdateFloorPlanGeneralInfo) =>
    floorPlanService.updateGeneralInfo(request)
  );
}

export function useUpdateFloorPlanItemsMutation() {
  return useMutation((request: UpdateFloorPlanItems) =>
    floorPlanService.updateFloorPlanItems(request)
  );
}

export function useDeleteFloorPlanMutation() {
  return useMutation((id: number) => floorPlanService.delete(id));
}

export function useGetBuildingPlanFloorPlansQuery(
  buildingPlanId: number | undefined
) {
  const queryOptions: UseQueryOptions<FloorPlan[]> = {
    enabled: !!buildingPlanId,
  };

  return useQuery<FloorPlan[]>(
    ["buildingPlanFloorPlans", buildingPlanId],
    () => floorPlanService.getBuildingPlanFloorPlans(buildingPlanId!),
    queryOptions
  );
}

export function useGetFloorPlanQuery(id: number | undefined) {
  const queryOptions: UseQueryOptions<FloorPlan> = {
    enabled: !!id,
  };

  return useQuery<FloorPlan>(
    ["buildingPlanFloorPlans", `FloorPlan${id}`],
    () => floorPlanService.getFloorPlan(id!),
    queryOptions
  );
}

export function useAddEmptyNoteMutation() {
  return useMutation((id: number) => floorPlanService.addEmptyNote(id));
}
