export const bubbles1 = [
  { r: "21%", x: "-10%", y: -30, color: "var(--primary-color)" },
  { r: "28%", x: "-7%", y: -24, color: "var(--primary-color)" },
  { r: "24%", x: "2%", y: -22, color: "var(--primary-color)" },
  { r: "24%", x: "8%", y: -17, color: "var(--primary-color)" },
  { r: "17%", x: "20%", y: -10, color: "var(--primary-color)" },
  { r: "17%", x: "30%", y: -7, color: "var(--primary-color)" },
  { r: "33%", x: "35%", y: -7, color: "var(--primary-color)" },
  { r: "24%", x: "46%", y: -10, color: "var(--primary-color)" },
  { r: "15%", x: "64%", y: -14, color: "var(--primary-color)" },
  { r: "15%", x: "70%", y: -20, color: "var(--primary-color)" },
  { r: "19%", x: "74%", y: -22, color: "var(--primary-color)" },
  { r: "21%", x: "84%", y: -37, color: "var(--primary-color)" },
];
export const bubbles2 = [
  { r: "28%", x: "-20%", y: -45, color: "#8d66f7" },
  { r: "17%", x: "-4%", y: -31, color: "#8d66f7" },
  { r: "17%", x: "4%", y: -26, color: "#8d66f7" },
  { r: "21%", x: "9%", y: -22, color: "#8d66f7" },
  { r: "21%", x: "18%", y: -10, color: "#8d66f7" },
  { r: "28%", x: "25%", y: -10, color: "#8d66f7" },
  { r: "15%", x: "42%", y: -10, color: "#8d66f7" },
  { r: "22%", x: "50%", y: -12, color: "#8d66f7" },
  { r: "17%", x: "61%", y: -15, color: "#8d66f7" },
  { r: "17%", x: "72%", y: -23, color: "#8d66f7" },
  { r: "24%", x: "81%", y: -33, color: "#8d66f7" },
];
export const bubbles3 = [
  { r: "28%", x: "-17%", y: -35, color: "var(--light-primary-color)" },
  { r: "17%", x: "0%", y: -29, color: "var(--light-primary-color)" },
  { r: "19%", x: "4%", y: -23, color: "var(--light-primary-color)" },
  { r: "19%", x: "17%", y: -20, color: "var(--light-primary-color)" },
  { r: "28%", x: "29%", y: -13, color: "var(--light-primary-color)" },
  { r: "17%", x: "49%", y: -13, color: "var(--light-primary-color)" },
  { r: "17%", x: "61%", y: -16, color: "var(--light-primary-color)" },
  { r: "21%", x: "70%", y: -25, color: "var(--light-primary-color)" },
  { r: "25%", x: "80%", y: -34, color: "var(--light-primary-color)" },
];
export const bubbles4 = [
  { r: "28%", x: "-13%", y: -40, color: "var(--light-select-color)" },
  { r: "24%", x: "0%", y: -35, color: "var(--light-select-color)" },
  { r: "15%", x: "11%", y: -33, color: "var(--light-select-color)" },
  { r: "15%", x: "18%", y: -32, color: "var(--light-select-color)" },
  { r: "28%", x: "22%", y: -26, color: "var(--light-select-color)" },
  { r: "24%", x: "36%", y: -23, color: "var(--light-select-color)" },
  { r: "17%", x: "45%", y: -20, color: "var(--light-select-color)" },
  { r: "17%", x: "56%", y: -31, color: "var(--light-select-color)" },
  { r: "21%", x: "67%", y: -35, color: "var(--light-select-color)" },
  { r: "30%", x: "75%", y: -40, color: "var(--light-select-color)" },
];
