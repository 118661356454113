import { useQuery, useMutation, UseQueryOptions } from "react-query";
import { SelectItem } from "primereact/selectitem";
import { Role } from "./models/role-model";
import { rolesService } from "../services/roles.service";
import { CreateRoleRequest } from "./models/create-role-request";
import { UpdateRoleRequest } from "./models/update-role-request";

export function useRolesQuery() {
  return useQuery<Role[]>(["roles"], () => rolesService.getRoles());
}

export function useRoleOptionsQuery() {
  return useQuery<SelectItem[]>(["roles", "roles-options"], () =>
    rolesService.getRolesOptions()
  );
}

export function useGetRoleQuery(id: number | undefined) {
  const queryOptions: UseQueryOptions<Role> = {
    enabled: !!id,
  };

  return useQuery<Role>(
    ["roles", id],
    () => rolesService.getById(id!),
    queryOptions
  );
}

export function useAddRoleMutation() {
  return useMutation((request: CreateRoleRequest) =>
    rolesService.addRole(request)
  );
}

export function useEditRoleMutation() {
  return useMutation((request: UpdateRoleRequest) =>
    rolesService.editRole(request)
  );
}

export function useDeleteRoleMutation() {
  return useMutation((id: number) => rolesService.deleteRole(id));
}
