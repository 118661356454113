import { useMutation, useQuery, UseQueryOptions } from "react-query";
import { TranslationKeyValue } from "./models/translation-key-value";
import { translationService } from "../services/translations-service";
import { SetTranslationRequest } from "./models/set-translation--request.model";

export function useLanguageTranslationsQuery(id: number | undefined) {
  const queryOptions: UseQueryOptions<TranslationKeyValue[]> = {
    enabled: !!id,
  };

  return useQuery<TranslationKeyValue[]>(
    ["translation-key-value", id],
    () => translationService.getLanguageTranslations(id ?? 0),
    queryOptions
  );
}

export function useSetTranslationMutation() {
  return useMutation((translation: SetTranslationRequest) =>
    translationService.setTranslation(translation)
  );
}

export function useTranslationsForCultureJsonQuery(culture?: string) {
  return useQuery(
    ["translations"],
    () => translationService.getTranslationsForCultureJson(culture!),
    {
      enabled: !!culture,
    }
  );
}
