import { BaseService } from "./base.service";
import { SelectItem } from "primereact/selectitem";
import { API_ENDPOINTS } from "../utils/endpoints";
import { Installation } from "../queries/models/installation.model";
import { CreateInstallationRequest } from "../queries/models/create-installation-request.model";
import { EditInstallationRequest } from "../queries/models/edit-installation-request";
import { EditAddressRequest } from "../queries/models/edit-address-request.model";

class InstallationsService extends BaseService {
  async getActiveInstallations(): Promise<Installation[]> {
    const url = `${this.endpointPath}/active`;
    return this.http.get<Installation[]>(url).then((response) => response.data);
  }

  async getActiveInstallationsAsSelectOptions(): Promise<SelectItem[]> {
    const url = `${this.endpointPath}/active-options`;
    return this.http.get<SelectItem[]>(url).then((response) => response.data);
  }

  async getInstallationById(id: number): Promise<Installation> {
    const url = `${this.endpointPath}/${id}`;
    return this.http.get<Installation>(url).then((response) => response.data);
  }

  async addInstallation(request: CreateInstallationRequest) {
    const url = `${this.endpointPath}/create`;
    return this.http
      .post<CreateInstallationRequest>(url, request)
      .then((response) => response.data);
  }

  async editInstallation(request: EditInstallationRequest) {
    const url = `${this.endpointPath}/update`;
    return this.http
      .put<EditAddressRequest>(url, request)
      .then((response) => response.data);
  }

  async deleteInstallation(id: number) {
    const url = `${this.endpointPath}/delete/${id}`;
    return this.http.delete(url).then((response) => response.data);
  }
}

export const installationsService = new InstallationsService(
  API_ENDPOINTS.INSTALLATIONS
);
