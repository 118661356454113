import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ChangeContainer,
  ChangesCopyMode,
  applyChanges,
  getChanges,
  reverseChanges,
} from "../utils/objectChanges";

export type ChangesController<S> = {
  undo: () => void;
  redo: () => void;
  canUndo: () => boolean;
  canRedo: () => boolean;
  /** Sets state to given value and deletes all state changes. */
  initializeState: (value: S) => void;
};

export type StateWithChangesOptions = {
  /** Enables using Ctrl+Z as undo and Ctrl+Shift+Z as redo */
  enableKeyboardShortcuts?: boolean;
};

/**
 * Remembers what changes in object on setting new state and allows to undo & redo
 * @param initialState Initial state of object (can be reinitialized with ChangesController's `inistializeState`)
 * @param options Changes manipulation options
 * @returns Stateful value with ChangesController
 */
export function useStateWithChanges<S>(
  initialState?: S | (() => S),
  options?: StateWithChangesOptions
): [S, Dispatch<SetStateAction<S>>, ChangesController<S>] {
  const [state, setState] = useState<S | undefined>(initialState);
  /** Assign only deep copies to preserve deep values of previous state. */
  const previousStateRef = useRef<S | undefined>(structuredClone(state));
  const changesRef = useRef<ChangeContainer<S | undefined>[]>([]);
  const changesIndexRef = useRef<number>(0);

  const initializeState = useCallback((value: S) => {
    changesIndexRef.current = 0;
    changesRef.current = [];
    setState(value);
    previousStateRef.current = structuredClone(value);
  }, []);

  const newState: Dispatch<SetStateAction<S>> = useCallback((item) => {
    setState((prev) => {
      const value =
        typeof item === "function"
          ? (item as (prevState: S | undefined) => S)(prev)
          : item;

      const changes = getChanges(previousStateRef.current, value);
      if (changes) {
        changesRef.current.splice(changesIndexRef.current);
        changesRef.current.push(changes);
        ++changesIndexRef.current;
      }
      previousStateRef.current = structuredClone(value);

      return value;
    });
  }, []);

  const canUndo = useCallback(() => {
    return changesIndexRef.current > 0 && changesRef.current.length > 0;
  }, []);

  const undo = useCallback(() => {
    if (!canUndo()) return;
    changesIndexRef.current = Math.min(
      Math.max(0, changesIndexRef.current - 1),
      changesRef.current.length
    );
    const change = changesRef.current[changesIndexRef.current];
    if (change) {
      setState((prev) => {
        prev = reverseChanges(prev, change, {
          mode: ChangesCopyMode.Objects,
          copyRoot: true,
        });
        previousStateRef.current = structuredClone(prev);
        return prev;
      });
    }
  }, [canUndo]);

  const canRedo = useCallback(() => {
    return changesIndexRef.current < changesRef.current.length;
  }, []);

  const redo = useCallback(() => {
    if (!canRedo()) return;
    const change = changesRef.current[changesIndexRef.current];
    changesIndexRef.current = Math.min(
      Math.max(0, changesIndexRef.current + 1),
      changesRef.current.length
    );
    if (change) {
      setState((prev) => {
        prev = applyChanges(prev, change, {
          mode: ChangesCopyMode.Objects,
          copyRoot: true,
        });
        previousStateRef.current = structuredClone(prev);
        return prev;
      });
    }
  }, [canRedo]);

  const changesController = useMemo(() => {
    return {
      undo,
      redo,
      canUndo,
      canRedo,
      initializeState,
    };
  }, [undo, redo, canUndo, canRedo, initializeState]);

  useEffect(() => {
    if (options?.enableKeyboardShortcuts) {
      const listener = function (this: any, ev: KeyboardEvent) {
        if ((ev.target as HTMLElement).tagName.toLowerCase() !== "body") return;

        if (
          ev.ctrlKey &&
          ev.key.toLowerCase() === "z" &&
          !ev.altKey &&
          !ev.metaKey
        ) {
          if (ev.shiftKey) {
            if (changesController.canRedo()) {
              ev.preventDefault();
              changesController.redo();
            }
          } else {
            if (changesController.canUndo()) {
              ev.preventDefault();
              changesController.undo();
            }
          }
        }
      };
      document.body.addEventListener("keydown", listener);
      return () => {
        document.body.removeEventListener("keydown", listener);
      };
    }
  }, [changesController, options?.enableKeyboardShortcuts]);

  return [state!, newState, changesController];
}
