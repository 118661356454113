import { useState, useCallback, useEffect, useMemo } from "react";
import { SummaryOverviewComponent } from "./SummaryOverviewComponent";
import { useReportSummaryQuery } from "../../queries/reporting.query";
import { previousMonday, nextSunday } from "date-fns";
import { Calendar } from "primereact/calendar";
import { ReportSummaryItem } from "../../queries/models/report-summary-witem.model";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { SummaryGroupedTableComponent } from "./SummaryGrouppedTableComponent";
import { TableFields } from "../../queries/models/table-fields.model";
import { TimeSpan } from "../../utils/timespan";
import Enumerable from "linq";
import { SelectButton } from "primereact/selectbutton";
import { SelectItem } from "primereact/selectitem";
import { WorkInstanceType } from "../../queries/models/enums/work-instance-type.enum";
import { ReportSummaryGroupItem } from "../../queries/models/report-summary-group-item.model";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { useTranslation } from "react-i18next";

export function MobileSummary() {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<Date[]>([
    previousMonday(new Date()),
    nextSunday(new Date()),
  ]);
  const [installations, setInstallations] = useState<string[] | undefined>(
    undefined
  );
  const [users, setUsers] = useState<string[] | undefined>(undefined);
  const [items, setItems] = useState<ReportSummaryItem[]>();
  const [selectedItems, setSelectedItems] = useState<ReportSummaryItem[]>();
  const [visibleItems, setVisibleItems] = useState<ReportSummaryItem[]>();

  const [usersSummary, setUsersSummary] = useState<ReportSummaryGroupItem[]>();
  const [installationsSummary, setInstallationsSummary] =
    useState<ReportSummaryGroupItem[]>();
  const [detailsDialogVisible, setDetailsDialogVisible] = useState(false);
  const usersSummaryFields: TableFields[] = useMemo(
    () => [
      { name: "username", header: t("common.username") },
      { name: "workDurationTicks", header: t("common.workTime") },
    ],
    [t]
  );

  const installationSummaryFields: TableFields[] = useMemo(
    () => [
      { name: "installationName", header: t("common.installation") },
      { name: "workDurationTicks", header: t("common.workTime") },
    ],
    [t]
  );

  const detailsOptions: SelectItem[] = useMemo(
    () => [
      { value: 1, label: t("common.users") },
      { value: 2, label: t("common.installations") },
    ],
    [t]
  );
  const [selectedDetailOption, setSelectedDetailOption] = useState<SelectItem>(
    detailsOptions[0]
  );

  const overviewQuery = useReportSummaryQuery(dateRange[0], dateRange[1]);

  const resetDate = useCallback(() => {
    setDateRange([previousMonday(new Date()), nextSunday(new Date())]);
  }, []);

  useEffect(() => {
    if (overviewQuery.data) {
      let tempItems = overviewQuery.data;
      tempItems.forEach((x) => {
        x.dateOnly = new Date(x.date.toDateString());
      });
      setItems(overviewQuery.data);
      setVisibleItems(overviewQuery.data);

      setUsers(Array.from(new Set<string>(items?.map((x) => x.username))));
      setInstallations(
        Array.from(new Set<string>(items?.map((x) => x.installationName)))
      );
    }
  }, [items, overviewQuery.data]);

  useEffect(() => {
    let visibleItemsList = Enumerable.from(visibleItems ?? []);
    let visibleSelectedItems = Enumerable.from(selectedItems ?? []).where((x) =>
      visibleItemsList.contains(x)
    );
    let usersGroups = visibleSelectedItems
      .groupBy((x) => x.userId)
      .select((x) => x.getSource());

    let installationsGroups = visibleSelectedItems
      .groupBy((x) => x.installationId)
      .select((x) => x.getSource());

    setUsersSummary(
      usersGroups
        .select<ReportSummaryGroupItem>((x) => {
          return {
            installationAddress: x[0].installationAddress,
            username: x[0].username,
            userId: x[0].userId,
            installationId: x[0].installationId,
            installationName: x[0].installationName,
            workDurationTicks: x.reduce((a, c) => a + c.workDurationTicks, 0),
            totalCostInEur: x.reduce((a, c) => a + c.totalCostInEur, 0),
            workDurationConceptualTicks: Enumerable.from(
              x.filter(
                (y) => y.workInstanceType === WorkInstanceType.Conceptual
              )
            ).sum((y) => y!.workDurationTicks ?? 0),
            workDurationRegularTicks: Enumerable.from(
              x.filter((y) => y.workInstanceType === WorkInstanceType.Regular)
            ).sum((y) => y!.workDurationTicks ?? 0),
          } as ReportSummaryGroupItem;
        })
        .toArray()
    );

    setInstallationsSummary(
      installationsGroups
        .select<ReportSummaryGroupItem>((x) => {
          return {
            installationAddress: x[0].installationAddress,
            username: x[0].username,
            userId: x[0].userId,
            installationId: x[0].installationId,
            installationName: x[0].installationName,
            workDurationTicks: x.reduce((a, c) => a + c.workDurationTicks, 0),
            totalCostInEur: x.reduce((a, c) => a + c.totalCostInEur, 0),
            workDurationConceptualTicks: Enumerable.from(
              x.filter(
                (y) => y.workInstanceType === WorkInstanceType.Conceptual
              )
            ).sum((y) => y!.workDurationTicks ?? 0),
            workDurationRegularTicks: Enumerable.from(
              x.filter((y) => y.workInstanceType === WorkInstanceType.Regular)
            ).sum((y) => y!.workDurationTicks ?? 0),
          };
        })
        .toArray()
    );
  }, [selectedItems, visibleItems]);

  return (
    <>
      <div className="h-full flex flex-column">
        <div className="flex">
          <Calendar
            className="w-full p-1 h-3rem"
            onClearButtonClick={resetDate}
            onChange={(e) => setDateRange(e.value as Date[])}
            value={dateRange}
            selectionMode="range"
            readOnlyInput
            locale="en"
            dateFormat="dd/mm/yy"
            showButtonBar={true}
          />
          <Button
            label={t("common.show")}
            disabled={!selectedItems || selectedItems?.length === 0}
            className="m-1"
            onClick={() => setDetailsDialogVisible(true)}
          />
        </div>

        <div style={{ height: "calc(100% - 3rem" }}>
          <LoaderWrapper isLoading={overviewQuery.isLoading || items === null}>
            <SummaryOverviewComponent
              installations={installations}
              users={users}
              selectedItems={selectedItems}
              items={items ?? []}
              onSetSelectedItems={setSelectedItems}
              onSetVisibleItems={setVisibleItems}
            />
          </LoaderWrapper>
        </div>
        <Dialog
          header={t("common.details")}
          style={{ width: "95%" }}
          visible={detailsDialogVisible}
          onHide={() => setDetailsDialogVisible(false)}
        >
          <SelectButton
            options={detailsOptions}
            value={selectedDetailOption.value}
            onChange={(e) => {
              setSelectedDetailOption(e);
            }}
            optionLabel="label"
            key="value"
            className="two-option-select-button p-1"
          />
          {selectedDetailOption.value === detailsOptions[0].value ? (
            <SummaryGroupedTableComponent
              fields={usersSummaryFields}
              items={usersSummary}
              header={t("common.usersSummary")}
              footer={TimeSpan.fromTicks(
                usersSummary?.reduce((a, c) => a + c.workDurationTicks, 0) ?? 0
              ).toHhMm()}
              scrollHeight="calc((100vh - 5rem) / 2)"
            />
          ) : (
            <SummaryGroupedTableComponent
              fields={installationSummaryFields}
              items={installationsSummary}
              header={t("common.installationSummary")}
              footer={TimeSpan.fromTicks(
                installationsSummary?.reduce(
                  (a, c) => a + c.workDurationTicks,
                  0
                ) ?? 0
              ).toHhMm()}
              scrollHeight="calc((100vh - 5rem) / 2)"
            />
          )}
        </Dialog>
      </div>
    </>
  );
}
