import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { Address } from "../../queries/models/address.model";
import { LocationLog } from "../../queries/models/location-log.model";
import { MapWithMarkers } from "../../components/ui/google-map/map-with-markers";
import { MarkerModel } from "../../components/ui/google-map/marker-model";
import { WorkTimeChartComponent } from "../../components/ui/WorkTimeChartComponent";
import { WorkInstance } from "../../queries/models/work-instance.model";
import { useTranslation } from "react-i18next";

interface WorkInstanceInstallationLocationProps {
  workInstance: WorkInstance | undefined;
  installationName: string;
  installationAddress: Address | undefined;
  isLoading: boolean;
  locationLogs?: LocationLog[];
  lastLocation?: LocationLog | undefined;
}

export function WorkInstanceInstallationLocation({
  workInstance,
  installationName,
  installationAddress,
  isLoading,
  locationLogs = [],
  lastLocation,
}: WorkInstanceInstallationLocationProps) {
  const { t } = useTranslation();
  const [hoveredPosition, setHoveredPosition] = useState<LocationLog>();
  const mapMarkers = useMemo(() => {
    let markers: MarkerModel[] = [
      {
        latitude: installationAddress?.latitude ?? 0,
        longitude: installationAddress?.longitude ?? 0,
        showInfoWindow: false,
        title: installationName,
        markerIconUrl:
          "http://maps.google.com/mapfiles/kml/shapes/ranger_station.png",
        radius: installationAddress?.radius,
        iconSize: 30,
        infoWindowBody: <></>,
      },
    ];

    if (lastLocation) {
      markers.push({
        latitude: lastLocation?.latitude ?? 0,
        longitude: lastLocation?.longitude ?? 0,
        showInfoWindow: false,
        title: "",
        markerIconUrl:
          "http://maps.google.com/mapfiles/kml/paddle/red-circle.png",
        iconSize: 30,
        infoWindowBody: <>{t("common.lastLocation")}</>,
      });
    }

    return markers;
  }, [
    installationAddress?.latitude,
    installationAddress?.longitude,
    installationAddress?.radius,
    installationName,
    lastLocation,
    t,
  ]);

  return (
    <>
      <section className="height-full">
        <div className="section-header">
          <div className="flex gap-2 align-items-center">
            <FontAwesomeIcon icon={faLocationDot} />
            <div className="font-medium">{t("common.locationData")}</div>
          </div>
        </div>
        <div className="section-content relative">
          <div
            className="h-full flex flex-column"
            style={{ minHeight: "350px" }}
          >
            <div className="flex w-full flex-column h-3rem">
              <div className="w-full flex mb-1">
                <div
                  className="w-6 text-center"
                  style={{ marginRight: "5px" }}
                >
                  <span className="font-medium">{t("common.city")}:</span>{" "}
                  &nbsp;
                  {installationAddress ? installationAddress.city : ""}
                </div>
                <div
                  style={{ marginLeft: "5px" }}
                  className="w-6 text-center"
                >
                  <span className="font-medium">{t("common.street")}:</span>
                  &nbsp;
                  {installationAddress ? installationAddress.street : ""}
                </div>
              </div>
              <div className="w-full flex mb-1">
                <div
                  className="w-6 text-center"
                  style={{ width: "calc(35% - 5px)", marginRight: "5px" }}
                >
                  <span className="font-medium">{t("common.radius")}:</span>
                  &nbsp;
                  {installationAddress ? installationAddress.radius : ""}
                </div>
                <div
                  className="w-6 text-center"
                  style={{ width: "calc(65% - 5px)", marginLeft: "5px" }}
                >
                  <span className="font-medium">{t("common.postalCode")}:</span>
                  &nbsp;
                  {installationAddress ? installationAddress.postalCode : ""}
                </div>
              </div>
            </div>
            <div
              className="w-full"
              style={{ height: "calc(100% - 8rem)" }}
            >
              <MapWithMarkers
                showInfoWindows
                currenPosition={hoveredPosition}
                markers={mapMarkers}
                strokes={locationLogs ?? []}
                onPositionHover={setHoveredPosition}
              />
            </div>
            <WorkTimeChartComponent
              className="h-8rem"
              workInstance={workInstance}
              currentPosition={hoveredPosition}
              onHover={setHoveredPosition}
            />
          </div>
        </div>
      </section>
    </>
  );
}
