import { BaseService } from "./base.service";
import { CreateOrUpdateFloorPlan } from "../queries/models/InstallationPlans/create-or-update-floor-plan.model";
import { API_ENDPOINTS } from "../utils/endpoints";
import { FloorPlan } from "../queries/models/InstallationPlans/floor-plan.model";
import { CreateOrUpdateFloorPlanGeneralInfo } from "../queries/models/InstallationPlans/create-or-update-floor-plan-general-info.model";
import { UpdateFloorPlanItems } from "../queries/models/InstallationPlans/update-floor-plan-items.model";

class FloorPlanService extends BaseService {
  async create(request: CreateOrUpdateFloorPlan) {
    const url = `${this.endpointPath}`;

    let formData = new FormData();

    if (request.id) {
      formData.append("id", request.id.toString());
    }
    formData.append("name", request.name.toString());
    if (request.description) {
      formData.append("description", request.description.toString());
    }
    formData.append("buildingPlanId", request.buildingPlanId.toString());
    formData.append(
      "backgroundImage",
      request.backgroundImage?.blob!,
      request.backgroundImage?.name!
    );
    formData.append("floorPlanItems", JSON.stringify(request.floorPlanItems));

    return this.http
      .postForm<CreateOrUpdateFloorPlan>(url, formData)
      .then((response) => response.data);
  }

  async createWithGeneralInfo(request: CreateOrUpdateFloorPlanGeneralInfo) {
    const url = `${this.endpointPath}/create-floor-plan-general-info`;

    let formData = new FormData();

    if (request.id) {
      formData.append("id", request.id.toString());
    }
    formData.append("name", request.name.toString());
    if (request.description) {
      formData.append("description", request.description.toString());
    }
    formData.append("buildingPlanId", request.buildingPlanId.toString());
    formData.append(
      "backgroundImage",
      request.backgroundImage?.blob!,
      request.backgroundImage?.name!
    );

    return this.http
      .postForm<CreateOrUpdateFloorPlanGeneralInfo>(url, formData)
      .then((response) => response.data);
  }

  async update(request: CreateOrUpdateFloorPlan) {
    const url = `${this.endpointPath}`;

    let formData = new FormData();

    if (request.id) {
      formData.append("id", request.id.toString());
    }
    formData.append("name", request.name.toString());
    if (request.description) {
      formData.append("description", request.description.toString());
    }
    formData.append("buildingPlanId", request.buildingPlanId.toString());
    formData.append("floorPlanItems", JSON.stringify(request.floorPlanItems));

    return this.http
      .putForm<CreateOrUpdateFloorPlan>(url, formData)
      .then((response) => response.data);
  }

  async updateGeneralInfo(request: CreateOrUpdateFloorPlanGeneralInfo) {
    const url = `${this.endpointPath}/update-floor-plan-general-info`;

    let formData = new FormData();

    if (request.id) {
      formData.append("id", request.id.toString());
    }
    formData.append("name", request.name.toString());
    if (request.description) {
      formData.append("description", request.description.toString());
    }
    formData.append("buildingPlanId", request.buildingPlanId.toString());
    if (request.backgroundImage?.blob) {
      formData.append(
        "backgroundImage",
        request.backgroundImage.blob,
        request.backgroundImage?.name
      );
    }

    return this.http
      .putForm<CreateOrUpdateFloorPlanGeneralInfo>(url, formData)
      .then((response) => response.data);
  }

  async updateFloorPlanItems(request: UpdateFloorPlanItems) {
    const url = `${this.endpointPath}/update-floor-plan-items`;

    return this.http
      .put<UpdateFloorPlanItems>(url, request)
      .then((response) => response.data);
  }

  async delete(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.delete(url).then((response) => response.data);
  }

  async getBuildingPlanFloorPlans(
    buildingPlanId: number
  ): Promise<FloorPlan[]> {
    const url = `${this.endpointPath}/by-building-plan-id/${buildingPlanId}`;
    return this.http.get<FloorPlan[]>(url).then((response) => response.data);
  }

  async getFloorPlan(id: number): Promise<FloorPlan> {
    const url = `${this.endpointPath}/${id}`;
    return this.http.get<FloorPlan>(url).then((response) => response.data);
  }

  async addEmptyNote(id: number): Promise<number> {
    const url = `${this.endpointPath}/add-empty-note/${id}`;
    return this.http.patch(url).then((response) => response.data);
  }
}

export const floorPlanService = new FloorPlanService(API_ENDPOINTS.FLOOR_PLANS);
