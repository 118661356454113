import { useEffect, useState } from "react";
import { useIsFetching, useIsMutating, useQueryClient } from "react-query";

export const useGlobalLoader = () => {
  const queryClient = useQueryClient();
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const [isLoading, setIsLoading] = useState(Boolean);

  useEffect(() => {
    setIsLoading(isFetching > 0 || isMutating > 0);
  }, [isFetching, isMutating, queryClient]);

  return isLoading;
};
