interface RoundedDotProps {
  color?: string;
}

export default function RoundedDot({ color }: RoundedDotProps) {
  return (
    <div
      style={{ backgroundColor: color }}
      className="h-1rem w-1rem border-circle border-1 border-black-alpha-50"></div>
  );
}
