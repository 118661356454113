import logo from "../../../assets/icons/logo.svg";
import { motion } from "framer-motion";
import { LoginForm, LoginFormProps } from "./LoginForm";
import { useMemo } from "react";
import QRCode from "react-qr-code";

export function LoginDesktop({
  executeLogin,
  navigateToForgotPassword,
  loginError,
  setLoginError,
  isVisible,
  setIsVisible,
  signUp,
}: LoginFormProps) {
  const qrCodeValue = useMemo(
    () => `${window.location.origin}/mobileDownload`,
    []
  );
  return (
    <div className="grid grid-nogutter overflow-hidden">
      <motion.div
        initial={{ opacity: 0.5 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: "backInOut" }}
        className="absolute flex h-full justify-content-center align-items-center"
        style={{
          width: "100%",
          height: "100%",
          zIndex: 1,
          background:
            "linear-gradient(93deg, rgba(124,77,255,0.5) 39%, rgba(193,163,236,0.05) 81%)",
        }}
      >
        <div
          className="grid grid-nogutter overflow-hidden"
          style={{
            height: "550px",
            borderRadius: "20px",
            backgroundColor: "white",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            width: "950px",
          }}
        >
          <div
            className="col-5 bg-white h-full"
            style={{ borderRadius: "20px 0px 0px 20px" }}
          >
            <div className="p-2 h-full flex flex-column">
              <div
                className="w-full flex justify-content-center text-3xl my-5"
                style={{
                  color: "var(--primary-color)",
                  textShadow: "1px 1px 3px #BFBFBF ",
                }}
              >
                Welcome to
              </div>
              <div className="w-full flex justify-content-center">
                <img
                  style={{ width: "100px", height: "100px" }}
                  src={logo}
                  alt="PurpleApp logo"
                />
              </div>
              <div
                className="w-full flex justify-content-center mt-2 text-xl"
                style={{
                  color: "var(--primary-color)",
                  textShadow: "1px 1px 2px #BFBFBF ",
                }}
              >
                PurpleApp
              </div>
              <div
                className="w-full px-4 mt-6"
                style={{
                  textAlign: "center",
                  color: "grey",
                  lineHeight: "1.75rem",
                }}
              >
                Administrate your company with simple tools. <br />
                Register now!
              </div>
              <div
                className="w-full px-4 mt-4 flex justify-content-center"
                style={{
                  textAlign: "center",
                  color: "grey",
                }}
              >
                <QRCode
                  value={qrCodeValue}
                  className="w-5rem h-5rem"
                />
              </div>
              <div
                className="w-full px-4 mt-1 flex justify-content-center"
                style={{
                  textAlign: "center",
                  color: "grey",
                }}
              >
                Get mobile app now!
              </div>
              <div
                className="w-full flex justify-content-center"
                style={{
                  marginTop: "auto",
                  marginBottom: "1rem",
                  color: "grey",
                }}
              ></div>
            </div>
          </div>
          <div className="col-7">
            <LoginForm
              executeLogin={executeLogin}
              navigateToForgotPassword={navigateToForgotPassword}
              loginError={loginError}
              setLoginError={setLoginError}
              isVisible={isVisible}
              setIsVisible={setIsVisible}
              signUp={signUp}
            ></LoginForm>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
