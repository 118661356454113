import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";

interface PrintingPreviewProps {
  qrCodes: string[];
}

export function PrintingPreview({ qrCodes }: PrintingPreviewProps) {
  const { t } = useTranslation();

  return (
    <div className="p-3 overflow-y-auto h-full">
      {qrCodes.length ? (
        <div className="flex flex-wrap gap-4">
          {qrCodes.map((x, i) => (
            <div key={i}>
              <QRCode
                id={`qrCode${i}`}
                value={x}
                size={128}
              />
              <div className="text-center">{x}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className="h-full w-full flex justify-content-center align-items-center text-xl font-bold text-500">
          {t("common.NO_QR_CODES_TO_PRINT")}
        </div>
      )}
    </div>
  );
}
