import { useQuery, useMutation, UseQueryOptions } from "react-query";
import { Installation } from "./models/installation.model";
import { installationsService } from "../services/installations.service";
import { CreateInstallationRequest } from "./models/create-installation-request.model";
import { EditInstallationRequest } from "./models/edit-installation-request";
import { SelectItem } from "primereact/selectitem";

export function useActiveInstallationsQuery() {
  return useQuery<Installation[]>(["installations"], () =>
    installationsService.getActiveInstallations()
  );
}

export function useInstallationsOptionsQuery() {
  return useQuery<SelectItem[]>(
    ["installations", "installations-options"],
    () => installationsService.getActiveInstallationsAsSelectOptions()
  );
}

export function useGetInstallationQuery(id: number | undefined) {
  const queryOptions: UseQueryOptions<Installation> = {
    enabled: !!id,
  };

  return useQuery<Installation>(
    ["installation", id],
    () => installationsService.getInstallationById(id!),
    queryOptions
  );
}

export function useAddInstallationMutation() {
  return useMutation((request: CreateInstallationRequest) =>
    installationsService.addInstallation(request)
  );
}

export function useEditInstallationMutation() {
  return useMutation((request: EditInstallationRequest) =>
    installationsService.editInstallation(request)
  );
}

export function useDeleteInstallationMutation() {
  return useMutation((id: number) =>
    installationsService.deleteInstallation(id)
  );
}
