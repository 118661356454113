/** Contains specification for changes of whole object and it's deep properties */
export type ChangeContainer<T = any> = {
  deepChanges?: ChangeObject<T, keyof T>[];
  newValue?: T;
  newValueRemove?: boolean;
  oldValue?: T;
  oldValueRemove?: boolean;
};

function isNonEmptyChangeContainer<T = any>(
  x?: ChangeContainer<T> | undefined
): x is ChangeContainer<T> {
  return (
    x !== undefined &&
    x !== null &&
    ((Array.isArray(x.deepChanges) && x.deepChanges.length > 0) ||
      "newValue" in x ||
      x.newValueRemove === true ||
      x.newValueRemove === false ||
      "oldValue" in x ||
      x.oldValueRemove === true ||
      x.oldValueRemove === false)
  );
}

/** Contains specification for changes of property and it's deep properties */
export type ChangeObject<T = any, K extends keyof T = keyof T> = {
  key: K;
  deepChanges?: ChangeObject<T[K], keyof T[K]>[];
  newValue?: T[K];
  newValueRemove?: boolean;
  newValueReference?: string[];
  oldValue?: T[K];
  oldValueRemove?: boolean;
  oldValueReference?: string[];
};

function isNonEmptyChangeObject<T = any>(
  x?: ChangeObject<T> | undefined
): x is ChangeObject<T> {
  return (
    x !== undefined &&
    x !== null &&
    "key" in x &&
    ((Array.isArray(x.deepChanges) && x.deepChanges.length > 0) ||
      "newValue" in x ||
      x.newValueRemove === true ||
      x.newValueRemove === false ||
      (Array.isArray(x.newValueReference) && x.newValueReference.length > 0) ||
      "oldValue" in x ||
      x.oldValueRemove === true ||
      x.oldValueRemove === false ||
      (Array.isArray(x.oldValueReference) && x.oldValueReference.length > 0))
  );
}

/** Reference to already visited objects with reference path */
type VisitedObject = { object: any; path: string[] };

/** Determines whether object could have deeper changes */
function isMutableObject(obj: any): obj is object {
  return obj && typeof obj === "object" && !(obj instanceof Date);
}

/** Determines whether object has already been visited, returns path if found, undefined otherwise */
function referenced(
  value: any,
  visited: VisitedObject[]
): string[] | undefined {
  return visited.find((x) => Object.is(x.object, value))?.path;
}

/** Checks if paths are the same */
function isSamePath(a: string[], b: string[]) {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

/** Creates object with properly named property for changes of value or reference */
function cloneOrReference<K extends "new" | "old", T>(
  kind: K,
  value: T,
  visited: VisitedObject[]
): K extends "new"
  ? { newValue: T } | { newValueReference: string[] }
  : { oldValue: T } | { oldValueReference: string[] } {
  const ref = referenced(value, visited);
  if (ref) {
    return {
      [kind === "new" ? "newValueReference" : "oldValueReference"]: ref,
    } as any;
  }
  return {
    [kind === "new" ? "newValue" : "oldValue"]: structuredClone(value),
  } as any;
}

/** Determines whether we should check deeper changes between objects */
function shouldGoDeeper(
  oldValue: any,
  newValue: any,
  visitedOld: VisitedObject[] = [],
  visitedNew: VisitedObject[] = []
) {
  return (
    isMutableObject(oldValue) &&
    isMutableObject(newValue) &&
    Array.isArray(oldValue) === Array.isArray(newValue) &&
    !referenced(oldValue, visitedOld) &&
    !referenced(newValue, visitedNew)
  );
}

/**
 * Gets deep changes between different state of object to reverse or apply later
 *
 * **REMEMBER** For deep changes to be detected, both states should have different deep references.
 * You can achieve that by saving old state with `structuredClone(obj)`, and then don't change it's deep properties.
 * @param previousObj Object state before the change
 * @param nextObj Object state after the change
 * @param visitedPrevious Paths with references for `previousObj` that have been visited
 * @param visitedNext Paths with references for `nextObj` that have been visited
 * @param path Current path for `previousObj` and `nextObj`
 * @returns ChangeContainer with deep changes for object
 */
function getDeepObjectChanges<T = any>(
  previousObj: T,
  nextObj: T,
  visitedPrevious: VisitedObject[] = [],
  visitedNext: VisitedObject[] = [],
  path: string[] = []
): ChangeObject<T>[] | undefined {
  visitedPrevious = [...visitedPrevious, { object: previousObj, path }];
  visitedNext = [...visitedNext, { object: nextObj, path }];

  const previousEntries = Object.getOwnPropertyNames(previousObj).map((x) => [
    x,
    previousObj[x as keyof typeof previousObj],
  ]) as [
    keyof typeof previousObj,
    (typeof previousObj)[keyof typeof previousObj]
  ][];
  const nextEntries = Object.getOwnPropertyNames(nextObj).map((x) => [
    x,
    nextObj[x as keyof typeof nextObj],
  ]) as [keyof typeof nextObj, (typeof nextObj)[keyof typeof nextObj]][];

  const changes: ChangeObject<T>[] = nextEntries
    .filter((x) => previousEntries.some((y) => y[0] === x[0]))
    .map((x) => {
      const oldValue = previousObj[x[0]];
      const newValue = x[1];

      if (shouldGoDeeper(oldValue, newValue, visitedPrevious, visitedNext)) {
        return {
          deepChanges: getDeepObjectChanges(
            oldValue,
            newValue,
            visitedPrevious,
            visitedNext,
            [...path, x[0].toString()]
          ),
          key: x[0],
        } as ChangeObject<T>;
      }

      return {
        ...cloneOrReference("old", oldValue, visitedPrevious),
        ...cloneOrReference("new", newValue, visitedNext),
        key: x[0],
      } as ChangeObject<T>;
    })
    .concat(
      previousEntries
        .filter((x) => !nextEntries.some((y) => y[0] === x[0]))
        .map(
          (x) =>
            ({
              ...cloneOrReference("old", x[1], visitedPrevious),
              newValueRemove: true,
              key: x[0],
            } as ChangeObject<T>)
        )
    )
    .concat(
      nextEntries
        .filter((x) => !previousEntries.some((y) => y[0] === x[0]))
        .map(
          (x) =>
            ({
              ...cloneOrReference("new", x[1], visitedNext),
              oldValueRemove: true,
              key: x[0],
            } as ChangeObject<T>)
        )
    )
    .filter(
      (x) =>
        (x.newValueReference && x.oldValueReference
          ? !isSamePath(x.newValueReference, x.oldValueReference)
          : x.newValueReference || x.oldValueReference) ||
        x.deepChanges?.length ||
        x.oldValueRemove ||
        x.newValueRemove ||
        (x.oldValue instanceof Date && x.newValue instanceof Date
          ? +x.oldValue !== +x.newValue
          : x.oldValue !== x.newValue)
    );
  if (!changes.length) return undefined;
  return changes;
}

/**
 * Gets deep changes between different state of object to reverse or apply later
 *
 * **REMEMBER** For deep changes to be detected, both states should have different deep references.
 * You can achieve that by saving old state with `structuredClone(obj)`, and then don't change it's deep properties.
 * @param previousObj Object state before the change
 * @param nextObj Object state after the change
 * @returns ChangeContainer with deep changes for object
 */
export function getChanges<T = any>(
  previousObj: T,
  nextObj: T
): ChangeContainer<T> | undefined {
  if (!shouldGoDeeper(previousObj, nextObj)) {
    //when top object isn't object or prev & next are incompatible
    const changes = getDeepObjectChanges({ "": previousObj }, { "": nextObj });
    return changes?.[0];
  }

  return { deepChanges: getDeepObjectChanges(previousObj, nextObj) };
}

function shallowCopy<T>(x: T): T {
  if (x === undefined || x === null || typeof x !== "object") return x;
  if (x instanceof Date) return new Date(x) as T;
  if (Array.isArray(x)) return [...x] as T;
  return { ...x };
}

/** Describes what is copied when applying or reversing changes. */
export enum ChangesCopyMode {
  /** Constructs new value when value didn't exist. */
  Values = 0,
  /** Constructs new array when any item changes (not the property inside item). */
  Arrays = 1,
  /**
   * Constructs new object (including arrays) when any property changes.
   * This results in new object tree from root to the changed value.
   */
  Objects = 2,
}

/**
 * Deeply mutates object with given specification,
 * uses deep copies of values from `changeContainer`, to not change it
 * @param obj Object to change, if isn't object or is falsy, the new one will be created
 * @param changeContainer Changes to apply
 * @param changeDirection The direction that changes should be applied
 * @param rootObject First object for path references
 * @param mode Constructing new object mode, defaults to {@linkcode ChangesCopyMode.Values}
 * @returns Same object instance changed accordingly
 */
function changeObj<T = any>(
  obj: T,
  changeContainer: ChangeContainer<T> | undefined,
  changeDirection: "apply" | "reverse",
  rootObject: any,
  mode: ChangesCopyMode = ChangesCopyMode.Values
): T {
  const removeKey: keyof ChangeContainer<T> =
    changeDirection === "apply" ? "newValueRemove" : "oldValueRemove";
  const valueKey: keyof ChangeContainer<T> =
    changeDirection === "apply" ? "newValue" : "oldValue";
  const referenceKey: keyof ChangeObject<T> =
    changeDirection === "apply" ? "newValueReference" : "oldValueReference";
  if (!isNonEmptyChangeContainer(changeContainer)) {
    return obj;
  }
  if (changeContainer.deepChanges === undefined) {
    if (changeContainer[removeKey]) return undefined as T;
    return structuredClone(changeContainer[valueKey]) as T;
  }

  if (!obj || typeof obj !== "object") {
    obj = {} as T;
  }

  if (
    (mode === ChangesCopyMode.Objects &&
      changeContainer.deepChanges.length > 0) ||
    (mode === ChangesCopyMode.Arrays &&
      Array.isArray(obj) &&
      changeContainer.deepChanges.length > 0 &&
      changeContainer.deepChanges.some(
        (x) => removeKey in x || referenceKey in x || valueKey in x
      ))
  ) {
    obj = shallowCopy(obj);
  }

  changeContainer.deepChanges.forEach((change) => {
    if (!isNonEmptyChangeObject(change)) {
      return;
    }

    const { key } = change;

    if (change[removeKey]) {
      delete obj[key];
      return;
    } else if (referenceKey in change) {
      const path = change[referenceKey] ?? [];
      let value = rootObject;
      for (const prop of path) {
        value = value?.[prop];
      }
      obj[key] = value;
      return;
    } else if ("deepChanges" in change && change.deepChanges) {
      obj[key] = changeObj(
        obj[key],
        { deepChanges: change.deepChanges } as ChangeContainer,
        changeDirection,
        rootObject,
        mode
      );
      return;
    } else if (valueKey in change) {
      obj[key] = structuredClone(change[valueKey])!;
    }
  });

  return obj;
}

/** Describes how to handle applying and reversing changes */
export type ApplyingChangesOptions = {
  /** Constructing new object mode, defaults to {@linkcode ChangesCopyMode.Values} */
  mode?: ChangesCopyMode;
  /**
   * Whether to construct new identical root object (shallow copy) after applying changes. Defaults to `false`.
   *
   * Useful when `mode` is not {@linkcode ChangesCopyMode.Objects} (doesn't copy whole tree to the change),
   * but you want to make sure, that you get a new object.
   */
  copyRoot?: boolean;
};

/**
 * Reverses changes on given object, reversed values are deep copies of values from changeContainer
 * @param obj Object to change
 * @param changeContainer Changes to reverse
 * @returns Same object instance changed accordingly
 */
export function reverseChanges<T = any>(
  obj: T,
  changeContainer: ChangeContainer<T> | undefined,
  {
    mode = ChangesCopyMode.Values,
    copyRoot = false,
  }: ApplyingChangesOptions = {}
): T {
  const changedObj = changeObj(obj, changeContainer, "reverse", obj, mode);
  if (copyRoot && mode !== ChangesCopyMode.Objects) {
    return shallowCopy(changedObj);
  }
  return changedObj;
}

/**
 * Applies changes on given object, applied values are deep copies of values from changeContainer
 * @param obj Object to change
 * @param changeContainer Changes to apply
 * @returns Same object instance changed accordingly
 */
export function applyChanges<T = any>(
  obj: T,
  changeContainer: ChangeContainer<T> | undefined,
  {
    mode = ChangesCopyMode.Values,
    copyRoot = false,
  }: ApplyingChangesOptions = {}
): T {
  const changedObj = changeObj(obj, changeContainer, "apply", obj, mode);
  if (copyRoot && mode !== ChangesCopyMode.Objects) {
    return shallowCopy(changedObj);
  }
  return changedObj;
}
