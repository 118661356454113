import { DashboardDetailsItem } from "../../queries/models/dashboard-details-item.model";
import { Button } from "primereact/button";
import { authService } from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

interface SelectedWorkDetailsProps {
  data?: DashboardDetailsItem;
  className?: string;
  onFinish: (workInstanceId: number) => void;
}

export function SelectedWorkDetails({
  data,
  className,
  onFinish,
}: SelectedWorkDetailsProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigateToDetails = useCallback(
    () =>
      window.open(
        `/app/work-instances/details/${data?.workInstance.id}`,
        "_blank"
      ),
    [data?.workInstance.id]
  );

  return (
    <div className={className}>
      {data && (
        <div className="w-full h-full grid">
          <div className="col-8 pt-2 pl-4">
            <div
              className="cursor-pointer text-xl font-bold max-w-max hover:text-indigo-600 hover:underline"
              onClick={() =>
                navigate(
                  `../installations/details/${data?.workInstance.installation.id}`
                )
              }
            >
              {data?.workInstance.installation.name}
            </div>
            <div className="font-semibold">
              {data?.workInstance.user?.username}
            </div>
          </div>
          <div className="col-4 pt-2 pl-4 flex flex-column">
            {authService.hasAccess(AppFeatures.CanForceEndWork) && (
              <div className="flex justify-content-end gap-2">
                <Button
                  label={t("common.details")}
                  className="w-8rem"
                  onClick={handleNavigateToDetails}
                />
                <Button
                  label={t("common.finishWork")}
                  className="delete-button w-8rem"
                  onClick={() => onFinish(data.workInstance.id)}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
