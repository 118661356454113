import { useMemo, useState, useCallback, useEffect } from "react";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { classNames } from "primereact/utils";
import { useWindowSize } from "../../hooks/use-window-size";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { AssetForm } from "./AssetForm";
import { Asset } from "../../queries/models/asset.model";
import { useQueryClient } from "react-query";
import { useTagsQuery } from "../../queries/tags.query";
import {
  useAddEmptyNoteMutation,
  useGetAssetQuery,
} from "../../queries/assets.query";
import { useGetNoteQuery } from "../../queries/notes.query";
import { useAddAssetMutation } from "../../queries/assets.query";
import { useEditAssetMutation } from "../../queries/assets.query";
import { useDeleteAssetMutation } from "../../queries/assets.query";
import { useAddNoteMutation } from "../../queries/notes.query";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CreateOrUpdateAsset } from "../../queries/models/create-or-update-asset.model";
import { useToast } from "../../components/ui/toast-context-provider";
import { Note } from "../../components/ui/Note/Note";
import { AddNoteRequest } from "../../queries/models/add-note-request";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { useTranslation } from "react-i18next";

export function AssetDetails() {
  const { t } = useTranslation();
  const { lg } = useWindowSize();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>(
    undefined
  );
  const params = useParams();
  const navigate = useNavigate();
  const tagsQuery = useTagsQuery();
  const id = useMemo(
    () => (params?.id ? Number(params.id) : undefined),
    [params]
  );
  const assetQuery = useGetAssetQuery(id);
  const addAssetMutation = useAddAssetMutation();
  const editAssetMutation = useEditAssetMutation();
  const deleteAssetMutation = useDeleteAssetMutation();
  const addEmptyNoteMutation = useAddEmptyNoteMutation();
  const selectedAssetNoteId = useMemo(() => {
    return selectedAsset?.noteId;
  }, [selectedAsset]);

  const noteQuery = useGetNoteQuery(selectedAssetNoteId);
  const addNoteMutation = useAddNoteMutation();

  const hasWriteAccess = useMemo(
    () => authService.hasAccess(AppFeatures.WebAssetsWrite),
    []
  );

  const createNewNote = useCallback(
    async (data: AddNoteRequest) => {
      var noteId: number;
      if (
        selectedAsset &&
        (selectedAssetNoteId === null || selectedAssetNoteId === undefined)
      ) {
        const addEmptyNoteMutateOptions = {
          onSuccess: async () => {
            toast.current?.show({
              severity: "success",
              detail: t("common.noteCreated"),
            });
            await queryClient.invalidateQueries("assets");
          },
          onError: async (error: any) => {
            toast.current?.show({
              severity: "error",
              detail: error?.data,
            });
          },
        };
        noteId = await addEmptyNoteMutation.mutateAsync(
          selectedAsset.id,
          addEmptyNoteMutateOptions
        );
      } else if (selectedAssetNoteId) {
        noteId = selectedAssetNoteId;
      }

      const addNoteMutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: t("common.saveSuccess"),
          });
          await queryClient.invalidateQueries("notes");
          await queryClient.invalidateQueries("assets");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: AddNoteRequest = {
        noteId: noteId!,
        message: data.message!,
        severity: data.severity,
        isConfidential: data.isConfidential,
        files: data.files,
      };
      addNoteMutation.mutateAsync(request, addNoteMutateOptions);
    },
    [
      addEmptyNoteMutation,
      addNoteMutation,
      queryClient,
      selectedAsset,
      selectedAssetNoteId,
      t,
      toast,
    ]
  );

  const noteItems = useMemo(() => {
    return noteQuery.data?.items ?? [];
  }, [noteQuery]);

  const deleteAsset = useCallback(async () => {
    if (selectedAsset?.id) {
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: t("common.saveSuccess"),
          });

          await queryClient.invalidateQueries("assets");
          setIsModalOpen(false);
          navigate(-1);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
          setIsModalOpen(false);
        },
      };
      await deleteAssetMutation.mutateAsync(selectedAsset?.id, mutateOptions);
    }
  }, [deleteAssetMutation, navigate, queryClient, selectedAsset?.id, t, toast]);

  const saveNewAsset = useCallback(
    async (data: CreateOrUpdateAsset) => {
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: t("common.saveSuccess"),
          });
          await queryClient.invalidateQueries("assets");
          navigate(-1);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      return addAssetMutation.mutateAsync(data, mutateOptions);
    },
    [addAssetMutation, navigate, queryClient, t, toast]
  );

  const editExistingAsset = useCallback(
    async (data: CreateOrUpdateAsset) => {
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: t("common.editSuccess"),
          });
          await queryClient.invalidateQueries("assets");
          navigate(-1);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      return editAssetMutation.mutateAsync(data, mutateOptions);
    },
    [editAssetMutation, navigate, queryClient, t, toast]
  );

  const handleSaveAsset = useCallback(
    (data: CreateOrUpdateAsset) => {
      return data.id === 0 ? saveNewAsset(data) : editExistingAsset(data);
    },
    [editExistingAsset, saveNewAsset]
  );

  const modalProps: CustomModalProps = useMemo(
    () => ({
      header: t("common.confirmation"),
      body: t("dialog.deleteThisAsset"),
      isOpen: isModalOpen,
      onClose: () => setIsModalOpen(false),
      onConfirm: deleteAsset,
      height: "max-content",
      confirmation: true,
      centered: true,
      justified: true,
    }),
    [deleteAsset, isModalOpen, t]
  );

  useEffect(() => {
    id && assetQuery.data
      ? setSelectedAsset(assetQuery.data)
      : setSelectedAsset({ name: "" } as Asset);
  }, [assetQuery.data, id, selectedAssetNoteId]);

  return (
    <div
      className={classNames(
        "h-full p-1 flex gap-2",
        lg ? "flex-row" : "flex-column"
      )}
    >
      <CustomModal {...modalProps} />
      <div className={lg ? "w-8" : "w-full"}>
        <RoundedShadowContainer
          medium
          fullHeight={lg ? true : false}
          mobile={lg}
        >
          <LoaderWrapper
            isLoading={
              assetQuery.isLoading ||
              tagsQuery.isLoading ||
              selectedAsset === undefined
            }
          >
            <div className="h-1 p-1 h-full">
              <AssetForm
                asset={selectedAsset ?? ({} as Asset)}
                tags={tagsQuery.data ?? []}
                onSave={handleSaveAsset}
                onCancel={() => navigate(-1)}
                onDelete={() => setIsModalOpen(true)}
              />
            </div>
          </LoaderWrapper>
        </RoundedShadowContainer>
      </div>
      <div className={lg ? "w-4" : "w-full pb-2"}>
        <RoundedShadowContainer
          mobile={lg}
          medium
          fullHeight={lg ? true : false}
        >
          <div className="h-full flex align-items-center justify-content-center">
            {!selectedAsset?.id ? (
              <div className="text-500 text-xl font-bold">
                {t("common.CREATE_EMPTY_ASSET_FIRST")}
              </div>
            ) : (
              <Note
                items={noteItems}
                isLoading={noteQuery.isLoading}
                onAddNote={createNewNote}
                addingWithWebCam
                hasWriteAccess={hasWriteAccess}
              />
            )}
          </div>
        </RoundedShadowContainer>
      </div>
    </div>
  );
}
