import { Calendar } from "primereact/calendar";

interface TableHeaderWithDateRangeFilterProps {
  header: string;
  value: (Date | null)[];
  showCalendarBottomBar?: boolean;
  maxDate?: Date;
  onFilterValueChange: (range: Date[]) => void;
  onClearClick?: () => void;
}

export function TableHeaderWithDateRangeFilter({
  header,
  value,
  showCalendarBottomBar = false,
  maxDate = undefined,
  onFilterValueChange,
  onClearClick,
}: TableHeaderWithDateRangeFilterProps) {
  return (
    <div className="w-full flex justify-content-between align-items-center">
      <div className="text-xl font-bold">{header}</div>
      <Calendar
        selectionMode="range"
        value={value.filter((x) => x).map((x) => x!)}
        onChange={(e) => onFilterValueChange(e.value as [])}
        showButtonBar={showCalendarBottomBar}
        onClearButtonClick={onClearClick}
        maxDate={maxDate}
        dateFormat="dd/mm/yy"
      />
    </div>
  );
}
