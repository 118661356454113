import { UserMonthlyOrdersValidation } from "../../queries/models/user-monthly-orders-validation.model";
import { UserMonthlyOrdersOverviewItem } from "../../queries/models/user-monthly-orders-overview.model";
import { ValidatedWorkInstance } from "../../queries/models/validated-work-instance.model";
import { Button } from "primereact/button";
import { OrderStatus } from "../../queries/models/enums/order-status.enum";
import { format } from "date-fns";
import { DataView } from "primereact/dataview";
import { TimeSpan } from "../../utils/timespan";
import { InstallatorWorkTimeOrder } from "../../queries/models/orders/instllator-worktime-order.model";
import { InstallatorTravelOrder } from "../../queries/models/orders/instllator-travel-order.model";
import { useUpdateWorkTimeMutation } from "../../queries/orders-validator.query";
import { UseQueryResult, useQueryClient } from "react-query";
import { EditableTime } from "../../components/ui/editable-time";
import { WorkTimeChartComponent } from "../../components/ui/WorkTimeChartComponent";
import { useGlobalLoader } from "../../hooks/useGlobalLoader";
import { EmptyContentBanner } from "../../components/ui/EmptyContentBanner";
import { useMemo } from "react";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { WorkInstanceType } from "../../queries/models/enums/work-instance-type.enum";
import { SpaceBeforeCapital } from "../../utils/space-before-capital";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { useTranslation } from "react-i18next";
import { Divider } from "primereact/divider";

export interface WorkDetailsSectionComponentProps {
  validation: UserMonthlyOrdersValidation | undefined;
  selectedOverViewItem: UserMonthlyOrdersOverviewItem | undefined;
  workInstance: UseQueryResult<ValidatedWorkInstance | undefined, unknown>;
  onSendOrder: (v: InstallatorTravelOrder | InstallatorWorkTimeOrder) => void;
}

export function WorkDetailsSectionComponent({
  workInstance,
  validation,
  selectedOverViewItem,
  onSendOrder,
}: WorkDetailsSectionComponentProps) {
  const { t } = useTranslation();
  const worktimeMutation = useUpdateWorkTimeMutation();
  const queryClient = useQueryClient();
  const globalLoader = useGlobalLoader();
  const hasWriteAccess = useMemo(() => {
    return authService.hasAccess(AppFeatures.WebInvoicingWrite);
  }, []);
  const hasSubmitOrdersPrivilidge = useMemo(() => {
    return authService.hasAccess(AppFeatures.CanSubmitInvoiceOrders);
  }, []);

  function handleOrderDurationUpdate(
    order: InstallatorWorkTimeOrder,
    ts: TimeSpan,
    comment: string
  ) {
    order.durationTicks = ts.ticks;
    worktimeMutation
      .mutateAsync({
        orderid: order.id,
        durationTicks: ts.ticks,
        comment: comment,
      })
      .then(() => {
        queryClient.invalidateQueries("ordersValidator");
      });
  }

  return (
    <div className="h-full overflow-auto">
      <LoaderWrapper isLoading={workInstance.isLoading}>
        {workInstance.data && (
          <div
            style={{ height: "80px" }}
            className="grid m-0"
          >
            <div className="col-8">
              <div className="grid">
                <div className="col-12 flex justify-content-center align-items-center text-xl">
                  {workInstance.data
                    ? workInstance.data.installation?.name
                    : ""}
                </div>
                <div className="col-12 flex justify-content-center align-items-center text-l">{`${
                  workInstance.data
                    ? workInstance.data.installation?.address.city
                    : ""
                } ${
                  workInstance.data
                    ? workInstance.data.installation?.address.street
                    : ""
                }`}</div>
              </div>
            </div>
            <div className="col-4 flex justify-content-center align-items-center text-xl font-bold">
              {workInstance.data
                ? format(workInstance.data.dateStarted!, "dd/LL/yyyy")
                : ""}
            </div>

            <div className="grid w-full">
              <div className="col-4 text-right text-500">
                {t("common.startedWork")}:
              </div>
              <div className="col-8">
                {workInstance.data
                  ? format(
                      workInstance.data.dateStarted!,
                      "HH:mm:ss dd/LL/yyyy"
                    )
                  : ""}
              </div>
              <div className="col-4 text-right text-500">
                {t("common.endedWork")}:
              </div>
              <div className="col-8">
                {workInstance.data
                  ? format(workInstance.data.dateEnded!, "HH:mm:ss dd/LL/yyyy")
                  : ""}
              </div>
              <div className="col-4 text-right text-500">
                {t("common.totalWork")}:
              </div>
              <div className="col-7">
                {workInstance.data
                  ? TimeSpan.fromTicks(
                      workInstance.data.summary.workDurationTicks
                    ).toHhMm()
                  : ""}
              </div>
              <div className="col-4 text-right text-500">
                {t("common.totalPauses")}:
              </div>
              <div className="col-8">
                {workInstance.data
                  ? TimeSpan.fromTicks(
                      workInstance.data.summary.pauseDurationTicks
                    ).toHhMm()
                  : ""}
                {validation?.user.includePauseTimeForInvoicing && (
                  <span className="text-red-500">
                    {` (${t("common.pauseTimeIncludedInWorktime")})`}
                  </span>
                )}
              </div>
              <div className="col-4 text-right text-500">
                {t("common.workType")}:
              </div>
              <div className="col-8">
                {workInstance.data
                  ? SpaceBeforeCapital.transform(
                      WorkInstanceType[workInstance.data.type]
                    )
                  : ""}
              </div>
            </div>

            <WorkTimeChartComponent workInstance={workInstance.data} />

            <DataView
              className="w-full"
              value={
                workInstance.data
                  ? workInstance.data.installatorWorkTimeOrders
                  : []
              }
              itemTemplate={(item: InstallatorWorkTimeOrder) => {
                return (
                  <div className="grid m-0 w-full">
                    <div className="col-2 flex justify-content-center align-items-center">
                      #{item.id}
                    </div>
                    <div className="col-4 flex justify-content-center align-items-center">
                      <EditableTime
                        disabled={
                          !hasWriteAccess || item.status === OrderStatus.Sent
                        }
                        timeSpan={TimeSpan.fromTicks(item.durationTicks)}
                        onEdit={(value, comment) =>
                          handleOrderDurationUpdate(item, value, comment)
                        }
                      />
                    </div>
                    <div className="col-3 flex justify-content-center align-items-center">
                      {OrderStatus[item.status]}
                    </div>
                    <div className="col-3">
                      <Button
                        className="green-action-button w-full"
                        disabled={
                          !hasSubmitOrdersPrivilidge ||
                          globalLoader ||
                          item.status === OrderStatus.Sent ||
                          !selectedOverViewItem?.hasOdooIdMapping
                        }
                        label={t("common.send")}
                        onClick={(e) => onSendOrder(item)}
                      />
                    </div>
                    <div
                      className="col-12"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {item.messageLog}
                    </div>
                  </div>
                );
              }}
              header={
                <div className="grid">
                  <div className="col-12">{t("common.workTimeOrders")}</div>
                  <Divider />
                  <div className="col-2 flex justify-content-center align-items-center">
                    {t("common.id")}
                  </div>
                  <div className="col-4 flex justify-content-end pr-5 align-items-center">
                    {t("common.duration")}
                  </div>
                  <div className="col-3 flex justify-content-center align-items-center">
                    {t("common.status")}
                  </div>
                  <div className="col-3 flex justify-content-center align-items-center"></div>
                </div>
              }
            />
          </div>
        )}

        {workInstance.data === undefined && !workInstance.isFetching && (
          <EmptyContentBanner text={t("common.SELECT_WORK_INSTANCE")} />
        )}
      </LoaderWrapper>
    </div>
  );
}
