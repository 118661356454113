import { AttachedPin } from "../../ui/DragDropPlan";
import { forNamedPins, measureLegend } from "./FloorplanLegendGenerator";
import { PinMeasurment, measurePin } from "./PinGenerator";

export type FloorplanImageSettings = {
  imageMargin?: number;
  textFontSize?: number;
  colorFillingOpacity?: number;
  imageScale?: number;
};

function generateDefaultBoxes(
  maxWidth: number,
  maxHeight: number,
  margin: number
): PinMeasurment[] {

  return [
    {
      text: "left border",
      left: -margin,
      right: -margin,
      top: -margin,
      bottom: maxHeight + margin,
    },
    {
      text: "right border",
      left: maxWidth + margin,
      right: maxWidth + margin,
      top: -margin,
      bottom: maxHeight + margin,
    },
    {
      text: "top border",
      left: -margin,
      right: maxWidth + margin,
      top: -margin,
      bottom: -margin,
    },
    {
      text: "bottom border",
      left: -margin,
      right: maxWidth + margin,
      top: maxHeight + margin,
      bottom: maxHeight + margin,
    },
  ];
}

export function generateFloorplanImage(
  canvas: HTMLCanvasElement,
  imageBitmap: ImageBitmap,
  pins: AttachedPin[] = [],
  descriptions: Map<AttachedPin, string>,
  {
    imageMargin = 50,
    textFontSize = 24,
    colorFillingOpacity = 1,
    imageScale = 1,
  }: FloorplanImageSettings = {},
  canvasSetting: "resizeXY" | "resizeX" | "resizeY" | "none" = "none"
): PinMeasurment[] {
  const imageWidth = imageBitmap.width * imageScale;
  const imageHeight = imageBitmap.height * imageScale;

  const context = canvas.getContext("2d", { alpha: true })!;

  const legendMarginHorizontal = 50;
  const [legendMeasurment, drawLegend] = measureLegend(
    context,
    pins,
    descriptions,
    legendMarginHorizontal,
    imageMargin * 2 + imageHeight,
    {
      colorFillingOpacity: colorFillingOpacity,
      fontSize: textFontSize,
      maxWidth: imageWidth + imageMargin * 2 - legendMarginHorizontal * 2,
    }
  );

  const requiredWidth = imageWidth + imageMargin * 2;
  const requiredHeight =
    imageHeight + imageMargin * 2 + legendMeasurment.height;

  if (canvasSetting === "resizeXY") {
    canvas.width = requiredWidth;
    canvas.height = requiredHeight;
  } else if (canvasSetting === "resizeX") {
    canvas.width = requiredWidth * (canvas.height / requiredHeight);
  } else if (canvasSetting === "resizeY") {
    canvas.height = requiredHeight * (canvas.width / requiredWidth);
  }

  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;

  const canvasScale = Math.min(
    canvasWidth / (imageWidth + imageMargin * 2),
    canvasHeight / (imageHeight + imageMargin * 2 + legendMeasurment.height)
  );

  context.save(); //whole canvas

  context.save(); //clearing
  context.beginPath();
  context.fillStyle = "white";
  //context.clearRect(0, 0, canvasWidth, canvasHeight); //alpha
  context.fillRect(0, 0, canvasWidth, canvasHeight);
  context.closePath();
  context.restore(); //clearing

  context.scale(canvasScale, canvasScale);

  context.save(); //image with pins
  context.translate(imageMargin, imageMargin);

  context.drawImage(imageBitmap, 0, 0, imageWidth, imageHeight);

  const pinBoxesMeasurments: PinMeasurment[] = generateDefaultBoxes(
    imageWidth,
    imageHeight,
    imageMargin
  );
  forNamedPins(pins, (pin, pinText) => {
    const pinPosX = pin.x * imageWidth,
      pinPosY = pin.y * imageHeight;

    const [currentBox, drawPin] = measurePin(
      context,
      pinText,
      pinPosX,
      pinPosY,
      {
        fontSize: textFontSize,
        fillingColor: pin.color ?? "purple",
        fillingOpacity: colorFillingOpacity,
        position: {
          x: "center",
          y: "middle",
        },
      }
    );
    drawPin();
    pinBoxesMeasurments.push(currentBox);
  });
  context.restore(); //image with pins

  context.save(); //legend
  drawLegend();
  context.restore(); //legend

  context.restore(); //whole canvas

  return pinBoxesMeasurments;
}
