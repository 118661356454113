import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTable,
  faCircleInfo,
  faHammer,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Divider } from "primereact/divider";
import { WorkInstanceDetails } from "../../queries/models/work-instance-details";
import { format } from "date-fns";
import { WorkInstanceEventsOverview } from "./WorkInstanceEventsOverview";
import { useWindowSize } from "../../hooks/use-window-size";
import { TimeSpan } from "../../utils/timespan";
import { classNames } from "primereact/utils";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { useTranslation } from "react-i18next";
import { WorkInstanceState } from "../../queries/models/enums/work-instance-state.enum";
import { useMemo } from "react";
import { DataView } from "primereact/dataview";
import { OrderStatus } from "../../queries/models/enums/order-status.enum";
import { EditableTime } from "../../components/ui/editable-time";
import { InstallatorWorkTimeOrder } from "../../queries/models/orders/instllator-worktime-order.model";
import { SpaceBeforeCapital } from "../../utils/space-before-capital";

interface WorkInstanceDetailsOverviewProps {
  details: WorkInstanceDetails | undefined;
  isLoading: boolean;
}

export function WorkInstanceDetailsOverview({
  details,
  isLoading,
}: WorkInstanceDetailsOverviewProps) {
  const { t } = useTranslation();
  const { lg } = useWindowSize();

  const installatorWorkTimeOrders = useMemo(() => {
    return details?.installatorWorkTimeOrders ?? [];
  }, [details]);

  return (
    <>
      <section className="height-full">
        <div className="section-header">
          <div className="flex gap-2 align-items-center">
            <FontAwesomeIcon icon={faTable} />
            <div className="font-medium">{t("common.overview")}</div>
          </div>
        </div>
        <div className="section-content px-0 flex pb-0 relative">
          <LoaderWrapper isLoading={isLoading}>
            <div className="overflow-y-auto">
              <div className="grid m-0">
                <div className={classNames("px-0", lg ? "col-6" : "col-12")}>
                  <div className="sub-section-header">
                    <div className="flex align-items-center gap-1">
                      <FontAwesomeIcon icon={faCircleInfo} />
                      <div>{t("common.generalInfo")}</div>
                    </div>
                  </div>
                  <Divider className="m-0" />
                  <div className="content-row block">
                    <div className="grid w-full p-2">
                      <div className="col-4 text-right text-500 text-lg">
                        {t("common.installation")}:
                      </div>
                      <div className="col-8 text-lg">
                        {details ? details.installation?.name ?? "-" : ""}
                      </div>
                      <div className="col-4 text-right text-500 text-lg">
                        {t("common.state")}:
                      </div>
                      <div className="col-8 text-lg">
                        {SpaceBeforeCapital.transform(
                          WorkInstanceState[details?.state ?? 0]
                        )}
                      </div>
                      <div className="col-4 text-right text-500 text-lg">
                        {t("common.datePlanned")}:
                      </div>
                      <div className="col-8 text-lg">
                        {details ? (
                          details.datePlanned ? (
                            <>{format(details.datePlanned, "dd/LL/yyyy")}</>
                          ) : (
                            <>-</>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-4 text-right text-500 text-lg">
                        {t("common.dateStarted")}:
                      </div>
                      <div className="col-8 text-lg">
                        {details ? (
                          details.dateStarted ? (
                            <>
                              {format(details.dateStarted, "dd/LL/yyyy HH:mm")}
                            </>
                          ) : (
                            <>-</>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-4 text-right text-500 text-lg">
                        {t("common.dateEnded")}:
                      </div>
                      <div className="col-8 text-lg">
                        {details ? (
                          details.dateEnded ? (
                            <>{format(details.dateEnded, "dd/LL/yyyy HH:mm")}</>
                          ) : (
                            <>-</>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-4 text-right text-500 text-lg">
                        {t("common.comment")}:
                      </div>
                      <div className="col-8 text-lg">
                        {details ? (
                          details.comment ? (
                            <>{details.comment}</>
                          ) : (
                            <>-</>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-4 text-right text-500 text-lg">
                        {t("common.totalWork")}:
                      </div>
                      <div className="col-8 text-lg">
                        {details
                          ? new TimeSpan(
                              details.summary?.workDurationTicks ?? 0
                            )?.toHhMm()
                          : ""}
                      </div>
                      <div className="col-4 text-right text-500 text-lg">
                        {t("common.totalPauses")}:
                      </div>
                      <div className="col-8 text-lg">
                        {details
                          ? new TimeSpan(
                              details.summary?.pauseDurationTicks ?? 0
                            ).toHhMm()
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                {lg && (
                  <Divider
                    className="m-0 my-10"
                    layout="vertical"
                  />
                )}
                <div className={classNames("px-0", lg ? "col-6" : "col-12")}>
                  <div className="sub-section-header">
                    <div className="flex align-items-center gap-1">
                      <FontAwesomeIcon icon={faUser} />
                      <div>{t("common.userInfo")}</div>
                    </div>
                  </div>
                  <Divider className="m-0" />
                  <div className="content-row block">
                    <div className="grid w-full p-2">
                      <div className="col-4 text-right text-500 text-lg">
                        {t("common.username")}:
                      </div>
                      <div className="col-8 text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis">
                        {details ? details.user?.username ?? "-" : ""}
                      </div>
                      <div className="col-4 text-right text-500 text-lg">
                        {t("common.fullName")}:
                      </div>
                      <div className="col-8 text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis">
                        {details ? details.user?.contactName ?? "-" : ""}
                      </div>
                      <div className="col-4 text-right text-500 text-lg">
                        {t("common.userPhone")}:
                      </div>
                      <div className="col-8 text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis">
                        {details ? details.user?.contactPhone ?? "-" : ""}
                      </div>
                      <div className="col-4 text-right text-500 text-lg">
                        {t("common.email")}:
                      </div>
                      <div className="col-8 text-lg white-space-nowrap overflow-hidden text-overflow-ellipsis">
                        {details ? details.user?.contactEmail ?? "-" : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sub-section-header">
                <div className="flex align-items-center gap-1">
                  <FontAwesomeIcon icon={faHammer} />
                  <div>{t("common.workEvents")}</div>
                </div>
              </div>
              <Divider className="m-0" />
              <div className="content-row block">
                <div
                  className=""
                  style={{ height: "max-content" }}
                >
                  {details ? (
                    details?.workEvents?.length !== 0 ? (
                      <div className="h-full">
                        <WorkInstanceEventsOverview
                          items={details.workEvents ?? []}
                        />
                      </div>
                    ) : (
                      <div className="flex justify-content-center align-items-center h-full">
                        <div className="text-500 text-2xl font-bold">
                          {t("common.NO_WORK_EVENTS")}
                        </div>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="sub-section-header mt-2">
                <div className="flex align-items-center gap-1">
                  <FontAwesomeIcon icon={faList} />
                  <div>{t("common.workTimeOrders")}</div>
                </div>
              </div>
              <Divider className="m-0" />
              <div className="content-row block">
                <DataView
                  value={installatorWorkTimeOrders}
                  header={
                    <div className="grid">
                      <div className="col-2 flex justify-content-center align-items-center">
                        {t("common.id")}
                      </div>
                      <div className="col-4 flex justify-content-end align-items-center">
                        {t("common.duration")}
                      </div>
                      <div className="col-6 flex justify-content-center align-items-center">
                        {t("common.status")}
                      </div>
                    </div>
                  }
                  itemTemplate={(item: InstallatorWorkTimeOrder) => {
                    return (
                      <div className="grid m-0 w-full">
                        <div className="col-2 flex justify-content-center align-items-center">
                          #{item.id}
                        </div>
                        <div className="col-4 flex justify-content-center align-items-center">
                          <EditableTime
                            disabled={true}
                            timeSpan={TimeSpan.fromTicks(item.durationTicks)}
                            onEdit={(value, comment) => {}}
                          />
                        </div>
                        <div className="col-6 flex justify-content-center align-items-center">
                          {OrderStatus[item.status]}
                        </div>
                        {item.wasModified && (
                          <div
                            className="col-12"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {item.messageLog}
                          </div>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </LoaderWrapper>
        </div>
      </section>
    </>
  );
}
