export const bubbles1 = [
  { r: 95, x: -45, y: -45, color: "var(--primary-color)" },
  { r: 125, x: -30, y: 10, color: "var(--primary-color)" },
  { r: 125, x: -29, y: 60, color: "var(--primary-color)" },
  { r: 175, x: -27, y: 100, color: "var(--primary-color)" },
  { r: 75, x: -27, y: 220, color: "var(--primary-color)" },
  { r: 75, x: -25, y: 250, color: "var(--primary-color)" },
  { r: 145, x: -23, y: 260, color: "var(--primary-color)" },
  { r: 145, x: -22, y: 320, color: "var(--primary-color)" },
  { r: 55, x: -23, y: 420, color: "var(--primary-color)" },
  { r: 55, x: -21, y: 440, color: "var(--primary-color)" },
  { r: 85, x: -19, y: 470, color: "var(--primary-color)" },
  { r: 85, x: -24, y: 510, color: "var(--primary-color)" },
];
export const bubbles2 = [
  { r: 125, x: -30, y: -35, color: "#8d66f7" },
  { r: 75, x: -20, y: 75, color: "#8d66f7" },
  { r: 45, x: -10, y: 135, color: "#8d66f7" },
  { r: 95, x: -8, y: 175, color: "#8d66f7" },
  { r: 95, x: -6, y: 235, color: "#8d66f7" },

  { r: 125, x: -5, y: 265, color: "#8d66f7" },
  { r: 65, x: -5, y: 355, color: "#8d66f7" },

  { r: 45, x: -4, y: 415, color: "#8d66f7" },
  { r: 75, x: -5, y: 455, color: "#8d66f7" },
  { r: 75, x: -15, y: 495, color: "#8d66f7" },
];
export const bubbles3 = [
  { r: 125, x: -15, y: -50, color: "var(--light-primary-color)" },
  { r: 75, x: -7, y: 20, color: "var(--light-primary-color)" },
  { r: 85, x: -7, y: 80, color: "var(--light-primary-color)" },
  { r: 85, x: 8, y: 130, color: "var(--light-primary-color)" },
  { r: 125, x: 9, y: 190, color: "var(--light-primary-color)" },
  { r: 75, x: 10, y: 260, color: "var(--light-primary-color)" },
  { r: 75, x: 9, y: 290, color: "var(--light-primary-color)" },
  { r: 95, x: 7, y: 310, color: "var(--light-primary-color)" },
  { r: 95, x: 5, y: 380, color: "var(--light-primary-color)" },
  { r: 95, x: 0, y: 450, color: "var(--light-primary-color)" },
];
export const bubbles4 = [
  { r: 125, x: 7, y: -50, color: "var(--light-select-color)" },
  { r: 125, x: 10, y: 20, color: "var(--light-select-color)" },
  { r: 125, x: 15, y: 70, color: "var(--light-select-color)" },
  { r: 125, x: 20, y: 120, color: "var(--light-select-color)" },
  { r: 125, x: 25, y: 150, color: "var(--light-select-color)" },
  { r: 125, x: 25, y: 190, color: "var(--light-select-color)" },
  { r: 125, x: 25, y: 250, color: "var(--light-select-color)" },
  { r: 125, x: 25, y: 290, color: "var(--light-select-color)" },
  { r: 125, x: 25, y: 350, color: "var(--light-select-color)" },
  { r: 125, x: 30, y: 370, color: "var(--light-select-color)" },
  { r: 125, x: 20, y: 410, color: "var(--light-select-color)" },
  { r: 125, x: 5, y: 470, color: "var(--light-select-color)" },
];
