interface TagItemProps {
  tagName: string;
  textColor: string;
  backGroundColor: string;
}

export function TagItem({ tagName, textColor, backGroundColor }: TagItemProps) {
  return (
    <div
      className="inline-block p-1"
      style={{
        color: textColor,
        backgroundColor: backGroundColor,
        borderRadius: ".3rem",
        margin: "0.15rem 0.15rem 0 0 ",
      }}
    >
      {tagName}
    </div>
  );
}
