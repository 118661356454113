import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  onSave: () => void;
  onClose: () => void;
  setNoteSectionVisible: (open: boolean) => void;
  noteSectionVisible: boolean;
}

export function ToolboxHeader({ name, onSave, onClose, setNoteSectionVisible, noteSectionVisible }: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex justify-content-between">
      <div className="text-xl font-bold text-800">{name}</div>
      <div className="flex gap-1">
        <Button label={t("common.note")}
        icon={noteSectionVisible ? "pi pi-folder-open" : "pi pi-folder"}
        onClick={() => setNoteSectionVisible(!noteSectionVisible)}
         />
        <Button
          label={t("common.save")}
          icon="pi pi-save"
          className="green-action-button"
          onClick={onSave}
        />
        <Button
          className="delete-button w-3rem"
          icon="pi pi-times"
          onClick={onClose}
        />
      </div>
    </div>
  );
}
