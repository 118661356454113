import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { Tag } from "../queries/models/tag.model";
import { CreateOrUpdateTagRequest } from "../queries/models/create-or-update-tag-request.model";

class TagsService extends BaseService {
  async getAll(): Promise<Tag[]> {
    const url = this.endpointPath;
    return this.http.get<Tag[]>(url).then((response) => response.data);
  }
  
  async getAllActive(): Promise<Tag[]> {
    const url = `${this.endpointPath}/active`;
    return this.http.get<Tag[]>(url).then((response) => response.data);
  }

  async addTag(request: CreateOrUpdateTagRequest) {
    const url = this.endpointPath;
    return this.http.post<CreateOrUpdateTagRequest>(url, request).then((response) => response.data);
  }

  async updateTag(request: CreateOrUpdateTagRequest) {
    const url = this.endpointPath;
    return this.http.put<CreateOrUpdateTagRequest>(url, request).then((response) => response.data);
  }

  async deleteTag(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.delete(url).then((response) => response.data);
  }
}

export const tagsService = new TagsService(API_ENDPOINTS.TAGS);
