import { useState, useRef, useCallback, useEffect } from "react";
import { useNullableImageQuery } from "../../queries/resource.query";
import { AxiosProgressEvent } from "axios";
import { CustomProgressBar } from "./custom-progress-bar";
import { Image } from "./image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

interface ImageWrapperProps {
  imageSrc: string | undefined | Blob;
  width?: string;
  height: string;
  preview?: boolean;
  withProgressBar?: boolean;
  progressBarClassName?: string;
  onClick?: (dataUrl: string) => void;
}

export function ImageWrapper({
  imageSrc,
  width,
  height,
  preview = false,
  withProgressBar = false,
  progressBarClassName = "",
  onClick,
}: ImageWrapperProps) {
  const [progress, setProgress] = useState<number | undefined>(
    withProgressBar ? 0 : undefined
  );

  const lastProgressUpdateRef = useRef<Date>();
  const onProgress = useCallback(
    (e: AxiosProgressEvent) => {
      const now = new Date();
      if (
        !lastProgressUpdateRef.current ||
        +now - +lastProgressUpdateRef.current >= 100 ||
        e.progress === 1 ||
        e.progress === undefined
      ) {
        lastProgressUpdateRef.current = now;
        setProgress(e.progress && e.progress * 100);
      }
    },
    [lastProgressUpdateRef]
  );

  const imageQuery = useNullableImageQuery(
    typeof imageSrc === "string" ? imageSrc : undefined,
    withProgressBar ? onProgress : undefined
  );
  const [imageHidden, setImageHidden] = useState<boolean>(false);

  const [imageData, setImageData] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (imageSrc instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageData(reader.result as string);
      };
      reader.readAsDataURL(imageSrc);
    } else {
      setImageData(imageQuery.data);
    }
  }, [imageQuery.data, imageSrc]);

  const handleImageClick = useCallback(() => {
    if (imageData && onClick) {
      onClick(imageData);
    }
  }, [imageData, onClick]);

  return (
    <>
      {withProgressBar && progress !== undefined && (
        <div className={progressBarClassName}>
          <CustomProgressBar value={progress} />
        </div>
      )}
      {imageData && (
        <div
          className="image-wrapper"
          onClick={handleImageClick}
        >
          <Image
            src={imageData}
            zoomSrc={imageData}
            alt="Error"
            width={width}
            height={imageHidden ? "0px" : height}
            minScale={0.1}
            maxScale={3}
            onShow={() => setImageHidden(true)}
            onHide={() => setImageHidden(false)}
            preview={preview}
          />
          {preview && (
            <div className="image-loupe">
              <div className="image-background">
                <FontAwesomeIcon
                  size={"xl"}
                  color="white"
                  icon={faMagnifyingGlass}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
