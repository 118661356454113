import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToolbox } from "@fortawesome/free-solid-svg-icons";
import { SpinnerLoaderWrapper } from "../../ui/SpinnerLoaderWrapper";
import { FloorPlan } from "../../../queries/models/InstallationPlans/floor-plan.model";
import { FloorPlanItemForm } from "./FloorPlanItemForm";
import { FloorPlanItem } from "../../../queries/models/InstallationPlans/floor-plan-item.model";
import {
  useCallback,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
} from "react";
import { CreateOrUpdateFloorPlanItem } from "../../../queries/models/InstallationPlans/create-or-update-floor-plan-item.model";
import { FloorPlanItemsTableOverview } from "./FloorPlanItemsTableOverview";
import { ToolboxHeader } from "./ToolBoxHeader";
import { useGetAllInstallationAssetsQuery } from "../../../queries/assets.query";
import {
  CustomModal,
  CustomModalProps,
} from "../../ui/MobileModal/custom-modal";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

interface ToolBoxProps {
  loading: boolean;
  floorPlan: FloorPlan | undefined;
  installationId: number;
  setFloorPlan: (v: FloorPlan) => void;
  onSaveFloorPlan: () => void;
  editableFloorPlanItem?: FloorPlanItem;
  setEditableFloorPlanItem?: Dispatch<
    SetStateAction<FloorPlanItem | undefined>
  >;
  setNoteSectionVisible: (open: boolean) => void;
  noteSectionVisible: boolean;
  selectedItemsGuids: string[];
  setSelectedItemsGuids: (values: string[]) => void;
}

export function ToolBox({
  loading,
  floorPlan,
  installationId,
  setFloorPlan,
  onSaveFloorPlan,
  editableFloorPlanItem,
  setEditableFloorPlanItem,
  setNoteSectionVisible,
  noteSectionVisible,
  selectedItemsGuids,
  setSelectedItemsGuids,
}: ToolBoxProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const assetsQuery = useGetAllInstallationAssetsQuery(installationId);
  const [itemToDeleteGuid, setItemToDeleteGuid] = useState<string | undefined>(
    undefined
  );
  const [closeModalOpen, setCloseModalOpen] = useState<boolean>(false);

  const isLoading = loading || assetsQuery.isLoading;
  const availableAssets = useMemo(() => {
    const available =
      assetsQuery.data?.filter((installationAsset) => {
        const assetUsedInCurrentFloorPlan = floorPlan?.floorPlanItems?.some(
          (item) => item.assetId === installationAsset.id
        );
        if (assetUsedInCurrentFloorPlan) return false;
        const assetNotUsedInAnyFloorPlans =
          installationAsset.floorPlanItems?.length === 0;
        if (assetNotUsedInAnyFloorPlans) return true;
        const assetUsedOnlyInCurrentFloorPlanButRemoved =
          installationAsset.floorPlanItems?.length === 1 &&
          installationAsset.floorPlanItems[0].floorPlanId === floorPlan?.id &&
          !assetUsedInCurrentFloorPlan;
        if (assetUsedOnlyInCurrentFloorPlanButRemoved) return true;
        return false;
      }) ?? [];
    return editableFloorPlanItem?.asset
      ? [editableFloorPlanItem?.asset, ...available]
      : available;
  }, [assetsQuery.data, editableFloorPlanItem?.asset, floorPlan]);

  const handleSubmitFloorPlanItem = useCallback(
    (value: CreateOrUpdateFloorPlanItem) => {
      if (floorPlan && editableFloorPlanItem) {
        const hasAlreadyGuid = !!value.guid;
        const floorPlanItem: FloorPlanItem = {
          ...editableFloorPlanItem,
          name: value.name,
          additionalDescription: value.additionalDescription,
          color: value.color,
          textColor: value.textColor,
          size: value.size,
          xPosition: editableFloorPlanItem.xPosition ?? value.xPosition,
          yPosition: editableFloorPlanItem.yPosition ?? value.yPosition,
          assetId: value.assetId,
          guid: hasAlreadyGuid ? value.guid : uuidv4(),
          asset: assetsQuery.data?.find((x) => x.id === value.assetId),
          isAttached: editableFloorPlanItem.isAttached,
          floorPlanId: floorPlan.id,
        };

        const index = floorPlan.floorPlanItems?.findIndex(
          (x) => x.guid === value.guid
        );

        const newFloorPlanItems: FloorPlanItem[] = [
          ...(floorPlan.floorPlanItems ?? []),
        ];
        if (index !== undefined && index >= 0)
          newFloorPlanItems[index] = floorPlanItem;
        else newFloorPlanItems.push(floorPlanItem);

        setFloorPlan({
          ...floorPlan,
          floorPlanItems: newFloorPlanItems,
        });

        setEditableFloorPlanItem?.(undefined);
        setSelectedItemsGuids([...selectedItemsGuids, floorPlanItem.guid!]);
      }
    },
    [
      floorPlan,
      editableFloorPlanItem,
      assetsQuery.data,
      setFloorPlan,
      setEditableFloorPlanItem,
      setSelectedItemsGuids,
      selectedItemsGuids,
    ]
  );

  const handleDeleteFloorPlanItem = useCallback(() => {
    if (itemToDeleteGuid) {
      const items = floorPlan?.floorPlanItems?.filter(
        (x) => x.guid !== itemToDeleteGuid
      );
      setFloorPlan({ ...floorPlan, floorPlanItems: items } as FloorPlan);
      setItemToDeleteGuid(undefined);
      setEditableFloorPlanItem?.(undefined);
    }
  }, [floorPlan, itemToDeleteGuid, setFloorPlan, setEditableFloorPlanItem]);

  const modalProps: CustomModalProps = useMemo(
    () => ({
      isOpen: !!itemToDeleteGuid,
      header: t("common.confirmation"),
      body: t("dialog.deleteThisItem"),
      onClose: () => setItemToDeleteGuid(undefined),
      confirmation: true,
      centered: true,
      justified: true,
      height: "max-content",
      onConfirm: async () => handleDeleteFloorPlanItem(),
    }),
    [handleDeleteFloorPlanItem, itemToDeleteGuid, t]
  );

  const closeModalProps: CustomModalProps = useMemo(
    () => ({
      isOpen: closeModalOpen,
      header: t("common.confirmation"),
      body: t(
        "dialog.doYouWantToCloseThisPageAndNavigateBackToInstallationPage"
      ),
      onClose: () => setCloseModalOpen(false),
      centered: true,
      justified: true,
      height: "max-content",
      confirmation: true,
      onConfirm: () => navigate(-1),
    }),
    [closeModalOpen, navigate, t]
  );

  return (
    <div className="h-full overflow-hidden">
      <section className="height-full">
        <CustomModal {...modalProps} />
        <CustomModal {...closeModalProps} />
        <div className="section-header flex align-items-center gap-2">
          <FontAwesomeIcon icon={faToolbox} />
          <div>{t("common.toolbox")}</div>
        </div>
        <div className="section-content">
          {!isLoading ? (
            <>
              {floorPlan && (
                <div className="max-h-full h-full flex-column flex">
                  <div className="w-full p-2">
                    <ToolboxHeader
                      name={floorPlan.name}
                      onSave={onSaveFloorPlan}
                      onClose={() => setCloseModalOpen(true)}
                      setNoteSectionVisible={setNoteSectionVisible}
                      noteSectionVisible={noteSectionVisible}
                    />
                  </div>
                  <div className="w-full pt-3 transition-duration-500 min-h-0 flex-auto">
                    <FloorPlanItemsTableOverview
                      items={floorPlan.floorPlanItems}
                      loading={isLoading}
                      editableItem={editableFloorPlanItem}
                      onAddNew={() => {
                        setEditableFloorPlanItem?.({
                          name: "",
                        } as FloorPlanItem);
                      }}
                      onSelectItem={(guid) => {
                        setEditableFloorPlanItem?.(
                          floorPlan.floorPlanItems?.find((x) => x.guid === guid)
                        );
                      }}
                      unselectItem={() => setEditableFloorPlanItem?.(undefined)}
                      selectedItemsGuids={selectedItemsGuids}
                      setSelectedItemsGuids={setSelectedItemsGuids}
                    />
                  </div>
                  <div className="w-full">
                    {editableFloorPlanItem && (
                      <>
                        <hr />
                        <FloorPlanItemForm
                          assets={availableAssets}
                          floorPlanItem={editableFloorPlanItem}
                          handleSubmit={handleSubmitFloorPlanItem}
                          handleCancel={() =>
                            setEditableFloorPlanItem?.(undefined)
                          }
                          handleDelete={(guid) => setItemToDeleteGuid(guid)}
                        />
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <SpinnerLoaderWrapper />
          )}
        </div>
      </section>
    </div>
  );
}
