import { useState, useMemo } from "react";
import { DashboardOverviewItem } from "../../../queries/models/dashboard-overview-item.model";
import { MapWithMarkers } from "../../../components/ui/google-map/map-with-markers";
import { LocationLog } from "../../../queries/models/location-log.model";
import { MarkerModel } from "../../../components/ui/google-map/marker-model";
import { useDashboardDetailQuery } from "../../../queries/dashboard.query";
import { WorkDurationState } from "../../../queries/models/enums/work-duration-state.enum";
import { SpaceBeforeCapital } from "../../../utils/space-before-capital";
import { TimeSpan } from "../../../utils/timespan";
import { differenceInMilliseconds, format } from "date-fns";
import { WorkTimeChartComponent } from "../../../components/ui/WorkTimeChartComponent";
import { RoundedShadowContainer } from "../../../components/ui/rounded-shadow-container";
import RoundedDot from "../../../components/ui/RoundedDot";

interface SelectedDashboardOverviewItemDetailsProps {
  item: DashboardOverviewItem;
}

export function SelectedDashboardOverviewItemDetails({
  item,
}: SelectedDashboardOverviewItemDetailsProps) {
  const [hoveredPosition, setHoveredPosition] = useState<
    LocationLog | undefined
  >(undefined);
  const dashboardDetailQuery = useDashboardDetailQuery(item?.workInstanceId);

  const [mapMarkers, setMapMarkers] = useState<MarkerModel[]>([]);

  useMemo(() => {
    setMapMarkers([
      {
        latitude: dashboardDetailQuery.data?.lastLocation.latitude ?? 0,
        longitude: dashboardDetailQuery.data?.lastLocation.longitude ?? 0,
        showInfoWindow: false,
        title: dashboardDetailQuery.data?.workInstance.user?.username ?? "",
        markerIconUrl:
          "http://maps.google.com/mapfiles/kml/paddle/red-circle.png",
        iconSize: 45,
        infoWindowBody: (
          <div className="text-base font-medium">
            {dashboardDetailQuery.data?.workInstance.user?.username}
          </div>
        ),
      },
      {
        latitude:
          dashboardDetailQuery.data?.workInstance.installation.address
            .latitude ?? 0,
        longitude:
          dashboardDetailQuery.data?.workInstance.installation.address
            .longitude ?? 0,
        showInfoWindow: false,
        title: dashboardDetailQuery.data?.workInstance.installation.name ?? "",
        markerIconUrl:
          "http://maps.google.com/mapfiles/kml/shapes/ranger_station.png",
        radius:
          dashboardDetailQuery.data?.workInstance.installation.address.radius,
        iconSize: 30,
        infoWindowBody: (
          <div className="text-base font-medium">
            {dashboardDetailQuery.data?.workInstance.installation.name}
          </div>
        ),
      },
    ]);
  }, [
    dashboardDetailQuery.data?.lastLocation.latitude,
    dashboardDetailQuery.data?.lastLocation.longitude,
    dashboardDetailQuery.data?.workInstance.installation.address.latitude,
    dashboardDetailQuery.data?.workInstance.installation.address.longitude,
    dashboardDetailQuery.data?.workInstance.installation.address.radius,
    dashboardDetailQuery.data?.workInstance.installation.name,
    dashboardDetailQuery.data?.workInstance.user?.username,
  ]);

  return (
    <div className="flex flex-column h-full gap-3">
      <div className="h-full border-round-xl shadow-4">
        <MapWithMarkers
          showInfoWindows
          markers={mapMarkers}
          currenPosition={hoveredPosition}
          onPositionHover={setHoveredPosition}
          strokes={[]}
          disableDefaultMapUI={false}
          mapBorderRadius=".75rem"
        />
      </div>
      {dashboardDetailQuery.data?.workInstance?.locationLogs && (
        <div>
          <RoundedShadowContainer small>
            <div className="p-2">
              <div className="text-xl font-bold">Worktime chart</div>
              <WorkTimeChartComponent
                className="w-full h-6rem p-0 m-0"
                workInstance={dashboardDetailQuery.data?.workInstance}
                onHover={setHoveredPosition}
                currentPosition={hoveredPosition}
                showTooltip={false}
              />
              <div className="flex flex-column gap-1">
                <div className="px-3 flex flex-row gap-1">
                  <div className="flex align-items-center gap-2 w-6">
                    <RoundedDot color="#1976D2" />
                    <div className="text-500">Work</div>
                  </div>
                  <div className="flex align-items-center gap-2 w-6">
                    <RoundedDot color="#FBC02D" />
                    <div className="text-500">Pause</div>
                  </div>
                </div>
                <div className="px-3 flex flex-row gap-1">
                  <div className="flex align-items-center gap-2 w-6">
                    <RoundedDot color="#00796B" />
                    <div className="text-500">In address</div>
                  </div>
                  <div className="flex align-items-center gap-1 w-6">
                    <RoundedDot color="#D32F2F" />
                    <div className="text-500">Outside address</div>
                  </div>
                </div>
              </div>
            </div>
          </RoundedShadowContainer>
        </div>
      )}
      <div>
        <div className="flex gap-2">
          <RoundedShadowContainer small>
            <div className="p-2">
              <div className="text-xl font-bold">Work state</div>
              <div className="text-500">
                {`${SpaceBeforeCapital.transform(
                  WorkDurationState[item.workDurationState]
                )}: ${TimeSpan.fromMiliseconds(
                  differenceInMilliseconds(
                    new Date(),
                    new Date(item.dateLastDuration ?? 0)
                  )
                ).toHhMm()}`}
              </div>
            </div>
          </RoundedShadowContainer>
          <RoundedShadowContainer small>
            <div className="p-2">
              <div className="text-xl font-bold">Date started</div>
              <div className="text-500">
                {format(item.dateStarted, "dd/LL/yyyy HH:mm")}
              </div>
            </div>
          </RoundedShadowContainer>
        </div>
      </div>
    </div>
  );
}
