import { useMutation, useQuery } from 'react-query';
import { workEntriesService } from '../services/work-entries.service';
import { WorkInstanceTimeEntry } from './models/work-instance-time-entry.model';
import { CreateWorkInstanceTimeEntry } from './models/create-work-instance-time-entry.model';
import { UpdateWorkInstanceTimeEntry } from './models/edit-work-instance-time-entry.model';

export function useUserEntriesQuery(userId: number | undefined, month: Date | undefined) {
    return useQuery<WorkInstanceTimeEntry[]>(
        ["userEntries", month, userId],
        () => fetchUserEntries(userId, month),
        { refetchOnWindowFocus: false, enabled: month !== undefined && userId !== undefined }
    );
}
async function fetchUserEntries(userId: number | undefined, month: Date | undefined) {
    return await workEntriesService.getUserEntries(userId!, month!);
}
export function useAddWorkInstanceTimeEntryMutation() {
    return useMutation((request: CreateWorkInstanceTimeEntry) => workEntriesService.addWorkInstance(request));
}
export function useUpdateWorkInstanceTimeEntryMutation() {
    return useMutation((request: UpdateWorkInstanceTimeEntry) => workEntriesService.editWorkInstance(request));
}

export function useDeleteWorkInstanceTimeEntryMutation() {
    return useMutation((id: number) => workEntriesService.deleteUser(id));
}