import { useState } from "react";
import { useDashboardOverviewQuery } from "../../../queries/dashboard.query";
import { DashboardOverviewItem } from "../../../queries/models/dashboard-overview-item.model";
import { Dialog } from "primereact/dialog";
import { MobileDashboardItemsOverview } from "./MobileDashboardItemsOverview";
import { SelectedDashboardOverviewItemDetails } from "./SelectedDashboardItemDetails";
import { LoaderWrapper } from "../../../components/ui/LoaderWrapper";

export function MobileDashboard() {
  const dashboardOverviewQuery = useDashboardOverviewQuery(60 * 1000);

  const [selectedOverviewItem, setSelectedOverviewItem] = useState<
    DashboardOverviewItem | undefined
  >(undefined);

  return (
    <div className="relative h-full">
      <LoaderWrapper isLoading={dashboardOverviewQuery.isLoading}>
        <div className="h-full">
          {selectedOverviewItem && (
            <Dialog
              className="mobile-dialog"
              visible={!!selectedOverviewItem}
              header={selectedOverviewItem.installationName}
              style={{ width: "95%", height: "90%" }}
              onHide={() => setSelectedOverviewItem(undefined)}
            >
              <SelectedDashboardOverviewItemDetails
                item={selectedOverviewItem}
              />
            </Dialog>
          )}
          <MobileDashboardItemsOverview
            items={dashboardOverviewQuery.data}
            selectedOverviewItem={selectedOverviewItem}
            setSelectedOverviewItem={setSelectedOverviewItem}
          />
        </div>
      </LoaderWrapper>
    </div>
  );
}
