import { addDays, format, isSameDay } from "date-fns";
import { PlanningWorkInstance } from "../../queries/models/planning-work-instance.model";
import { PlanningForm } from "./PlanningForm";
import { SelectItem } from "primereact/selectitem";
import { PlanningUser } from "../../queries/models/planning-users.model";
import {
  useAddWorkInstanceMutation,
  useEditWorkInstanceMutation as useUpdateWorkInstanceMutation,
} from "../../queries/planning.query";
import { useDeleteWorkInstanceMutation } from "../../queries/work-instances.query";
import { useMemo, useState, useCallback } from "react";
import { AddOrEdtiWorkInstance as AddOrUpdateWorkInstance } from "../../queries/models/form-work-instance.model";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import { CreateWorkInstanceRequest } from "../../queries/models/create-work-instance.model";
import { UpdateWorkInstanceRequest } from "../../queries/models/edit-work-instance.model";
import Enumerable from "linq";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { useTranslation } from "react-i18next";

interface EditPlanningComponentProps {
  selectedDates: Date[];
  entries?: PlanningWorkInstance[];
  users?: SelectItem[] | undefined;
  installations?: SelectItem[] | undefined;
  selectedUsers?: PlanningUser[];
}

export function EditPlanningComponent({
  selectedDates = [],
  entries = [],
  users = [],
  installations = [],
  selectedUsers = [],
}: EditPlanningComponentProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const addWorkInstanceMutation = useAddWorkInstanceMutation();
  const updateWorkInstanceMutation = useUpdateWorkInstanceMutation();
  const deleteWorkInstanceMutation = useDeleteWorkInstanceMutation();
  const [workInstanceToDelete, setWorkInstanceToDelete] = useState<
    AddOrUpdateWorkInstance | undefined
  >(undefined);

  const selectedDatesGroups = useMemo(() => {
    if (selectedDates.length === 0) return [];

    const orderedDates = Enumerable.from(selectedDates)
      .orderBy((x) => x)
      .where((x) => x !== undefined)
      .select((x) => x!);

    const dateGroups: Date[][] = [];
    let groupStart = orderedDates.first();
    let groupEnd = orderedDates.first();
    let inRowCounter = 0;

    orderedDates.forEach((entry) => {
      if (!isSameDay(entry!, addDays(groupStart!, inRowCounter))) {
        inRowCounter = 0;
        dateGroups.push([groupStart!, groupEnd!]);
        groupStart = entry!;
      }
      inRowCounter++;
      groupEnd = entry!;
    });
    dateGroups.push([groupStart!, orderedDates.last()]);

    const result = dateGroups.map((x) => {
      if (isSameDay(x[0], x[1])) {
        return format(x[0], "dd/LL/yyyy");
      }
      return `${format(x[0], "dd/LL/yyyy")} - ${format(x[1], "dd/LL/yyyy")}`;
    });
    return result;
  }, [selectedDates]);

  const createNewWorkInstance = async (data: AddOrUpdateWorkInstance) => {
    setIsSubmitting(true);
    const promises = selectedDates.map((selectedDate) => {
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: t("alert.saveSuccess"),
          });
          await queryClient.invalidateQueries("planningWorkInstances");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: CreateWorkInstanceRequest = {
        userId: data.userId!,
        installationId: data.installationId!,
        comment: data.comment,
        date: selectedDate,
      };

      return addWorkInstanceMutation.mutateAsync(request, mutateOptions);
    });
    await Promise.allSettled(promises).then(() => setIsSubmitting(false));
  };
  const updateWorkInstance = async (data: AddOrUpdateWorkInstance) => {
    setIsSubmitting(true);
    const promises = selectedDates.map((selectedDate) => {
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: t("alert.updateSuccess"),
          });
          await queryClient.invalidateQueries("planningWorkInstances");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
        onSettled: () => {
          setIsSubmitting(false);
        },
      };

      const request: UpdateWorkInstanceRequest = {
        userId: data.userId!,
        installationId: data.installationId!,
        comment: data.comment,
        date: data.date!,
        id: data.id!,
      };
      return updateWorkInstanceMutation.mutateAsync(request, mutateOptions);
    });
    await Promise.allSettled(promises).then(() => setIsSubmitting(false));
  };
  const deleteWorkInstance = useCallback(
    async (data: AddOrUpdateWorkInstance) => {
      setIsSubmitting(true);
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: t("alert.deleteSuccess"),
          });
          await queryClient.invalidateQueries("planningWorkInstances");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
        onSettled: () => {
          setIsSubmitting(false);
          setWorkInstanceToDelete(undefined);
        },
      };

      await deleteWorkInstanceMutation.mutateAsync(data.id!, mutateOptions);
    },
    [deleteWorkInstanceMutation, queryClient, t, toast]
  );

  const sortedEntries = useMemo(
    () => [...entries].sort((a, b) => +a.date - +b.date),
    [entries]
  );

  const modalProps: CustomModalProps = useMemo(
    () => ({
      isOpen: !!workInstanceToDelete,
      onClose: () => setWorkInstanceToDelete(undefined),
      header: t("common.confirmation"),
      body: t("dialog.deleteThisWorkEntry"),
      confirmation: true,
      justified: true,
      centered: true,
      height: "max-content",
      onConfirm: () => {
        if (workInstanceToDelete) {
          deleteWorkInstance(workInstanceToDelete);
        }
      },
    }),
    [deleteWorkInstance, t, workInstanceToDelete]
  );

  return (
    <>
      <CustomModal {...modalProps} />
      <div className="daily-entries d-none d-lg-block top-frame p-2">
        {selectedDates.length > 0 && (
          <>
            <section>
              <div className="section-header flex">
                Edit for &nbsp;
                {selectedDatesGroups.map((x, i) => (
                  <div
                    className="mx-1"
                    key={x}
                  >
                    {x}
                    {i + 1 < selectedDatesGroups.length ? ",\xa0" : " "}
                  </div>
                ))}
              </div>
              <div className="section-content">
                <LoaderWrapper isLoading={isSubmitting}>
                  <PlanningForm
                    entry={undefined}
                    installationOptions={installations}
                    selectedUsers={selectedUsers}
                    onCreateWorkInstance={createNewWorkInstance}
                    onUpdateWorkInstance={updateWorkInstance}
                    onDeleteWorkInstance={deleteWorkInstance}
                    multipleUsers
                  />
                </LoaderWrapper>
              </div>
            </section>
            {sortedEntries.length > 0 && (
              <section>
                <div className="section-header">Planned work instances</div>
                <div className="section-content">
                  {sortedEntries.map((entry, i) => (
                    <div
                      key={entry.workInstanceId}
                      style={{
                        borderBottom:
                          i >= 0 && i < sortedEntries.length - 1
                            ? "1px #bfbfbf solid"
                            : "",
                      }}
                    >
                      <PlanningForm
                        entry={entry}
                        selectedUsers={selectedUsers}
                        installationOptions={installations}
                        onCreateWorkInstance={createNewWorkInstance}
                        onUpdateWorkInstance={updateWorkInstance}
                        onDeleteWorkInstance={(e) => {
                          setWorkInstanceToDelete(e);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </section>
            )}
          </>
        )}
      </div>
    </>
  );
}
