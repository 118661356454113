import { useQuery, useMutation, UseQueryOptions } from "react-query";
import { Company } from "./models/company-model";
import { companiesService } from "../services/companies.service";
import { CreateOrUpdateCompanyRequest } from "./models/create-or-upodate-company-request";

export function useCompaniesQuery() {
  return useQuery<Company[]>(["companies"], () => companiesService.getAll());
}

export function useActiveCompaniesQuery() {
  return useQuery<Company[]>(["companies"], () =>
    companiesService.getAllActive()
  );
}

export function useGetCompanyQuery(id: number | undefined) {
  const queryOptions: UseQueryOptions<Company> = {
    enabled: !!id,
  };

  return useQuery<Company>(
    ["companies", id],
    () => companiesService.getById(id!),
    queryOptions
  );
}

export function useAddCompanyMutation() {
  return useMutation((request: CreateOrUpdateCompanyRequest) =>
    companiesService.add(request)
  );
}

export function useEditCompanyMutation() {
  return useMutation((request: CreateOrUpdateCompanyRequest) =>
    companiesService.edit(request)
  );
}

export function useDeactivateCompanyMutation() {
  return useMutation((id: number) => companiesService.deactivate(id));
}

export function useDeleteCompanyMutation() {
  return useMutation((id: number) => companiesService.delete(id));
}
