import { useState, useCallback } from "react";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { AssetTypeForm } from "./AssetTypeForm";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { AssetType } from "../../queries/models/asset-type.model";
import { CreateOrUpdateAssetType } from "../../queries/models/create-or-update-asset-type";
import {
  useActiveAssetTypesQuery,
  useAddAssetTypeMutation,
  useDeleteAssetTypeMutation,
  useUpdateAssetTypeMutation,
} from "../../queries/asset-type.query";
import { CreateOrUpdateAssetTypeRequest } from "../../queries/models/create-or-update-asset-type-request.model";
import { AssetTypesOverview } from "./AssetTypeOverview";
import { useTranslation } from "react-i18next";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";

export function AssetTypesComponent() {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [selectedAssetType, setSelectedAssetType] = useState<
    AssetType | undefined
  >(undefined);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const assetTypesQuery = useActiveAssetTypesQuery();
  const addAssetTypeMutation = useAddAssetTypeMutation();
  const updateAssetTypeMutation = useUpdateAssetTypeMutation();
  const deleteAssetTypeMutation = useDeleteAssetTypeMutation();

  const loading = assetTypesQuery.isLoading || assetTypesQuery.isFetching;

  const saveNewAssetType = useCallback(
    (data: CreateOrUpdateAssetType) => {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedAssetType(undefined);
          toast.current?.show({
            severity: "success",
            detail: t("common.saveSuccess"),
          });
          await queryClient.invalidateQueries("asset-types");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: CreateOrUpdateAssetTypeRequest = {
        name: data.name,
        comment: data.comment,
        textColor: `#${data.textColor}`,
        backgroundColor: `#${data.backgroundColor}`,
        icon: data.icon,
      };

      return addAssetTypeMutation.mutateAsync(request, mutateOptions);
    },
    [addAssetTypeMutation, queryClient, t, toast]
  );

  const editExistingAssetType = useCallback(
    (data: CreateOrUpdateAssetType) => {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedAssetType(undefined);
          toast.current?.show({
            severity: "success",
            detail: t("common.editSuccess"),
          });
          await queryClient.invalidateQueries("asset-types");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: CreateOrUpdateAssetTypeRequest = {
        id: data.id,
        name: data.name,
        comment: data.comment,
        textColor: `#${data.textColor}`,
        backgroundColor: `#${data.backgroundColor}`,
        icon: data.icon,
      };

      return updateAssetTypeMutation.mutateAsync(request, mutateOptions);
    },
    [queryClient, t, toast, updateAssetTypeMutation]
  );

  const handleSaveAssetType = useCallback(
    (data: CreateOrUpdateAssetType) =>
      data.id === 0 ? saveNewAssetType(data) : editExistingAssetType(data),
    [editExistingAssetType, saveNewAssetType]
  );

  const handleDeleteAssetType = useCallback(() => {
    if (selectedAssetType?.id) {
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: t("common.deleteSuccess"),
          });
          await queryClient.invalidateQueries("asset-types");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      deleteAssetTypeMutation.mutateAsync(selectedAssetType.id, mutateOptions);
    }
  }, [deleteAssetTypeMutation, queryClient, selectedAssetType?.id, t, toast]);

  const deleteConfirmationModalProps: CustomModalProps = {
    isOpen: isDeleteConfirmationModalOpen,
    onClose: () => setIsDeleteConfirmationModalOpen(false),
    header: t("common.confirmation"),
    body: selectedAssetType?.assets?.length
      ? t("dialog.assetTypeInUseDoYouReallyWantToDeleteThisAssetType")
      : t("dialog.deleteThisAssetType"),
    justified: true,
    centered: true,
    height: "max-content",
    confirmation: true,
    onConfirm: async () => {
      handleDeleteAssetType();
      setIsDeleteConfirmationModalOpen(false);
    },
  };

  return (
    <div className="h-full p-1 flex gap-2">
      <CustomModal {...deleteConfirmationModalProps} />
      <div className="h-full w-6">
        <RoundedShadowContainer
          medium
          fullHeight
        >
          <AssetTypesOverview
            items={assetTypesQuery.data}
            isLoading={assetTypesQuery.isLoading}
            selectedAssetType={selectedAssetType}
            setSelectedAssetType={setSelectedAssetType}
          />
        </RoundedShadowContainer>
      </div>
      <div className="h-full w-6">
        <RoundedShadowContainer
          medium
          fullHeight
        >
          <LoaderWrapper isLoading={loading}>
            <div className="h-full">
              {selectedAssetType ? (
                <AssetTypeForm
                  assetType={selectedAssetType}
                  onCancel={() => setSelectedAssetType(undefined)}
                  onSave={handleSaveAssetType}
                  onDelete={() => setIsDeleteConfirmationModalOpen(true)}
                />
              ) : (
                <div className="h-full flex justify-content-center align-items-center">
                  <FontAwesomeIcon
                    icon={faTag}
                    className="text-primary cursor-pointer"
                    style={{ fontSize: 270 }}
                    onClick={() =>
                      setSelectedAssetType({ name: "" } as AssetType)
                    }
                  />
                </div>
              )}
            </div>
          </LoaderWrapper>
        </RoundedShadowContainer>
      </div>
    </div>
  );
}
