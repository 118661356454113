import { useMutation, useQuery } from "react-query";
import { ordersValidatorService } from "../services/orders-validator.service";
import { UserMonthlyOrdersOverviewItem } from "./models/user-monthly-orders-overview.model";
import { UserMonthlyOrdersValidation } from "./models/user-monthly-orders-validation.model";
import { SendOrdersRequest } from "./models/send-orders-request";
import { UpdateWorkTimeRequest } from "./models/update-work-time-request.model";
import { ValidatedWorkInstance } from "./models/validated-work-instance.model";

async function fetchOverview(date: Date) {
    if (!date) {
        return [];
    } else {
        return await ordersValidatorService.getOverview(date);
    }
}

export function useOrdersValidatorUsersOverviewQuery(date: Date) {
    return useQuery<UserMonthlyOrdersOverviewItem[]>(
        ["ordersValidator", "overview", date],
        () => fetchOverview(date),
        { refetchOnWindowFocus: false }
    );
}

async function fetchUserValidation(userId: number | undefined, date: Date) {
    if (!userId || !date) {
        return;
    } else {
        return await ordersValidatorService.getUserValidation(userId, date);
    }
}

export function useOrdersValidatorUserValidationQuery(userId: number | undefined, date: Date) {
    return useQuery<UserMonthlyOrdersValidation | undefined>(
        ["ordersValidator", "userValidation", userId, date],
        () => fetchUserValidation(userId, date),
        { refetchOnWindowFocus: false }
    );
}

async function fetchWorkInstanceDetails(workInstanceId: number | undefined) {
    if (!workInstanceId) {
        return;
    } else {
        return await ordersValidatorService.getWorkInstance(workInstanceId);
    }
}

export function useWorkInstanceDetailsQuery(workInstanceId: number | undefined) {
    return useQuery<ValidatedWorkInstance | undefined>(
        ["ordersValidator", "workInstance", workInstanceId],
        () => fetchWorkInstanceDetails(workInstanceId),
        { refetchOnWindowFocus: false, enabled: workInstanceId !== undefined }
    );
}

export function useSendOrdersMutation() {
    return useMutation((request: SendOrdersRequest) => ordersValidatorService.sendOrders(request));
}
export function useUpdateWorkTimeMutation() {
    return useMutation((request: UpdateWorkTimeRequest) => ordersValidatorService.updateWorkTime(request));
}