import { BaseSyntheticEvent } from "react";
import { InputText } from "primereact/inputtext";

interface TableHeaderWithFilterProps {
  header: string;
  value: string;
  onChange: (e: BaseSyntheticEvent) => void;
}

export function TableHeaderWithFilter({
  header,
  value,
  onChange,
}: TableHeaderWithFilterProps) {
  return (
    <div className="flex justify-content-between align-items-center">
      <div className="text-xl font-bold">{header}</div>
      <InputText
        placeholder="Filter"
        value={value}
        onChange={onChange}
        className="w-6"
      />
    </div>
  );
}
