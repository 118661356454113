import { useMutation, useQuery, UseQueryOptions } from "react-query";
import { User } from "./models/user.model";
import { usersService } from "../services/users.service";
import { CreateUserRequest } from "./models/create-user-request.model";
import { UpdateUserRequest } from "./models/update-user-request";
import { CreateCompanyRequest } from "./models/create-company-request.model";

export function useUserQuery(id: number | undefined) {
  const queryOptions: UseQueryOptions<User> = {
    enabled: !!id,
  };

  return useQuery<User>(
    ["users", `user-${id}`],
    () => usersService.getUserById(id!),
    queryOptions
  );
}

export function useUsersQuery() {
  return useQuery<User[]>(["users"], () => usersService.getAllActiveUsers());
}

export function useUsersForUserScreenQuery() {
  return useQuery<User[]>(["users"], () =>
    usersService.getAllActiveUsersToUsersScreen()
  );
}

export function useActiveUsersAsSelectOptionQuery() {
  return useQuery(["users", "users-options"], () =>
    usersService.getAllActiveUsersAsSelectOptions()
  );
}

export function useAddUserMutation() {
  return useMutation((request: CreateUserRequest) =>
    usersService.addUser(request)
  );
}

export function useEditUserMutation() {
  return useMutation((request: UpdateUserRequest) =>
    usersService.editUser(request)
  );
}

export function useDeleteUserMutation() {
  return useMutation((id: number) => usersService.deleteUser(id));
}

export function useForceLoginAgainMutation() {
  return useMutation((id: number) => usersService.forceLoginAgainUser(id));
}

export function useAddCompanyMutation() {
  return useMutation((request: CreateCompanyRequest) =>
    usersService.addCompany(request)
  );
}
