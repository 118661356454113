import { BaseService } from "./base.service";
import { SelectItem } from "primereact/selectitem";
import { API_ENDPOINTS } from "../utils/endpoints";
import { Role } from "../queries/models/role-model";
import { CreateRoleRequest } from "../queries/models/create-role-request";
import { UpdateRoleRequest } from "../queries/models/update-role-request";

class RolesService extends BaseService {

    async getRoles(): Promise<Role[]> {
        const url = `${this.endpointPath}`;
        return this.http.get<Role[]>(url).then((response) => response.data);
    }

    async getRolesOptions(): Promise<SelectItem[]> {
        const url = `${this.endpointPath}/as-options`;
        return this.http.get<SelectItem[]>(url).then((response) => response.data);
    }

    async getById(id: number): Promise<Role> {
        const url = `${this.endpointPath}/${id}`;
        return this.http.get<Role>(url).then((response) => response.data);
    }

    async addRole(request: CreateRoleRequest) {
        const url = `${this.endpointPath}/create`;
        return this.http.post<number>(url, request).then((response) => response.data);
    }

    async editRole(request: UpdateRoleRequest) {
        const url = `${this.endpointPath}/update`;
        return this.http.put<number>(url, request).then((response) => response.data);
    }

    async deleteRole(id: number) {
        const url = `${this.endpointPath}/delete/${id}`;
        return this.http.delete(url).then((response) => response.data);
    }
}

export const rolesService = new RolesService(API_ENDPOINTS.ROLES);