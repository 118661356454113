import {
  LegacyRef,
  ReactEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useImageQuery,
  useNullableImageQuery,
} from "../../queries/resource.query";
import { AxiosProgressEvent } from "axios";
import { CustomProgressBar } from "./custom-progress-bar";
import { classNames } from "primereact/utils";

interface PrivateImageProps {
  imageSrc: string | Blob;
  maxWidth?: string;
  maxHeight?: string;
  withProgressBar?: boolean;
  progressBarClassName?: string;
  zoomLevel?: number;
  zoomOrigin?: string;
  imageRef?: LegacyRef<HTMLImageElement>;
  children?: ReactNode;
  onLoad?: ReactEventHandler<HTMLImageElement>;
  width?: string;
  height?: string;
  contained?: boolean;
}

export function PrivateImage({
  imageSrc,
  maxWidth,
  maxHeight,
  withProgressBar,
  progressBarClassName,
  zoomLevel,
  zoomOrigin,
  imageRef,
  children,
  onLoad,
  width,
  height,
  contained = true,
}: PrivateImageProps) {
  const [progress, setProgress] = useState<number | undefined>(
    withProgressBar ? 0 : undefined
  );
  const lastProgressUpdateRef = useRef<Date>();
  const onProgress = useCallback(
    (e: AxiosProgressEvent) => {
      const now = new Date();
      if (
        !lastProgressUpdateRef.current ||
        +now - +lastProgressUpdateRef.current >= 100 ||
        e.progress === 1 ||
        e.progress === undefined
      ) {
        lastProgressUpdateRef.current = now;
        setProgress(e.progress && e.progress * 100);
      }
    },
    [lastProgressUpdateRef]
  );

  const imageQuery = useNullableImageQuery(
    typeof imageSrc === "string" ? imageSrc : undefined,
    withProgressBar ? onProgress : undefined
  );

  useEffect(() => {
    if (withProgressBar && imageSrc) {
      setProgress(0);
    } else {
      setProgress(undefined);
    }
  }, [withProgressBar, imageSrc]);

  const [imageData, setImageData] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (imageSrc instanceof Blob) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageData(reader.result as string);
      };
      reader.readAsDataURL(imageSrc);
    } else {
      setImageData(imageQuery.data);
    }
  }, [imageQuery.data, imageSrc]);

  return (
    <div
      style={{ zIndex: -1 }}
      className={classNames(
        "h-full w-full justify-content-around align-items-center",
        contained && "flex"
      )}
    >
      {withProgressBar && progress !== undefined && imageQuery.isLoading && (
        <div className={progressBarClassName}>
          <CustomProgressBar
            value={progress}
            hidden={imageQuery.isFetched}
          />
        </div>
      )}
      {!imageQuery.isLoading && (
        <img
          className="fade-in"
          alt="Error"
          src={imageData}
          style={{
            maxWidth: maxWidth,
            maxHeight: maxHeight,
            transform: `translateZ(0) scale(${zoomLevel})`,
            transformOrigin: zoomOrigin,
            userSelect: "none",
          }}
          ref={imageRef}
          draggable={false}
          onLoad={onLoad}
          width={width}
          height={height}
        />
      )}
      {children}
    </div>
  );
}
