import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { useState } from "react";
import { RegisterForm } from "./RegisterForm";
import {
  bubbles1,
  bubbles2,
  bubbles3,
  bubbles4,
} from "../AnimationAssets/login-bubbles";
import { btnVariants, btnStyles } from "../FramerMotion/button-states";

export interface LoginFormProps {
  executeLogin: (
    username: string | undefined,
    password: string | undefined
  ) => void;
  isVisible: boolean;
  setIsVisible: (e: boolean) => void;
  navigateToForgotPassword: () => void;
  loginError: string | undefined;
  setLoginError: (error: any) => void;
  signUp: () => void;
}

export function LoginForm({
  executeLogin,
  navigateToForgotPassword,
  loginError,
  setLoginError,
  isVisible,
  setIsVisible,
  signUp,
}: LoginFormProps) {
  const [username, setUsername] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();

  const [companyRegistered, setCompanyRegistered] = useState<boolean>(false);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ x: "100%", opacity: 0.001 }}
        animate={{ x: "0%", opacity: 1 }}
        exit={{ x: "100%", opacity: 0.001 }}
        transition={{ ease: "circOut", duration: 0.6 }}
        className="w-full flex h-full"
        style={{ borderRadius: "0px 20px 20px 0px" }}
      >
        <div className="h-full w-full flex absolute">
          {bubbles4.map((e, index) => (
            <motion.div
              initial={{ x: e.x + Math.random() * (200 - 100) + 100 }}
              animate={{ x: 0 }}
              transition={{
                ease: "linear",
                duration: 0.6,
                delay: Math.random() * (0.5 - 0.3) + 0.1,
              }}
              key={index}
              className="absolute"
              style={{
                width: e.r,
                height: e.r,
                borderRadius: e.r,
                backgroundColor: e.color,
                marginLeft: e.x,
                marginTop: e.y,
                zIndex: 1,
                boxShadow:
                  "0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)",
              }}
            ></motion.div>
          ))}
          <div
            className=" h-full absolute"
            style={{ width: "calc(100% - 30px)", marginLeft: " 30px" }}
          >
            {bubbles3.map((e, index) => (
              <motion.div
                initial={{ x: e.x + Math.random() * (200 - 100) + 100 }}
                animate={{ x: 0 }}
                transition={{
                  ease: "linear",
                  duration: 0.4,
                  delay: Math.random() * (0.5 - 0.3) + 0.1,
                }}
                key={index}
                className="absolute"
                style={{
                  width: e.r,
                  height: e.r,
                  borderRadius: e.r,
                  backgroundColor: e.color,
                  marginLeft: e.x,
                  marginTop: e.y,
                  zIndex: 2,
                  boxShadow:
                    "0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)",
                }}
              ></motion.div>
            ))}
            <div
              className="h-full absolute"
              style={{
                width: "calc(100% - 30px)",
                marginLeft: " 30px",
                zIndex: 4,
              }}
            >
              {bubbles2.map((e, index) => (
                <motion.div
                  initial={{ x: e.x + Math.random() * (200 - 100) + 100 }}
                  animate={{ x: 0 }}
                  transition={{
                    ease: "linear",
                    duration: 0.4,
                    delay: Math.random() * (0.5 - 0.3) + 0.1,
                  }}
                  key={index}
                  className="absolute"
                  style={{
                    width: e.r,
                    height: e.r,
                    borderRadius: e.r,
                    backgroundColor: e.color,
                    marginLeft: e.x,
                    marginTop: e.y,
                    zIndex: 3,
                    boxShadow:
                      "0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)",
                  }}
                ></motion.div>
              ))}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
                className="absolute h-full w-full ml-3 z-4"
                style={{ backgroundColor: "#8d66f7" }}
              >
                <AnimatePresence>
                  {!isVisible && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 1 }}
                      className="h-full w-full"
                      style={{ padding: "50px 0px 70px 20px" }}
                    >
                      <RegisterForm
                        isVisible={!isVisible}
                        setIsVisible={setIsVisible}
                        companyRegistered={companyRegistered}
                        setCompanyRegistered={setCompanyRegistered}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
              <AnimatePresence>
                {isVisible && (
                  <motion.div
                    initial={{ x: "100%" }}
                    animate={{ x: "0%" }}
                    exit={{ x: "110%" }}
                    transition={{ ease: "easeInOut", duration: 0.6 }}
                    className="h-full absolute"
                    style={{
                      width: "calc(100% - 30px)",
                      marginLeft: " 30px",
                      zIndex: 7,
                    }}
                  >
                    {bubbles1.map((e, index) => (
                      <div
                        key={index}
                        className="absolute"
                        style={{
                          width: e.r,
                          height: e.r,
                          borderRadius: e.r,
                          backgroundColor: e.color,
                          marginLeft: e.x,
                          marginTop: e.y,
                          zIndex: 6,
                          boxShadow:
                            "0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)",
                        }}
                      ></div>
                    ))}
                    <div
                      className="h-full absolute"
                      style={{
                        width: "calc(100% - 30px)",
                        marginLeft: " 30px",
                        backgroundColor: "var(--primary-color)",
                        zIndex: 7,
                      }}
                    >
                      <div
                        className="w-full h-full relative overflow-hidden absolute"
                        style={{
                          backgroundColor: "var(--primary-color)",
                          borderRadius: "0px 20px 20px 0px",
                          paddingTop: "100px",
                          paddingLeft: "-40px",
                        }}
                      >
                        <div className="w-full flex pl-5 mt-2 flex-column">
                          <div
                            style={{ width: "75%", color: "white" }}
                            className="flex justify-content-center text-xl font-bolder mb-5"
                          >
                            Login to Admin Panel
                          </div>
                          <div style={{ width: "75%" }}>
                            <div
                              className=" font-semibold mb-1"
                              style={{ color: "white" }}
                            >
                              Username
                            </div>
                            <InputText
                              id="username"
                              className={classNames(
                                "w-full shadow-2",
                                loginError ? "p-invalid" : ""
                              )}
                              value={username ?? ""}
                              onChange={(e) => {
                                setUsername(e.target.value);
                                setLoginError(undefined);
                              }}
                            />
                          </div>
                          <div style={{ width: "75%" }}>
                            <div
                              className="w-full font-semibold mb-1"
                              style={{ color: "white" }}
                            >
                              Password
                            </div>
                            <Password
                              id="password"
                              className={classNames(
                                "w-full shadow-2",
                                loginError ? "p-invalid" : ""
                              )}
                              inputClassName="w-full"
                              value={password ?? ""}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                setLoginError(undefined);
                              }}
                              onKeyDown={(e) =>
                                e.key === "Enter"
                                  ? executeLogin(username, password)
                                  : {}
                              }
                              feedback={false}
                              toggleMask
                            />
                          </div>
                          <div style={{ width: "75%" }}>
                            <div className="w-full text-right mb-4">
                              {loginError && (
                                <div
                                  className="w-12 h-3rem text-red-500 mt-2 px-2 py-1 border-2 border-round-sm flex"
                                  style={{
                                    backgroundColor: "rgba(255,0,0,0.12)",
                                  }}
                                >
                                  <div className="w-2rem h-full flex align-items-center text-2xl justify-content-center">
                                    <FontAwesomeIcon
                                      icon={faCircleExclamation}
                                    />
                                  </div>
                                  <div className="w-full h-full flex justify-content-center align-items-center">
                                    {loginError}
                                  </div>
                                </div>
                              )}
                              <div
                                className="w-12 cursor-pointer"
                                onClick={navigateToForgotPassword}
                                style={{ color: "white" }}
                              >
                                Forgot password?
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ width: "75%" }}
                            className="flex gap-5 mb-5"
                          >
                            <AnimatePresence>
                              <motion.button
                                style={btnStyles.paOutlinedButton}
                                initial="iniOutlinedButton"
                                whileHover="hovOutlinedButton"
                                variants={btnVariants}
                                onClick={signUp}
                              >
                                Sign up
                              </motion.button>
                            </AnimatePresence>
                            <AnimatePresence>
                              <motion.button
                                style={btnStyles.paButton}
                                initial="iniButton"
                                whileHover="hovButton"
                                variants={btnVariants}
                                onClick={() => executeLogin(username, password)}
                              >
                                Sign in
                              </motion.button>
                            </AnimatePresence>
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
