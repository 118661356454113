interface CustomProgressBarProps {
  value: number;
  hidden?: boolean;
}

export function CustomProgressBar({
  value,
  hidden = false,
}: CustomProgressBarProps) {
  return (
    <div
      className="progressbar"
      hidden={hidden}>
      <div
        style={{
          height: "100%",
          width: `${value}%`,
          backgroundColor: "#7C4DFF",
          transition: "width 0.5s",
        }}></div>
      <span className="progressPercent">{`${Math.round(value)} %`}</span>
    </div>
  );
}
