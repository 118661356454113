import logo from "../../../assets/icons/logo.svg";
import { LoginFormProps } from "./LoginForm";
import { LoginMobileForm } from "./LoginMobileForm";

export function LoginMobile({
  executeLogin,
  navigateToForgotPassword,
  loginError,
  setLoginError,
  isVisible,
  setIsVisible,
  signUp,
}: LoginFormProps) {
  return (
    <div
      className="flex flex-column"
      style={{
        minHeight: "100vh",
      }}
    >
      <div className="w-full flex flex-column">
        <div
          className="w-full flex justify-content-center text-3xl my-4"
          style={{
            color: "var(--primary-color)",
            textShadow: "1px 1px 3px #BFBFBF ",
          }}
        >
          Welcome to
        </div>
        <div className="w-full flex justify-content-center">
          <img
            style={{ width: "100px", height: "100px" }}
            src={logo}
            alt="PurpleApp logo"
          />
        </div>
        <div
          className="w-full flex justify-content-center mt-2 text-xl"
          style={{
            color: "var(--primary-color)",
            textShadow: "1px 1px 2px #BFBFBF ",
          }}
        >
          PurpleApp
        </div>
      </div>
      <div className="w-full min-h-max flex flex-column flex-grow-1">
        <LoginMobileForm
          executeLogin={executeLogin}
          navigateToForgotPassword={navigateToForgotPassword}
          loginError={loginError}
          setLoginError={setLoginError}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          signUp={signUp}
        />
      </div>
    </div>
  );
}
