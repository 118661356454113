import { useMemo } from "react";
import {
  Field,
  ErrorMessage,
  FastField,
  FormikContextType,
  FieldInputProps,
  FieldMetaProps,
} from "formik";
import TextError from "./TextError";
import { FormInputLabel } from "./FormInputLabel";
import { classNames } from "primereact/utils";
import { InputNumber, InputNumberProps } from "primereact/inputnumber";
import * as Yup from "yup";
import { TimeSpan } from "../../../utils/timespan";

export interface FormikInputDurationProps extends InputNumberProps {
  label: string;
  name: string;
  validationSchema?: Yup.ObjectSchema<any, Yup.AnyObject, any, "">;
  isIndependent?: boolean;
  isRequired?: boolean;
}

function calculateOutputOnHoursChange(init: number, hours: number) {
  if (hours > 23) hours = 23;
  const minutes = TimeSpan.fromMinutes(TimeSpan.fromTicks(init).minutesFloor);
  return TimeSpan.fromHours(hours).ticks + minutes.ticks;
}
function calculateOutputOnMinutesChange(init: number, minutes: number) {
  if (minutes > 59) minutes = 59;
  const hours = TimeSpan.fromHours(TimeSpan.fromTicks(init).hoursFloor);

  return hours.ticks + TimeSpan.fromMinutes(minutes).ticks;
}

function FormikInputDuration({
  label,
  name,
  validationSchema,
  isIndependent = false,
  isRequired = undefined,
  ...rest
}: FormikInputDurationProps) {
  const Component = useMemo(
    () => (isIndependent ? FastField : Field),
    [isIndependent]
  );

  return (
    <>
      <FormInputLabel
        nameFor={name}
        validationSchema={validationSchema}
        forceIsRequired={isRequired}
      >
        {label}
      </FormInputLabel>
      <Component name={name}>
        {({
          form,
          field,
          meta,
        }: {
          form: FormikContextType<any>;
          field: FieldInputProps<number>;
          meta: FieldMetaProps<number>;
        }) => {
          return (
            <div className="flex w-full flex-row align-content-center">
              <InputNumber
                id={name}
                className={classNames({
                  "w-3rem": true,
                  "p-invalid": meta.error && meta.touched,
                })}
                min={0}
                max={23}
                maxLength={2}
                value={TimeSpan.fromTicks(field.value).hoursFloor}
                onBlur={(e) => form?.setFieldTouched(name, true)}
                onChange={(e) => {
                  form.setFieldValue(
                    name,
                    calculateOutputOnHoursChange(field.value, e.value ?? 0)
                  );
                }}
                {...rest}
              />
              <div className="px-1 flex align-self-center">h</div>
              <InputNumber
                id={name}
                className={classNames({
                  "w-3rem": true,
                  "p-invalid": meta.error && meta.touched,
                })}
                min={0}
                max={59}
                maxLength={2}
                value={TimeSpan.fromTicks(field.value).minutesFloor}
                onBlur={(e) => form?.setFieldTouched(name, true)}
                onChange={(e) => {
                  form.setFieldValue(
                    name,
                    calculateOutputOnMinutesChange(field.value, e.value ?? 0)
                  );
                }}
                {...rest}
              />
              <div className="px-1 flex align-self-center">min</div>
            </div>
          );
        }}
      </Component>
      <ErrorMessage
        component={TextError}
        name={name}
      />
    </>
  );
}

export default FormikInputDuration;
