import { motion } from "framer-motion";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import {
  useConfirmComapnyMutation,
  useGetCompanyByTokenQuery,
} from "../../queries/models/_shared/auth.query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useToast } from "../../components/ui/toast-context-provider";
import Truncate from "react-truncate-inside/es";
import { useTranslation } from "react-i18next";

export function ConfirmCompanyRegistration() {
  const { t } = useTranslation();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [queryParameters] = useSearchParams();
  const token = queryParameters.get("t");

  const mutation = useConfirmComapnyMutation();
  const companyQuery = useGetCompanyByTokenQuery(token!);
  const navigate = useNavigate();

  function executeMutation() {
    setIsLoading(true);
    mutation.mutate(
      {
        token: token!,
      },
      {
        onSuccess: (response) => {
          toast.current?.show({
            severity: "success",
            detail: t("dialog.everythingIsFine"),
          });
          setTimeout(() => {
            navigate("/");
          }, 2000);
        },
        onError: (error) => {
          toast.current?.show({
            severity: "error",
            detail: error?.toString(),
          });
          console.error(error);
        },
        onSettled: (x, y, z, c) => {
          setIsLoading(false);
        },
      }
    );
  }

  return (
    <div className="surface-300">
      <motion.div
        initial={{ opacity: 0.5 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: "backInOut" }}
        className="absolute flex h-full justify-content-center align-items-center"
        style={{
          width: "100%",
          height: "100%",
          zIndex: 1,
          background:
            "linear-gradient(93deg, rgba(124,77,255,0.5) 39%, rgba(193,163,236,0.05) 81%)",
        }}
      >
        <div
          className="grid grid-nogutter overflow-hidden"
          style={{
            width: "20rem",
            borderRadius: "20px",
            backgroundColor: "white",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <Card className="w-20rem border-round-2xl">
            <h2 className="mt-1 mb-4 text-center text-2xl">
              <Truncate text={`Hello ${companyQuery.data?.name ?? ""}!`} />
            </h2>
            <div className="mb-2">
              <div className="w-full font-semibold mb-1 text-primary  text-xl">
                {t("common.pleaseConfirmYourCompanyRegistration")}
              </div>
            </div>
            <Button
              label={t("common.confirm")}
              loading={isLoading}
              className="bg-primary w-full mt-2"
              onClick={executeMutation}
              disabled={!companyQuery?.data?.id}
            />
          </Card>
        </div>
      </motion.div>
    </div>
  );
}
