import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { Company } from "../queries/models/company-model";
import { CreateOrUpdateCompanyRequest } from "../queries/models/create-or-upodate-company-request";

class CompaniesService extends BaseService {
  async getAll(): Promise<Company[]> {
    const url = `${this.endpointPath}`;
    return this.http.get<Company[]>(url).then((response) => response.data);
  }

  async getAllActive(): Promise<Company[]> {
    const url = `${this.endpointPath}/active`;
    return this.http.get<Company[]>(url).then((response) => response.data);
  }

  async getById(id: number): Promise<Company> {
    const url = `${this.endpointPath}/${id}`;
    return this.http.get<Company>(url).then((response) => response.data);
  }

  async add(request: CreateOrUpdateCompanyRequest) {
    const url = `${this.endpointPath}`;
    return this.http
      .post<number>(url, request)
      .then((response) => response.data);
  }

  async edit(request: CreateOrUpdateCompanyRequest) {
    const url = `${this.endpointPath}`;
    return this.http
      .put<number>(url, request)
      .then((response) => response.data);
  }

  async deactivate(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.patch(url).then((response) => response.data);
  }

  async delete(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.delete(url).then((response) => response.data);
  }
}

export const companiesService = new CompaniesService(API_ENDPOINTS.COMPANIES);
