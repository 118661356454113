import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { CoordinateModel } from "../queries/models/coordinate-model";
import { GeocodeResult } from "../queries/models/geocode-result.model";

class GeolocationService extends BaseService {
  async getCoordinateModel(
    country: string,
    city: string,
    street: string
  ): Promise<CoordinateModel> {
    const url = `${this.endpointPath}/${encodeURI(
      country.replaceAll("/", "\\")
    )}/${encodeURI(city.replaceAll("/", "\\"))}/${encodeURI(
      street.replaceAll("/", "\\")
    )}`;
    return this.http
      .get<CoordinateModel>(url)
      .then((response) => response.data);
  }

  async getGeolocationFeedback(
    country: string,
    city: string,
    street: string
  ): Promise<GeocodeResult> {
    const url = `${this.endpointPath}/location-feedback/${encodeURI(
      country.replaceAll("/", "\\")
    )}/${encodeURI(city.replaceAll("/", "\\"))}/${encodeURI(
      street.replaceAll("/", "\\")
    )}`;
    return this.http.get<GeocodeResult>(url).then((response) => response.data);
  }
}

export const geolocationService = new GeolocationService(
  API_ENDPOINTS.GEOLOCATION
);
