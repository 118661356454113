import { useMemo } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { PrintingSettings } from "./models/priniting-settings";
import { FormHeader } from "./FormHeader";
import FormikInputNumber from "../../components/ui/formik/FormikInputNumber";
import FormikDropDown from "../../components/ui/formik/FormikDropdown";
import { SelectItem } from "primereact/selectitem";
import { PrintingQrPage } from "./models/printing-qr-page.model";
import { useTranslation } from "react-i18next";

interface PrintingSettingsFormProps {
  exportButtonDisabled: boolean;
  onGenerateQrCodes: (count: number) => Promise<void>;
  onExportToPdf: (values: PrintingSettings) => void;
}

export function PrintingSettingsForm({
  exportButtonDisabled,
  onGenerateQrCodes,
  onExportToPdf,
}: PrintingSettingsFormProps) {
  const { t } = useTranslation();
  const printingOptions: SelectItem[] = useMemo(
    () => [
      {
        label: "A3 Landscape",
        value: new PrintingQrPage("a3", 297, 420, "l", 6, 6, 5, 5, 45, 4),
      },
      {
        label: "A4 Landscape",
        value: new PrintingQrPage("a4", 210, 297, "l", 6, 6, 5, 5, 45, 4),
      },
      {
        label: "A5 Landscape",
        value: new PrintingQrPage("a5", 148, 210, "l", 6, 6, 5, 5, 45, 4),
      },
      {
        label: "A3 Portrait",
        value: new PrintingQrPage("a3", 420, 297, "p", 6, 6, 5, 5, 45, 4),
      },
      {
        label: "A4 Portrait",
        value: new PrintingQrPage("a4", 297, 210, "p", 6, 6, 5, 5, 45, 4),
      },
      {
        label: "A5 Portrait",
        value: new PrintingQrPage("a5", 210, 148, "p", 6, 6, 5, 5, 45, 4),
      },
    ],
    []
  );

  const initialValues: PrintingSettings = {
    count: 1,
    setting: printingOptions[1].value,
  };

  const validationSchema = Yup.object({
    count: Yup.number()
      .min(1, t("alert.minimalValueIs1"))
      .required(t("alert.required")),
    setting: Yup.object().required(t("alert.required")),
  });

  return (
    <div className="h-full p-1 border-round-xl">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onExportToPdf}
        enableReinitialize
        validateOnChange
        validateOnMount
      >
        {(formik) => (
          <Form>
            <FormHeader
              header={t("common.printingSettings")}
              generateButtonDisabled={!!formik.getFieldMeta("count").error}
              exportToPdfButtonDisabled={exportButtonDisabled}
              onGenerateClick={() => onGenerateQrCodes(formik.values["count"])}
            />
            <div className="grid p-1">
              <div className="col-6">
                <FormikInputNumber
                  validationSchema={validationSchema}
                  label={t("common.count")}
                  name="count"
                  isIndependent
                />
              </div>

              <div className="col-6">
                <FormikDropDown
                  validationSchema={validationSchema}
                  label={t("common.setting")}
                  name="setting"
                  options={printingOptions}
                  isIndependent
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
