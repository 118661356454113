import { useEffect, useMemo, useRef } from "react";
import { ValidatedWorkInstance } from "../../queries/models/validated-work-instance.model";
import Enumerable from "linq";
import { getUnixTime } from "date-fns";
import { TimeSpan } from "../../utils/timespan";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import { LocationLog } from "../../queries/models/location-log.model";
import { WorkInstance } from "../../queries/models/work-instance.model";
import { WorktimeChartXData } from "../params/worktime-chart-xdata";

export interface WorkTimeChartComponentProps {
  className?: string;
  workInstance: WorkInstance | ValidatedWorkInstance | undefined;
  showTooltip?: boolean;
  currentPosition?: LocationLog;
  onHover?: (x: LocationLog | undefined) => void;
}

export function WorkTimeChartComponent({
  workInstance,
  showTooltip = true,
  currentPosition,
  onHover,
  className = "w-full h-10rem p-0 m-0",
}: WorkTimeChartComponentProps) {
  const chartOptions = useMemo(() => {
    const durations = workInstance?.summary!.workDurations!;
    const eventDurationData = Enumerable.from(durations).select((d) => {
      return {
        from: getUnixTime(new Date(d.startDate)) * 1000,
        to: getUnixTime(new Date(d.endDate)) * 1000,
        color: d.color,
        label: {
          text:
            d.label +
            " (" +
            TimeSpan.fromTicks(d.durationTicks).toHhMmSs() +
            ")",
        },
      };
    });
    const addressPresences = workInstance?.summary!.addressPresences!;
    const addressPresencesData = Enumerable.from(addressPresences).select(
      (d) => {
        return {
          from: getUnixTime(new Date(d.dateStart)) * 1000,
          to: getUnixTime(new Date(d.dateEnd)) * 1000,
          color: d.isIn ? "#00796B" : "#D32F2F",
          label: {
            text: d.isIn ? "In address" : "Outside address",
          },
        };
      }
    );

    let locationData: Enumerable.IEnumerable<[number, number]> =
      Enumerable.from(
        workInstance?.locationLogs.map(
          (z) =>
            [getUnixTime(new Date(z.date)) * 1000, 0] satisfies [number, number]
        ) ?? []
      );
    const eventDurationDataInterpolation: [number, number][] = [];
    eventDurationData.forEach((x) => {
      const closestSpeedFrom = locationData.minBy((y) =>
        Math.abs(y[0] - x.from)
      );
      eventDurationDataInterpolation.push([x.from, closestSpeedFrom[1]]);
      const closestSpeedTo = locationData.minBy((y) => Math.abs(y[0] - x.to));
      eventDurationDataInterpolation.push([x.to, closestSpeedTo[1]]);
    });

    const addressPresencesDataInterpolation: [number, number][] = [];
    addressPresencesData.forEach((x) => {
      const closestSpeedFrom = locationData.minBy((y) =>
        Math.abs(y[0] - x.from)
      );
      addressPresencesDataInterpolation.push([x.from, closestSpeedFrom[1]]);
      const closestSpeedTo = locationData.minBy((y) => Math.abs(y[0] - x.to));
      addressPresencesDataInterpolation.push([x.to, closestSpeedTo[1]]);
    });

    locationData = locationData
      .concat(eventDurationDataInterpolation)
      .concat(addressPresencesDataInterpolation)
      .orderBy((x) => x[0])
      .distinct((x) => x[0]);

    const activitiesSeries = eventDurationData
      .select((band) => {
        const xAxisData = locationData.where(
          (x) => x[0] >= band.from && x[0] <= band.to
        );
        const bandData = xAxisData
          .select((b) => [b[0], -1000])
          .distinct((x) => x[0])
          .toArray();

        const serie = {
          data: bandData,
          name: band.label.text,
          color: band.color,
          yAxisIndex: 0,
          type: "line",
          step: "left",
          symbol: "none",
          smooth: false,
          animation: false,
          areaStyle: {
            animation: false,
          },
        };
        return serie;
      })
      .toArray();

    const addressPresenceSerie = addressPresencesData
      .select((band) => {
        const xAxisData = locationData.where(
          (x) => x[0] >= band.from && x[0] <= band.to
        );
        const bandData = xAxisData
          .select((b) => [b[0], 1000])
          .distinct((x) => x[0])
          .toArray();

        const serie = {
          data: bandData,
          name: band.label.text,
          color: band.color,
          yAxisIndex: 0,
          type: "line",
          step: "left",
          symbol: "none",
          smooth: false,
          animation: false,
          areaStyle: {
            animation: false,
          },
        };
        return serie;
      })
      .toArray();

    const formatter = function (params: any, _ticket: any, _callback: any) {
      if (params instanceof Array) {
        if (params.length) {
          let message = "";
          message += `<b>${params[0].axisValueLabel}</b>`;
          params.forEach((param) => {
            message += `<br/>${param.marker}${param.seriesName}`; // : ${param.value}${param.data.unit || ""}
          });
          return message;
        } else {
          return null;
        }
      } else {
        let message = "";
        message += `${params[0].axisValueLabel}`;
        message += `<br/>${params.marker}${params.seriesName}: ${params.value}${
          params.data.unit || ""
        }`;
        return message;
      }
    };

    return {
      grid: { top: 8, right: 8, bottom: 64, left: 8 },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
        show: false,
      },
      dataZoom: [
        {
          type: "slider",
          xAxisIndex: 0,
          filterMode: "none",
        },
      ],
      series: [...activitiesSeries, ...addressPresenceSerie],
      tooltip: {
        show: showTooltip,
        trigger: "axis",
        formatter: formatter,
      },
      axisPointer: {
        lineStyle: {
          color: "#000",
          width: 2.4,
          shadowColor: "#FFF",
          shadowBlur: 3,
        },
      },
    } as EChartsOption;
  }, [showTooltip, workInstance?.locationLogs, workInstance?.summary]);

  const xLocations = useMemo(() => {
    return workInstance === undefined
      ? []
      : workInstance.locationLogs.map(
          (ll) =>
            ({
              unixTimestamp: getUnixTime(new Date(ll.date)) * 1000,
              locationLog: ll,
            } as WorktimeChartXData)
        );
  }, [workInstance]);

  const chartInstance = useRef<ReactECharts>(null);
  useEffect(() => {
    if (chartInstance.current === undefined) {
      return;
    }
    const currentInstance = chartInstance.current?.getEchartsInstance();
    if (!currentInstance) {
      return;
    }

    const mouseout = (params: any) => {
      if (onHover !== undefined) {
        onHover(undefined);
      }
    };

    const handleHighlight = (e: {
      escapeConnect?: boolean;
      seriesIndex?: number;
      dataIndex?: number;
      type: string;
      batch?: {
        dataIndex: number;
        escapeConnect: boolean;
        seriesIndex: number;
        type: string;
      }[];
    }) => {
      if (!onHover) return;
      let entry: any = undefined;
      if (!e) return;
      if (e.seriesIndex !== undefined && e.dataIndex !== undefined) {
        entry = chartOptions?.series?.[e.seriesIndex]?.data?.[e.dataIndex];
      } else if (e.batch) {
        // It doesn't matter which batch element you take, as it is for same date, so the first should be fine
        const batchElement = e.batch?.[1];
        if (!batchElement) return;
        entry =
          chartOptions?.series?.[batchElement.seriesIndex]?.data?.[
            batchElement.dataIndex
          ];
      }
      if (!entry) return;
      const timeStamp = entry[0];
      const locationLog = xLocations.find(
        (x) => x.unixTimestamp === timeStamp
      )?.locationLog;
      onHover(locationLog);
    };

    currentInstance.on("highlight", handleHighlight as any);
    const zr = currentInstance.getZr();
    zr.on("mouseout", mouseout);
    return () => {
      try {
        currentInstance?.off("highlight", handleHighlight);
        zr?.off("mouseout", mouseout);
      } catch {}
    };
  }, [onHover, chartOptions?.series, xLocations]);

  useEffect(() => {
    const myChart = chartInstance.current?.getEchartsInstance();
    if (!myChart) return;

    if (!currentPosition) {
      myChart.dispatchAction({
        type: "hideTip",
      });
      return;
    }
    let serieIndex = -1;
    let currentIndex = -1;
    const currentDate = getUnixTime(new Date(currentPosition.date)) * 1000;
    for (let i = 0; i < chartOptions?.series?.length ?? 0; i++) {
      const serie = chartOptions?.series[i];
      if (!serie) continue;
      for (let j = 0; j < serie.data?.length ?? 0; j++) {
        const date = serie.data[j][0];
        if (!date || currentDate !== date) continue;
        serieIndex = i;
        currentIndex = j;
        break;
      }
      if (currentIndex !== -1) break;
    }

    if (currentIndex === -1) return;

    myChart.dispatchAction({
      type: "downplay",
      seriesIndex: serieIndex,
      dataIndex: currentIndex,
    });
    myChart.dispatchAction({
      type: "highlight",
      seriesIndex: serieIndex,
      dataIndex: currentIndex,
    });
    myChart.dispatchAction({
      type: "showTip",
      seriesIndex: serieIndex,
      dataIndex: currentIndex,
    });
  }, [chartOptions?.series, currentPosition]);

  return (
    <ReactECharts
      ref={chartInstance}
      className={className}
      option={chartOptions}
      notMerge={true}
      lazyUpdate={true}
    />
  );
}
