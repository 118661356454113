import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "primereact/tooltip";
import {
  MutableRefObject,
  SyntheticEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { PrivateImage } from "./PrivateImage";
import { IconName } from "@fortawesome/fontawesome-common-types";
import {
  MoveScalePlane,
  MoveScalePoint,
  movePointersOnPlane,
  scaleAround,
} from "../../utils/moveScalePlane";
import { DraggableItemTypes } from "../../constants/draggable-item-types.constants";

const draggableBackgroundEventsClassName = "draggableBackgroundEvents";

type PinBase = {
  id: number | string;
  name: string;
  color?: string;
  codes?: string[];
  guid?: string;
  asText?: boolean;
  icon?: IconName;
};

export interface UnattachedPin extends PinBase {
  x?: number;
  y?: number;
  unattached: true;
}

export interface AttachedPin extends PinBase {
  x: number;
  y: number;
  unattached?: false;
}

export type Pin = AttachedPin | UnattachedPin;

function fromPixelPosition(
  monitor: DropTargetMonitor,
  imageRef: MutableRefObject<HTMLImageElement | null>
) {
  const clientOffset = monitor.getClientOffset();
  const imageRect = imageRef.current?.getBoundingClientRect();
  if (!imageRect || !clientOffset) return { x: undefined, y: undefined };

  const x = (clientOffset.x - imageRect.x) / imageRect.width;
  const y = (clientOffset.y - imageRect.y) / imageRect.height;
  return { x, y };
}

export const DraggablePin = memo(
  ({
    pin,
    onAttach,
    disabled,
    onClick,
    scale,
    onOutsideDrop,
    setIconId = false,
    transition,
  }: {
    pin: Pin;
    onAttach?: (thisPin: Pin) => void;
    disabled?: boolean;
    onClick?: (thisPin: Pin) => void;
    scale?: number;
    onOutsideDrop?: (thisPin: Pin) => void;
    setIconId?: boolean;
    transition?: string;
  }) => {
    const [{ isDragging }, drag] = useDrag(
      () => ({
        type: DraggableItemTypes.PIN,
        collect: (monitor) => ({
          isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
          if (item.unattached && monitor.didDrop()) {
            onAttach?.(item);
          } else if (!item.unattached && !monitor.didDrop()) {
            onOutsideDrop?.(item);
          }
        },
        item: pin,
      }),
      [pin, onAttach]
    );

    const clickHandler = useCallback(() => {
      onClick?.(pin);
    }, [pin, onClick]);

    return (
      <span
        ref={disabled ? undefined : drag}
        style={{
          display: isDragging && !pin.unattached ? "none" : undefined,
          position: pin.unattached ? "relative" : "absolute",
          left: pin.unattached ? undefined : pin.x * 100 + "%",
          top: pin.unattached ? undefined : pin.y * 100 + "%",
          pointerEvents: "all",
          cursor: disabled ? onClick && "pointer" : "grab",
          transform: pin.unattached
            ? undefined
            : `translateZ(0) translate(-50%, -100%) scale(${scale})`,
          transformOrigin: "bottom center",
          transition: transition,
        }}
        onClick={clickHandler}
      >
        {!pin.asText ? (
          <>
            <FontAwesomeIcon
              icon={pin.icon ?? "location-pin"}
              size="2xl"
              id={setIconId ? "pin" + pin.id : undefined}
              color={pin.color}
            />
            {!isDragging && (
              <Tooltip
                target={"#pin" + pin.id}
                position="top"
              >
                <div className="flex flex-column justify-content-center align-items-center gap-1">
                  <div>{pin.name}</div>
                  {pin.codes?.map((x, i) => (
                    <span key={i}>
                      <FontAwesomeIcon
                        icon="qrcode"
                        className="mr-2"
                      />
                      {x}
                    </span>
                  ))}
                </div>
              </Tooltip>
            )}
          </>
        ) : (
          <div
            style={{
              backgroundColor: pin.color,
              backdropFilter: "blur(1px)",
              borderRadius: "0.5rem",
              padding: "0.25rem",
              textAlign: "center",
            }}
          >
            <p
              className="m-0 p-0"
              style={{
                textShadow:
                  "1px -1px #000, -1px -1px #000, 1px 1px #000, -1px 1px #000, -1px 0px #000, 1px 0px #000, 0px 1px #000, 0px -1px #000",
                color: "white",
                fontWeight: "bold",
                fontSize: "0.8rem",
                letterSpacing: 1,
              }}
            >
              {pin.name}
            </p>
          </div>
        )}
      </span>
    );
  }
);

export const DragDropContainer = memo(
  ({
    pins: defaultPins = [],
    onPinsChange,
    onPinChange,
    onPinOutsideDrop,
    onClickPin,
    imageSrc,
  }: {
    pins?: AttachedPin[];
    onPinsChange?: (pins: AttachedPin[]) => void;
    onPinChange?: (pin: AttachedPin) => void;
    onPinOutsideDrop?: (pin: AttachedPin) => void;
    onClickPin?: (pin: AttachedPin) => void;
    imageSrc: string;
  }) => {
    const [pins, setPins] = useState<AttachedPin[]>(defaultPins);
    useEffect(() => {
      setPins(defaultPins);
    }, [defaultPins]);

    const imageRef = useRef<HTMLImageElement | null>(null);
    const [maxZoom, setMaxZoom] = useState(4);

    const movePin = useCallback(
      (item: Pin, x: number, y: number) => {
        const index = pins.findIndex((pin) => item.id === pin.id);
        const newPin: AttachedPin = { ...item, x, y, unattached: false };
        let result = [...pins];
        if (index < 0) {
          result.push(newPin);
        } else {
          result[index] = newPin;
        }
        setPins(result);
        onPinsChange?.(result);
        onPinChange?.(newPin);
        return newPin;
      },
      [pins, onPinsChange, onPinChange]
    );

    const [, drop] = useDrop<Pin>(() => {
      return {
        accept: DraggableItemTypes.PIN,
        drop: (item, monitor) => {
          const { x, y } = fromPixelPosition(monitor, imageRef);
          if (x === undefined || y === undefined) return item;
          movePin(item, x, y);
        },
      };
    }, [movePin]);

    const fillRef = useCallback(
      (ref: HTMLImageElement | null) => {
        imageRef.current = ref;
        drop(ref);
      },
      [drop, imageRef]
    );

    // styling part --------------------------------------------------------------------

    const maxContainerRef = useRef<HTMLDivElement | null>(null);
    const [fixedSize, setFixedSize] = useState<{
      width: number;
      height: number;
    }>();

    const recalculateImageContainerFixedSize = useCallback(
      (imgLoadEvent?: SyntheticEvent<HTMLImageElement, Event>) => {
        if (imageRef.current) {
          setMaxZoom(
            4 *
              Math.max(
                1,
                imageRef.current.naturalWidth / window.innerWidth,
                imageRef.current.naturalHeight / window.innerHeight
              )
          );
        }

        if (!imageRef.current || !maxContainerRef.current) return undefined;

        const containerRect = maxContainerRef.current.getBoundingClientRect();

        const containerWidth = containerRect.width;
        const imgWidth = imageRef.current.naturalWidth;
        const containerHeight = containerRect.height;
        const imgHeight = imageRef.current.naturalHeight;

        const containerRatio = containerWidth / containerHeight;
        const imgRatio = imgWidth / imgHeight;

        if (containerRatio > imgRatio) {
          const newHeight = containerHeight;
          const newWidth = newHeight * imgRatio;
          setFixedSize({
            width: newWidth,
            height: newHeight,
          });
        } else {
          const newWidth = containerWidth;
          const newHeight = newWidth / imgRatio;
          setFixedSize({
            width: newWidth,
            height: newHeight,
          });
        }
      },
      []
    );

    useEffect(() => {
      if (maxContainerRef.current) {
        const element = maxContainerRef.current;
        const observer = new ResizeObserver(() =>
          recalculateImageContainerFixedSize()
        );
        observer.observe(element);

        recalculateImageContainerFixedSize();

        return () => {
          observer.unobserve(element);
        };
      }
    }, [recalculateImageContainerFixedSize]);

    // Zooming and moving view part --------------------------------------------------------------

    const eventContainerRef = useRef<HTMLDivElement | null>(null);

    const [moving, setMoving] = useState(false);
    const movingRef = useRef(moving);
    const [moveAndScale, setMoveAndScale] = useState<MoveScalePlane>({
      scale: 1,
      transform: { x: 0, y: 0 },
    });
    const moveAndScaleRef = useRef(moveAndScale);
    const pointersRef = useRef<MoveScalePoint[]>([]);
    const initialPointersRef = useRef<MoveScalePoint[]>();
    const initialPlaneRef = useRef<MoveScalePlane>();

    function toCenteredPercentageCoordinates(
      position: { x: number; y: number },
      relativeTo: {
        x: number;
        y: number;
        width: number;
        height: number;
      }
    ): { x: number; y: number } {
      return {
        x: (100 * (position.x - relativeTo.x)) / relativeTo.width - 50,
        y: (100 * (position.y - relativeTo.y)) / relativeTo.height - 50,
      };
    }

    function fromCenteredPercentageCoordinates(
      relativePosition: { x: number; y: number },
      originalRelativeTo: {
        x: number;
        y: number;
        width: number;
        height: number;
      }
    ): { x: number; y: number } {
      return {
        x:
          ((relativePosition.x + 50) * originalRelativeTo.width) / 100 +
          originalRelativeTo.x,
        y:
          ((relativePosition.y + 50) * originalRelativeTo.height) / 100 +
          originalRelativeTo.y,
      };
    }

    const lastImageRectRef = useRef<{
      x: number;
      y: number;
      width: number;
      height: number;
    }>();
    const pointerEventToImageCoordinatePercent = useCallback(
      (e: PointerEvent | WheelEvent) => {
        if (!imageRef.current) return undefined;
        lastImageRectRef.current ??= imageRef.current.getBoundingClientRect();

        const rect = lastImageRectRef.current;
        const result = {
          ...toCenteredPercentageCoordinates(e, rect),
          id: "pointerId" in e ? e.pointerId : undefined,
        };
        return result;
      },
      []
    );

    const updatePointers = useCallback(() => {
      const newRect = imageRef.current?.getBoundingClientRect();
      if (newRect && lastImageRectRef.current) {
        pointersRef.current = pointersRef.current.map((pointer) => ({
          ...toCenteredPercentageCoordinates(
            fromCenteredPercentageCoordinates(
              pointer,
              lastImageRectRef.current!
            ),
            newRect
          ),
          id: pointer.id,
        }));
      }
      lastImageRectRef.current = newRect;
      if (pointersRef.current.length > 0 && pointersRef.current.length < 3) {
        initialPointersRef.current = structuredClone(pointersRef.current);
        initialPlaneRef.current = structuredClone(moveAndScaleRef.current);
        movingRef.current = true;
      } else {
        initialPointersRef.current = undefined;
        initialPlaneRef.current = undefined;
        pointersRef.current = [];
        movingRef.current = false;
      }
      setMoving(movingRef.current);
    }, []);

    const changeZoom = useCallback(
      (by: number, around?: MoveScalePoint): boolean => {
        const oldScale = moveAndScaleRef.current.scale;

        moveAndScaleRef.current = scaleAround(
          around ?? moveAndScaleRef.current.transform,
          by,
          initialPlaneRef.current ?? moveAndScaleRef.current,
          {
            scale: [1, maxZoom],
            x: [-50, 50],
            y: [-50, 50],
          }
        );
        setMoveAndScale(moveAndScaleRef.current);
        updatePointers();
        return moveAndScaleRef.current.scale !== oldScale;
      },
      [maxZoom, updatePointers]
    );

    const onWheel = useCallback(
      function (this: HTMLElement, e: WheelEvent) {
        updatePointers();
        const changedZoom = changeZoom(
          e.deltaY < 0 ? 2 : 0.5,
          pointerEventToImageCoordinatePercent(e)
        );
        setTimeout(() => {
          // fix for when moving with mouse and using wheel:
          // let it update automatically on next calculation, after render
          // in this render might be different when changed scale
          lastImageRectRef.current = undefined;
        }, 10);
        if (changedZoom && e.cancelable) {
          e.preventDefault();
        }
      },
      [changeZoom, pointerEventToImageCoordinatePercent, updatePointers]
    );

    const lastPointerMoveAnimationFrameRef = useRef<number>();
    const onPointerMove = useCallback(
      function (this: HTMLElement, e: PointerEvent) {
        let pointUpdated = false;
        for (let i = 0; i < pointersRef.current.length; i++) {
          if (pointersRef.current[i].id === e.pointerId) {
            pointUpdated = true;
            const updatePoint = pointerEventToImageCoordinatePercent(e);
            if (updatePoint) pointersRef.current[i] = updatePoint;
            else pointersRef.current = [];
          }
        }
        if (!pointUpdated) return;

        if (lastPointerMoveAnimationFrameRef.current)
          cancelAnimationFrame(lastPointerMoveAnimationFrameRef.current);
        lastPointerMoveAnimationFrameRef.current = requestAnimationFrame(() => {
          if (
            !initialPointersRef.current?.length ||
            !pointersRef.current.length
          )
            return;

          moveAndScaleRef.current = movePointersOnPlane(
            initialPointersRef.current,
            pointersRef.current,
            initialPlaneRef.current,
            {
              scale: [1, maxZoom],
              x: [-50, 50],
              y: [-50, 50],
            }
          );
          setMoveAndScale(moveAndScaleRef.current);
          lastPointerMoveAnimationFrameRef.current = undefined;
        });
      },
      [pointerEventToImageCoordinatePercent, maxZoom]
    );

    const onPointerUp = useCallback(
      function (this: HTMLElement, e: PointerEvent) {
        pointersRef.current = pointersRef.current.filter(
          (x) => x.id !== e.pointerId
        );
        updatePointers();
      },
      [updatePointers]
    );

    const onPointerDown = useCallback(
      function (this: HTMLElement, e: PointerEvent) {
        const targetName = (e.target as HTMLElement)?.tagName?.toLowerCase();
        const targetClassList = (e.target as HTMLElement)?.classList;
        if (
          movingRef.current ||
          targetName === "img" ||
          targetClassList?.contains(draggableBackgroundEventsClassName)
        ) {
          eventContainerRef.current?.setPointerCapture(e.pointerId);
          const newPoint = pointerEventToImageCoordinatePercent(e);
          if (newPoint) pointersRef.current.push(newPoint);
          else pointersRef.current = [];
          updatePointers();
        }
      },
      [pointerEventToImageCoordinatePercent, updatePointers]
    );

    useEffect(() => {
      if (eventContainerRef.current) {
        const element = eventContainerRef.current;
        element.addEventListener("wheel", onWheel);
        element.addEventListener("pointerdown", onPointerDown);
        element.addEventListener("pointermove", onPointerMove);
        element.addEventListener("pointerup", onPointerUp);
        element.addEventListener("pointercancel", onPointerUp);
        element.addEventListener("lostpointercapture", onPointerUp);

        return () => {
          element.removeEventListener("wheel", onWheel);
          element.removeEventListener("pointerdown", onPointerDown);
          element.removeEventListener("pointermove", onPointerMove);
          element.removeEventListener("pointerup", onPointerUp);
          element.removeEventListener("pointercancel", onPointerUp);
          element.removeEventListener("lostpointercapture", onPointerUp);
        };
      }
    }, [onWheel, onPointerDown, onPointerMove, onPointerUp]);

    return (
      <div
        style={{
          padding: "2rem",
          backgroundColor: "Lavender",
          backgroundSize: `${moveAndScale.scale / 2}rem ${
            moveAndScale.scale / 2
          }rem`,
          backgroundImage:
            "linear-gradient(to right, gainsboro 1px, transparent 1px), linear-gradient(to bottom, gainsboro 1px, transparent 1px)",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          transition: moving ? "" : "0.1s linear",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          userSelect: "none",
          WebkitUserSelect: "none",
          WebkitTouchCallout: "none",
          touchAction: "none",
          transform: "translateZ(0)",
        }}
        draggable={false}
        ref={eventContainerRef}
        className={draggableBackgroundEventsClassName}
        onContextMenu={(e) => e.preventDefault()}
        onContextMenuCapture={(e) => e.preventDefault()}
      >
        <div
          ref={maxContainerRef}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transform: "translateZ(0)",
          }}
          className={draggableBackgroundEventsClassName}
        >
          <div
            style={{
              position: "relative",
              width: fixedSize ? fixedSize.width + "px" : "100%",
              height: fixedSize ? fixedSize.height + "px" : "100%",
              backfaceVisibility: "hidden",
              transform: `translateZ(0) scale(${moveAndScale.scale}) translate(${moveAndScale.transform.x}%, ${moveAndScale.transform.y}%)`,
              transformOrigin: `center`,
              transition: moving ? "" : "0.1s linear",
            }}
            className={draggableBackgroundEventsClassName}
          >
            <PrivateImage
              imageSrc={imageSrc}
              imageRef={fillRef}
              withProgressBar
              progressBarClassName="w-full absolute left-0 bottom-50"
              onLoad={recalculateImageContainerFixedSize}
              contained={false}
              //-----
              //ios blurry image fix.
              //WARNING!!! only on ios: too much width will crash image or browser page, too little will make it blurry
              width={`${maxZoom * 100}%`}
              zoomLevel={1 / maxZoom}
              zoomOrigin="0 0"
              //-----
            />
            {fixedSize !== undefined &&
              pins.map((x) => (
                <DraggablePin
                  pin={x}
                  key={x.id}
                  scale={1 / moveAndScale.scale}
                  onOutsideDrop={(pin) =>
                    onPinOutsideDrop?.({
                      ...pin,
                      x: pin.x!,
                      y: pin.y!,
                      unattached: false,
                    })
                  }
                  onClick={onClickPin as (thisPin: Pin) => void}
                  setIconId
                  transition={moving ? "" : "0.1s linear"}
                  disabled={moving}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }
);
