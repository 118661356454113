import { useMutation, useQuery } from "react-query";
import { SelectItem } from "primereact/selectitem";
import { languagesService } from "../services/languages.service";
import { Language } from "./models/language.model";

export function useGetAllLanguagesQuery() {
  return useQuery<Language[]>(["languages"], () =>
    languagesService.getAllLanguages()
  );
}

export function useLanguagesOptionsQuery() {
  return useQuery<SelectItem[]>(["languages-options"], () =>
    languagesService.getAllLanguagesAsSelectOptions()
  );
}

export function usePublishedLanguagesOptionsQuery() {
  return useQuery<SelectItem[]>(["languages"], () =>
    languagesService.getPublishLanguagesAsSelectOptions()
  );
}

export function useSelectedLanguagesQuery() {
  return useQuery<Language[]>(["selected-languages"], () =>
    languagesService.getAllSelectedLanguages()
  );
}

export function useSelectSingleLanguageMutation() {
  return useMutation((id: number) => languagesService.selectSingleLanguage(id));
}

export function useUnselectSingleLanguageMutation() {
  return useMutation((id: number) =>
    languagesService.unselectSingleLanguage(id)
  );
}

export function usePublishSingleLanguageMutation() {
  return useMutation((id: number) =>
    languagesService.publishSingleLanguage(id)
  );
}

export function useUnpublishSingleLanguageMutation() {
  return useMutation((id: number) =>
    languagesService.unpublishSingleLanguage(id)
  );
}
