export class FileExtension {
  static downloadBlob(data: any, fileName: string): void {
    const a = document.createElement("a");
    document.body.appendChild(a);
    // tslint:disable-next-line:one-variable-per-declaration
    const blob = new Blob([data]),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
