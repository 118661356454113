//PurpleApp Styles (pas) for motion framer animations
export const btnStyles = {
  paOutlinedButton: {
    fontWeight: "700",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, `Segoe UI`, Roboto, Helvetica, Arial, sans-serif, `Apple Color Emoji`, `Segoe UI Emoji`, `Segoe UI Symbol`",
    width: "100%",
    padding: "8px 16px 8px 16px",
  },
  paButton: {
    fontWeight: "700",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    borderRadius: "3px",
    border: "0",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, `Segoe UI`, Roboto, Helvetica, Arial, sans-serif, `Apple Color Emoji`, `Segoe UI Emoji`, `Segoe UI Symbol`",
    width: "100%",
    padding: "8px 16px 8px 16px",
  },
  paButtonDisabled: {
    filter: "brightness(0.8)",
    fontWeight: "700",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    borderRadius: "3px",
    border: "0",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, `Segoe UI`, Roboto, Helvetica, Arial, sans-serif, `Apple Color Emoji`, `Segoe UI Emoji`, `Segoe UI Symbol`",
    width: "100%",
    padding: "8px 16px 8px 16px",
  },
};

//Initial, onHover styles for buttons
export const btnVariants = {
  iniOutlinedButton: {
    backgroundColor: "var(--primary-color)",
    color: "#ffffff",
    border: "solid 1px #ffffff",
    borderRadius: "3px",
    transition: { duration: "500ms" },
  },
  iniButton: {
    backgroundColor: "#FFFFFF",
    color: "var(--primary-color)",
    transition: { duration: "500ms" },
  },
  hovButton: {
    backgroundColor: "var(--light-primary-color)",
    color: "#ffffff",
    cursor: "pointer",
  },
  hovDisabledButton: {
    cursor: "not-allowed",
  },
  hovOutlinedButton: {
    backgroundColor: "var(--light-primary-color)",
    color: "#FFFFFF",
    cursor: "pointer",
  },
};
