import { useQuery, UseQueryOptions } from "react-query";
import { workEventsService } from "../services/work-events.service";
import { WorkEvent } from "./models/work-event.model";

export function useWorkEventQuery(id: number | undefined) {
    const  options: UseQueryOptions<WorkEvent> = {
        enabled: !!id
    }

    return useQuery<WorkEvent>(
        ["work-event", id],
        () => workEventsService.getWorkEventById(id!),
        options
    )
}