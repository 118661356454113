import { useMemo } from "react";
import { SideBarNavigationDesktop } from "./side-bar-nav-desktop";
import { BottomBarNavigationMobile } from "./bottom-bar-nav-mobile";
import { useWindowSize } from "../../hooks/use-window-size";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faUserGroup,
  faRectangleList,
  faGaugeHigh,
  faDollarSign,
  faHouseChimneyWindow,
  faCalendar,
  faLanguage,
  faBuildingUser,
  faQrcode,
  faTag,
  faBox,
  faShieldHalved,
  faCalendarCheck,
  faBuilding,
  faTableCells,
} from "@fortawesome/free-solid-svg-icons";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";

export interface MenuItem {
  to: string;
  label: string;
  icon: IconProp;
}

export function BarNavigation() {
  const { lg } = useWindowSize() || {};

  const menuItems = useMemo(() => {
    const items = [];

    if (authService.hasAccess(AppFeatures.WebDashboardScreen)) {
      items.push({
        to: "dashboard",
        label: "navigation.dashboard",
        icon: faGaugeHigh,
      });
    }

    if (authService.hasAccess(AppFeatures.WebInstallationsScreen)) {
      items.push({
        to: "installations",
        label: "navigation.installations",
        icon: faHouseChimneyWindow,
      });
    }
    if (authService.hasAccess(AppFeatures.WebUsersScreen)) {
      items.push({
        to: "users",
        label: "navigation.users",
        icon: faUserGroup,
      });
    }
    if (authService.hasAccess(AppFeatures.WebPlanningScreen)) {
      items.push({
        to: "planning",
        label: "navigation.planning",
        icon: faCalendar,
      });
    }
    if (authService.hasAccess(AppFeatures.WebWorkEntriesScreen)) {
      items.push({
        to: "work-entries",
        label: "navigation.workEntries",
        icon: faCalendarCheck,
      });
    }
    if (authService.hasAccess(AppFeatures.WebWorkInstancesScreen)) {
      items.push({
        to: "work-instances",
        label: "navigation.workInstances",
        icon: faBuildingUser,
      });
    }
    if (authService.hasAccess(AppFeatures.WebInvoicingScreen)) {
      items.push({
        to: "orders-validator",
        label: "navigation.invoicing",
        icon: faDollarSign,
      });
    }
    if (authService.hasAccess(AppFeatures.WebSummaryScreen)) {
      items.push({
        to: "summary",
        label: "navigation.summary",
        icon: faRectangleList,
      });
    }
    if (authService.hasAccess(AppFeatures.WebAssetsScreen)) {
      items.push({
        to: "assets",
        label: "navigation.assets",
        icon: faBox,
      });
    }
    if (authService.hasAccess(AppFeatures.WebAssetStickerScreen)) {
      items.push({
        to: "asset-sticker",
        label: "navigation.assetSticker",
        icon: faQrcode,
      });
    }
    if (authService.hasAccess(AppFeatures.WebTagsScreen)) {
      items.push({
        to: "tags",
        label: "navigation.tags",
        icon: faTag,
      });
    }

    if (authService.hasAccess(AppFeatures.WebTranslationsScreen)) {
      items.push({
        to: "translations",
        label: "navigation.translations",
        icon: faLanguage,
      });
    }
    if (authService.hasAccess(AppFeatures.WebRolesScreen)) {
      items.push({
        to: "roles",
        label: "navigation.roles",
        icon: faShieldHalved,
      });
    }
    if (authService.hasAccess(AppFeatures.WebCompaniesScreen)) {
      items.push({
        to: "companies",
        label: "navigation.companies",
        icon: faBuilding,
      });
    }
    if (authService.hasAccess(AppFeatures.WebKanbanBoardScreen)) {
      items.push({
        to: "tasks-board",
        label: "navigation.workingTasks",
        icon: faTableCells,
      });
    }
    return items;
  }, []);

  return lg ? (
    <SideBarNavigationDesktop menuItems={menuItems} />
  ) : (
    <BottomBarNavigationMobile menuItems={menuItems} />
  );
}
