import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { Note } from "../queries/models/note.model";
import { AddNoteRequest } from "../queries/models/add-note-request";
import qs from "qs";

class NotesService extends BaseService {
  async getNote(id: number): Promise<Note> {
    const url = `${this.endpointPath}/${id}`;
    return this.http.get<Note>(url).then((response) => response.data);
  }

  async getNoteItemsForMonth(id: number, month: Date): Promise<Note> {
    const url = `${this.endpointPath}/${id}/month`;
    return this.http
      .get<Note>(url, {
        params: {
          date: month,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
      .then((response) => response.data);
  }

  async addNote(request: AddNoteRequest) {
    const url = `${this.endpointPath}/create`;
    var formData = new FormData();

    request.files?.forEach((f) => {
      formData.append("files", f.blob!, f.name);
    });
    formData.append("noteId", request.noteId.toString());
    formData.append("message", request.message.toString());
    formData.append("severity", request.severity.toString());
    formData.append("isConfidential", request.isConfidential.toString());

    return this.http
      .postForm<AddNoteRequest>(url, formData)
      .then((response) => response.data);
  }
}

export const notesService = new NotesService(API_ENDPOINTS.NOTES);
