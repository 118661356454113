export enum WorkInstanceState {
    Unspecified = 0,
    Pending = 10,
    Active = 20,
    Finished = 30,
}


class WorkInstanceStateHelper {
    public isFinished(state: WorkInstanceState): boolean {
        return state === WorkInstanceState.Finished;
    }
}
export const workInstanceStateHelper = new WorkInstanceStateHelper();