import { useQueryClient } from "react-query";
import { useToast } from "../../../components/ui/toast-context-provider";
import { useCallback, useState } from "react";
import {
  CustomModal,
  CustomModalProps,
} from "../../../components/ui/MobileModal/custom-modal";
import { AssetTypesOverview } from "../AssetTypeOverview";
import { AssetTypeForm } from "../AssetTypeForm";
import { AssetType } from "../../../queries/models/asset-type.model";
import {
  useActiveAssetTypesQuery,
  useAddAssetTypeMutation,
  useDeleteAssetTypeMutation,
  useUpdateAssetTypeMutation,
} from "../../../queries/asset-type.query";
import { CreateOrUpdateAssetType } from "../../../queries/models/create-or-update-asset-type";
import { CreateOrUpdateAssetTypeRequest } from "../../../queries/models/create-or-update-asset-type-request.model";

export function MobileAssetTypesComponent() {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [selectedAssetType, setSelectedAssetType] = useState<
    AssetType | undefined
  >(undefined);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const AssetTypesQuery = useActiveAssetTypesQuery();
  const addAssetTypeMutation = useAddAssetTypeMutation();
  const updateAssetTypeMutation = useUpdateAssetTypeMutation();
  const deleteAssetTypeMutation = useDeleteAssetTypeMutation();

  const saveNewAssetType = useCallback(
    (data: CreateOrUpdateAssetType) => {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedAssetType(undefined);

          toast.current?.show({
            severity: "success",
            detail: "Save success",
          });
          await queryClient.invalidateQueries("AssetTypes");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: CreateOrUpdateAssetTypeRequest = {
        name: data.name,
        comment: data.comment,
        textColor: `#${data.textColor}`,
        backgroundColor: `#${data.backgroundColor}`,
        icon: data.icon,
      };

      return addAssetTypeMutation.mutateAsync(request, mutateOptions);
    },
    [addAssetTypeMutation, queryClient, toast]
  );

  const editExistingAssetType = useCallback(
    (data: CreateOrUpdateAssetType) => {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedAssetType(undefined);
          toast.current?.show({
            severity: "success",
            detail: "Edit success",
          });
          await queryClient.invalidateQueries("AssetTypes");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: CreateOrUpdateAssetType = {
        id: data.id,
        name: data.name,
        comment: data.comment,
        textColor: `#${data.textColor}`,
        backgroundColor: `#${data.backgroundColor}`,
        icon: data.icon,
      };

      return updateAssetTypeMutation.mutateAsync(request, mutateOptions);
    },
    [queryClient, toast, updateAssetTypeMutation]
  );

  const handleSaveAssetType = useCallback(
    (data: CreateOrUpdateAssetType) =>
      data.id === 0 ? saveNewAssetType(data) : editExistingAssetType(data),
    [editExistingAssetType, saveNewAssetType]
  );

  const handleDeleteAssetType = useCallback(() => {
    if (selectedAssetType?.id) {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedAssetType(undefined);
          toast.current?.show({
            severity: "success",
            detail: "Delete success",
          });
          await queryClient.invalidateQueries("AssetTypes");
          setIsDeleteConfirmationModalOpen(false);
          setSelectedAssetType(undefined);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      deleteAssetTypeMutation.mutateAsync(selectedAssetType.id, mutateOptions);
    }
  }, [deleteAssetTypeMutation, queryClient, selectedAssetType?.id, toast]);

  const deleteConfirmationModalProps: CustomModalProps = {
    isOpen: isDeleteConfirmationModalOpen,
    onClose: () => setIsDeleteConfirmationModalOpen(false),
    header: "Confirmation",
    body: "Delete this asset type?",
    justified: true,
    centered: true,
    height: "max-content",
    confirmation: true,
    onConfirm: async () => {
      handleDeleteAssetType();
    },
  };

  const selectedAssetTypeModal: CustomModalProps = {
    isOpen: !!selectedAssetType,
    onClose: () => {
      setSelectedAssetType(undefined);
    },
    header: selectedAssetType?.name ?? "",
    justified: true,
    centered: true,
    height: "max-content",
    confirmation: false,
    body: selectedAssetType && (
      <AssetTypeForm
        assetType={selectedAssetType}
        onCancel={() => {
          setSelectedAssetType(undefined);
        }}
        onSave={handleSaveAssetType}
        onDelete={() => setIsDeleteConfirmationModalOpen(true)}
      />
    ),
  };

  return (
    <div className="h-full p-1">
      <CustomModal {...selectedAssetTypeModal} />
      <CustomModal {...deleteConfirmationModalProps} />
      <div className="h-full w-12">
        <AssetTypesOverview
          items={AssetTypesQuery.data}
          isLoading={AssetTypesQuery.isLoading}
          selectedAssetType={selectedAssetType}
          setSelectedAssetType={(x) => {
            setSelectedAssetType(x);
          }}
        />
      </div>
    </div>
  );
}
