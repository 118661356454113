import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

interface FormHeaderProps {
  header: string;
  generateButtonDisabled: boolean;
  exportToPdfButtonDisabled: boolean;
  onGenerateClick: () => Promise<any> | void;
  onExportToPdfClick?: () => void;
}

export function FormHeader({
  header,
  generateButtonDisabled,
  exportToPdfButtonDisabled,
  onGenerateClick,
  onExportToPdfClick,
}: FormHeaderProps) {
  const { t } = useTranslation();

  return (
    <div className="flex justify-content-between p-1">
      <div className="text-xl font-bold">{header}</div>
      <div className="flex gap-1">
        <Button
          label={t("common.generate")}
          icon="pi pi-qrcode"
          onClick={onGenerateClick}
          disabled={generateButtonDisabled}
          type="button"
        />
        <Button
          label={t("common.exportToPdf")}
          icon="pi pi-print"
          onClick={onExportToPdfClick}
          disabled={exportToPdfButtonDisabled}
          type="submit"
        />
      </div>
    </div>
  );
}
