import { Tag } from "../../queries/models/tag.model";
import { CreateOrUpdateTag } from "../../queries/models/create-or-update-tag";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { classNames } from "primereact/utils";
import { useWindowSize } from "../../hooks/use-window-size";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import { useToast } from "../../components/ui/toast-context-provider";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import FormikInputTextarea from "../../components/ui/formik/FormikInputTextarea";
import FormikColorPicker from "../../components/ui/formik/FormikColorPicker";
import { useMemo } from "react";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import FormikDropDown from "../../components/ui/formik/FormikDropdown";
import {
  faIconItemTemplate,
  iconsAsSelectOptions,
} from "../../utils/fontAwesomeIcons";
import { useTranslation } from "react-i18next";

interface TagFormProps {
  tag: Tag;
  isSubmitting?: boolean;
  onSave: (form: CreateOrUpdateTag) => Promise<any>;
  onDelete: () => Promise<any> | void;
  onCancel: () => void;
}

export function TagForm({
  tag,
  isSubmitting,
  onSave,
  onDelete,
  onCancel,
}: TagFormProps) {
  const { t } = useTranslation();
  const toast = useToast();
  const { lg } = useWindowSize();
  const hasWriteAccess = useMemo(() => {
    return authService.hasAccess(AppFeatures.WebTagsWrite);
  }, []);

  const initialValues: CreateOrUpdateTag = {
    id: tag.id ?? 0,
    name: tag.name ?? "",
    comment: tag.comment ?? "",
    textColor: tag.textColor?.substring(1) ?? "FFFFFF",
    backgroundColor: tag.backgroundColor?.substring(1) ?? "7C4DFF",
    icon: tag.icon ?? undefined,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t("alert.mustBe3CharactersOrMore"))
      .required(t("alert.required")),
    comment: Yup.string().optional(),
    textColor: Yup.string(),
    backgroundColor: Yup.string(),
    icon: Yup.string().optional(),
  });

  return (
    <div className="p-2 h-full w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSave}
        enableReinitialize
        validateOnChange
        validateOnMount
      >
        {(formik) => (
          <Form
            className={classNames(
              "flex",
              lg ? "flex-column" : "flex-column-reverse"
            )}
          >
            <EditContentHeader
              header={tag.id ? tag.name : t("common.addNewTag")}
              showDeleteButton={hasWriteAccess && !!tag.id}
              saveButtonDisabled={
                !hasWriteAccess || !formik.dirty || !formik.isValid
              }
              onSaveClick={async () => {
                if (!hasWriteAccess) return;
                if (!formik.isValid) {
                  toast.current?.show({
                    severity: "error",
                    detail: t("alert.formInvalid"),
                  });
                  return;
                }
                return formik.submitForm();
              }}
              onCancelClick={onCancel}
              onDeleteClick={onDelete}
            />
            <div className="grid">
              <div className="field col-12 md:col-12">
                <FormikInputText
                  label={t("common.name")}
                  name="name"
                  validationSchema={validationSchema}
                  isIndependent
                  disabled={!hasWriteAccess}
                />
              </div>
              <div className="col-12 md:col-12">
                <FormikInputTextarea
                  label={t("common.comment")}
                  name="comment"
                  validationSchema={validationSchema}
                  isIndependent
                  disabled={!hasWriteAccess}
                />
              </div>
              <div className="col-6 md:col-6">
                <FormikColorPicker
                  label={t("common.textColor")}
                  name="textColor"
                  validationSchema={validationSchema}
                  isIndependent
                  disabled={!hasWriteAccess}
                />
              </div>
              <div className="col-6 md:col-6">
                <FormikColorPicker
                  label={t("common.backgroundColor")}
                  name="backgroundColor"
                  validationSchema={validationSchema}
                  isIndependent
                  disabled={!hasWriteAccess}
                />
              </div>
              <div className="col-12 md:col-12">
                <FormikDropDown
                  label={t("common.icon")}
                  name="icon"
                  validationSchema={validationSchema}
                  isIndependent
                  disabled={!hasWriteAccess}
                  options={iconsAsSelectOptions}
                  filter
                  showClear
                  itemTemplate={faIconItemTemplate}
                  valueTemplate={faIconItemTemplate}
                  virtualScrollerOptions={{
                    itemSize: 48,
                  }}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
