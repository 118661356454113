import { useState, useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faTrashCan,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { Divider } from "primereact/divider";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useDeleteWorkTaskMutation } from "../../../queries/work-tasks.query";
import { useUpdateWorkTaskNameMutation } from "../../../queries/work-tasks.query";
import { useToast } from "../../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import { CustomModal } from "../../../components/ui/MobileModal/custom-modal";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { UpdateWorkTaskName } from "../../../queries/models/update-work-task-name.model";
import { useTranslation } from "react-i18next";
import { AppFeatures } from "../../../queries/models/enums/app-feature-enum";
import { authService } from "../../../services/auth.service";

function OptionElement({
  label,
  icon,
  onClick,
}: {
  label: string;
  icon?: IconProp;
  onClick: () => void;
}) {
  return (
    <div
      className="flex align-items-center gap-2 cursor-pointer p-1 border-round select-none hover:surface-300"
      onClick={onClick}
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      <div>{label}</div>
    </div>
  );
}

interface Props {
  taskId: number;
  taskName: string;
  onEditClick: () => void;
  onClose: () => void;
}

export function EditWorkTaskPanel({
  taskId,
  taskName,
  onEditClick,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const deleteWorkTaskMutation = useDeleteWorkTaskMutation();
  const updateWorkTaskNameMutation = useUpdateWorkTaskNameMutation();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [updateNameVisible, setUpdateNameVisible] = useState(false);
  const [editName, setEditName] = useState(taskName);

  const hasWriteAccess = useMemo(
    () => authService.hasAccess(AppFeatures.WebKanbanBoardWrite),
    []
  );

  const handleDelete = useCallback(async () => {
    const mutateOptions = {
      onSuccess: async () => {
        toast.current?.show({
          severity: "success",
          detail: t("alert.saveSuccess"),
        });
        await queryClient.invalidateQueries(["work-tasks"]);
      },
      onError: (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error.data,
        });
      },
    };

    return await deleteWorkTaskMutation.mutateAsync(taskId, mutateOptions);
  }, [deleteWorkTaskMutation, queryClient, t, taskId, toast]);

  const handleUpdateName = useCallback(async () => {
    const mutateOptions = {
      onSuccess: async () => {
        toast.current?.show({
          severity: "success",
          detail: t("alert.updateNameSuccess"),
        });
        await queryClient.invalidateQueries(["work-tasks"]);
        onClose();
      },
      onError: (error: any) => {
        toast.current?.show({
          severity: "error",
          detail: error.data,
        });
      },
    };

    const request: UpdateWorkTaskName = {
      workTaskId: taskId,
      name: editName,
    };

    return await updateWorkTaskNameMutation.mutateAsync(request, mutateOptions);
  }, [
    editName,
    onClose,
    queryClient,
    t,
    taskId,
    toast,
    updateWorkTaskNameMutation,
  ]);

  return (
    <>
      <CustomModal
        header={t("common.confirmation")}
        body={t("dialog.deleteThisTask")}
        isOpen={deleteModalOpen}
        height="max-content"
        confirmation
        centered
        justified
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
      <OptionElement
        label={t("common.open")}
        icon={faPencil}
        onClick={onEditClick}
      />
      <Divider />
      <OptionElement
        label={t("common.editTitle")}
        icon={faPenToSquare}
        onClick={() => setUpdateNameVisible(true)}
      />
      {hasWriteAccess && (
        <OptionElement
          label={t("common.delete")}
          icon={faTrashCan}
          onClick={() => setDeleteModalOpen(true)}
        />
      )}
      {updateNameVisible && (
        <>
          <InputText
            className="p-inputtext-sm mt-1 w-full"
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
          />
          <div className="flex gap-1 pt-1">
            <Button
              className="green-action-button w-6"
              label={t("common.save")}
              icon="pi pi-save"
              disabled={
                editName?.length < 3 || editName === taskName || !hasWriteAccess
              }
              onClick={handleUpdateName}
              loading={updateWorkTaskNameMutation.isLoading}
            />
            <Button
              className="yellow-button w-6"
              label={t("common.cancel")}
              onClick={() => setUpdateNameVisible(false)}
              icon="pi pi-times"
            />
          </div>
        </>
      )}
    </>
  );
}
