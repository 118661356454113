import { useMemo, useState } from "react";
import { Button } from "primereact/button";
import { NoteItemComponent } from "./NoteItem";
import { NoteItem } from "../../../queries/models/note-item.model";
import { AddNoteItem } from "./AddNoteItem";
import { AddNoteRequest } from "../../../queries/models/add-note-request";
import { FileDownload } from "../FileDownload";
import { ImageWrapper } from "../ImageWrapper";
import { NoteSeverity } from "../../../queries/models/enums/note-severity";
import { GalleryPreview } from "../GalleryPreview/gallery-preview";
import { LoaderWrapper } from "../LoaderWrapper";
import { useTranslation } from "react-i18next";

interface NoteProps {
  items: NoteItem[];
  isLoading?: boolean;
  onAddNote: (e: AddNoteRequest) => void;
  addingWithWebCam?: boolean;
  hasWriteAccess?: boolean;
}

export function Note({
  items,
  onAddNote,
  isLoading = false,
  addingWithWebCam = false,
  hasWriteAccess = false,
}: NoteProps) {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState<number>();
  const [isAddingEntryMode, setIsAddingEntryMode] = useState<boolean>(false);

  const groupedItems = useMemo(() => {
    const result = new Map<string, NoteItem[]>();
    items
      .filter((x) => x.severity !== NoteSeverity.SystemLog)
      .sort((a, b) => +b.date - +a.date)
      .forEach((item) => {
        const dateString = item.date.toISOString().split("T")[0];
        if (!result.has(dateString)) {
          result.set(dateString, []);
        }
        result.get(dateString)!.push(item);
      });

    return result;
  }, [items]);

  const allFiles = useMemo(() => {
    return (
      items.flatMap((x) => {
        let result = x.files;
        result.forEach((y) => {
          y.date = x.date;
        });
        return result;
      }) ?? []
    );
  }, [items]);
  const allImages = useMemo(() => {
    return items.flatMap((x) => x.images) ?? [];
  }, [items]);

  return (
    <div className="w-full h-full">
      <LoaderWrapper isLoading={isLoading}>
        <div className="overflow-x-hidden pb-2">
          {!isAddingEntryMode && (
            <div className="w-full h-3rem justify-content-between flex px-2 align-items-center">
              <div className="w-13 text-2xl font-medium"></div>
              <Button
                className="yellow-button my-2 min-w-max"
                style={{ height: "39px" }}
                label="Add Note"
                icon="pi pi-plus"
                disabled={!hasWriteAccess}
                onClick={() => {
                  setIsAddingEntryMode(true);
                }}
              />
            </div>
          )}

          {isAddingEntryMode && (
            <div className="w-full p-2">
              <AddNoteItem
                onSave={(e) => {
                  onAddNote(e);
                  setIsAddingEntryMode(false);
                }}
                onCancel={() => setIsAddingEntryMode(false)}
                addingWithWebcam={addingWithWebCam}
              />
            </div>
          )}

          {[...Array.from(groupedItems.entries())].map(
            ([group, values], index) => (
              <div
                key={index}
                className="py-1"
              >
                <div
                  className="w-full flex flex-row"
                  style={{}}
                >
                  <div
                    className="flex-grow-1 mr-5 ml-8"
                    style={{
                      height: "0.75rem",
                      borderBottom: "solid 1px rgb(182, 182, 182)",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "fit-content",
                      fontSize: "1rem",
                      color: "rgb(122, 122, 122)",
                    }}
                  >
                    {group}
                  </div>
                  <div
                    className="flex-grow-1 mx-5"
                    style={{
                      height: "0.75rem",
                      borderBottom: "solid 1px rgb(182, 182, 182)",
                    }}
                  ></div>
                </div>
                <div className="w-full">
                  {values.map((noteItem, innerIndex) => (
                    <div key={innerIndex}>
                      <NoteItemComponent {...noteItem} />
                    </div>
                  ))}
                </div>
              </div>
            )
          )}
          {!isLoading && (
            <div className="flex flex-column p-2">
              <section className="mt-1">
                <div className="section-header">{t("common.allImages")}</div>
                <div className="section-content flex justify-content-start flex-wrap">
                  {allImages && activeIndex !== undefined && (
                    <GalleryPreview
                      images={allImages}
                      activeIndex={activeIndex}
                      onItemChange={(e) => setActiveIndex(e)}
                      onClose={() => setActiveIndex(undefined)}
                    />
                  )}
                  {allImages.length > 0 ? (
                    allImages.map((image, index) => {
                      let imgEl = (
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setActiveIndex(index);
                          }}
                        >
                          <ImageWrapper
                            key={index}
                            imageSrc={image.thumbnailSrc}
                            width="64px"
                            height="64px"
                            preview={true}
                          />
                        </div>
                      );
                      return <div key={index}>{imgEl}</div>;
                    })
                  ) : (
                    <div
                      className="w-12 py-2 flex"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                        color: "grey",
                        height: "4.25rem",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {t("common.NO_IMAGES")}
                    </div>
                  )}
                </div>
              </section>
              <section className="mt-1">
                <div className="section-header">{t("common.allFiles")}</div>
                <div className="section-content flex justify-content-start flex-nowrap flex-column">
                  {allFiles.length > 0 ? (
                    <div className="bullet-list">
                      {allFiles.map((x) => (
                        <FileDownload
                          key={x.id}
                          file={x}
                        />
                      ))}
                    </div>
                  ) : (
                    <div
                      className="w-12 py-2 flex"
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                        color: "grey",
                        height: "4.25rem",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {t("common.NO_FILES")}
                    </div>
                  )}
                </div>
              </section>
            </div>
          )}
        </div>
      </LoaderWrapper>
    </div>
  );
}
