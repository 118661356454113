import { Tag } from "../../../queries/models/tag.model";
import { TagItem } from "./tag-item";

interface TagItemProps {
  tag: Tag;
  className?: string;
}

export function TagSpan({ tag, className = "" }: TagItemProps) {
  return (
    <span className={className}>
      <TagItem
        backGroundColor={tag.backgroundColor}
        tagName={tag.name}
        textColor={tag.textColor}
      />
    </span>
  );
}
