import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { classNames } from "primereact/utils";

interface Props {
  name: string;
  color: string;
  expanded: boolean;
  setExpanded: (value: boolean) => void;
  isOver: boolean;
}

export function BoardColumnHeaderElement({
  name,
  color,
  expanded,
  setExpanded,
  isOver,
}: Props) {
  return (
    <div
      className="flex p-1 border-round-top-xl align-items-center h-2rem"
      style={{ backgroundColor: color }}
    >
      <motion.div
        className="cursor-pointer"
        onClick={() => setExpanded(!expanded)}
        animate={{
          rotate: !expanded ? 180 : 0,
        }}
      >
        <FontAwesomeIcon
          icon={faAngleLeft}
          size="xl"
          color="#424242"
        ></FontAwesomeIcon>
      </motion.div>
      {expanded && (
        <div
          style={{ color: "#424242" }}
          className={classNames(
            "w-full text-center font-bold transition-duration-100 text-lg"
          )}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.25,
              delay: 0.25,
            }}
          >
            {name}
          </motion.div>
        </div>
      )}
    </div>
  );
}
