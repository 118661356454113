import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  CircleF,
} from "@react-google-maps/api";
import { GEOGRAPHICAL_MIDDLE_OF_BELGIUM } from "../../../constants/geographical-middle-of-belgium.constants";
import { Loader } from "../Loader";
import { useState, useMemo, useCallback } from "react";

interface Coordinate {
  lat: number;
  lng: number;
}

const defaultMapCenter: Coordinate = {
  lat: GEOGRAPHICAL_MIDDLE_OF_BELGIUM.latitude,
  lng: GEOGRAPHICAL_MIDDLE_OF_BELGIUM.longitude,
};

export interface MapContainerProps {
  latitude?: number;
  longitude?: number;
  radius?: number;
  onCoordinatesChanged: (e: google.maps.MapMouseEvent) => void;
  zoom: number;
  mapBorderRadius?: string;
}

export function EditCoordinatesComponent({
  latitude,
  longitude,
  radius,
  onCoordinatesChanged,
  zoom,
  mapBorderRadius = "0",
}: MapContainerProps) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    language: "en",
  });

  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const [mapCenter, setMapCenter] = useState<Coordinate>(defaultMapCenter);

  useMemo(() => {
    if (latitude === undefined || longitude === undefined) {
      setMapCenter(defaultMapCenter);
    } else {
      setMapCenter({ lat: latitude, lng: longitude });
    }
  }, [latitude, longitude]);

  const handleMapDragEnd = useCallback(() => {
    const mapBounds = mapInstance?.getBounds();
    const center = mapBounds?.getCenter();

    if (!center) {
      setMapCenter(defaultMapCenter);
    } else {
      setMapCenter({ lat: center.lat(), lng: center.lng() });
    }
  }, [mapInstance]);

  const onMapLoad = (map: google.maps.Map) => {
    setMapInstance(map);
  };

  return (
    <div className="flex align-items-center h-full">
      {!isLoaded ? (
        <Loader type="spinner" />
      ) : (
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "100%",
            borderRadius: mapBorderRadius,
          }}
          zoom={latitude && longitude ? zoom : 6}
          center={mapCenter}
          onDragEnd={handleMapDragEnd}
          onLoad={onMapLoad}
        >
          {latitude && longitude && (
            <>
              <MarkerF
                position={{ lat: latitude, lng: longitude }}
                draggable={true}
                onDragEnd={onCoordinatesChanged}
              />
              <CircleF
                center={{ lat: latitude, lng: longitude }}
                radius={radius}
                options={{
                  fillColor: "#4CAF50",
                  fillOpacity: 0.55,
                  strokeColor: "#4CAF50",
                  strokeWeight: 1.2,
                }}
              />
            </>
          )}
        </GoogleMap>
      )}
    </div>
  );
}
