import { FloorPlanItem } from "../../../queries/models/InstallationPlans/floor-plan-item.model";
import { CreateOrUpdateFloorPlanItem } from "../../../queries/models/InstallationPlans/create-or-update-floor-plan-item.model";
import * as Yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import FormikInputText from "../../ui/formik/FormikInputText";
import FormikColorPicker from "../../ui/formik/FormikColorPicker";
import FormikDropDown from "../../ui/formik/FormikDropdown";
import { Button } from "primereact/button";
import { SelectItem } from "primereact/selectitem";
import { useEffect, useMemo, useRef, useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { FormInputLabel } from "../../ui/formik/FormInputLabel";
import { Asset } from "../../../queries/models/asset.model";
import {
  faIconItemTemplate,
  selectAssetIcon,
} from "../../../utils/fontAwesomeIcons";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomModal } from "../../ui/MobileModal/custom-modal";
import QRCode from "react-qr-code";

function QrCodeInfo({ code, onClick }: { code: string; onClick: () => void }) {
  return (
    <div
      className="cursor-pointer bg-gray-600 hover:bg-gray-800 px-2 py-1 border-round-2xl text-white"
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon="qrcode"
        className="mr-2 vertical-align-bottom"
      />
      {code}
      <></>
    </div>
  );
}

interface FloorPlanItemsFormProps {
  floorPlanItem: FloorPlanItem;
  assets: Asset[] | undefined;
  handleSubmit: (values: CreateOrUpdateFloorPlanItem) => void;
  handleCancel: () => void;
  handleDelete: (guid: string | undefined) => void;
}

export function FloorPlanItemForm({
  floorPlanItem,
  handleSubmit,
  handleCancel,
  handleDelete,
  assets,
}: FloorPlanItemsFormProps) {
  const { t } = useTranslation();

  const lastCode = useMemo(
    () => floorPlanItem.asset?.codes?.at(-1)?.code,
    [floorPlanItem.asset?.codes]
  );

  const assetOptions = useMemo(() => {
    return (
      assets?.map(
        (x) =>
          ({
            label: x.name,
            value: x.id,
            icon: selectAssetIcon(x),
          } as SelectItem)
      ) ?? []
    );
  }, [assets]);

  const initialValues: CreateOrUpdateFloorPlanItem = {
    id: floorPlanItem?.id ?? 0,
    name: floorPlanItem?.name ?? "",
    additionalDescription: floorPlanItem?.additionalDescription ?? "",
    color: floorPlanItem?.color ?? "7c4dff",
    textColor: floorPlanItem?.textColor ?? "ffffff",
    isAttached: floorPlanItem?.isAttached ?? false,
    size: 1,
    xPosition: floorPlanItem?.size ?? 0,
    yPosition: floorPlanItem?.size ?? 0,
    floorPlanId: floorPlanItem?.floorPlanId ?? 0,
    assetId: floorPlanItem?.assetId ?? undefined,
    guid: floorPlanItem?.guid ?? undefined,
  };

  const [isAssetItem, setIsAssetItem] = useState<boolean>(
    floorPlanItem?.guid ? !!floorPlanItem.assetId : true
  );

  const [codes, setCodes] = useState<string[] | undefined>(undefined);

  useEffect(() => {
    setIsAssetItem(floorPlanItem?.guid ? !!floorPlanItem.assetId : true);
  }, [floorPlanItem, floorPlanItem.assetId]);

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t("alert.mustBe3CharactersOrMore"))
      .required(t("alert.required")),
    additionalDescription: Yup.string().optional(),
    color: Yup.string().required(t("alert.required")),
    textColor: Yup.string().required(t("alert.required")),
    isAttached: Yup.boolean().required(t("alert.required")),
    xPosition: Yup.number().required(t("alert.required")),
    yPosition: Yup.number().required(t("alert.required")),
    assetId: isAssetItem
      ? Yup.number().required(t("alert.required"))
      : Yup.number().optional(),
  });

  const formikRef = useRef<FormikProps<CreateOrUpdateFloorPlanItem> | null>(
    null
  );

  useEffect(() => {
    formikRef.current?.validateForm();
  }, [isAssetItem]);

  return (
    <div className="p-2 w-full">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnChange
        validateOnMount
        innerRef={(ref) => (formikRef.current = ref)}
      >
        {(formik) => (
          <Form>
            <div className="grid">
              <div className="col-4 flex-column flex">
                <FormInputLabel validationSchema={validationSchema}>
                  {t("common.isAsset")}
                </FormInputLabel>
                <InputSwitch
                  onChange={(e) => {
                    if (e.value) {
                      setIsAssetItem(true);
                    } else {
                      formik.setFieldValue("assetId", undefined);
                      setIsAssetItem(false);
                    }
                  }}
                  checked={isAssetItem}
                />
              </div>
              <div className="col-4">
                <FormikColorPicker
                  validationSchema={validationSchema}
                  name="color"
                  label={t("common.color")}
                  isIndependent
                />
              </div>
              <div className="col-4 flex align-items-end">
                {lastCode && (
                  <QrCodeInfo
                    code={lastCode}
                    onClick={() =>
                      setCodes(floorPlanItem.asset?.codes?.map((x) => x.code))
                    }
                  />
                )}
              </div>
              <div className="col-12">
                <FormikDropDown
                  validationSchema={validationSchema}
                  options={assetOptions}
                  name="assetId"
                  label={t("common.selectAsset")}
                  showClear
                  disabled={!isAssetItem}
                  itemTemplate={faIconItemTemplate}
                  valueTemplate={faIconItemTemplate}
                  onChange={(e) => {
                    if (typeof e.value === "number") {
                      const asset = assets?.find((x) => x.id === e.value);
                      if (asset) {
                        formik.setValues((x) => {
                          return {
                            ...x,
                            assetId: e.value,
                            name: asset.name,
                            additionalDescription: asset.description,
                          };
                        }, true);
                      }
                    } else {
                      formik.setValues((x) => {
                        return {
                          ...x,
                          assetId: undefined,
                        };
                      }, true);
                    }
                  }}
                />
              </div>
              <div className="col-12">
                <FormikInputText
                  validationSchema={validationSchema}
                  name="name"
                  label={t("common.name")}
                  isIndependent
                />
              </div>
              <div className="col-12">
                <FormikInputText
                  validationSchema={validationSchema}
                  name="additionalDescription"
                  label={t("common.comment")}
                  isIndependent
                />
              </div>
              <div className="col-12">
                <div className="flex gap-2">
                  <Button
                    type="submit"
                    className="green-action-button w-full"
                    icon={floorPlanItem.guid ? "pi pi-save" : "pi pi-plus"}
                    label={
                      floorPlanItem.guid ? t("common.save") : t("common.append")
                    }
                    disabled={!formik.dirty || !formik.isValid}
                  />
                  <Button
                    type="button"
                    className="yellow-button w-full"
                    icon="pi pi-times"
                    label={t("common.cancel")}
                    onClick={handleCancel}
                  />
                  {floorPlanItem.guid && (
                    <Button
                      type="button"
                      className="delete-button w-full"
                      icon="pi pi-trash"
                      label={t("common.delete")}
                      onClick={() => handleDelete(floorPlanItem.guid)}
                    />
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <CustomModal
        isOpen={!!codes}
        header={t("dialog.qrCode")}
        onClose={() => setCodes(undefined)}
        centered
        justified
        height="max-content"
        body={
          <div className="flex gap-3 justify-content-center">
            {codes?.map((x, i) => (
              <div
                key={i}
                className="flex flex-column justify-content-center align-items-center gap-2"
              >
                <QRCode
                  value={x}
                  size={180}
                />
                <div className="text-xl">{x}</div>
              </div>
            ))}
          </div>
        }
      />
    </div>
  );
}
