import { useWorkInstanceDetailsQuery } from "../../queries/work-instances.query";
import { useWindowSize } from "../../hooks/use-window-size";
import { WorkInstanceInstallationLocation } from "./WorkInstanceInstallationLocation";
import { classNames } from "primereact/utils";
import { WorkInstanceDetailsOverview } from "./WorkInstanceDetailsOverview";
import { useParams } from "react-router-dom";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";

export function WorkInstanceDetails() {
  const { lg } = useWindowSize() || {};
  const params = useParams();
  const workInstanceDetailsQuery = useWorkInstanceDetailsQuery(
    Number(params?.id)
  );
  return (
    <div className="h-full overflow-y-hidden">
      <div
        className={classNames(
          "flex h-full gap-1",
          lg ? "flex-row" : "flex-column overflow-y-auto"
        )}
      >
        <div className={classNames(lg ? "w-6" : "w-12")}>
          <div className={classNames("p-1", lg ? "h-full" : "")}>
            <WorkInstanceDetailsOverview
              details={workInstanceDetailsQuery.data}
              isLoading={workInstanceDetailsQuery.isLoading}
            />
          </div>
        </div>
        <div
          className={classNames(" flex flex-column gap-1", lg ? "w-6" : "w-12")}
        >
          <div className="h-full">
            <div
              className="p-1 h-full relative"
              style={{ minHeight: "40vh" }}
            >
              <LoaderWrapper isLoading={workInstanceDetailsQuery.isLoading}>
                <WorkInstanceInstallationLocation
                  workInstance={workInstanceDetailsQuery.data}
                  installationName={
                    workInstanceDetailsQuery.data?.installation?.name ?? ""
                  }
                  installationAddress={
                    workInstanceDetailsQuery.data?.installation.address
                  }
                  isLoading={workInstanceDetailsQuery.isLoading}
                  locationLogs={workInstanceDetailsQuery.data?.locationLogs}
                  lastLocation={workInstanceDetailsQuery.data?.lastLocationLog}
                />
              </LoaderWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
