import { useEffect, useMemo, useState } from "react";
import { UserMonthlyOrdersOverviewItem } from "../../queries/models/user-monthly-orders-overview.model";
import { UserMonthlyOrdersValidation } from "../../queries/models/user-monthly-orders-validation.model";
import {
  useOrdersValidatorUserValidationQuery,
  useOrdersValidatorUsersOverviewQuery,
  useSendOrdersMutation,
  useWorkInstanceDetailsQuery,
} from "../../queries/orders-validator.query";
import { OverviewSectionComponent } from "./OverviewSectionComponent";
import { WorkSectionComponent } from "./WorkSectionComponent";
import { ValidatedWorkInstance } from "../../queries/models/validated-work-instance.model";
import { WorkDetailsSectionComponent } from "./WorkDetailsSectionComponent";
import { SendOrdersRequest } from "../../queries/models/send-orders-request";
import Enumerable from "linq";
import { OrderStatus } from "../../queries/models/enums/order-status.enum";
import { useQueryClient } from "react-query";
import { InstallatorTravelOrder } from "../../queries/models/orders/instllator-travel-order.model";
import { InstallatorWorkTimeOrder } from "../../queries/models/orders/instllator-worktime-order.model";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";

export function OrdersValidatorPage() {
  const queryClient = useQueryClient();
  const [date, setDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth())
  );
  const [selectedOverviewItem, setSelectedOverviewItem] =
    useState<UserMonthlyOrdersOverviewItem>();
  const [selectedUserValidation, setSelectedUserValidation] =
    useState<UserMonthlyOrdersValidation>();
  const [selectedWorkInstance, setSelectedWorkInstance] =
    useState<ValidatedWorkInstance>();
  const sendOrdersMutation = useSendOrdersMutation();
  const hasSubmitOrdersPrivilidge = useMemo(() => {
    return authService.hasAccess(AppFeatures.CanSubmitInvoiceOrders);
  }, []);

  const overviewQuery = useOrdersValidatorUsersOverviewQuery(date);
  const validationQuery = useOrdersValidatorUserValidationQuery(
    selectedOverviewItem?.userId,
    date
  );
  const workInstanceQuery = useWorkInstanceDetailsQuery(
    selectedWorkInstance?.id
  );

  useEffect(() => {
    if (validationQuery.data) {
      setSelectedUserValidation(validationQuery.data);
    }
    if (selectedWorkInstance) {
      setSelectedWorkInstance(
        validationQuery.data?.workInstances.find(
          (x) => x.id === selectedWorkInstance.id
        )
      );
    }
  }, [validationQuery.data, selectedWorkInstance]);

  function handleSendUserOrders(sub: UserMonthlyOrdersValidation) {
    if (!hasSubmitOrdersPrivilidge) return;
    let workOrdersIds = Enumerable.from(sub.workInstances)
      .selectMany((x) => x.installatorWorkTimeOrders)
      .where((x) => x?.status !== OrderStatus.Sent)
      .select((x) => x.id)
      .toArray();
    let travelOrdersIds = Enumerable.from(sub.workInstances)
      .selectMany((x) => x.installatorTravelOrders)
      .where((x) => x?.status !== OrderStatus.Sent)
      .select((x) => x.id)
      .toArray();
    let request: SendOrdersRequest = {
      orderIds: [...workOrdersIds, ...travelOrdersIds],
    };
    sendOrdersMutation.mutateAsync(request).then(() => {
      queryClient.invalidateQueries("ordersValidator");
    });
  }

  function handleSendWorkInstanceOrders(sub: ValidatedWorkInstance) {
    if (!hasSubmitOrdersPrivilidge) return;
    let workOrdersIds = Enumerable.from(sub.installatorWorkTimeOrders)
      .where((x) => x?.status !== OrderStatus.Sent)
      .select((x) => x.id)
      .toArray();
    let travelOrdersIds = Enumerable.from(sub.installatorTravelOrders)
      .where((x) => x?.status !== OrderStatus.Sent)
      .select((x) => x.id)
      .toArray();
    let request: SendOrdersRequest = {
      orderIds: [...workOrdersIds, ...travelOrdersIds],
    };
    sendOrdersMutation.mutateAsync(request).then(() => {
      queryClient.invalidateQueries("ordersValidator");
    });
  }

  function handleSendSingleOrder(
    sub: InstallatorTravelOrder | InstallatorWorkTimeOrder
  ) {
    if (!hasSubmitOrdersPrivilidge) return;
    let request: SendOrdersRequest = {
      orderIds: [sub.id],
    };
    sendOrdersMutation.mutateAsync(request).then(() => {
      queryClient.invalidateQueries("ordersValidator");
    });
  }

  return (
    <div className="flex h-full p-1 gap-2 overflow-hidden">
      <div className="w-3 h-full">
        <RoundedShadowContainer
          small
          fullHeight
        >
          <LoaderWrapper isLoading={overviewQuery.isLoading}>
            <OverviewSectionComponent
              items={overviewQuery.data ?? []}
              date={date}
              selectedItem={selectedOverviewItem}
              onDateChange={setDate}
              onSelectItem={(x) => {
                setSelectedOverviewItem(x);
                setSelectedWorkInstance(undefined);
              }}
            />
          </LoaderWrapper>
        </RoundedShadowContainer>
      </div>
      <div className="w-4 h-full">
        <RoundedShadowContainer
          small
          fullHeight
        >
          <LoaderWrapper isLoading={validationQuery.isLoading}>
            <WorkSectionComponent
              validation={selectedUserValidation}
              selectedItem={selectedWorkInstance}
              selectedOverviewItem={selectedOverviewItem}
              onSelectItem={setSelectedWorkInstance}
              onSendUserOrders={handleSendUserOrders}
              onSendWorkInstanceOrders={handleSendWorkInstanceOrders}
            />
          </LoaderWrapper>
        </RoundedShadowContainer>
      </div>
      <div className="w-5 h-full">
        <RoundedShadowContainer
          small
          fullHeight
        >
          <WorkDetailsSectionComponent
            validation={selectedUserValidation}
            selectedOverViewItem={selectedOverviewItem}
            workInstance={workInstanceQuery}
            onSendOrder={handleSendSingleOrder}
          />
        </RoundedShadowContainer>
      </div>
    </div>
  );
}
