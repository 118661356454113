import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { Asset } from "../queries/models/asset.model";
import { CreateOrUpdateAsset } from "../queries/models/create-or-update-asset.model";
import { SelectItem } from "primereact/selectitem";

class AssetsService extends BaseService {
  async getAllActive(): Promise<Asset[]> {
    const url = `${this.endpointPath}`;
    return this.http.get<Asset[]>(url).then((response) => response.data);
  }

  async getAllAsSelectOptions(): Promise<SelectItem[]> {
    const url = `${this.endpointPath}/as-select-options`;
    return this.http.get<SelectItem[]>(url).then((response) => response.data);
  }

  async getAllInInstallation(installationId: number): Promise<Asset[]> {
    const url = `${this.endpointPath}/installation/${installationId}`;
    return this.http.get<Asset[]>(url).then((response) => response.data);
  }

  async getAllInInstallationAsSelectOptions(installationId: number): Promise<SelectItem[]> {
    const url = `${this.endpointPath}/installations-assets-as-select-options/${installationId}`;
    return this.http.get<SelectItem[]>(url).then((response) => response.data);
  }

  async getById(id: number): Promise<Asset | undefined> {
    const url = `${this.endpointPath}/${id}`;
    return this.http.get<Asset>(url).then((response) => response.data);
  }

  async create(request: CreateOrUpdateAsset) {
    const url = `${this.endpointPath}`;
    return this.http
      .post<CreateOrUpdateAsset>(url, request)
      .then((response) => response.data);
  }

  async update(request: CreateOrUpdateAsset) {
    const url = `${this.endpointPath}`;
    return this.http
      .put<CreateOrUpdateAsset>(url, request)
      .then((response) => response);
  }

  async addEmptyNote(id: number) {
    const url = `${this.endpointPath}/add-empty-note/${id}`;
    return this.http.post<number>(url).then((response) => response.data);
  }

  async delete(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.delete(url).then((response) => response.data);
  }
}

export const assetsService = new AssetsService(API_ENDPOINTS.ASSETS);
