import { useMemo, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPowerOff,
  faUser,
  faBook,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { authService } from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { CustomModal, CustomModalProps } from "../ui/MobileModal/custom-modal";
import logo from "../../assets/icons/logo-white.svg";
import { Tooltip } from "primereact/tooltip";
import { useUserQuery } from "../../queries/users.query";
import { motion } from "framer-motion";
import { useWindowSize } from "../../hooks/use-window-size";
import { Company } from "../../queries/models/company-model";
import { useTranslation } from "react-i18next";

export function TopBarNavigation() {
  const { t } = useTranslation();
  const { lg } = useWindowSize();
  const navigate = useNavigate();
  const [isLogoutConfirmationModalOpen, setIsLogoutConfirmationModalOpen] =
    useState<boolean>(false);

  const logoutConfirmationModalProps: CustomModalProps = useMemo(() => {
    return {
      header: t("common.confirmation"),
      body: t("dialog.doYouReallyWantToLogout"),
      height: "160px",
      isOpen: isLogoutConfirmationModalOpen,
      confirmation: true,
      centered: true,
      justified: true,
      onClose: () => setIsLogoutConfirmationModalOpen(false),
      onConfirm: () => {
        setIsLogoutConfirmationModalOpen(false);
        authService.logout();
        navigate("/");
      },
    };
  }, [t, isLogoutConfirmationModalOpen, navigate]);

  const loggedUser = authService.getLoggedUser();

  const userData = useUserQuery(authService.getLoggedUser()?.id);

  const isNotFilled = useMemo(() => {
    if (userData.data)
      return (userData.data.company as Company)?.isOdooIntegrated === false;
    else return false;
  }, [userData]);

  const toolTipOffset = (loggedUser?.username?.length ?? 0) * -8.5 - 25;

  const onNavigateToDocumentation = useCallback(
    () => window.open("https://www.purpledocs.portacapena.com/"),
    []
  );

  return (
    <>
      <div
        className="bg-primary flex"
        style={{ height: "50px" }}
      >
        <Tooltip
          target=".noBilling"
          position="mouse"
          mouseTrackLeft={10}
          mouseTrackTop={10}
          mouseTrack={true}
        />
        <Tooltip
          target=".username"
          position="mouse"
          mouseTrackLeft={toolTipOffset}
          mouseTrack={true}
        />
        <Tooltip
          target=".documentation"
          position="mouse"
          mouseTrackLeft={-175}
          mouseTrack={true}
        />

        <CustomModal {...logoutConfirmationModalProps} />
        <div className="flex align-items-center">
          <div className="flex pl-3">
            <div className="justify-content-center flex">
              <img
                className="ml-1"
                style={{ width: "40px" }}
                src={logo}
                alt="My Happy SVG"
              />
            </div>
            <div className="text-3xl font-bold text-white no-underline ml-2">
              PurpleApp
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-content-end px-1 w-full align-items-center gap-1">
          {lg && (
            <div className="flex mr-3 relative">
              <span
                className={isNotFilled ? "noBilling" : ""}
                data-pr-tooltip="Company not integrated with Odoo"
              >
                <div
                  className="font-bold text-xl "
                  data-pr-tooltip={t("common.goToDocumentation")}
                >
                  {loggedUser?.companyName}
                </div>
              </span>
              {isNotFilled && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: [
                      1, 0.75, 0.5, 0.25, 0, 0, 1, 0.75, 0.5, 0.25, 0, 0, 1,
                      0.75, 0.5, 0.25, 0, 0, 1,
                    ],
                  }}
                  transition={{ duration: 3 }}
                  className="absolute right-0 text-xl"
                  style={{ marginRight: "-0.7rem", marginTop: "-0.7rem" }}
                >
                  <FontAwesomeIcon
                    data-pr-tooltip={t("common.goToDocumentation")}
                    icon={faCircleExclamation}
                    className=" border-circle "
                    style={{
                      backgroundColor: "white",
                      color: "red",
                    }}
                  />
                </motion.div>
              )}
            </div>
          )}
          <span
            className="documentation"
            data-pr-tooltip={t("common.goToDocumentation")}
            onClick={onNavigateToDocumentation}
          >
            <FontAwesomeIcon
              icon={faBook}
              className="mr-2 p-2 border-circle cursor-pointer"
              style={{
                backgroundColor: "white",
                color: "#7C4DFF",
                width: "20px",
                height: "20px",
              }}
            />
          </span>
          <span
            className="username"
            data-pr-tooltip={`${loggedUser?.username} (${loggedUser?.companyName})`}
          >
            <FontAwesomeIcon
              icon={faUser}
              className="mr-2 p-2 border-circle cursor-pointer"
              style={{
                backgroundColor: "white",
                color: "#7C4DFF",
                width: "20px",
                height: "20px",
              }}
            />
          </span>
          <span onClick={() => setIsLogoutConfirmationModalOpen(true)}>
            <FontAwesomeIcon
              icon={faPowerOff}
              className="mr-2 p-2 border-circle cursor-pointer"
              style={{
                backgroundColor: "white",
                color: "#7C4DFF",
                width: "20px",
                height: "20px",
              }}
            />
          </span>
        </div>
      </div>
    </>
  );
}
