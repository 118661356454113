import { useCallback, useMemo, useState } from "react";
import { BoardWorkTask } from "../../queries/models/board-work-task.model";
import { WorkTaskForm } from "./WorkTaskForm";
import { useInstallationsOptionsQuery } from "../../queries/installations.query";
import { useActiveUsersAsSelectOptionQuery } from "../../queries/users.query";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { CreateOrUpdateWorkTask } from "../../queries/models/create-or-update-work-task";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import { useAddWorkTaskMutation } from "../../queries/work-tasks.query";
import { useUpdateWorkTaskMutation } from "../../queries/work-tasks.query";
import { useDeleteWorkTaskMutation } from "../../queries/work-tasks.query";
import { CustomModal } from "../../components/ui/MobileModal/custom-modal";
import { useGetNoteQuery } from "../../queries/notes.query";
import { Note } from "../../components/ui/Note/Note";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { AddNoteRequest } from "../../queries/models/add-note-request";
import { useAddNoteMutation } from "../../queries/notes.query";
import { Divider } from "primereact/divider";
import { useTranslation } from "react-i18next";

interface Props {
  workingTask: BoardWorkTask;
  setWorkingTask: (workingTask?: BoardWorkTask) => void;
  onCancelEdition: () => void;
  onSaveItemsSequenceChange: (id: number) => void;
}

export function WorkTaskManager({
  workingTask,
  setWorkingTask,
  onCancelEdition,
  onSaveItemsSequenceChange,
}: Props) {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const noteQuery = useGetNoteQuery(workingTask.noteId);
  const usersQuery = useActiveUsersAsSelectOptionQuery();
  const installationsQuery = useInstallationsOptionsQuery();
  const addWorkTaskMutation = useAddWorkTaskMutation();
  const updateWorkTaskMutation = useUpdateWorkTaskMutation();
  const deleteWorkTaskMutation = useDeleteWorkTaskMutation();
  const addNoteMutation = useAddNoteMutation();
  const [deleteConfirmationModalOpen, setDeleteConfirmationModelOpen] =
    useState(false);

  const hasWriteAccess = useMemo(
    () => authService.hasAccess(AppFeatures.WebKanbanBoardWrite),
    []
  );

  const loading = usersQuery.isLoading || installationsQuery.isLoading;

  const noteItems = useMemo(() => {
    return noteQuery.data?.items ?? [];
  }, [noteQuery]);

  const saveNewWorkTask = useCallback(
    async (data: CreateOrUpdateWorkTask) => {
      const mutateOptions = {
        onSuccess: async (res: number) => {
          toast.current?.show({
            severity: "success",
            detail: t("alert.saveSuccess"),
          });
          await queryClient.invalidateQueries(["work-tasks"]);

          onCancelEdition();

          onSaveItemsSequenceChange(res);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      return addWorkTaskMutation.mutateAsync(data, mutateOptions);
    },
    [
      addWorkTaskMutation,
      onCancelEdition,
      onSaveItemsSequenceChange,
      queryClient,
      t,
      toast,
    ]
  );

  const editWorkTask = useCallback(
    async (data: CreateOrUpdateWorkTask) => {
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: t("alert.saveSuccess"),
          });
          await queryClient.invalidateQueries(["work-tasks"]);
          onCancelEdition();
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      return updateWorkTaskMutation.mutateAsync(data, mutateOptions);
    },
    [onCancelEdition, queryClient, t, toast, updateWorkTaskMutation]
  );

  const handleSaveTask = useCallback(
    async (data: CreateOrUpdateWorkTask) =>
      data.id === 0 ? saveNewWorkTask(data) : editWorkTask(data),
    [editWorkTask, saveNewWorkTask]
  );

  const handleDeleteWorkTask = useCallback(async () => {
    if (workingTask.id) {
      const mutateOptions = {
        onSuccess: async () => {
          await queryClient.invalidateQueries("work-tasks");
          toast.current?.show({
            severity: "success",
            detail: t("alert.workTaskSuccessfullyDeleted"),
          });
          onCancelEdition();
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error.data,
          });
        },
      };

      return deleteWorkTaskMutation.mutateAsync(workingTask.id, mutateOptions);
    }
  }, [
    deleteWorkTaskMutation,
    onCancelEdition,
    queryClient,
    t,
    toast,
    workingTask.id,
  ]);

  const handleCreateNewNote = useCallback(
    async (data: AddNoteRequest) => {
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: t("alert.saveSuccess"),
          });
          await queryClient.invalidateQueries("notes");
          await queryClient.invalidateQueries("work-tasks");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error.data,
          });
        },
      };

      const request: AddNoteRequest = {
        noteId: workingTask.noteId,
        message: data.message,
        severity: data.severity,
        isConfidential: data.isConfidential,
        files: data.files,
      };

      await addNoteMutation.mutateAsync(request, mutateOptions);
    },
    [addNoteMutation, queryClient, t, toast, workingTask.noteId]
  );

  return (
    <div className="h-full">
      <CustomModal
        header={t("common.confirmation")}
        body={t("dialog.deleteThisTask")}
        isOpen={deleteConfirmationModalOpen}
        height="max-content"
        width="300px"
        maxWidth="600px"
        confirmation
        centered
        justified
        onClose={() => setDeleteConfirmationModelOpen(false)}
        onConfirm={handleDeleteWorkTask}
      />
      <div className="flex flex-column h-full">
        <div>
          <LoaderWrapper isLoading={loading}>
            <WorkTaskForm
              workTask={workingTask}
              userOptions={usersQuery.data ?? []}
              installationOptions={installationsQuery.data ?? []}
              onCancel={onCancelEdition}
              onSave={handleSaveTask}
              onDelete={() => setDeleteConfirmationModelOpen(true)}
            />
          </LoaderWrapper>
        </div>
        {workingTask.noteId && (
          <div>
            <Divider />
            <Note
              items={noteItems}
              isLoading={noteQuery.isLoading || noteQuery.isFetching}
              onAddNote={handleCreateNewNote}
              addingWithWebCam
              hasWriteAccess={hasWriteAccess}
            />
          </div>
        )}
      </div>
    </div>
  );
}
