import { FloorPlan } from "../../../queries/models/InstallationPlans/floor-plan.model";
import { CreateOrUpdateFloorPlanGeneralInfo } from "../../../queries/models/InstallationPlans/create-or-update-floor-plan-general-info.model";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormikInputText from "../../ui/formik/FormikInputText";
import FormikImageFileUpload from "../../ui/formik/FormikFilesUpload";
import { EditContentHeader } from "../../ui/edit-content-header";
import { PrivateImage } from "../../ui/PrivateImage";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface FloorPlanFormProps {
  floorPlan: FloorPlan;
  installationId: number;
  onSave: (form: CreateOrUpdateFloorPlanGeneralInfo) => Promise<any> | void;
  onDelete: (id: number) => Promise<any> | void;
  onCancel: () => void;
}

export function FloorPlanForm({
  floorPlan,
  installationId,
  onSave,
  onCancel,
  onDelete,
}: FloorPlanFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const initialValues: CreateOrUpdateFloorPlanGeneralInfo = {
    id: floorPlan?.id ?? 0,
    name: floorPlan?.name ?? "",
    description: floorPlan?.description ?? "",
    buildingPlanId: floorPlan?.buildingPlanId ?? 0,
    backgroundImageId: floorPlan?.backgroundImageId ?? 0,
    backgroundImage: undefined,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t("alert.required")),
    description: Yup.string().optional(),
    backgroundImage: floorPlan.backgroundImage
      ? Yup.object().optional()
      : Yup.object().required(t("alert.required")),
  });

  return (
    <div className="h-full overflow-y">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSave}
        enableReinitialize
        validateOnMount
        validateOnChange
      >
        {(formik) => (
          <Form>
            <EditContentHeader
              header={floorPlan.id ? t("common.edit") : t("common.addNewFloorPlan")}
              showDeleteButton={!!floorPlan.id}
              saveButtonDisabled={!formik.dirty || !formik.isValid}
              onSaveClick={formik.submitForm}
              onCancelClick={onCancel}
              onDeleteClick={() => onDelete(floorPlan.id)}
              extraBtn1={
                floorPlan.id && (
                  <Button
                    className="mr-1 w-full"
                    icon="pi pi-pencil"
                    type="button"
                    disabled={!floorPlan.id}
                    label={t("common.board")}
                    onClick={() => {
                      navigate(
                        `/app/building-plan/${floorPlan.id}/${installationId}`
                      );
                    }}
                  />
                )
              }
            />
            <div className="grid w-full">
              <div className="col-12">
                <FormikInputText
                  validationSchema={validationSchema}
                  label={t("common.name")}
                  name="name"
                  isIndependent
                />
                <FormikInputText
                  validationSchema={validationSchema}
                  label={t("common.description")}
                  name="description"
                  isIndependent
                />
                <div className="flex flex-row pt-2">
                  <div
                    className={floorPlan.backgroundImage ? "w-max" : "w-full"}
                  >
                    <FormikImageFileUpload
                      className="w-full"
                      validationSchema={validationSchema}
                      label={t("common.backgroundImage")}
                      name="backgroundImage"
                      multiple={false}
                      previewWidth={300}
                      imageOnly
                    />
                  </div>
                  {floorPlan.backgroundImage?.imageSrc && (
                    <div>
                      Current background image
                      <PrivateImage
                        imageSrc={floorPlan.backgroundImage?.imageSrc}
                        maxWidth="100%"
                        withProgressBar
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
