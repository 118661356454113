import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { Language } from "../queries/models/language.model";
import { SelectItem } from "primereact/selectitem";

class LanguagesService extends BaseService {
  async getAllLanguages(): Promise<Language[]> {
    const url = `${this.endpointPath}`;
    return this.http<Language[]>(url).then((response) => response.data);
  }

  async getAllLanguagesAsSelectOptions(): Promise<SelectItem[]> {
    const url = `${this.endpointPath}/all-as-select-options`;
    return this.http.get<SelectItem[]>(url).then((response) => response.data);
  }

  async getPublishLanguagesAsSelectOptions(): Promise<SelectItem[]> {
    const url = `${this.endpointPath}/published-as-select-options`;
    return this.http.get<SelectItem[]>(url).then((response) => response.data);
  }

  async getAllSelectedLanguages(): Promise<Language[]> {
    const url = `${this.endpointPath}/selected`;
    return this.http.get<Language[]>(url).then((response) => response.data);
  }

  async selectSingleLanguage(id: number) {
    const url = `${this.endpointPath}/select/${id}`;
    return this.http.patch(url).then((response) => response.data);
  }

  async unselectSingleLanguage(id: number) {
    const url = `${this.endpointPath}/unselect/${id}`;
    return this.http.patch(url).then((response) => response.data);
  }

  async publishSingleLanguage(id: number) {
    const url = `${this.endpointPath}/publish/${id}`;
    return this.http.patch(url).then((response) => response.data);
  }

  async unpublishSingleLanguage(id: number) {
    const url = `${this.endpointPath}/unpublish/${id}`;
    return this.http.patch(url).then((response) => response.data);
  }
}

export const languagesService = new LanguagesService(API_ENDPOINTS.LANGUAGES);
