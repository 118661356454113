import {
  Field,
  ErrorMessage,
  FastField,
  FormikContextType,
  FieldInputProps,
  FieldMetaProps,
} from "formik";
import TextError from "./TextError";
import { FormInputLabel } from "./FormInputLabel";
import { classNames } from "primereact/utils";
import { useMemo } from "react";
import { Slider, SliderProps } from "primereact/slider";
import * as Yup from "yup";

export interface FormikSliderProps extends SliderProps {
  label: string;
  name: string;
  validationSchema?: Yup.ObjectSchema<any, Yup.AnyObject, any, "">;
  isIndependent?: boolean;
  onAfterChange?: (value: number | [number, number]) => void;
  nested?: boolean;
}

function FormikSlider({
  label,
  name,
  onAfterChange,
  validationSchema,
  isIndependent = false,
  nested = false,
  min = 10,
  max = 500,
  ...rest
}: FormikSliderProps) {
  const Component = useMemo(() => {
    return isIndependent ? FastField : Field;
  }, [isIndependent]);

  return (
    <>
      <Component name={name}>
        {({
          form,
          field,
          meta,
        }: {
          form: FormikContextType<any>;
          field: FieldInputProps<number | [number, number]>;
          meta: FieldMetaProps<number | [number, number]>;
        }) => {
          return (
            <>
              <FormInputLabel
                nameFor={name}
                validationSchema={validationSchema}
              >
                {label + "(" + field.value + ")"}
              </FormInputLabel>
              <div className="px-2 pt-1">
                <Slider
                  id={name}
                  className={classNames({
                    "w-full": true,
                    "p-invalid": meta.error && meta.touched,
                  })}
                  value={field.value}
                  min={min}
                  max={max}
                  step={1}
                  onBlur={() => form.setFieldTouched(name, true)}
                  onChange={(e) => {
                    form.setFieldValue(name, e.value);
                    if (onAfterChange) {
                      onAfterChange(e.value);
                    }
                  }}
                  {...rest}
                />
              </div>
            </>
          );
        }}
      </Component>
      <ErrorMessage component={TextError} name={name} />
    </>
  );
}

export default FormikSlider;
