export type PinMeasurment = {
  text: string;
  left: number;
  right: number;
  top: number;
  bottom: number;
};

export type DrawPinOptions = {
  fontSize?: number;
  fillingColor?: string;
  fillingOpacity?: number;
  position?: {
    x?: "center" | "left" | "right";
    includePaddingX?: boolean;
    y?: "middle" | "top" | "bottom";
    includePaddingY?: boolean;
  };
};

export function measurePin(
  context: CanvasRenderingContext2D,
  text: string,
  posX: number,
  posY: number,
  options?: DrawPinOptions
): [PinMeasurment, () => void] {
  const o = {
    fontSize: options?.fontSize ?? 24,
    fillingColor: options?.fillingColor ?? "purple",
    fillingOpacity: options?.fillingOpacity ?? 1,
    position: {
      x: options?.position?.x ?? "center",
      y: options?.position?.y ?? "middle",
      includePaddingX: options?.position?.includePaddingX ?? false,
      includePaddingY: options?.position?.includePaddingY ?? false,
    },
  };

  context.save();
  context.font = `800 ${o.fontSize}px Arial`;
  context.textBaseline = o.position.y;
  context.textAlign = o.position.x;
  context.fillStyle = o.fillingColor;
  context.strokeStyle = "black";
  context.lineWidth = o.fontSize / 32;
  const textMeasured = context.measureText(text);
  context.restore();

  const textHeight =
    textMeasured.actualBoundingBoxAscent +
    textMeasured.actualBoundingBoxDescent;

  const naturalPaddingX = textHeight / 3;
  const naturalPaddingY = textHeight / 4;
  const paddingX = naturalPaddingX;
  const paddingY = naturalPaddingY;

  function getPaddingCoefficient(
    include: boolean,
    position: typeof o.position.x | typeof o.position.y
  ) {
    if (!include) return 0;
    if (position === "center" || position === "middle") return 0;
    if (position === "left" || position === "top") return 1;
    if (position === "right" || position === "bottom") return -1;
    return 0;
  }

  const textX =
    posX +
    getPaddingCoefficient(o.position.includePaddingX, o.position.x) * paddingX;
  const textY =
    posY +
    getPaddingCoefficient(o.position.includePaddingY, o.position.y) * paddingY;

  const currentBox: PinMeasurment = {
    text: text,
    left: textX - paddingX - textMeasured.actualBoundingBoxLeft,
    top: textY - paddingY - textMeasured.actualBoundingBoxAscent,
    right: textX + paddingX + textMeasured.actualBoundingBoxRight,
    bottom: textY + paddingY + textMeasured.actualBoundingBoxDescent,
  };

  function draw() {
    context.save(); //whole pin
    context.font = `800 ${o.fontSize}px Arial`;
    context.textBaseline = o.position.y;
    context.textAlign = o.position.x;
    context.fillStyle = o.fillingColor;
    context.strokeStyle = "black";
    context.lineWidth = o.fontSize / 32;

    context.save(); //box
    context.globalAlpha = o.fillingOpacity;
    context.beginPath(); //box
    context.roundRect(
      currentBox.left,
      currentBox.top,
      currentBox.right - currentBox.left,
      currentBox.bottom - currentBox.top,
      10000
    );
    context.fill();
    context.restore(); //box
    context.stroke();
    context.closePath(); //box

    context.fillStyle = "white";
    context.strokeStyle = "black";
    context.lineWidth = o.fontSize / 24;

    context.beginPath(); //text
    context.fillText(text, textX, textY);
    context.strokeText(text, textX, textY);
    context.closePath(); //text

    context.restore(); //whole pin
  }

  return [currentBox, draw];
}
