import { Dialog } from "primereact/dialog";
import { AttachedPin } from "../../ui/DragDropPlan";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { fileService } from "../../../services/image.service";
import { Button } from "primereact/button";
import { ToggleButton } from "primereact/togglebutton";
import { FileExtension } from "../../../utils/file-extensions.ts";
import {
  FloorplanImageSettings,
  generateFloorplanImage,
} from "./FloorplanAsImageGenerator";
import { ExportSettingsConfiguration } from "./ExportSettingsConfiguration";
import { PinMeasurment } from "./PinGenerator";
import { useTranslation } from "react-i18next";

function getOverlappingBoxes(
  boxes: PinMeasurment[]
): Map<PinMeasurment, PinMeasurment[]> {
  const result = new Map();

  for (let i = boxes.length - 1; i >= 0; --i) {
    const a = boxes[i];
    const overlapping: PinMeasurment[] = [];
    for (let j = i - 1; j >= 0; --j) {
      const b = boxes[j];
      if (
        a.left < b.right &&
        b.left < a.right &&
        a.top < b.bottom &&
        b.top < a.bottom
      ) {
        overlapping.push(b);
      }
    }
    if (overlapping.length) result.set(a, overlapping);
  }

  return result;
}

export interface ExportFloorplanDialogProps {
  data?: {
    name: string;
    imageSrc: string;
    pins: AttachedPin[];
  };
  onHide: () => void;
  legendDescription: (pin: AttachedPin) => string | undefined;
  draggable?: boolean;
}

export const ExportFloorplanDialog = memo(function ({
  data,
  onHide,
  legendDescription,
  draggable = false,
}: ExportFloorplanDialogProps) {
  const { t } = useTranslation();
  const lastAnimationFrameRef = useRef(0);
  const [exportSettings, setExportSettings] = useState<FloorplanImageSettings>({
    imageMargin: 50,
    textFontSize: 24,
    colorFillingOpacity: 1,
    imageScale: 1,
  });

  const [fullPreview, setFullPreview] = useState(true);
  const [overlappingBoxes, setOverlappingBoxes] =
    useState<Map<PinMeasurment, PinMeasurment[]>>();
  const overlappingMessage = useMemo(() => {
    if (!overlappingBoxes?.size) return null;
    let message = "";
    overlappingBoxes.forEach(
      (value, key) =>
        (message += `"${key.text}" might overlap with "${value
          .map((x) => x.text)
          .join('", "')}"\n`)
    );
    return message;
  }, [overlappingBoxes]);

  const legendDescriptions = useMemo(() => {
    const result = new Map<AttachedPin, string>();
    data?.pins.forEach((x) => {
      const description = legendDescription(x);
      if (description) result.set(x, description);
    });
    return result;
  }, [legendDescription, data]);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const imageBitmap = useMemo(async () => {
    if (!data?.imageSrc) return null;
    const imageBlob = (await fileService.getImage(data.imageSrc)).data;
    const bitmap = await createImageBitmap(imageBlob);
    return bitmap;
  }, [data?.imageSrc]);

  useEffect(() => {
    if (!data) return;
    (async () => {
      const bitmap = await Promise.resolve(imageBitmap);
      if (!bitmap) return;
      if (!canvasRef.current) {
        await new Promise((r) => setTimeout(r, 100));
        if (!canvasRef.current) return;
      }

      if (lastAnimationFrameRef.current)
        cancelAnimationFrame(lastAnimationFrameRef.current);
      lastAnimationFrameRef.current = requestAnimationFrame(() => {
        if (!canvasRef.current) return;

        const boxes = generateFloorplanImage(
          canvasRef.current,
          bitmap,
          data.pins,
          legendDescriptions,
          exportSettings,
          fullPreview ? "resizeXY" : "resizeX"
        );
        setTimeout(() => {
          const overlappingBoxes = getOverlappingBoxes(boxes);
          setOverlappingBoxes(overlappingBoxes);
        }, 0);
      });
    })();
  }, [data, fullPreview, imageBitmap, exportSettings, legendDescriptions]);

  const downloadImage = useCallback(async () => {
    if (!data) return;
    const bitmap = await Promise.resolve(imageBitmap);
    if (!bitmap) return;

    const tempCanvas = document.createElement("canvas");

    generateFloorplanImage(
      tempCanvas,
      bitmap,
      data.pins,
      legendDescriptions,
      exportSettings,
      "resizeXY"
    );

    await new Promise((resolve, reject) => {
      tempCanvas.toBlob(
        (x) => {
          FileExtension.downloadBlob(
            x,
            `${data.name}_${new Date().toISOString()}.jpg`
          );
          x ? resolve(x) : reject();
        },
        "image/jpg",
        1
      );
    });
  }, [data, imageBitmap, legendDescriptions, exportSettings]);

  const [isDownloading, setIsDownloading] = useState(false);
  const onDownloadClick = useCallback(() => {
    setIsDownloading(true);
    downloadImage().finally(() => {
      setIsDownloading(false);
    });
  }, [downloadImage]);

  return (
    <Dialog
      header={t("common.export")}
      style={{ width: "95%" }}
      visible={!!data}
      onHide={onHide}
      draggable={draggable}
    >
      <div>
        <div>Options:</div>
        <div className="grid m-0 p-0 gap-2 justify-items-end">
          <ExportSettingsConfiguration
            settings={exportSettings}
            setSettings={setExportSettings}
          />
        </div>

        <div className="flex flex-row my-2 w-full">
          <Button
            label={t("common.download")}
            icon="pi pi-download"
            onClick={onDownloadClick}
            loading={isDownloading}
          />
          <div className="flex-1" />
          <div className="mr-3 flex align-items-center">Preview:</div>
          <ToggleButton
            checked={fullPreview}
            onChange={(e) => setFullPreview(e.value)}
            onLabel={t("common.full")}
            offLabel={t("common.small")}
          />
        </div>

        {overlappingMessage && (
          <pre className="text-red-400 text-bold text-lg">
            {overlappingMessage}
          </pre>
        )}

        <div
          className="flex w-full overflow-auto"
          style={{ maxHeight: 502 }}
        >
          <canvas
            className="border-1 h-full"
            ref={canvasRef}
            width={fullPreview ? undefined : 500}
            height={fullPreview ? undefined : 500}
          ></canvas>
        </div>
      </div>
    </Dialog>
  );
});
