import { useQuery, useMutation } from "react-query";
import { tagsService } from "../services/tags.service";
import { CreateOrUpdateTagRequest } from "./models/create-or-update-tag-request.model";

export function useTagsQuery() {
    return useQuery(
        ["tags"],
        () => tagsService.getAll()
    )
}

export function useActiveTagsQuery() {
    return useQuery(
        ["tags"],
        () => tagsService.getAllActive()
    )
}

export function useAddTagMutation() {
    return useMutation((request: CreateOrUpdateTagRequest) => tagsService.addTag(request));
}

export function useUpdateTagMutation() {
    return useMutation((request: CreateOrUpdateTagRequest) => tagsService.updateTag(request));
}

export function useDeleteTagMutation() {
    return useMutation((id: number) => tagsService.deleteTag(id));
}