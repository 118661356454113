import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenRuler } from "@fortawesome/free-solid-svg-icons";
import { SpinnerLoaderWrapper } from "../ui/SpinnerLoaderWrapper";
import { FloorPlan } from "../../queries/models/InstallationPlans/floor-plan.model";
import { AttachedPin, DragDropContainer } from "../ui/DragDropPlan";
import { useCallback, useMemo, useState } from "react";
import { selectAssetIcon } from "../../utils/fontAwesomeIcons";
import { Button } from "primereact/button";
import { ChangesController } from "../../hooks/useStateWithChanges";
import {
  ExportFloorplanDialog,
  ExportFloorplanDialogProps,
} from "./floorplan-image-generator/ExportFloorplanDialog";
import { useTranslation } from "react-i18next";

interface PlanBoardProps {
  isLoading: boolean;
  floorPlan: FloorPlan | undefined;
  selectedItemsGuids: string[];
  setSelectedItemsGuids: (guids: string[]) => void;
  onFloorPlanChange?: (newFloorPlan: FloorPlan) => void;
  onUnattachPin?: (pin: AttachedPin) => void;
  onClickPin?: (pin: AttachedPin) => void;
  changesController?: ChangesController<FloorPlan | undefined>;
}

export function PlanBoard({
  isLoading,
  floorPlan,
  selectedItemsGuids,
  setSelectedItemsGuids,
  onFloorPlanChange,
  onUnattachPin,
  onClickPin,
  changesController,
}: PlanBoardProps) {
  const { t } = useTranslation();
  const pins: AttachedPin[] | undefined = useMemo(() => {
    return floorPlan?.floorPlanItems
      ?.filter(
        (item) =>
          item.isAttached && selectedItemsGuids.some((x) => x === item.guid)
      )
      .map(
        (item) =>
          ({
            id: (item.id || item.guid) ?? 0,
            name: item.name,
            x: item.xPosition,
            y: item.yPosition,
            color: `#${item.color}`,
            codes: [item.asset?.codes?.at(-1)?.code]
              .filter((x) => x)
              .map((x) => x!),
            unattached: false,
            guid: item.guid,
            asText: !item.assetId,
            icon: selectAssetIcon(item.asset),
          } satisfies AttachedPin)
      );
  }, [floorPlan, selectedItemsGuids]);

  const onPinChange = useCallback(
    (newPin: AttachedPin) => {
      if (!floorPlan) return;
      const items = [...(floorPlan.floorPlanItems ?? [])];

      const itemToUpdate =
        items.find((x) => x.guid === newPin.guid) ??
        items.find((x) => x.id === newPin.id);
      if (!itemToUpdate) return;

      itemToUpdate.xPosition = newPin.x;
      itemToUpdate.yPosition = newPin.y;
      itemToUpdate.isAttached = !newPin.unattached;

      onFloorPlanChange?.({
        ...floorPlan,
        floorPlanItems: items,
      });

      if (newPin.guid) {
        setSelectedItemsGuids([...selectedItemsGuids, newPin.guid]);
      }
    },
    [floorPlan, onFloorPlanChange, selectedItemsGuids, setSelectedItemsGuids]
  );

  const [exportDialog, setExportDialog] =
    useState<ExportFloorplanDialogProps["data"]>();
  const exportDialogOnHide = useCallback(() => setExportDialog(undefined), []);
  const legendDescriptionGenerator = useCallback(
    (pin: AttachedPin) => {
      const matchedItem = floorPlan?.floorPlanItems?.find(
        (x) => x.guid === pin.guid
      );
      if (!matchedItem) return "";
      let result = [];
      if (!pin.asText) result.push(matchedItem.name);
      //if (matchedItem.asset) result.push(matchedItem.asset.name);
      if (matchedItem.additionalDescription)
        result.push(matchedItem.additionalDescription);

      if (!result.length) return undefined;
      return "- " + result.join(" - ");
    },
    [floorPlan]
  );

  return (
    <div className="h-full">
      <section className="height-full">
        <div className="flex flex-row justify-content-between w-full">
          <div className="section-header flex align-items-center gap-2">
            <FontAwesomeIcon icon={faPenRuler} />
            <div>{t("common.floorPlanBoard")}</div>
          </div>
          {changesController && (
            <div className="mr-1">
              <Button
                className="mr-3"
                onClick={async () => {
                  if (!floorPlan?.backgroundImage?.imageSrc) return;
                  setExportDialog({
                    imageSrc: floorPlan.backgroundImage.imageSrc as string,
                    pins: [...(pins ?? [])].sort(
                      (a, b) => a.y - b.y || a.x - b.x
                    ),
                    name: floorPlan.name,
                  });
                }}
                icon="pi pi-download"
                severity="info"
              />
              <Button
                className="mr-1"
                onClick={changesController.undo}
                disabled={!changesController.canUndo()}
                icon="pi pi-undo"
                severity="help"
              />
              <Button
                className="mr-1"
                onClick={changesController.redo}
                disabled={!changesController.canRedo()}
                icon="pi pi-refresh"
                severity="help"
              />
            </div>
          )}
        </div>
        <div className="section-content">
          {!isLoading ? (
            <div className="h-full p-0 m-0 border-round-xl">
              {floorPlan?.backgroundImage?.imageSrc && (
                <DragDropContainer
                  imageSrc={floorPlan.backgroundImage?.imageSrc as string}
                  pins={pins}
                  onPinChange={onPinChange}
                  onPinOutsideDrop={onUnattachPin}
                  onClickPin={onClickPin}
                />
              )}
            </div>
          ) : (
            <SpinnerLoaderWrapper />
          )}
        </div>
      </section>
      <ExportFloorplanDialog
        onHide={exportDialogOnHide}
        data={exportDialog}
        legendDescription={legendDescriptionGenerator}
      />
    </div>
  );
}
