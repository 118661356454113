
export class DateUtils {
    public static checkIfDayIsInWeekend(date: Date): boolean {
        const number = this.getNumberOfDayInWeek(date);
        if (number === 5 || number === 6) {
            return true;
        }
        return false;
    }
    public static checkIfDayIsNumberInWeek(date: Date, dayNumber: number): boolean {
        if (this.getNumberOfDayInWeek(date) === dayNumber) {
            return true;
        }
        return false;
    }

    public static getNumberOfDayInWeek(day: Date): number | undefined {
        if (!day) {
            return undefined;
        }
        const week = [6, 0, 1, 2, 3, 4, 5];
        return week[day.getDay()];
    }
    public static getNumberOfDaysInMonth(date: Date): number {
      return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    }
}
