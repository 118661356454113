export const API_ENDPOINTS: Record<string, string> = {
  AUTHENTICATION: "auth",
  APP_SETTINGS: "web/app-settings",
  DASHBOARD: "web/dashboard",
  TAGS: "web/tags",
  ASSET_TYPES: "web/asset-types",
  PLANNING: "web/planning",
  WORK_ENTRIES: "web/work-entries",
  ADDRESSES: "web/addresses",
  ASSETS: "web/assets",
  ORDERS_VALIDATOR: "web/orders-validator",
  RERPORTING: "web/reporting",
  USERS: "web/users",
  ROLES: "web/roles",
  SUMMARY: "summary",
  GEOLOCATION: "web/geolocation",
  INSTALLATIONS: "web/installations",
  COUNTRIES: "web/countries",
  WAREHOUSES: "web/warehouses",
  NOTES: "web/notes",
  INSTALLATION_HISTORY: "web/installations-history",
  WORK_INSTANCES: "web/work-instances",
  WORK_EVENTS: "web/work-events",
  LANGUAGES: "localization/languages",
  TRANSLATIONS: "translations/translations",
  INSTALLATION_TASKS: "web/installation-tasks",
  BUILDING_PLANS: "web/building-plans",
  FLOOR_PLANS: "web/floor-plans",
  FLOOR_PLAN_ITEMS: "web/floor-plan-items",
  MAINTENANCE: "web/maintenance",
  COMPANIES: "web/companies",
  WORKING_TASKS: "web/work-tasks-board",
};
