import { useCallback, useState, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useUsersForUserScreenQuery } from "../../queries/users.query";
import { RoundedShadowContainer } from "../../components/ui/rounded-shadow-container";
import { useUserQuery } from "../../queries/users.query";
import { useEditUserMutation } from "../../queries/users.query";
import { useAddUserMutation } from "../../queries/users.query";
import { useDeleteUserMutation } from "../../queries/users.query";
import { useRoleOptionsQuery } from "../../queries/roles.query";
import { LoaderWrapper } from "../../components/ui/LoaderWrapper";
import { UsersTableOverview } from "./UsersTableOverview";
import { User } from "../../queries/models/user.model";
import { AddOrEditUser } from "../../queries/models/form-user.model";
import { UpdateUserRequest } from "../../queries/models/update-user-request";
import { CreateUserRequest } from "../../queries/models/create-user-request.model";
import { UserForm } from "./UserForm";
import { useToast } from "../../components/ui/toast-context-provider";
import { useQueryClient } from "react-query";
import {
  CustomModal,
  CustomModalProps,
} from "../../components/ui/MobileModal/custom-modal";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { useTranslation } from "react-i18next";

export function Users() {
  const { t } = useTranslation();
  const toast = useToast();
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [editUserDetails, setEditUserDetails] = useState<User | undefined>(
    undefined
  );
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);

  const queryClient = useQueryClient();
  const usersQuery = useUsersForUserScreenQuery();
  const userQuery = useUserQuery(selectedUser?.id);
  const rolesQuery = useRoleOptionsQuery();
  const editUserMutation = useEditUserMutation();
  const addUserMutation = useAddUserMutation();
  const deleteUserMutation = useDeleteUserMutation();

  const itemsLoading: boolean = usersQuery.isFetching || rolesQuery.isFetching;
  const userLoading: boolean = userQuery.isLoading;

  const hasWriteAccess = useMemo(
    () => authService.hasAccess(AppFeatures.WebUsersWrite),
    []
  );

  const handleAddNewUser = useCallback(() => {
    setEditUserDetails({ contactName: "" } as User);
  }, []);

  useEffect(() => {
    setEditUserDetails(userQuery.data);
  }, [userQuery.data]);

  const saveNewUser = useCallback(
    (data: AddOrEditUser) => {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedUser(undefined);
          setEditUserDetails(undefined);
          toast.current?.show({
            severity: "success",
            detail: t("alert.saveSuccess"),
          });
          await queryClient.invalidateQueries("users");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: CreateUserRequest = {
        contactName: data.contactName,
        contactPhone: data.contactPhone,
        contactEmail: data.contactEmail,
        password: data.password ?? "",
        username: data.username,
        roleId: data.roleId,
        defaultLanguage: data.defaultLanguage,
        assignedInstallationIds: data.assignedInstallationIds ?? [],
        includePauseTimeForInvoicing: data.includePauseTimeForInvoicing,
        billingInfo_BillToName: data.billingInfo_BillToName,
        billingInfo_City: data.billingInfo_City,
        billingInfo_Email: data.billingInfo_Email,
        billingInfo_Phone: data.billingInfo_Phone,
        billingInfo_PostalCode: data.billingInfo_PostalCode,
        billingInfo_Street: data.billingInfo_Street,
        billingInfo_VatEu: data.billingInfo_VatEu,
      };

      return addUserMutation.mutateAsync(request, mutateOptions);
    },
    [addUserMutation, queryClient, t, toast]
  );

  const editExistingUser = useCallback(
    (data: AddOrEditUser) => {
      const mutateOptions = {
        onSuccess: async () => {
          setSelectedUser(undefined);
          setEditUserDetails(undefined);
          toast.current?.show({
            severity: "success",
            detail: t("alert.editSuccess"),
          });
          await queryClient.invalidateQueries("users");
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };

      const request: UpdateUserRequest = {
        id: data.id,
        contactName: data.contactName,
        contactPhone: data.contactPhone,
        contactEmail: data.contactEmail,
        defaultLanguage: data.defaultLanguage,
        password: data.password ?? "",
        username: data.username,
        roleId: data.roleId,
        assignedInstallationIds: data.assignedInstallationIds ?? [],
        includePauseTimeForInvoicing: data.includePauseTimeForInvoicing,
        billingInfo_BillToName: data.billingInfo_BillToName,
        billingInfo_City: data.billingInfo_City,
        billingInfo_Email: data.billingInfo_Email,
        billingInfo_Phone: data.billingInfo_Phone,
        billingInfo_PostalCode: data.billingInfo_PostalCode,
        billingInfo_Street: data.billingInfo_Street,
        billingInfo_VatEu: data.billingInfo_VatEu,
      };

      return editUserMutation.mutateAsync(request, mutateOptions);
    },
    [editUserMutation, queryClient, t, toast]
  );

  const handleSaveUser = useCallback(
    (data: AddOrEditUser) => {
      return data.id === 0 ? saveNewUser(data) : editExistingUser(data);
    },
    [editExistingUser, saveNewUser]
  );

  const handleDeleteUser = useCallback(async () => {
    if (selectedUser?.id) {
      const mutateOptions = {
        onSuccess: async () => {
          await queryClient.invalidateQueries("users");
          toast.current?.show({
            severity: "success",
            detail: t("alert.userSuccessfullyDeleted"),
          });
          setSelectedUser(undefined);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error?.data,
          });
        },
      };
      setIsDeleteConfirmationModalOpen(false);
      return deleteUserMutation.mutateAsync(selectedUser.id, mutateOptions);
    }
  }, [deleteUserMutation, queryClient, selectedUser?.id, t, toast]);

  const deleteConfirmationModalProps: CustomModalProps = useMemo(() => {
    return {
      header: t("common.confirmation"),
      body: t("dialog.deleteThisUser"),
      height: "160px",
      isOpen: isDeleteConfirmationModalOpen,
      confirmation: true,
      centered: true,
      justified: true,
      onClose: () => setIsDeleteConfirmationModalOpen(false),
      onConfirm: () => handleDeleteUser(),
    };
  }, [handleDeleteUser, isDeleteConfirmationModalOpen, t]);

  return (
    <div className="flex gap-2 p-1 h-full">
      <CustomModal {...deleteConfirmationModalProps} />
      <div className="w-7 h-full">
        <RoundedShadowContainer
          small
          fullHeight
        >
          <LoaderWrapper isLoading={itemsLoading}>
            <UsersTableOverview
              items={usersQuery.data ?? []}
              roleItems={rolesQuery.data ?? []}
              onAddNewUser={handleAddNewUser}
              selectedUser={selectedUser}
              onSelectUser={setSelectedUser}
              hasWriteAccess={hasWriteAccess}
            />
          </LoaderWrapper>
        </RoundedShadowContainer>
      </div>
      <div className="w-5 h-full">
        <RoundedShadowContainer
          small
          fullHeight
        >
          <LoaderWrapper isLoading={userLoading}>
            {editUserDetails ? (
              <UserForm
                user={editUserDetails}
                onSave={handleSaveUser}
                onCancel={() => setEditUserDetails(undefined)}
                onDelete={() => setIsDeleteConfirmationModalOpen(true)}
                hasWriteAccess={hasWriteAccess}
              />
            ) : (
              <>
                {!userLoading && (
                  <div className="h-full flex align-items-center justify-content-center">
                    <FontAwesomeIcon
                      icon={faUserPlus}
                      style={{ fontSize: 270 }}
                      color="#7C4DFF"
                      cursor="pointer"
                      onClick={handleAddNewUser}
                    />
                  </div>
                )}
              </>
            )}
          </LoaderWrapper>
        </RoundedShadowContainer>
      </div>
    </div>
  );
}
