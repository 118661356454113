import { BaseService } from "./base.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { AppSettings } from "../queries/models/app-settings.model";
import { UpdateAppSettings } from "../queries/models/update-app-settings.model";

class AppSettingsService extends BaseService {

    async getNextQr(): Promise<string> {
        const url = `${this.endpointPath}/next-qr`;
        return this.http.get<string>(url).then((response) => response.data);
    }
    async getNextQrs(count: number): Promise<string[]> {
        const url = `${this.endpointPath}/next-qrs/${count}`;
        return this.http.get<string[]>(url).then((response) => response.data);
    }
    async getAppSettings(): Promise<AppSettings> {
        const url = `${this.endpointPath}`;
        return this.http.get<AppSettings>(url).then((response) => response.data);
    }
    async editAppSettings(request: UpdateAppSettings) {
        const url = `${this.endpointPath}`;
        return this.http.put<AppSettings>(url, request).then((response) => response.data);
    }
}

export const appSettingsService = new AppSettingsService(API_ENDPOINTS.APP_SETTINGS);